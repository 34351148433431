export const MainSystemActionTypes = {
    SET_REMOTE_SYSTEM_INFO: "SET_REMOTE_SYSTEM_INFO",
}

export const KepsSystemActionTypes = {
    SET_SHORT_CODES: "SET_SHORT_CODES"
}

export const SanalSinavSystemActionTypes = {
    SET_SANAL_SINAV_BILGISI: "SET_SANAL_SINAV_BILGISI"
}