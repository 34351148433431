import React from 'react';
import { sinavApi, errorMessage, errorMessageWithKonum } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Selection, Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
export default class RolIslemleri extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roller: [],
            popup: false,
            ekranlar: [],
            loadingText: "",
            toolbarDisabled: false
        };
        this.popupForm = null;
        this.mainDataGrid = null;
        this.dataGridEkran = null;
        this.secilenEkranlar = [];
        this.satir = null;
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.ekranSecimiChanged = this.ekranSecimiChanged.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.popupGizle = () => {
            this.secilenEkranlar = [];
            this.setState({
                popup: false, loadingText: "",
                toolbarDisabled: false
            });
            this.satirIptal();
        };
        this.refreshData();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(

            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh', disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.refreshData();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa', disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    async popupAc() {
        this.setState({
            popup: true,
            toolbarDisabled: true
        })
    }

    async roller() {

        return new Promise((resolve, reject) => {

            service.get(sinavApi + "rol/getRoller",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            roller: res.data.Data
                        });
                        resolve("Rol bilgileri getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "rol bilgileri"));
                });

        });
    }

    async ekranlar() {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "rol/getEkranlar",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ekranlar: res.data.Data
                        });
                        resolve("ekran bilgileri getirildi");

                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(errorMessage(error, "ekran bilgileri"));
                });

        })
    }

    async refreshData() {
        this.setState({
            loadingText: "rol bilgileri getiriliyor...",
            toolbarDisabled: true
        })
        let hataMesajlari = "";
        let isError = false;

        await this.roller().then(res => { }).catch(error => {
            hataMesajlari += error + "<br/>";
            isError = true;
        });

        await this.ekranlar().then(res => { }).catch(error => {
            hataMesajlari += error + "<br/>";
            isError = true;
        });

        await this.satirIptal();

        this.setState({
            loadingText: "",
            toolbarDisabled: false
        });
        if (isError) {
            alert(hataMesajlari);
        }
    }

    async duzenle(rolId) {
        await this.getSecilenRol(rolId)
            .then(res => {
                this.popupAc();
            })
            .catch(error => {
                alert(error);
            });
    }

    async getSecilenRol(rolId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "rol/getRolEkranByRolId/" + rolId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        let arrayList = [];
                        res.data.Data.forEach((item, index) => {
                            arrayList.push(item.Ekran.Id)
                        });
                        this.dataGridEkran.instance.selectRows(arrayList);
                        resolve("rol bilgisi getirildi");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 500);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "seçilen rol bilgisi"));
                });
        });
    }

    async componentWillUnmount() {
    }

    duzenleClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.duzenle(satir.Id);
        }
    }

    ekranSecimiChanged() {
        this.secilenEkranlar = this.dataGridEkran.instance.getSelectedRowsData();
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }

    //seçilen satırı iptal etmek için kullanıyoruz
    async satirIptal() {
        this.mainDataGrid.instance.clearSelection();
        this.mainDataGrid.instance.option("focusedRowIndex", -1);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.roller}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            keyExpr="Id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onSelectionChanged={this.onSelectionChanged}
                            onInitialized={this.onInitialized}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Roller'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                            <Column dataField={'Adi'} caption="Rol" />
                        </DataGrid>
                        <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                            close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                            onHiding={this.popupGizle} visible={this.state.popup} width={"100%"} height={"100%"}
                            toolbarItems={
                                [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                            , onClick: (e) => {
                                                var data = {
                                                    Ekranlar: this.secilenEkranlar
                                                }
                                                this.setState({
                                                    loadingText: "Rol bilgisi kaydediliyor...",
                                                    toolbarDisabled: true
                                                });
                                                service.post(sinavApi + 'Rol/RolEkranSave/' + this.satir.Id,
                                                    data,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    }
                                                )
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            this.popupGizle();
                                                        }
                                                        else {
                                                            this.setState({
                                                                loadingText: "",
                                                                toolbarDisabled: false
                                                            })
                                                            notify(res.data.ErrorMessage, 'error', 5000);
                                                        }
                                                    }).catch(error => {
                                                        this.setState({
                                                            loadingText: "",
                                                            toolbarDisabled: false
                                                        })
                                                        alert(errorMessage(error));
                                                    })
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                            , onClick: () => { this.popupGizle(); }
                                        }
                                    }

                                ]
                            }
                        >
                            <Position my={'center'} at={'center'} of={window} />
                            <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form formData={this.formData}>
                                            <GroupItem cssClass="first-group" colCount={1} caption="Ekranlar">
                                                <SimpleItem>
                                                    <DataGrid
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        dataSource={this.state.ekranlar}
                                                        columnHidingEnabled={true}
                                                        filterPanel={{ visible: true }}
                                                        filterRow={{ visible: true }}
                                                        columnAutoWidth={true}
                                                        columnFixing={true}
                                                        autoNavigateToFocusedRow={true}
                                                        focusedRowEnabled={true}
                                                        hoverStateEnabled={true}

                                                        onSelectionChanged={this.ekranSecimiChanged}
                                                        keyExpr="Id"
                                                        paging={{
                                                            pageSize: 20
                                                        }}
                                                        pager={{
                                                            showPageSizeSelector: true,
                                                            allowedPageSizes: [5, 10, 50, 75, 100],
                                                            showInfo: true
                                                        }}
                                                        ref={(ref) => this.dataGridEkran = ref}
                                                        width="100%"
                                                    >

                                                        <Paging defaultPageSize={15} />
                                                        <Selection mode="multiple"
                                                            selectAllMode="allPages"
                                                            showCheckBoxesMode="onClick" />
                                                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                        <Column dataField={'Id'} caption="Id" visible={false} />
                                                        <Column dataField={'Modul'} caption="Modül" hidingPriority="1" />
                                                        <Column dataField={'Href'} caption="Href" hidingPriority="2" />
                                                        <Column dataField={'Adi'} caption="Ekran" hidingPriority="3" />
                                                        <Column dataField={'Aciklama'} caption="Açıklama" hidingPriority="4" />

                                                    </DataGrid>
                                                </SimpleItem>
                                            </GroupItem>
                                        </Form>
                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>

                    </div>
                </div>
            </React.Fragment>
        );


    }


}