import * as React from "react";
import Globals from '../../../Globals';
import service from 'axios';
import { sinavApi, otomasyonApi, GetStore, isEmpty, authApi } from '../../../Util';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { MasterDetail, Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, ButtonItem } from 'devextreme-react/form';
import DataGrid, {
    Export, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, RequiredRule
} from 'devextreme-react/data-grid';
import { Popover } from 'devextreme-react/popover';
import jquery from 'jquery';
import auth from "../../../Auth";
import '../../OtomasyonModulu/toolbarButtons.css';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import OgrenciDetail from "./OgrenciDetail"; class OgrenciKayit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            odemePlaniVisible: false,
            odemePlaniDetayVisible: false,
            tahsilatVisible: false,
            gorusmeVisible: false,
            subeDegisimiVisible: false,
            danismanDegisimiVisible: false,
            normalSmsVisible: false,
            secilenOgrenciNormalSmsVisible: false,
            secilenOgrenciBelgeVisible: false,
            gorusmeBaslik: "",
            OgrenciId: 0,
            toolBarDisabled: false,
            ogrenciAktarVisible: false,
            ogrenciDonemBilgiVisible: false,
            smsRaporVisible: false,
            smsSinavVisible: false,
            makbuzVisible: false,
            tahsilatDetayVisible: false,
            devamsizlikRaporVisible: false,
            selectedOgrenciRowKeys: []
        }
        this.gridSmsRapor = null;
        this.gridTaksitTahsilat = null;
        this.normalSmsMesajTextBox = null;
        this.normalSmsSecilenOgrenciMesajTextBox = null;
        this.filterEditor = null;
        this.selectBoxSinifSube = null;
        this.selectBoxSinifSeviye = null;
        this.selectBoxKayitTuru = null;
        this.selectBoxYayin = null;
        this.selectBoxSinavTuru = null;
        this.selectBoxMerkeziSinav = null;
        this.selectBoxSubeDegisimiSinifSube = null;
        this.gridMain = null;
        this.gridTaksit = null;
        this.gridOgrenciDonemBilgi = null;
        this.grdiGorusmeNotu = null;
        this.gridDevamsizlikAltRapor = null;
        this.gridDevamsizlikUstRapor = null;
        this.formMain = null;
        this.formOdemePlani = null;
        this.popupOdemePlani = null;
        this.formOdemePlaniDetay = null;
        this.formGorusme = null;
        this.formSubeDegisimi = null;
        this.formDanismanDegisimi = null;
        this.formNormalSms = null;
        this.formSecilenOgrenciNormalSms = null;
        this.formOgrenciAktar = null;
        this.formSinavSms = null;

        this.popupOdemePlaniDetay = null;
        this.formTahsilat = null;
        this.formMakbuz = null;
        this.satirIndex = null;
        this.satir = null;
        this.satirTaksit = null;
        this.secilenOgrenciler = [];
        this.satirGorusmeNotu = null;
        this.satirTaksitTahsilat = null;
        this.textBoxKalanTutar = null;
        this.textBoxIlkTaksitTarihi = null;
        this.btnOgrenciNoVer = null;
        this.toolTipKesinKayitYap = null;
        this.toolTipGorusmeler = null;
        this.toolTipSubeDegistir = null;
        this.toolTipDanismanDegistir = null;
        this.toolTipKayitListesiExcel = null;
        this.toolTipKayitSozlesmesi = null;
        this.toolTipOdemePlaniGoster = null;
        this.toolTipOdemePlaniOlustur = null;
        this.toolTipYeniOgrenciEkle = null;
        this.toolTipOgrenciDuzenle = null;
        this.toolTipOgrenciSil = null;
        this.toolTipDanismanListesi = null;
        this.toolTipYoklamaListesi = null;
        this.toolTipDevamsizlikListesi = null;
        this.toolTipOgrenciNoVer = null;
        this.toolTipYenidenOdemePlaniOlustur = null;
        this.toolTipTekSenet = null;
        this.toolTipSenet = null;
        this.toolTipTahsilatYap = null;
        this.toolTipTahsilatSil = null;
        this.toolTipMakbuz = null;
        this.toolTipOgrenciEtutListesi = null;
        this.toolTipOgrenciOdevListesi = null;
        this.toolTipNormalSms = null;
        this.toolTipSecilenOgrenciBelge = null;
        this.toolGecisYap = null;
        this.toolTipSecilenOgrenciNormalSms = null;
        this.toolTipSecilenOgrenciBelge = null;
        this.toolTipOgrenciAktar = null;
        this.toolTipOgrenciDonemBilgi = null;
        this.toolTipSmsGonderimBilgi = null;
        this.toolTipSeciliOgrenciSinavSms = null;
        this.smsSecilenOgrenciSinavMesajTextBox = null;
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onToolbarPreparingGridTaksit = this.onToolbarPreparingGridTaksit.bind(this);
        this.onToolbarPreparingGridGorusmeNotu = this.onToolbarPreparingGridGorusmeNotu.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onInitializedGridTaksit = this.onInitializedGridTaksit.bind(this);
        this.onInitializedGridGorusmeNotu = this.onInitializedGridGorusmeNotu.bind(this);
        this.onSelectionChangedGridTaksit = this.onSelectionChangedGridTaksit.bind(this);
        this.onSelectionChangedGridGorusmeNotu = this.onSelectionChangedGridGorusmeNotu.bind(this);
        this.onInitializedPopupOdemePlani = this.onInitializedPopupOdemePlani.bind(this);
        this.onInitializedPopupOdemePlaniDetay = this.onInitializedPopupOdemePlaniDetay.bind(this);
        this.onInitializedFormOdemePlani = this.onInitializedFormOdemePlani.bind(this);
        this.onInitializedFormOdemePlaniDetay = this.onInitializedFormOdemePlaniDetay.bind(this);
        this.onInitializedFormTahsilat = this.onInitializedFormTahsilat.bind(this);
        this.onInitializedFormMakbuz = this.onInitializedFormMakbuz.bind(this);
        this.onInitializedFormGorusme = this.onInitializedFormGorusme.bind(this);
        this.onInitializedTextBoxKalanTutar = this.onInitializedTextBoxKalanTutar.bind(this);
        this.onInitializedSelectBoxSinifSube = this.onInitializedSelectBoxSinifSube.bind(this);
        this.onInitializedSelectBoxSinifSeviye = this.onInitializedSelectBoxSinifSeviye.bind(this);
        this.onInitializedBtnOgrenciNoVer = this.onInitializedBtnOgrenciNoVer.bind(this);
        this.onInitializedSelectBoxKayiTuru = this.onInitializedSelectBoxKayiTuru.bind(this);
        this.onInitializedFormMain = this.onInitializedFormMain.bind(this);
        this.onInitializedFormSubeDegisimi = this.onInitializedFormSubeDegisimi.bind(this);
        this.onInitializedFormDanismanDegisimi = this.onInitializedFormDanismanDegisimi.bind(this);
        this.onInitializedFormNormalSms = this.onInitializedFormNormalSms.bind(this);
        this.onInitializedFormOgrenciAktar = this.onInitializedFormOgrenciAktar.bind(this);
        this.onInitializedFormSecilenOgrenciNormalSms = this.onInitializedFormSecilenOgrenciNormalSms.bind(this);
        this.onInitializedSelectBoxSubeDegisimiSinifSube = this.onInitializedSelectBoxSubeDegisimiSinifSube.bind(this);
        this.onInitializedGridOgrenciDonemBilgi = this.onInitializedGridOgrenciDonemBilgi.bind(this);
        this.onInitializedFormSinavSms = this.onInitializedFormSinavSms.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.onInitializedSelectBoxMerkeziSinav = this.onInitializedSelectBoxMerkeziSinav.bind(this);
        this.onInitializedGridTaksitTahsilat = this.onInitializedGridTaksitTahsilat.bind(this);
        this.onToolbarPreparingGridTaksitTahsilat = this.onToolbarPreparingGridTaksitTahsilat.bind(this);
        this.onSelectionChangedGridTaksitTahsilat = this.onSelectionChangedGridTaksitTahsilat.bind(this);
        this.onSelectionOgrenciGridRowChanged = this.onSelectionOgrenciGridRowChanged.bind(this);
        this.onInitializedSeciliOgrenciVeliSmsCheckBox = this.onInitializedSeciliOgrenciVeliSmsCheckBox.bind(this);
        this.onInitializedSeciliOgrenciVeliNormalSmsCheckBox = this.onInitializedSeciliOgrenciVeliNormalSmsCheckBox.bind(this);
        this.onInitializedSeciliOgrenciVeliKurumSmsCheckBox = this.onInitializedSeciliOgrenciVeliKurumSmsCheckBox.bind(this);
        this.onExporting = this.onExporting.bind(this);

        this.popupSmsRaporGizle = () => {
            this.setState({
                smsRaporVisible: false
            });
        }

        this.popupSmsRaporAc = () => {
            this.setState({
                smsRaporVisible: true
            });
        }

        this.popupSmsSinavGizle = () => {
            this.setState({
                smsSinavVisible: false
            });
        }

        this.popupSmsSinavAc = () => {
            this.setState({
                smsSinavVisible: true
            });
        }

        this.popupOdemePlaniGizle = () => {
            this.setState({
                odemePlaniVisible: false
            });
        }

        this.popupOdemePlaniDetayGizle = () => {
            this.setState({
                odemePlaniDetayVisible: false
            });
        }

        this.popupTahsilatGizle = () => {
            this.setState({
                tahsilatVisible: false
            });
        }

        this.popupMakbuzGizle = () => {
            this.setState({
                makbuzVisible: false
            });
        }

        this.popupGorusmeGizle = () => {
            this.setState({
                gorusmeVisible: false
            });
        }

        this.popupTahsilatDetayGizle = () => {
            this.gridTaksit.instance.refresh();
            this.setState({
                tahsilatDetayVisible: false
            })
        }

        this.popupSubeDegisimGizle = () => {
            this.setState({
                subeDegisimVisible: false
            });
        };

        this.popupDanismanDegisimGizle = () => {
            this.setState({
                danismanDegisimiVisible: false
            });
        };

        this.popupNormalSmsGizle = () => {
            this.setState({
                normalSmsVisible: false
            });
        }

        this.popupOgrenciAktarGizle = () => {
            this.setState({
                ogrenciAktarVisible: false
            });
        }

        this.popupOgrenciDonemBilgiGizle = () => {
            this.setState({
                ogrenciDonemBilgiVisible: false
            });
        }

        this.popupSecilenOgrenciNormalSmsGizle = () => {
            this.setState({
                secilenOgrenciNormalSmsVisible: false
            });
        }

        this.popupDevamsizlikRaporGizle = () => {
            this.setState({
                devamsizlikRaporVisible: false
            });
        }


        this.formDataOdemePlani = {
            DinamikUcret: 0,
            Ucret1: { Id: 0 },
            Ucret2: { Id: 0 },
            Ucret3: { Id: 0 },
            Indirim: { Id: 0 },
            Ogrenci: { Id: 0 },
            OdemeTuru: 0,
            Pesinat: 0,
            IndirimOrani: 0,
            TaksitSayisi: 0,
            IlkTaksitTarihi: null,
            IlkTaksit: false,
            Aciklama: "",
            IsNakit: false,
            Dosya: null
        }

        this.formDataOdemePlaniDetay = {
            Id: 0,
            HizmetToplami: 0,
            Pesinat: 0,
            IndirimToplamTutar: 0,
            OdenecekToplamTutar: 0,
            TaksitSayisi: 0,
            IlkTaksitTarihi: null,
            Aciklama: "",
            OdemeTuru: "",
            TahsilatDurumu: "",
            TaksitTutar: 0
        }

        this.formTahsilatData = {
            Aciklama: "",
            TaksitOdemeTuru: null,
            TaksitId: 0,
            Tahsilat: 0,
            TahsilatTarihi: new Date(),
            OdemePlaniId: 0,
            OgrenciId: 0,
            MakbuzTuru: 1,
            BankaId: 0,
            SlipNo: ""
        }

        this.formMakbuzData = {
            TaksitId: 0,
            MakbuzTuru: 1
        };

        this.formGorusmeData = {
            Tarih: new Date(),
            Aciklama: null,
            IsVeliGorsun: false,
            Ogrenci: { Id: null }
        }

        this.formSubeDegisimiData = {
            SinifSeviyeId: null,
            SubeId: null
        }

        this.formDanismanDegisimiData = {
            DanismanId: null
        };

        this.formNormalSmsData = {
            Mesaj: "",
            GonderimTipi: 0,
            VeliCepTelEkstra: false
        };

        this.formSecilenOgrenciNormalSmsData = {
            Mesaj: "",
            GonderimTipi: 0,
            Ogrenciler: [],
            VeliCepTelEkstra: false
        }

        this.formOgrenciAktarData = {
            Ogrenciler: [],
            GecmisDonemId: Globals.service.DonemId,
            DonemId: null,
            OgrenciNoAktar: false
        };
        this.formSinavSmsData = {
            Ogrenciler: [],
            MerkeziSinavId: null,
            GonderimTipi: null,
            VeliCepTelEkstra: false
        }
        this.ogrenciStore = GetStore(
            "Id"
            , "OtomasyonOgrenci"
            , otomasyonApi + "kayitkabul/listogrenciler"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");
        this.sinifSubeDataSource = GetStore(
            "Id"
            , "OtomasyonSinifSube"
            , otomasyonApi + "geneltanim/listsinifsubebykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");
        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruFilter = {
            YayinId: 0,
            SinavTuruId: 0
        };
        this.merkeziSinavDataSource = new DataSource(
            {
                filter: ["Degerlendirilebilir", "=", true],
                paginate: false,
                store: createStore({
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'merkezisinav/sgemerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.taksitFilter = {
            taksitId: 0
        };
        this.tahsilatDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.taksitFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listtahsilatdetay',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.devamsizlikFilter = {
            ogrenciId: 0
        };
        this.devamsizlikAltRaporDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.devamsizlikFilter,
                    loadUrl: otomasyonApi + 'devamsizlik/devamsizlikaltraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.devamsizlikUstRaporDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.devamsizlikFilter,
                    loadUrl: otomasyonApi + 'devamsizlik/devamsizlikustraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });

        this.odemeTuruDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Value",
                loadParams: {
                    enumName: "OdemeTuru"
                },
                loadUrl: otomasyonApi + 'common/getenums',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.ogrenciFilter = { ogrenciId: 0 };
        this.gorusmeNotuDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listgorusmenotu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ogrenciTcNoFilter = { tcNo: "0" };
        this.ogrenciDonemDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciTcNoFilter,
                    loadUrl: otomasyonApi + 'ogrenci/getogrencidonembilgi',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ucretDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    loadUrl: otomasyonApi + 'geneltanim/listucretbykullanici',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.odemePlaniFilter = { odemePlaniId: 0 };

        this.taksitDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.odemePlaniFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listtaksitbyodemeplani',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.smsRaporFilter = { numara: "", veliNumara: "" };

        this.smsRaporDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.smsRaporFilter,
                    loadUrl: otomasyonApi + 'sms/getsmsrapor',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.indirimDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    loadUrl: otomasyonApi + 'geneltanim/listindirimbykullanici',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.checkBoxSeciliOgrenciVeliSmsCheckBox = null;
        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox = null;
        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox = null;
    }

    renderSwitch(cellInfo) {
        console.log(cellInfo);
        const setEditedValue = valueChangedEventArg => {
            cellInfo.setValue(valueChangedEventArg.value);
        }
        if (!!cellInfo.data.Fotograf) {
            return (

                <React.Fragment>
                    <img src={cellInfo.data.Fotograf} alt={cellInfo.data.Adi + " " + cellInfo.data.Soyadi} style={{ width: "120px", height: "120px" }}></img>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>

                </React.Fragment>
            );
        }

    }
    //#region  form bilgileri
    async onEditorPreparing(e) {

        if (e.parentType === "dataRow" && e.dataField === "File") {
            e.editorName = "dxFileUploader";
            e.editorOptions.name = "myFile";
            e.editorOptions.uploadUrl = otomasyonApi + "KayitKabul/FotografUpload";
            e.editorOptions.uploadHeaders = { 'Authorization': 'Bearer ' + Globals.service.Token };
            e.editorOptions.onUploaded = (args) => {
                var result = JSON.parse(args.request.response);
                if (result.Success) {
                    const rowIndex = e.row.rowIndex
                    e.component.cellValue(rowIndex, "Fotograf", result.Data);
                    notify("Öğrenci fotoğrafı yüklendi", "success", 5000);
                }
                else {
                    notify("Öğrenci fotoğrafı yüklenemedi", "error", 5000);
                }
            };

        }

        if (e.parentType === "dataRow" && e.dataField === "AyrilmaTarihi") {
            let date = new Date(e.editorOptions.value);
            if (date.getFullYear() === 1901) {
                e.editorOptions.value = null;
            }
        }
        if (e.parentType === "dataRow" && e.dataField === "DogumTarihi") {
            let date = new Date(e.editorOptions.value);
            if (date.getFullYear() === 1901) {
                e.editorOptions.value = null;
            }
        }

        if (e.parentType === "dataRow" && e.dataField === "EOkulNo") {
            if (e.row.isNewRow) {
                e.editorOptions.value = 0;
                e.editorOptions.min = 0;
            }
            else {
                e.editorOptions.min = 0;
            }
        }
        if (e.parentType === "dataRow" && e.dataField === "OgrenciNo") {
            if (!e.row.isNewRow && e.editorOptions.value > 0) {
                e.editorOptions.disabled = true;
            }
            else {
                e.editorOptions.buttons = [{
                    location: "after",
                    elementAttr: {
                        id: "btnOgrenciNoVerX"
                    },
                    name: "ogrenciNoVer",
                    options: {
                        icon: "plus",

                        text: "Öğrenci No Ver",
                        disabled: e.editorOptions.value > 0 ? true : false,
                        ref: (ref) => { this.btnOgrenciNoVer = ref },
                        onInitialized: this.onInitializedBtnOgrenciNoVer,
                        onContentReady: (e) => {
                            e.element.addEventListener("mouseover", () => {
                                this.toolTipOgrenciNoVer.instance.option("visible", true);
                            });
                            e.element.addEventListener("mouseleave", () => {
                                this.toolTipOgrenciNoVer.instance.option("visible", false);
                            });
                        },
                        onClick: () => {
                            service.get(otomasyonApi + "kayitkabul/getogrencino/" + e.row.data.SinifSeviye.Id,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                }).then(res => {
                                    if (res.data.Success) {
                                        const rowIndex = e.row.rowIndex
                                        e.component.cellValue(rowIndex, "OgrenciNo", parseInt(res.data.Data));
                                        if (e.editorOptions.value > 0) {
                                            this.btnOgrenciNoVer.instance().option("disabled", true);
                                        }


                                    }
                                    else {
                                        notify(res.data.ErrorMessage, "error", 5000);
                                    }
                                })
                                .catch(err => {
                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                })
                        }
                    }
                }];
            }
        }

        if (e.parentType === "dataRow" && e.dataField === "SinifSube.Id") {
            if (!isEmpty(e.row.data)) {
                e.editorOptions.disabled = (typeof e.row.data.SinifSeviye.Id !== "number");
                e.editorOptions.dataSource.filter(["SeviyeId", "=", e.row.data.SinifSeviye.Id]);
                e.editorOptions.dataSource.load();
            }
        }
    }

    gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.satir = null;
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }

    onInitialized(e) {
        this.gridMain = e.component;
    }

    onInitializedFormMain(e) {
        this.formMain = e.component;
    }

    onInitializedFormOgrenciAktar(e) {
        this.formOgrenciAktar = e.component;
    }

    onInitializedBtnOgrenciNoVer(e) {
        this.btnOgrenciNoVer = e.component;
    }

    onInitializedSelectBoxSinifSube(e) {
        this.selectBoxSinifSube = e.component;
    }

    onInitializedSelectBoxSinifSeviye(e) {
        this.selectBoxSinifSeviye = e.component;
    }

    onInitializedSelectBoxSubeDegisimiSinifSube(e) {
        this.selectBoxSubeDegisimiSinifSube = e.component;
    }

    onInitializedSelectBoxKayiTuru(e) {
        this.selectBoxKayitTuru = e.component;
    }

    onInitializedGridTaksit(e) {
        this.gridTaksit = e.component;
    }

    onInitializedGridGorusmeNotu(e) {
        this.grdiGorusmeNotu = e.component;
    }
    onInitializedGridOgrenciDonemBilgi(e) {
        this.gridOgrenciDonemBilgi = e.component;
    }

    onInitializedPopupOdemePlani(e) {
        this.popupOdemePlani = e.component;
    }

    onInitializedPopupOdemePlaniDetay(e) {
        this.popupOdemePlaniDetay = e.component;
    }

    onInitializedFormOdemePlani(e) {
        this.formOdemePlani = e.component;
    }

    onInitializedFormOdemePlaniDetay(e) {
        this.formOdemePlani = e.component;
    }

    onInitializedFormTahsilat(e) {
        this.formTahsilat = e.component;
    }

    onInitializedFormMakbuz(e) {
        this.formMakbuz = e.component;
    }

    onInitializedFormGorusme(e) {
        this.formGorusme = e.component;
    }

    onInitializedFormSubeDegisimi(e) {
        this.formSubeDegisimi = e.component;
    }

    onInitializedFormDanismanDegisimi(e) {
        this.formDanismanDegisimi = e.component;
    }

    onInitializedTextBoxKalanTutar(e) {
        this.textBoxKalanTutar = e.component;
    }

    onInitializedFormNormalSms(e) {
        this.formNormalSms = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }

    onInitializedSelectBoxMerkeziSinav(e) {
        this.selectBoxMerkeziSinav = e.component;
    }

    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedFormSecilenOgrenciNormalSms(e) {
        this.formSecilenOgrenciNormalSms = e.component;
    }

    onInitializedGridTaksitTahsilat(e) {
        this.gridTaksitTahsilat = e.component;
    }

    onInitializedFormSinavSms(e) {
        this.formSinavSms = e.component;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnSmsRapor"
                    },
                    icon: "attach",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSmsGonderimBilgi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSmsGonderimBilgi.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.smsRaporFilter.numara = this.satir.CepTelefonu;
                            this.smsRaporFilter.veliNumara = this.satir.VeliCepTelefonu;
                            await this.smsRaporDataSource.load();
                            await this.gridSmsRapor.instance.refresh();
                            this.setState({
                                smsRaporVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnOgrenciDonemBilgi"
                    },
                    icon: "info",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciDonemBilgi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciDonemBilgi.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.ogrenciTcNoFilter.tcNo = this.satir.TcNo;
                            await this.ogrenciDonemDataSource.load();
                            await this.gridOgrenciDonemBilgi.instance.refresh();
                            this.setState({
                                ogrenciDonemBilgiVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnOgrenciAktar"
                    },
                    icon: "importselected",
                    type: "success",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciAktar.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciAktar.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.donemDataSource.filter(["Id", "<>", Globals.service.DonemId]);
                            await this.donemDataSource.load();
                            this.setState({
                                ogrenciAktarVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnGecisYap"
                    },
                    icon: "user",
                    type: "success",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipGecisYap.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipGecisYap.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            try {
                                localStorage.removeItem("oturum");
                                localStorage.removeItem("session");
                                localStorage.removeItem("initialized");
                            } catch (error) {
                            }
                            const data = {
                                KullaniciAdi: this.satir.TcNo,
                                Sifre: this.satir.Sifre,
                                Donem: { Id: this.satir.Donem.Id }
                            }
                            service.post(authApi + "Auth/authenticate"
                                , data)
                                .then(res => {
                                    auth.login(() => {


                                        var localStoregeData = {};
                                        res.data.AdSoyad = res.data.Kullanici.Adi + " " + res.data.Kullanici.Soyadi;
                                        res.data.Menu = res.data.Kullanici.KullaniciMenuler;
                                        res.data.Rol = res.data.Kullanici.Rol;
                                        res.data.Id = res.data.Kullanici.Id;
                                        res.data.DonemId = res.data.Kullanici.Donem.Id;
                                        res.data.DonemAdi = res.data.Kullanici.DonemAdi;
                                        res.data.KurumId = res.data.Kullanici.KurumId;
                                        localStorage.setItem("oturum", JSON.stringify(res.data));
                                        // Globals.AdSoyad = localStoregeData.Kullanici.Adi + " " + localStoregeData.Kullanici.Soyadi;
                                        // Globals.menuler = localStoregeData.Kullanici.KullaniciMenuler;
                                        Globals.service = localStoregeData;
                                        // Globals.Token = localStoregeData.Token;
                                        // Globals.Rol = localStoregeData.Kullanici.Rol;
                                        if (Globals.service.Rol === "SubeKayitKabul") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeMuhasebe") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeKayitMuhasebe") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "Sube") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "GenelMerkez") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeVeli") {
                                            this.props.history.push("/velidashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeOgrenci") {
                                            window.location.href = "/ogrencidashboard";
                                        }
                                        else {
                                            this.props.history.push("/home");
                                        }

                                    });
                                })
                                .catch((error) => {
                                    // Error 😨
                                    if (error.response) {
                                        notify(error.response.data.message, 'error', 5000);
                                    } else if (error.request) {
                                        notify(error.request, 'error', 5000);
                                    } else {
                                        notify("Teknik bir hat oluştu", 'error', 5000);
                                    }

                                });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    elementAttr: {
                        id: "btnSecilenOgrenciBelge"
                    },
                    icon: "textdocument",
                    type: "success",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSecilenOgrenciBelge.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSecilenOgrenciBelge.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.OgrenciBelgesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnSeciliOgrenciSms"
                    },
                    icon: "email",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSeciliOgrenciSinavSms.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSeciliOgrenciSinavSms.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            // this.smsRaporFilter.numara = this.satir.CepTelefonu;
                            // this.smsRaporFilter.veliNumara = this.satir.VeliCepTelefonu;
                            // await this.smsRaporDataSource.load();
                            // await this.gridSmsRapor.instance.refresh();
                            this.setState({
                                smsSinavVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: true,
                options: {
                    elementAttr: {
                        id: "btnSecilenOgrenciNormalSms"
                    },
                    icon: "email",
                    type: "success",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSecilenOgrenciNormalSms.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSecilenOgrenciNormalSms.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.setState({
                            secilenOgrenciNormalSmsVisible: true
                        });
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: true,
                options: {
                    elementAttr: {
                        id: "btnNormalSms"
                    },
                    icon: "email",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipNormalSms.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipNormalSms.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.setState({
                            normalSmsVisible: true
                        });
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnDevamsizlikListesi"
                    },
                    icon: "runner",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipDevamsizlikListesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipDevamsizlikListesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.DevamsizlikListesiBas();
                    }
                }
            },
            {
                location: "after",
                locateInMenu: "auto",
                widget: "dxButton",
                visible: ["SubeOgretmen", "Sube", "GenelMerkez"].includes(Globals.service.Rol) ? true : false,
                options: {
                    elementAttr: {
                        id: "btnOgrenciOdevListesi"
                    },
                    icon: "exportpdf",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciOdevListesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciOdevListesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.OgrenciOdevListesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" || Globals.service.Rol === "Sube" ? true : false,
                options: {
                    elementAttr: {
                        id: "btnOgrenciEtutListesi"
                    },
                    icon: "exportpdf",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciEtutListesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciEtutListesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.OgrenciEtutListesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnYoklamaListesi"
                    },
                    icon: "exportpdf",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipYoklamaListesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipYoklamaListesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.yoklamaListesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    elementAttr: {
                        id: "btnDanismanListesi"
                    },
                    icon: "exportpdf",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipDanismanListesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipDanismanListesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.danismanListesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    elementAttr: {
                        id: "btnKayitListesiExcel"
                    },
                    icon: "xlsfile",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipKayitListesiExcel.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipKayitListesiExcel.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.kayitListesiBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnGorusmeler"
                    },
                    icon: "comment",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipGorusmeler.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipGorusmeler.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.ogrenciFilter.ogrenciId = this.satir.Id;

                            await this.grdiGorusmeNotu.instance.refresh();
                            this.setState({
                                OgrenciId: this.satir.Id,
                                gorusmeVisible: true,
                                gorusmeBaslik: this.satir.Adi + " " + this.satir.Soyadi + " için görüşme notları"
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    icon: "search",
                    elementAttr: {
                        id: "btnOdemePlaniGoster"
                    },
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOdemePlaniGoster.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOdemePlaniGoster.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.odemePlaniDetay();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    icon: "formula",
                    elementAttr: {
                        id: "btnOdemePlaniOlustur"
                    },
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOdemePlaniOlustur.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOdemePlaniOlustur.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.odemePlaniOlustur();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    elementAttr: {
                        id: "btnSozlesmeBas"
                    },
                    icon: "print",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipKayitSozlesmesi.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipKayitSozlesmesi.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.sozlesmeBas();
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    elementAttr: {
                        id: "btnYeniOgrenciEkle"
                    },
                    icon: "add",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipYeniOgrenciEkle.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipYeniOgrenciEkle.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        this.gridMain.instance.option("focusedRowIndex", -1);
                        this.gridMain.instance.addRow();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto',
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                widget: 'dxButton', options: {
                    elementAttr: {
                        id: "btnOgrenciDuzenle"
                    },
                    type: 'default',
                    icon: 'edit',
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciDuzenle.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciDuzenle.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.satirIndex === -1 | this.satirIndex === null) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.gridMain.instance.editRow(this.satirIndex);
                        }
                    }
                }
            },
            {
                location: 'after',
                locateInMenu: 'auto',
                widget: 'dxButton',
                visible: Globals.service.Rol === "SubeKayitKabul" | Globals.service.Rol === "SubeOgretmen" | Globals.service.Rol === "SubeMuhasebe" ? false : true,
                options: {
                    type: 'danger',
                    elementAttr: {
                        id: "btnOgrenciSil"
                    },
                    icon: 'trash',
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipOgrenciSil.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipOgrenciSil.instance.option("visible", false);
                        });
                    },
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            let result = confirm("<i>Kaydınız silinecektir.Bu işlem geri alınamaz  emin misiniz?<br/><b><font style='color:red'>Bunun yerine öğrenci kaydını pasife alabilirsiniz</font></b></i>", "Silme onayı");
                            result.then(async (dialogResult) => {
                                if (dialogResult === true) {
                                    let result2 = confirm("<i>Tekrardan belirtmek isteriz kaydınız silinecektir.Bu işlem geri alınamaz  emin misiniz?<br/><b><font style='color:red'>Bunun yerine öğrenci kaydını pasife alabilirsiniz</font></b></i>", "Silme onayı(İkinci onay)");
                                    result2.then(async (dialogResult2) => {
                                        if (dialogResult2 === true) {
                                            await this.gridMain.instance.deleteRow(this.satirIndex);
                                            await this.gridMainSatirIptal();
                                        }
                                    });

                                }
                            });

                        }
                    }
                }
            },
            {

                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    icon: "user",
                    type: "success",
                    elementAttr: {
                        id: "btnDanismanDegistir",
                        class: "btn-success"
                    },
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipDanismanDegistir.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipDanismanDegistir.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.gridMain.instance.getSelectedRowKeys().length === 0) {
                            alert("Lütfen öğrenci listesinden, en sol tarafta bulunan seçim kutuları ile bir veya birden fazla öğrenci seçiniz", "Uyarı");
                        }
                        else {
                            this.setState({
                                danismanDegisimiVisible: true
                            });

                        }

                    }
                }
            },
            {

                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    icon: "group",
                    type: "normal",
                    elementAttr: {
                        id: "btnSubeDegistir"
                    },
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSubeDegistir.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSubeDegistir.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.gridMain.instance.getSelectedRowKeys().length === 0) {
                            alert("Lütfen öğrenci listesinden, en sol tarafta bulunan seçim kutuları ile bir veya birden fazla öğrenci seçiniz", "Uyarı");
                        }
                        else {
                            this.setState({
                                subeDegisimVisible: true
                            });

                        }

                    }
                }
            },
            {

                location: "after",
                widget: "dxButton",
                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                options: {
                    icon: "check",
                    elementAttr: {
                        id: "btnKesinKayitYap"
                    },
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipKesinKayitYap.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipKesinKayitYap.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.gridMain.instance.getSelectedRowKeys().length === 0) {
                            alert("Lütfen öğrenci listesinden, en sol tarafta bulunan seçim kutuları ile bir veya birden fazla öğrenci seçiniz", "Uyarı");
                        }
                        else {
                            let ogrenciData = [];
                            this.gridMain.instance.getSelectedRowKeys().forEach((ogrenci) => {
                                ogrenciData.push({
                                    OgrenciId: parseInt(ogrenci)
                                })
                            });
                            service.post(otomasyonApi + "kayitkabul/kesinKayitYap", ogrenciData,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                })
                                .then(async (res) => {
                                    if (res.data.Success) {
                                        await this.gridMainSatirIptal();
                                        await this.gridMain.instance.refresh();
                                        notify("İşleminiz gerçekleşti", "success", 5000);
                                    } else {
                                        notify("Teknik bir hata oluştu," + res.data.ErrorMessage, "error", 5000);
                                    }
                                })
                                .catch(err => {
                                    notify("Teknik bir hata oluştu," + err, "error", 5000);
                                });

                        }

                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton',
                visible: Globals.service.Rol === "SubeKayitKabul" | Globals.service.Rol === "SubeOgretmen" | Globals.service.Rol === "SubeMuhasebe" ? false : true,
                options: {
                    type: 'success', hint: 'Öğrenciyi Aktif Yap', icon: 'isnotblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
                            result.then(async (dialogResult) => {
                                if (dialogResult === true) {
                                    this.ogrenciStore.store().update(this.satir.Id, { Deleted: false }).done(() => {
                                        this.gridMain.instance.refresh().done(() => {
                                            this.gridMainSatirIptal();
                                        });
                                    });
                                }
                            });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton',
                visible: Globals.service.Rol === "SubeKayitKabul" | Globals.service.Rol === "SubeOgretmen" | Globals.service.Rol === "SubeMuhasebe" ? false : true,
                options: {
                    type: 'danger', hint: 'Öğrenciyi Pasif Yap', icon: 'isblank',
                    onClick: () => {
                        if (this.satirIndex === undefined | this.satirIndex === null) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {

                            let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    this.ogrenciStore.store().update(this.satir.Id, { Deleted: true }).done(() => {
                                        this.gridMain.instance.refresh().done(() => {
                                            this.gridMainSatirIptal();
                                        });
                                    });
                                }
                            });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh().done(() => {
                            this.gridMainSatirIptal();
                        });
                    }
                }
            },
            {
                location: 'after',
                locateInMenu: 'auto',
                template: () => {
                    return ("<div class='dx-item-content dx-toolbar-item-content'><div class='dx-button dx-button-default dx-button-mode-contained dx-widget dx-button-has-icon' aria-label='help' title='Yardım' tabindex='0' role='button'><div class='dx-button-content'><a href='/yardim/kepsogrenciekrani.pdf' target='_blank' title='Yardım'><i class='dx-icon dx-icon-help'></i></a></div></div></div></div>");
                }
                // widget: 'dxButton',
                // options: {
                //     type: 'default',
                //     hint: 'Yardım',
                //     icon: 'help',
                //     onClick: () => {
                //         window.location.href = "/yardim/kepsogrenciekrani.pdf";
                //     }
                // }
            }
        );
    }

    onToolbarPreparingGridTaksit(e) {
        e.toolbarOptions.items.unshift(

            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "search",
                    text: "Alınan Tahsilatlar",
                    elementAttr: {
                        id: "btnTahsilat"
                    },
                    type: "default",
                    onClick: () => {

                        if (this.satirTaksit === null || this.satirTaksit === undefined) {
                            alert("Lütfen bir taksit satırı seçiniz", "Uyarı");
                        }
                        else {

                            this.taksitFilter.taksitId = this.satirTaksit.Id;
                            this.gridTaksitTahsilat.instance.refresh();
                            this.setState({
                                tahsilatDetayVisible: true
                            });
                        }

                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "print",
                    text: "Makbuz",
                    elementAttr: {
                        id: "btnMakbuz"
                    },
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipMakbuz.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipMakbuz.instance.option("visible", false);
                        });
                    },
                    onClick: () => {

                        if (this.satirTaksit === null || this.satirTaksit === undefined) {
                            alert("Lütfen bir taksit satırı seçiniz", "Uyarı");
                        }
                        else {
                            this.formMakbuzData.TaksitId = this.satirTaksit.Id;
                            this.setState({
                                makbuzVisible: true
                            });
                        }

                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "print",
                    elementAttr: {
                        id: "btnSenet"
                    },
                    text: "Senet",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipSenet.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipSenet.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.formDataOdemePlaniDetay.IsNakit) {
                            alert("Üzgünüz, ödemesi nakit alınmış bir ödeme planı için senet bastırılamaz", "Uyarı");

                        }
                        else {

                            service.get(otomasyonApi + "kayitkabul/senetbas/" + this.formDataOdemePlaniDetay.Id,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                })
                                .then(res => {
                                    if (res.data.Success) {
                                        alert("Senediniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile senedinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                                        if (this.gridTaksit.instance !== null) {
                                            this.gridTaksit.instance.clearSelection();
                                            this.satirTaksit = null;
                                            this.gridTaksit.instance.option("focusedRowIndex", -1);
                                        }
                                    }
                                    else {
                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                    }
                                })
                                .catch(err => {
                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                });
                        }

                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "print",
                    elementAttr: {
                        id: "btnTekSenet"
                    },
                    text: "Tek Senet",
                    type: "default",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipTekSenet.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipTekSenet.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.formDataOdemePlaniDetay.IsNakit) {
                            alert("Üzgünüz, ödemesi nakit alınmış bir ödeme planı için senet bastırılamaz", "Uyarı");

                        }
                        else {

                            service.get(otomasyonApi + "kayitkabul/teksenetbas/" + this.formDataOdemePlaniDetay.Id,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                })
                                .then(res => {
                                    if (res.data.Success) {
                                        alert("Senediniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile senedinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                                        if (this.gridTaksit.instance !== null) {
                                            this.gridTaksit.instance.clearSelection();
                                            this.satirTaksit = null;
                                            this.gridTaksit.instance.option("focusedRowIndex", -1);
                                        }
                                    }
                                    else {
                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                    }
                                })
                                .catch(err => {
                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                });
                        }

                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "todo",
                    elementAttr: {
                        id: "btnTahsilatYap"
                    },
                    text: "Tahsilat Yap",
                    type: "success",
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipTahsilatYap.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipTahsilatYap.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.satirTaksit === null || this.satirTaksit === undefined) {
                            alert("Lütfen bir taksit satırı seçiniz", "Uyarı");
                        }
                        else {
                            this.formTahsilatData = {
                                Aciklama: "",
                                TaksitOdemeTuru: null,
                                MakbuzTuru: 1,
                                TaksitId: this.satirTaksit.Id,
                                Tahsilat: parseFloat(this.satirTaksit.Tutar) - parseFloat(this.satirTaksit.ToplamTahsilat),
                                OdemePlaniId: this.satirTaksit.OdemePlani.Id,
                                OgrenciId: this.state.OgrenciId,
                                TahsilatTarihi: new Date(),
                                BankaId: 0,
                                SlipNo: ""
                            }
                            this.setState({
                                tahsilatVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "remove",
                    elementAttr: {
                        id: "btnTahsilatSil"
                    },
                    text: "Tahsilatları Sil",
                    type: "danger",
                    visible: (Globals.service.Rol === "SubeKayitKabul" || Globals.service.Rol === "SubeOgretmen") ? false : true,
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipTahsilatSil.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipTahsilatSil.instance.option("visible", false);
                        });
                    },
                    onClick: () => {
                        if (this.satirTaksit === null || this.satirTaksit === undefined) {
                            alert("Lütfen bir taksit satırı seçiniz", "Uyarı");
                        }
                        else {
                            let result = confirm("<i><font color='red'>Seçili taksidin <font color='red'>bütün tahsilatlarını</font> silmek istediğinize emin misiniz?</font></i>", "Tahsilat bilgisi silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(otomasyonApi + "kayitkabul/tahsilatsil/" + this.satirTaksit.Id,
                                        null,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(async (res) => {
                                            if (res.data.Success) {
                                                if (this.gridTaksit.instance !== null) {
                                                    await this.gridTaksit.instance.refresh();
                                                    await service.get(otomasyonApi + "kayitkabul/gettoplamtahsilat/" + this.satirTaksit.OdemePlani.Id, {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    })
                                                        .then(res => {
                                                            const kalanOdeme = parseFloat(res.data.Data);
                                                            this.textBoxKalanTutar.instance().option("value", kalanOdeme);
                                                            if (this.gridTaksit.instance !== null) {
                                                                this.gridTaksit.instance.clearSelection();
                                                                this.satirTaksit = null;
                                                                this.gridTaksit.instance.option("focusedRowIndex", -1);
                                                            }
                                                        });

                                                }
                                            }
                                            else {
                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                            }
                                        })
                                        .catch(err => {
                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                        });
                                }
                            });
                        }
                    }
                }
            }
        );
    }

    onToolbarPreparingGridGorusmeNotu(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "trash",
                    text: "Sil",
                    hint: "Sil",
                    type: "danger",
                    onClick: () => {


                        if (this.satirGorusmeNotu === null || this.satirGorusmeNotu === undefined) {
                            alert("Lütfen birgörüşme notu satırı seçiniz", "Uyarı");
                        }
                        else {
                            service.post(otomasyonApi + "kayitkabul/gorusmenotusil/" + this.satirGorusmeNotu.Id, null,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                })
                                .then(res => {
                                    if (res.data.Success) {
                                        if (this.grdiGorusmeNotu.instance !== null) {
                                            this.grdiGorusmeNotu.instance.clearSelection();
                                            this.satirGorusmeNotu = null;
                                            this.grdiGorusmeNotu.instance.option("focusedRowIndex", -1);
                                        }
                                        this.grdiGorusmeNotu.instance.refresh();
                                    }
                                    else {
                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                    }
                                })
                                .catch(err => {
                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                });
                        }

                    }
                }
            },

        );
    }

    onToolbarPreparingGridTaksitTahsilat(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "trash",
                    text: "Sil",
                    hint: "Sil",
                    type: "danger",
                    visible: (Globals.service.Rol === "SubeKayitKabul" || Globals.service.Rol === "SubeOgretmen") ? false : true,
                    onClick: () => {


                        if (this.satirTaksitTahsilat === null || this.satirTaksitTahsilat === undefined) {
                            alert("Lütfen tahsilat satırı seçiniz", "Uyarı");
                        }
                        else {
                            let result = confirm("<i><font color='red'>Seçilen tahsilat satırını silinecektir, emin misiniz?</font></i>", "Tahsilat Bilgisi Silme  Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(otomasyonApi + "kayitkabul/taksittahsilatsatirsil/" + this.satirTaksitTahsilat.Id, null,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(async (res) => {
                                            if (res.data.Success) {
                                                if (this.gridTaksitTahsilat.instance !== null) {
                                                    this.gridTaksitTahsilat.instance.clearSelection();
                                                    this.satirTaksitTahsilat = null;
                                                    this.gridTaksitTahsilat.instance.option("focusedRowIndex", -1);
                                                }
                                                await this.gridTaksitTahsilat.instance.refresh();
                                                await service.get(otomasyonApi + "kayitkabul/gettoplamtahsilat/" + this.satirTaksit.OdemePlani.Id, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(res => {
                                                        const kalanOdeme = parseFloat(res.data.Data);
                                                        this.textBoxKalanTutar.instance().option("value", kalanOdeme);
                                                    });


                                            }
                                            else {
                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                            }
                                        })
                                        .catch(err => {
                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                        });
                                }
                            });
                        }

                    }
                }
            },

        );
    }

    onSelectionChangedGridTaksit(item) {
        if (item.row !== undefined) {
            this.satirTaksit = item.row.data;
        }
    }

    onSelectionChangedGridTaksitTahsilat(item) {
        if (item.row !== undefined) {
            this.satirTaksitTahsilat = item.row.data;
        }
    }


    onSelectionChangedGridGorusmeNotu(item) {
        if (item.row !== undefined) {
            this.satirGorusmeNotu = item.row.data;
        }
    }

    odemePlaniDetay() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            if (this.satir.IsOdemePlani) {
                service.get(otomasyonApi + "kayitkabul/odemeplanidetay/" + this.satir.Id, {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(async (res) => {
                    if (res.data.Success) {
                        this.formDataOdemePlaniDetay = res.data.Data;
                        this.odemePlaniFilter.odemePlaniId = this.formDataOdemePlaniDetay.Id;
                        var ilkTaksitTarihi = new Date(res.data.Data.IlkTaksitTarihi);
                        if (ilkTaksitTarihi.getFullYear() === 1) {
                            this.formDataOdemePlaniDetay.IlkTaksitTarihi = null;
                            this.textBoxIlkTaksitTarihi.instance().option("value", null);
                        }
                        await this.gridTaksit.instance.refresh();

                        this.popupOdemePlaniDetay.instance.option("title", this.satir.Adi + " " + this.satir.Soyadi + " için oluşturulan ödeme planı");
                        this.setState({
                            odemePlaniDetayVisible: true,
                            OgrenciId: this.satir.Id
                        });
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                })
                    .catch(err => {
                        notify(err, "error", 5000);
                    });

            } else {
                notify("Bu öğrenci için ödeme planı oluşturulmamış", "warning", 5000);
            }
        }
    }

    sozlesmeBas() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            if (this.satir.IsOdemePlani) {
                service.get(otomasyonApi + "kayitkabul/sozlesmebas/" + this.satir.Id, {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(async (res) => {
                    if (res.data.Success) {
                        alert("Sözleşmeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile sözleşmenizi bilgisayarınıza indiriniz</a>", "Bilgi");
                        this.gridMainSatirIptal();
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                })
                    .catch(err => {
                        notify(err, "error", 5000);
                    });

            } else {
                notify("Bu öğrenci için  ödeme planı oluşturulmamış", "warning", 5000);
            }
        }
    }

    kayitListesiBas() {

        service.get(otomasyonApi + "kayitkabul/kayitlistesibas", {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        }).then(async (res) => {
            if (res.data.Success) {
                alert("Kayıt listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile kayıt listesinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                this.gridMainSatirIptal();
            }
            else {
                notify(res.data.ErrorMessage, "error", 5000);
            }
        })
            .catch(err => {
                notify(err, "error", 5000);
            });
    }

    yoklamaListesiBas() {
        service.get(otomasyonApi + "kayitkabul/yoklamalistesibas", {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        }).then(async (res) => {
            if (res.data.Success) {
                alert("Yoklama listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile yoklama listesinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                this.gridMainSatirIptal();
            }
            else {
                notify(res.data.ErrorMessage, "error", 5000);
            }
        })
            .catch(err => {
                notify(err, "error", 5000);
            });
    }

    DevamsizlikListesiBas() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            this.devamsizlikFilter.ogrenciId = this.satir.Id;
            this.gridDevamsizlikAltRapor.instance.refresh();
            this.gridDevamsizlikUstRapor.instance.refresh();
            this.setState({
                devamsizlikRaporVisible: true
            });
            this.gridMainSatirIptal();
        }
    }

    OgrenciBelgesiBas() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            service.get(otomasyonApi + "ogrenci/getOgrenciBelge/" + this.satir.Id, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(async (res) => {
                if (res.data.Success) {
                    alert("Öğrenci belgeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile öğrenci belgenizi bilgisayarınıza indiriniz</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    notify(err, "error", 5000);
                });
        }
    }

    OgrenciEtutListesiBas() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            service.get(otomasyonApi + "kayitkabul/ogrencietuttakip/" + this.satir.KurumId + "/" + this.satir.Id, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(async (res) => {
                if (res.data.Success) {
                    alert("Öğrenci etüt listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile etüt listesinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    notify(err, "error", 5000);
                });
        }
    }

    OgrenciOdevListesiBas() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            service.get(otomasyonApi + "odev/ogrenciodevlistesiraporu/" + this.satir.Id, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(async (res) => {
                if (res.data.Success) {
                    alert("Öğrenci ödev listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile ödev listesinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    notify(err, "error", 5000);
                });
        }
    }

    danismanListesiBas() {

        service.get(otomasyonApi + "kayitkabul/danismanlistesibas", {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        }).then(async (res) => {
            if (res.data.Success) {
                alert("Danışman listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile danışman listesinizi bilgisayarınıza indiriniz</a>", "Bilgi");
                this.gridMainSatirIptal();
            }
            else {
                notify(res.data.ErrorMessage, "error", 5000);
            }
        })
            .catch(err => {
                notify(err, "error", 5000);
            });
    }

    odemePlaniOlustur() {
        if (this.satir === null | this.satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
        }
        else {
            if (this.satir.IsOdemePlani) {
                alert("Bu öğrenci için ödeme planı oluşturulmuş,<b style='color:red'>Ödeme planı detay menüsüne tıklayabilirsiniz<b>", "Bilgi");
                notify("Bu öğrenci için ödeme planı oluşturulmuş,Ödeme planı detay menüsüne tıklayabilirsiniz", "warning", 5000);
            }
            else {
                if (this.satir.KayitTuru === 2) {
                    this.popupOdemePlani.instance.option("title", this.satir.Adi + " " + this.satir.Soyadi + " için ödeme planı");
                    this.formDataOdemePlani.Ogrenci.Id = this.satir.Id;
                    this.setState({
                        OgrenciId: this.satir.Id,
                        odemePlaniVisible: true
                    });
                }
                else {
                    alert("Kayıt türü kesin olan öğrenciler için ödeme planı oluşturabilirsiniz", "Bilgi");
                    notify("Kayıt türü kesin olan öğrenciler için ödeme planı oluşturabilirsiniz", "warning", 5000);
                }


            }
        }
    }

    onSelectionOgrenciGridRowChanged({ selectedRowKeys, selectedRowsData }) {
        this.setState({
            ...this.state,
            selectedOgrenciRowKeys: selectedRowKeys
        });
        console.log(this.state.selectedOgrenciRowKeys);
    }

    onExporting(e) {
        var workbook = new Workbook();
        var worksheet = workbook.addWorksheet('Main sheet');
        var PromiseArray = [];
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: (options) => {
                var { excelCell, gridCell } = options;
                if (gridCell.rowType === "data") {
                    if (gridCell.column.dataField === "Fotograf" && (gridCell.value !== undefined | gridCell.value !== "" | gridCell.value !== null)) {
                        excelCell.value = undefined;
                        PromiseArray.push(
                            new Promise((resolve, reject) => {
                                addImage(gridCell.value, workbook, worksheet, excelCell, resolve);
                            })
                        );
                    }
                }
            }
        }).then(function () {
            Promise.all(PromiseArray).then(() => {
                workbook.xlsx.writeBuffer().then(function (buffer) {
                    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Ogrenciler.xlsx");
                });
            });
        });
        e.cancel = true;
    }

    onInitializedSeciliOgrenciVeliSmsCheckBox(e) {
        this.checkBoxSeciliOgrenciVeliSmsCheckBox = e.component;
    }

    onInitializedSeciliOgrenciVeliNormalSmsCheckBox(e) {
        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox = e.component;
    }

    onInitializedSeciliOgrenciVeliKurumSmsCheckBox(e) {
        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox = e.component;
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <Popover
                    target="#btnSeciliOgrenciSms"
                    position="top"
                    width={400}
                    showTitle={true}
                    title={"Seçili Öğrencilere Sınav Sms Gönder"}
                    ref={(ref) => this.toolTipSeciliOgrenciSinavSms = ref}
                >
                    Seçilen öğrencilere seçtiğiniz sınav bilgisinin smsini gönderebilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnSmsRapor"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Sms Gönderim Bilgisi"}
                    ref={(ref) => this.toolTipSmsGonderimBilgi = ref}
                >
                    Seçilen öğrenciye ait sms gönderim raporu alabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnOgrenciDonemBilgi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Dönem Bilgisi"}
                    ref={(ref) => this.toolTipOgrenciDonemBilgi = ref}
                >
                    Seçtiğiniz öğrencinin hangi dönemlerde kayıtlı olduğu hakkında bilgi vermektedir.<br></br>
                </Popover>
                <Popover
                    target="#btnOgrenciAktar"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Aktar"}
                    ref={(ref) => this.toolTipOgrenciAktar = ref}
                >
                    Seçtiğiniz öğrencilerin yeni döneme aktarılmasını sağlar(sectiğiniz döneme).<br></br>
                </Popover>
                <Popover
                    target="#btnGecisYap"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Ekranına Geç"}
                    ref={(ref) => this.toolTipGecisYap = ref}
                >
                    Seçtiğiniz öğrencinin ekranına otomatik olarak geçiş yapabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnSecilenOgrenciBelge"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Belgesi"}
                    ref={(ref) => this.toolTipSecilenOgrenciBelge = ref}
                >
                    Seçilen  Öğrenciye öğrenci belgesi oluşturabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnSecilenOgrenciNormalSms"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Sms Gönder"}
                    ref={(ref) => this.toolTipSecilenOgrenciNormalSms = ref}
                >
                    Seçilen  Öğrenci ve Öğrenci Velilerine sms gönderebilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnNormalSms"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Sms Gönder"}
                    ref={(ref) => this.toolTipNormalSms = ref}
                >
                    Öğrenci veya Velilere sms gönderebilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnOgrenciOdevListesi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Ödev Listesi"}
                    ref={(ref) => this.toolTipOgrenciOdevListesi = ref}
                >
                    Seçili öğrenciye ait ödev listesini alabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnOgrenciEtutListesi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci Etüt Listesi"}
                    ref={(ref) => this.toolTipOgrenciEtutListesi = ref}
                >
                    Seçili öğrenciye ait etüt listesini alabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnMakbuz"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Makbuz"}
                    ref={(ref) => this.toolTipMakbuz = ref}
                >
                    Seçili takside ait ödeme makbuzunuzu alabilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnTahsilatSil"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Tahsilat Sil"}
                    ref={(ref) => this.toolTipTahsilatSil = ref}
                >
                    Seçili takside ait tahsilatları silebilirsiniz.<br></br>
                    <font style={{ color: "red" }}>Bu işlem kesinlikle geri alınamaz!</font>
                </Popover>
                <Popover
                    target="#btnTahsilatYap"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Tahsilat Yap"}
                    ref={(ref) => this.toolTipTahsilatYap = ref}
                >
                    Seçili takside ait tahsilatları yapabilirsiniz.<br></br>
                    Makbuz 1 (Taksit-Tahsilat tutarını içerir)<br></br>
                    Makbuz 2 (Taksit-Tahsilat tutarını içermez)<br></br>
                    Makbuz 3 (Taksit-Tahsilat bilgilerini içermez.)
                </Popover>
                <Popover
                    target="#btnSenet"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Senet"}
                    ref={(ref) => this.toolTipSenet = ref}
                >
                    Taksit sayısı kadar senet oluşturabilirsiniz.
                </Popover>
                <Popover
                    target="#btnTekSenet"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Tek Senet"}
                    ref={(ref) => this.toolTipTekSenet = ref}
                >
                    Ödeme tarihinin son taksit tarihi olduğu tek senet oluşturabilirsiniz.
                </Popover>
                <Popover
                    target="#btnYenidenOdemePlaniOlustur"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Yeniden Ödeme Planı Oluştur"}
                    ref={(ref) => this.toolTipYenidenOdemePlaniOlustur = ref}
                >
                    Hizmet Toplamı, İndirim, Peşinat ve Taksit Sayısını değiştirerek ödeme planını değiştirebilirsiniz.<br></br>
                    Tamamı veya bir kısmı tahsil edilmiş taksitlerde değişiklik yapılmaz.
                </Popover>
                <Popover
                    target="#btnOgrenciNoVerX"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenci No Ver"}
                    ref={(ref) => this.toolTipOgrenciNoVer = ref}
                >
                    Sınıf seviyesine göre otomatik öğrenci numarası verir.<br></br>
                    Hangi sayıdan devam etmesini istiyorsanız ilk kaydı o sayıdan başlatmalısınız.


                </Popover>
                <Popover
                    target="#btnDevamsizlikListesi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Devamsızlık Listesi"}
                    ref={(ref) => this.toolTipDevamsizlikListesi = ref}
                >
                    Devamsızlık Listelerini pdf formatında indirebilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnYoklamaListesi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Yoklama Listesi"}
                    ref={(ref) => this.toolTipYoklamaListesi = ref}
                >
                    Sınıf Yoklama Listelerini pdf formatında indirebilirsiniz.<br></br>
                </Popover>
                <Popover
                    target="#btnDanismanListesi"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Danışman Listesi"}
                    ref={(ref) => this.toolTipDanismanListesi = ref}
                >
                    Danışmanlara bağlı öğrenci Listelerini pdf formatında indirebilirsiniz.
                </Popover>
                <Popover
                    target="#btnOgrenciSil"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenciyi Sil"}
                    ref={(ref) => this.toolTipOgrenciSil = ref}
                >
                    Öğrenciye ait bütün bilgileri silebilirsiniz.<br></br>
                    <font style={{ color: "red" }}>Bu işlem kesinlikle geri alınamaz! </font>
                </Popover>
                <Popover
                    target="#btnOgrenciDuzenle"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Öğrenciyi Düzenle"}
                    ref={(ref) => this.toolTipOgrenciDuzenle = ref}
                >
                    Bilgileri düzenleyebilir veya yeni bilgiler ekleyebilirsiniz.
                </Popover>
                <Popover
                    target="#btnYeniOgrenciEkle"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Yeni Öğrenci Ekle"}
                    ref={(ref) => this.toolTipYeniOgrenciEkle = ref}
                >
                    Öğrenci, Anne, Baba ve Veli bilgilerini girerek kayıt ekleyebilirsiniz. <br></br>
                    Şube tanımı yapılmadan kayıt eklenemez.<br></br>
                    Şube tanımları  <font style={{ color: "red" }}> Genel Tanımlar</font> ekranından yapılmaktadır.
                </Popover>
                <Popover
                    target="#btnOdemePlaniOlustur"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Ödeme Planı Oluştur"}
                    ref={(ref) => this.toolTipOdemePlaniOlustur = ref}
                >
                    Ücret tanımlarınızı yaparak veya Dinamik Ücret ile Ödeme Planı oluşturabilirsiniz.<br></br>
                    Ücret ve İndirim tanımları <font style={{ color: "red" }}> Genel Tanımlar</font> ekranından yapılmaktadır.
                </Popover>
                <Popover
                    target="#btnOdemePlaniGoster"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Ödeme Planı Göster"}
                    ref={(ref) => this.toolTipOdemePlaniGoster = ref}
                >
                    Taksit ödemelerinin tahsilatlarını yapabilirsiniz.<br></br>
                    Ödeme Planını ve taksitleri yeniden yapılandırabilirsiniz.
                </Popover>
                <Popover
                    target="#btnSozlesmeBas"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Kayıt Sözleşmesi"}
                    ref={(ref) => this.toolTipKayitSozlesmesi = ref}
                >
                    Kayıt sözleşmesini pdf formatında indirebilirsiniz.<br></br>
                    Sözleşme oluşabilmesi için ödeme planı yapılmalıdır.
                </Popover>
                <Popover
                    target="#btnKayitListesiExcel"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Kayıt Listesi Excel"}
                    ref={(ref) => this.toolTipKayitListesiExcel = ref}
                >
                    Öğrenci listenizi Excel formatında indirebilirsiniz.
                </Popover>
                <Popover
                    target="#btnGorusmeler"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Görüşmeler"}
                    ref={(ref) => this.toolTipGorusmeler = ref}
                >
                    Öğrenci ile yapılan görüşmeleri takip edebilirsiniz.<br></br>
                    Her kullanıcı kendi görüşmelerini görebilir.
                </Popover>
                <Popover
                    target="#btnKesinKayitYap"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Bilgi"}
                    ref={(ref) => this.toolTipKesinKayitYap = ref}
                >
                    Seçilen öğrencilerin kayıt türünü kesin kayıt yapar
                </Popover>
                <Popover
                    target="#btnSubeDegistir"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Bilgi"}
                    ref={(ref) => this.toolTipSubeDegistir = ref}
                >
                    Seçilen öğrencilerin şube bilgilerini değiştirir
                </Popover>
                <Popover
                    target="#btnDanismanDegistir"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Bilgi"}
                    ref={(ref) => this.toolTipDanismanDegistir = ref}
                >
                    Seçilen öğrencilerin danışman bilgilerini değiştirir
                </Popover>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Öğrenci İşlemleri>>Öğrenci Kayıt"}</h4>
                            <DataGrid
                                showBorders={true}
                                keyboardNavigation={
                                    {
                                        editOnKeyPress: true,
                                        enterKeyAction: "startEdit",
                                        enabled: true,
                                        enterKeyDirection: "column"
                                    }
                                }
                                onContextMenuPreparing={
                                    (e) => {
                                        if (e.row.rowType === "data") {
                                            e.items = [{
                                                text: "Seçimi iptal et",
                                                onItemClick: () => {
                                                    this.gridMainSatirIptal();
                                                }
                                            },
                                            {
                                                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                                text: "Ödeme planı detay",
                                                onItemClick: () => {
                                                    this.odemePlaniDetay();
                                                }
                                            },
                                            {
                                                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                                text: "Ödeme planı oluştur",
                                                onItemClick: () => {
                                                    this.odemePlaniOlustur();
                                                }
                                            },
                                            {
                                                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                                text: "Kayıt Sözleşmesi",
                                                onItemClick: () => {
                                                    this.sozlesmeBas();
                                                }
                                            },
                                            {
                                                visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                                text: "Kayıt Listesi Excel",
                                                onItemClick: () => {
                                                    this.kayitListesiBas();
                                                }
                                            }]
                                        }
                                    }
                                }
                                showRowLines={true}
                                dataSource={this.ogrenciStore}
                                remoteOperations={{
                                    summary: true,
                                    paging: true,
                                    groupPaging: true,
                                    filtering: true,
                                    grouping: true,
                                    sorting: true
                                }}
                                repaintChangesOnly={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'multiple' }}
                                showCheckBoxesMode={true}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={true}
                                key="Id"
                                onEditorPreparing={this.onEditorPreparing}
                                onInitialized={this.onInitialized}
                                onFocusedRowChanged={(e) => {
                                    this.satirIndex = e.rowIndex;
                                    var rowData = e.row && e.row.data;
                                    if (rowData) {
                                        this.satir = rowData;
                                    }
                                }}
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export
                                    enabled={Globals.service.Rol === "SubeOgretmen" ? false : true}
                                    allowExportSelectedData={false}
                                    texts={
                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                    }
                                />
                                <Editing
                                    mode={'popup'}
                                    // texts={{
                                    //     confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>",
                                    //     confirmDeleteTitle: "Silme Onay"
                                    // }}
                                    popup={{
                                        title: "!!!T.C. No alanları öğrenci ve velinin kullanıcı adı olarak tanımlanmaktadır!!!",
                                        showTitle: true,
                                        width: "80%",
                                        height: "80%",
                                        shading: true,
                                        dragEnabled: true,
                                        showCloseButton: false,
                                        ref: (ref) => { this.popupForm = ref },
                                        close_ClickOnOutsideClick: false,

                                        position: {
                                            my: "center",
                                            at: "center",
                                            of: window
                                        },
                                        toolbarItems: [
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                                    onClick: async () => {
                                                        const validateStatus = this.formMain.instance().validate();

                                                        if (!validateStatus.isValid) {
                                                            notify("Lütfen formu kontrol ediniz, doldurulmayan alanlar var.", "warning", 5000);
                                                            return;
                                                        }
                                                        else {
                                                            if (this.selectBoxKayitTuru.instance().option("value") === 2) {
                                                                if (this.selectBoxSinifSube.instance().option("value") === null || this.selectBoxSinifSube.instance().option("value") === undefined || this.selectBoxSinifSube.instance().option("value") === 0) {
                                                                    notify("Lütfen formu kontrol ediniz, doldurulmayan alanlar var.", "warning", 5000);
                                                                    return;
                                                                }
                                                                else {
                                                                    await this.gridMain.instance.saveEditData().catch(err => {
                                                                        notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                                    });
                                                                    await this.gridMainSatirIptal();
                                                                }
                                                            }
                                                            else {
                                                                await this.gridMain.instance.saveEditData().catch(err => {
                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                                });
                                                                await this.gridMainSatirIptal();
                                                            }

                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                                    onClick: () => {
                                                        this.gridMain.instance.cancelEditData();
                                                    }
                                                }
                                            }
                                        ]
                                    }}
                                    form={{
                                        onInitialized: this.onInitializedFormMain,
                                        ref: (ref) => this.formMain = ref,
                                        colCount: 1,
                                        items: [
                                            {
                                                colSpan: 3,
                                                itemType: "group",
                                                items: [
                                                    {
                                                        itemType: "tabbed",
                                                        tabPanelOptions: {
                                                            deferRendering: false,
                                                            loop: true,
                                                            animationEnabled: true,
                                                            swipeEnabled: true
                                                        },
                                                        tabs: [
                                                            {
                                                                colCount: 2,
                                                                title: "Öğrenci Bilgileri",
                                                                items: [
                                                                    { dataField: "Adi" },
                                                                    { dataField: "Soyadi" },
                                                                    { dataField: "TcNo" },
                                                                    {
                                                                        dataField: "Cinsiyet",
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Value",
                                                                                    loadParams: {
                                                                                        enumName: "Cinsiyet"
                                                                                    },
                                                                                    loadUrl: otomasyonApi + 'common/getenums',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Value",
                                                                            displayExpr: "Name"
                                                                        }
                                                                    },
                                                                    { dataField: "CepTelefonu" },
                                                                    { dataField: "Email" },
                                                                    {
                                                                        dataField: "Sifre",
                                                                    },
                                                                    { dataField: "Adres" },
                                                                    {
                                                                        dataField: "AnneMeslek.Id",
                                                                        label: {
                                                                            text: "Anne Meslek"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listmeslek',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "BabaMeslek.Id",
                                                                        label: {
                                                                            text: "Baba Meslek"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listmeslek',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "DogumTarihi", editorType: "dxDateBox", editorOptions: {
                                                                            showClearButton: true
                                                                        }
                                                                    },
                                                                    { dataField: "File" },
                                                                    {
                                                                        dataField: "Fotograf", visible: true

                                                                    },
                                                                ],
                                                                itemTemplate: function (itemData, itemIndex, itemElement) {
                                                                    itemElement.append("<p style='color:#6600cc;'>" + itemData.Fotograf + "</p>");
                                                                }
                                                            },
                                                            {
                                                                colCount: 2,
                                                                title: "Anne-Baba ve Veli Bilgileri",
                                                                items: [
                                                                    { dataField: "AnneAdSoyad" },
                                                                    { dataField: "BabaAdSoyad" },
                                                                    { dataField: "VeliAdSoyad" },
                                                                    { dataField: "VeliTcNo" },
                                                                    { dataField: "VeliCepTelefonu" },
                                                                    { dataField: "VeliCepTelefonu2" },
                                                                    { dataField: "VeliEmail" },
                                                                    {
                                                                        dataField: "VeliSifre"
                                                                    },
                                                                    {
                                                                        dataField: "VeliYakinlik.Id",
                                                                        label: {
                                                                            text: "Veli Yakınlık"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listyakinlik',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "VeliMeslek.Id",
                                                                        label: {
                                                                            text: "Veli Meslek"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listmeslek',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    { dataField: "VeliAdres" }
                                                                ]
                                                            },
                                                            {
                                                                colCount: 2,
                                                                title: "Kayıt Bilgileri",
                                                                items: [
                                                                    { dataField: "KayitTarihi", editorType: "dxDateBox" },
                                                                    {
                                                                        dataField: "KayitTuru",
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            onInitialized: this.onInitializedSelectBoxKayiTuru,
                                                                            ref: (ref) => this.selectBoxKayitTuru = ref,
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Value",
                                                                                    loadParams: {
                                                                                        enumName: "KayitTuru"
                                                                                    },
                                                                                    loadUrl: otomasyonApi + 'common/getenums',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Value",
                                                                            displayExpr: "Name",
                                                                            onValueChanged: (e) => {
                                                                                if (this.gridMain.instance.option("focusedRowIndex") === -1) {
                                                                                    this.gridMain.instance.cellValue(0, "KayitTuru", e.value);
                                                                                } else {
                                                                                    this.gridMain.instance.cellValue(this.gridMain.instance.option("focusedRowIndex"), "KayitTuru", e.value);
                                                                                }
                                                                                this.selectBoxKayitTuru.instance().option("value", e.value);
                                                                                if (e.value !== 2) {
                                                                                    this.selectBoxSinifSube.instance().option("isRequired", false);
                                                                                    this.selectBoxSinifSube.instance().option("validate", false);
                                                                                    this.selectBoxSinifSube.instance().option("isValid", true);
                                                                                    this.selectBoxSinifSube.instance().option("validationStatus", "valid");
                                                                                    this.selectBoxSinifSube.instance().repaint();
                                                                                }
                                                                                else {
                                                                                    this.selectBoxSinifSube.instance().option("isRequired", true);
                                                                                    this.selectBoxSinifSube.instance().option("validate", true);
                                                                                    this.selectBoxSinifSube.instance().option("isValid", false);
                                                                                    this.selectBoxSinifSube.instance().option("validationStatus", "invalid");
                                                                                    this.selectBoxSinifSube.instance().repaint();
                                                                                }

                                                                            },
                                                                        }

                                                                    },
                                                                    {
                                                                        dataField: "Servis.Id",
                                                                        label: {
                                                                            text: "Servis"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listservisbykullanici',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "Yurt.Id",
                                                                        label: {
                                                                            text: "Yurt"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listyurtbykullanici',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    { dataField: "Yemek" },
                                                                    { dataField: "Kitap" },
                                                                    {
                                                                        dataField: "Grup.Id",
                                                                        label: {
                                                                            text: "Grup"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listgrupbykullanici',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "AyrilmaTarihi", editorType: "dxDateBox", editorOptions: {
                                                                            showClearButton: true
                                                                        }
                                                                    },
                                                                    { dataField: "Aciklama" }
                                                                ]
                                                            },
                                                            {
                                                                colCount: 2,
                                                                title: "Kurum-Sınıf Bilgileri",
                                                                items: [
                                                                    {
                                                                        dataField: "KurumId",
                                                                        label: {
                                                                            text: "Kurum"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: sinavApi + 'kurum/kurumlar',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "KurumAdi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "Bolum.Id",
                                                                        label: {
                                                                            text: "Bölüm"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listbolum',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "SinifSeviye.Id",
                                                                        label: {
                                                                            text: "Sınıf Seviyesi"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi",
                                                                            onInitialized: this.onInitializedSelectBoxSinifSeviye,
                                                                            ref: (ref) => this.selectBoxSinifSeviye = ref,
                                                                            onValueChanged: async (e) => {
                                                                                if (this.gridMain.instance.option("focusedRowIndex") === -1) {
                                                                                    this.gridMain.instance.cellValue(0, "SinifSeviye.Id", e.value);
                                                                                } else {
                                                                                    this.gridMain.instance.cellValue(this.gridMain.instance.option("focusedRowIndex"), "SinifSeviye.Id", e.value);
                                                                                }
                                                                                this.selectBoxSinifSeviye.instance().option("value", e.value);
                                                                                this.selectBoxSinifSube.instance().option("value", null);
                                                                                var dataSource = this.selectBoxSinifSube.instance().option("dataSource");
                                                                                dataSource.filter(["SeviyeId", "=", e.value]);
                                                                                await dataSource.load();
                                                                                this.selectBoxSinifSube.instance().option("dataSource", dataSource);
                                                                                if (this.btnOgrenciNoVer !== null && this.btnOgrenciNoVer !== undefined) {
                                                                                    this.btnOgrenciNoVer.instance().option("disabled", false);
                                                                                }

                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "SinifSube.Id",
                                                                        label: {
                                                                            text: "Sınıf Şubesi"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + "geneltanim/listsinifsubebykullanici",
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi",
                                                                            itemTemplate: (itemData, itemIndex, itemElement) => {
                                                                                return "<div>" + itemData.Adi + " (toplam Öğr. S:<span style='color:green;'>" + itemData.OgrenciSayisi + "</span>) (Dönem Öğr. S:<span style='color:red;'>" + itemData.DonemOgrenciSayisi + "</span>)</div>";
                                                                            },
                                                                            onInitialized: this.onInitializedSelectBoxSinifSube,
                                                                            ref: (ref) => this.selectBoxSinifSube = ref
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "OgrenciNo",

                                                                    },
                                                                    {
                                                                        dataField: "EOkulNo", editorOptions: {
                                                                            mode: "number",
                                                                            value: 0,
                                                                            onValueChanged: (e) => {
                                                                                if (e.value === null | e.value === undefined | e.value === "") {
                                                                                    e.component.option("value", 0);
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        dataField: "Danisman.Id",
                                                                        label: {
                                                                            text: "Danışman"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: sinavApi + 'kullanici/listdanismanbykullanici',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: (item) => {
                                                                                if (!item) {
                                                                                    return "";
                                                                                }
                                                                                else {
                                                                                    return item.Adi + " " + item.Soyadi + "(" + item.Brans + ")";
                                                                                }
                                                                            },
                                                                        }
                                                                    },
                                                                    { dataField: "Okulu" },
                                                                    {
                                                                        dataField: "EgitimTuru.Id",
                                                                        label: {
                                                                            text: "Eğitim Türü"
                                                                        },
                                                                        editorType: "dxSelectBox",
                                                                        editorOptions: {
                                                                            dataSource: new DataSource({
                                                                                loadMode: "raw",
                                                                                store: createStore({
                                                                                    key: "Id",
                                                                                    loadUrl: otomasyonApi + 'geneltanim/listegitimturu',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = {
                                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                                        };
                                                                                    }
                                                                                })
                                                                            }),
                                                                            valueExpr: "Id",
                                                                            displayExpr: "Adi"
                                                                        }
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                colCount: 1,
                                                                title: "Bilgi",
                                                                items: [
                                                                    {
                                                                        dataField: "GenelBilgi",
                                                                        editorType: "dxHtmlEditor",
                                                                        editorOptions: {
                                                                            height: 300,
                                                                            toolbar: {
                                                                                items: [
                                                                                    "bold",
                                                                                    "italic",
                                                                                    "color",
                                                                                    "background",
                                                                                    "link",
                                                                                    {
                                                                                        "formatName": "header",
                                                                                        "formatValues": [
                                                                                            1,
                                                                                            2,
                                                                                            3,
                                                                                            false
                                                                                        ]
                                                                                    },
                                                                                    "variable",
                                                                                    "strike",
                                                                                    "image",
                                                                                    "subscript",
                                                                                    "superscript",
                                                                                    "underline",
                                                                                    "blockquote",
                                                                                    "increaseIndent",
                                                                                    "decreaseIndent",
                                                                                    "orderedList",
                                                                                    "bulletList",
                                                                                    "alignLeft",
                                                                                    "alignCenter",
                                                                                    "alignRight",
                                                                                    "alignJustify",
                                                                                    "codeBlock",
                                                                                    "undo",
                                                                                    "redo",
                                                                                    "clear",
                                                                                    "separator"
                                                                                ],
                                                                                multiline: true
                                                                            },
                                                                        }
                                                                    }
                                                                ]
                                                            }

                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }}
                                    refreshMode={"repaint"}
                                >

                                </Editing>
                                <ColumnFixing enabled={true} />
                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? false : true} />
                                <LoadPanel enabled={false} />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Pager
                                    visible={true}
                                    allowedPageSizes={[15, 30, 45, 100]}
                                    displayMode={"full"}
                                    showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />

                                <Column dataField={'Id'} caption="Id" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" visible={false} />
                                <Column dataField={'IsOdemePlani'} caption="Ö. Planı Var mı?" dataType={"boolean"} />
                                <Column dataField={'Adi'} caption="Adı">
                                    <RequiredRule message="Ad alanı zorunludur" />
                                </Column>
                                <Column dataField={'Soyadi'} caption="Soyad">
                                    <RequiredRule message="Soyad alanı zorunludur" />
                                </Column>
                                <Column dataField={'Seviye'} caption="Sınıf" />
                                <Column dataField={'Sube'} caption="Şube" />
                                <Column dataField={'OgrenciNo'} caption="Öğrenci No" dataType="number" visible={true} calculateCellValue={(e) => {
                                    return e.OgrenciNo
                                }}>
                                    <RequiredRule message="Öğrenci no alanı zorunludur" />
                                </Column>
                                <Column dataField={'VeliCepTelefonu'} caption="Veli Cep Telefonu"
                                    editorOptions={{
                                        mask: "(000) 000-0000"
                                    }}
                                    visible={false}>
                                    <RequiredRule message="Veli Cep Telefonu alanı zorunludur" />
                                </Column>
                                <Column dataField={'KayitT'} caption="Kayıt Turu" />
                                <Column dataField={'EOkulNo'} caption="EOkul No" dataType="number" editorOptions={{
                                    mode: "number"
                                }} visible={false}>
                                </Column>
                                <Column dataField={'TcNo'}
                                    caption="T.C. No"
                                    dataType="string"
                                    visible={false}
                                    editorOptions={{
                                        mask: "00000000000"
                                    }}
                                >
                                    <RequiredRule message="T.C. No alanı zorunludur" />
                                </Column>
                                <Column dataField={'Cinsiyet'} caption="Cinsiyet" visible={false} />
                                <Column dataField={'CepTelefonu'} caption="CepTelefonu"
                                    editorOptions={{
                                        mask: "(000) 000-0000"
                                    }}
                                    visible={false}>
                                    <RequiredRule message="Cep Telefonu alanı zorunludur" />
                                </Column>
                                <Column dataField={'Email'} caption="Email" visible={false}>
                                </Column>
                                <Column dataField={'Adres'} caption="Adres" visible={false} />


                                {/* <Column dataField={'Fotograf'} caption="Fotoğraf" visible={false} allowFiltering={false} allowSorting={false} allowSearch={false} /> */}

                                <Column dataField={'File'} caption="Fotoğraf" showInColumnChooser={false} allowHiding={true} visible={false}
                                    setCellValue={(rowData, value) => {
                                        rowData.Fotograf = value[0].name;
                                    }}
                                />
                                <Column dataField={'AnneAdSoyad'} caption="Anne Ad Soyad" visible={false} />
                                <Column dataField={'BabaAdSoyad'} caption="Baba Ad Soyad" visible={false} />
                                <Column dataField={'VeliAdSoyad'} caption="Veli Ad Soyad" visible={false}>
                                    <RequiredRule message="Veli Ad Soyad alanı zorunludur" />
                                </Column>
                                <Column dataField={'VeliTcNo'}
                                    caption="Veli T.C. No"
                                    dataType="string"
                                    visible={false}
                                    editorOptions={{
                                        mask: "00000000000"
                                    }}
                                >
                                    <RequiredRule message="Veli T.C. No alanı zorunludur" />
                                </Column>

                                <Column dataField={'VeliCepTelefonu2'} caption="Veli Cep Telefonu 2"
                                    editorOptions={{
                                        mask: "(000) 000-0000"
                                    }}
                                    visible={false}>
                                </Column>
                                <Column dataField={'VeliEmail'} caption="Email" visible={false}>
                                </Column>
                                <Column dataField={'GenelBilgi'} caption="Bilgi" visible={false} showInColumnChooser={false}

                                >
                                </Column>
                                <Column dataField={'VeliYakinlik.Id'} visible={false} showInColumnChooser={false} />
                                <Column dataField={'VeliAdres'} caption="Adres" visible={false} />
                                <Column dataField={'KurumId'} caption="KurumId" visible={false} showInColumnChooser={false}>
                                    <RequiredRule message="Kurum alanı zorunludur" />
                                </Column>
                                <Column dataField={'Bolum.Id'} caption="Bölüm Id" visible={false} showInColumnChooser={false}>
                                    <RequiredRule message="Bölüm alanı zorunludur" />
                                </Column>
                                <Column
                                    dataField={'SinifSeviye.Id'}
                                    caption="Sınıf Seviye Id"
                                    visible={false}
                                    showInColumnChooser={false}>
                                    <RequiredRule message="Sınıf alanı zorunludur" />
                                </Column>
                                <Column dataField={'SinifSube.Id'} caption="Sınıf Şube Id" visible={false} showInColumnChooser={false}>
                                </Column>
                                <Column dataField={'Danisman.Id'} caption="Danışman Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'EgitimTuru.Id'} caption="Eğitim Türü Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'KayitTuru'} caption="Kayıt Türü" visible={false}>
                                    <RequiredRule message="Kayıt türü alanı zorunludur" />
                                </Column>
                                <Column dataField={'Okulu'} caption="Okulu" visible={false} />
                                <Column dataField={'KayitTarihi'} caption="Kayıt Tarihi" dataType={"date"} visible={false} />
                                <Column dataField={'DogumTarihi'} caption="Doğum Tarihi" dataType={"date"} visible={false} />
                                <Column dataField={'AyrilmaTarihi'} caption="Ayrılma Tarihi" dataType={"date"} visible={false} />
                                <Column dataField={'Servis.Id'} caption="Servis Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'Yurt.Id'} caption="Yurt Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'Grup.Id'} caption="Grup Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'VeliMeslek.Id'} caption="Veli Meslek Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'AnneMeslek.Id'} caption="Anne Meslek Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'BabaMeslek.Id'} caption="Baba Meslek Id" visible={false} showInColumnChooser={false} />
                                <Column dataField={'Yemek'} dataType="boolean" caption="Yemek" visible={false} />
                                <Column dataField={'Kitap'} dataType="boolean" caption="Kitap" visible={false} />
                                <Column dataField={'Bolumm'} caption="Bölüm" visible={false} />
                                <Column dataField={'Danismann'} caption="Danışman" visible={false} />
                                <Column dataField={'EgitimTuruu'} caption="Eğitim Türü" visible={false} />
                                <Column dataField={'Serviss'} caption="Servis" visible={false} />
                                <Column dataField={'Yurtt'} caption="Yurt" visible={false} />
                                <Column dataField={'Yakinlikk'} caption="Veli Yakınlık" visible={false} />
                                <Column dataField={'VeliMeslekk'} caption="Veli Meslek" visible={false} />
                                <Column dataField={'BabaMeslekk'} caption="Baba Meslek" visible={false} />
                                <Column dataField={'AnneMeslekk'} caption="Anne Meslek" visible={false} />
                                <Column dataField={'Aciklama'} caption="Açıklama" visible={false} />
                                <Column dataField={'Fotograf'} caption="Fotoğraf" visible={false} showInColumnChooser={true} editCellRender={this.renderSwitch} cellRender={cellRender} />
                                <Column dataField={'Sifre'} caption="Şifre" visible={false} showInColumnChooser={false}>
                                    <RequiredRule message="Şifre alanı zorunludur" />
                                </Column>
                                <Column dataField={'VeliSifre'} caption="Veli Şifre" visible={false} showInColumnChooser={false}>
                                    <RequiredRule message="Veli şifre alanı zorunludur" />
                                </Column>
                                <Column dataField={'Grupp'} caption="Grup" visible={false} />
                                <Column dataField={'KurumAdi'} caption="Kurum" visible={false} showInColumnChooser={true} />
                                <MasterDetail
                                    enabled={true}
                                    component={OgrenciDetail}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true} onInitialized={this.onInitializedPopupOdemePlani} ref={(ref) => this.popupOdemePlani = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"70%"} height={"70%"}
                    onHiding={this.popupOdemePlaniGizle} visible={this.state.odemePlaniVisible}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Ödeme Planı Oluştur', type: 'success', hint: 'Ödeme Planı Oluştur', icon: 'formula'
                                    , onClick: (e) => {
                                        const validateStatus = this.formOdemePlani.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            if (this.formDataOdemePlani.Ucret1.Id === null) {
                                                this.formDataOdemePlani.Ucret1.Id = 0;
                                            }
                                            if (this.formDataOdemePlani.Ucret2.Id === null) {
                                                this.formDataOdemePlani.Ucret2.Id = 0;
                                            }
                                            if (this.formDataOdemePlani.Ucret3.Id === null) {
                                                this.formDataOdemePlani.Ucret3.Id = 0;
                                            }
                                            if (this.formDataOdemePlani.Indirim.Id === null) {
                                                this.formDataOdemePlani.Indirim.Id = 0;
                                            }
                                            if (this.formDataOdemePlani.IndirimOrani === null) {
                                                this.formDataOdemePlani.IndirimOrani = 0;
                                            }
                                            service.post(otomasyonApi + "kayitkabul/odemeplaniolustur"
                                                , this.formDataOdemePlani
                                                , {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                .then(async (res) => {
                                                    if (res.data.Success) {
                                                        notify("Ödeme planı oluşturuldu", "success", 5000);
                                                        await this.popupOdemePlaniGizle();
                                                        await this.gridMain.instance.refresh();
                                                        await this.gridMainSatirIptal();

                                                    } else {
                                                        notify(res.data.ErrorMessage, "error", 5000);
                                                    }
                                                })
                                                .catch(err => {
                                                    if (err.response) {
                                                        var resError = JSON.parse(err.response.request.response);
                                                        var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                        var dictionary = Object.keys(resError.errors);
                                                        dictionary.forEach((satir, index) => {
                                                            mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                        });
                                                        mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                        mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                        mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                        alert(mesaj);
                                                        // client received an error response (5xx, 4xx)
                                                    } else if (err.request) {
                                                        // client never received a response, or request never left
                                                    } else {
                                                        // anything else
                                                    }
                                                })
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupOdemePlaniGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>

                        <Form formData={this.formDataOdemePlani} onInitialized={this.onInitializedFormOdemePlani} ref={(ref) => this.formOdemePlani = ref}>
                            <GroupItem cssClass="first-group" colCount={3} caption="Kullanıcı Bilgileri">
                                <SimpleItem dataField={"DinamikUcret"} editorOptions={{
                                    format: "#,##0.##"
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Dinamik Ücret" />
                                </SimpleItem>
                                <SimpleItem dataField={"Pesinat"} dataType={"number"} editorType={"dxNumberBox"} editorOptions={{
                                    format: "#,##0.##",

                                }}>
                                    <Label text="Peşinat" />
                                </SimpleItem>
                                <SimpleItem dataField={"IlkTaksit"} dataType={"bool"} editorType={"dxCheckBox"} editorOptions={{


                                }}>
                                    <Label text="İlk Taksit Peşinattır" />
                                </SimpleItem>
                                <SimpleItem dataField={"Ucret1.Id"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.ucretDataSource,
                                        remoteOperations: false,
                                        searchEnabled: false,
                                        showClearButton: true,
                                        displayExpr: (item) => {
                                            if (!item) {
                                                return "";
                                            }
                                            else {
                                                return "(" + item.Adi + " " + item.Tutar + ")";
                                            }
                                        },
                                        valueExpr: "Id"
                                    }}  >
                                    <Label text="Ücret Bir" />
                                </SimpleItem>
                                <SimpleItem dataField={"Ucret2.Id"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.ucretDataSource,
                                        remoteOperations: false,
                                        searchEnabled: false,
                                        showClearButton: true,
                                        displayExpr: (item) => {
                                            if (!item) {
                                                return "";
                                            }
                                            else {
                                                return "(" + item.Adi + " " + item.Tutar + ")";
                                            }
                                        },
                                        valueExpr: "Id"
                                    }}  >
                                    <Label text="Ücret İki" />
                                </SimpleItem>
                                <SimpleItem dataField={"Ucret3.Id"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.ucretDataSource,
                                        remoteOperations: false,
                                        searchEnabled: false,
                                        showClearButton: true,
                                        displayExpr: (item) => {
                                            if (!item) {
                                                return "";
                                            }
                                            else {
                                                return "(" + item.Adi + " " + item.Tutar + ")";
                                            }
                                        },
                                        valueExpr: "Id"
                                    }}  >
                                    <Label text="Ücret Üç" />
                                </SimpleItem>
                                <SimpleItem dataField={"Indirim.Id"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.indirimDataSource,
                                        remoteOperations: false,
                                        searchEnabled: false,
                                        showClearButton: true,
                                        displayExpr: (item) => {
                                            if (!item) {
                                                return "";
                                            }
                                            else {
                                                return "(" + item.Adi + " " + item.Tutar + ")";
                                            }
                                        },
                                        valueExpr: "Id"
                                    }}  >
                                    <Label text="İndirim" />
                                </SimpleItem>
                                <SimpleItem dataField={"IndirimOrani"} dataType={"number"} editorType={"dxNumberBox"}
                                    editorOptions={{
                                        format: "#0"
                                    }}
                                >
                                    <Label text="İndirim Oranı(Yüzde)" />
                                </SimpleItem>
                                <SimpleItem dataField={"TaksitSayisi"} dataType={"number"} editorType={"dxNumberBox"}
                                    editorOptions={{
                                        format: "#0"
                                    }}
                                >
                                    <Label text="Taksit Sayısı" />
                                </SimpleItem>

                                <SimpleItem dataField={"IlkTaksitTarihi"} editorOptions={{ value: null }} editorType={"dxDateBox"}>
                                    <Label text="İlk Taksit Tarihi" />
                                    <RequiredRule message="İlk Taksit Tarihi alanı zorunludur" />
                                </SimpleItem>
                                <SimpleItem dataField={"OdemeTuru"} editorType={"dxSelectBox"} editorOptions={{
                                    dataSource: this.odemeTuruDataSource,
                                    valueExpr: "Value",
                                    displayExpr: "Name"
                                }}>

                                </SimpleItem>
                                <SimpleItem dataField={"Aciklama"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                </Popup>

                {/* #region ödeme planı detay */}
                <Popup showTitle={true} shading={true} dragEnabled={true} onInitialized={this.onInitializedPopupOdemePlaniDetay} ref={(ref) => this.popupOdemePlaniDetay = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"70%"} height={"70%"}
                    onHiding={this.popupOdemePlaniDetayGizle} visible={this.state.odemePlaniDetayVisible}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    visible: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? true : false,
                                    text: 'Yeniden Ödeme Planı Oluştur',
                                    elementAttr: {
                                        id: "btnYenidenOdemePlaniOlustur"
                                    },
                                    type: 'success',
                                    icon: 'repeat',
                                    onContentReady: (e) => {
                                        e.element.addEventListener("mouseover", () => {
                                            this.toolTipYenidenOdemePlaniOlustur.instance.option("visible", true);
                                        });
                                        e.element.addEventListener("mouseleave", () => {
                                            this.toolTipYenidenOdemePlaniOlustur.instance.option("visible", false);
                                        });
                                    },
                                    onClick: () => {
                                        let result = confirm("<i><font color='red'>Ödeme planınızı tekrar yapılandırmak istediğinize emin misiniz?</font></i>", "Ödeme Planı Tekrar Oluşturma Onay");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                var yeniOdemePlaniData = {
                                                    OdemePlaniId: this.formDataOdemePlaniDetay.Id,
                                                    HizmetToplami: this.formDataOdemePlaniDetay.HizmetToplami,
                                                    Pesinat: this.formDataOdemePlaniDetay.Pesinat,
                                                    IndirimToplamTutar: this.formDataOdemePlaniDetay.IndirimToplamTutar,
                                                    TaksitSayisi: this.formDataOdemePlaniDetay.TaksitSayisi
                                                };
                                                service.post(otomasyonApi + "kayitkabul/odemeplaniyenidenyapilandir",
                                                    yeniOdemePlaniData,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    })
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            notify("Ödeme planınız tekrar yapılandırıldı", "success", 5000);
                                                            this.popupOdemePlaniDetayGizle();
                                                        }
                                                        else {
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    }).catch(err => {
                                                        notify(err, "error", 5000);
                                                    });
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupOdemePlaniDetayGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <Form colCount={1} formData={this.formDataOdemePlaniDetay} onInitialized={this.onInitializedFormOdemePlaniDetay} ref={(ref) => this.formOdemePlaniDetay = ref}>
                            <GroupItem cssClass="first-group" colCount={2} caption="Ödeme Planı Detayı">
                                <SimpleItem dataField={"HizmetToplami"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? false : true,
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Hizmet Toplamı" />
                                </SimpleItem>
                                <SimpleItem dataField={"TaksitSayisi"} editorOptions={{
                                    format: "#0",
                                    readOnly: ["SubeKayitKabul", "GenelMerkez", "Sube"].includes(Globals.service.Rol) ? false : true,
                                    buttons: [{
                                        location: "after",
                                        name: "btnTaksitYapilandir",
                                        options: {
                                            text: "Yeniden Taksitlendir",
                                            icon: "orderedlist",
                                            type: "default",
                                            visible: Globals.service.Rol === 'SubeKayitKabul' ? false : true,
                                            hint: "Yeniden Taksitlendir",
                                            onClick: () => {
                                                var yeniTaksitPlaniData = {
                                                    OdemePlaniId: this.formDataOdemePlaniDetay.Id,
                                                    KalanTutar: this.formDataOdemePlaniDetay.KalanTutar,
                                                    TaksitSayisi: this.formDataOdemePlaniDetay.TaksitSayisi
                                                };
                                                service.post(otomasyonApi + "kayitkabul/taksityapilandir",
                                                    yeniTaksitPlaniData,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    })
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            notify("Taksit planı tekrar yapılandırıldı", "success", 5000);
                                                            this.popupOdemePlaniDetayGizle();
                                                        }
                                                        else {
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    }).catch(err => {
                                                        notify(err, "error", 5000);
                                                    });
                                            }
                                        }
                                    }]
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Taksit Sayısı" />
                                </SimpleItem>
                                <SimpleItem dataField={"IndirimToplamTutar"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? false : true,
                                }} editorType={"dxNumberBox"}>
                                    <Label text="İndirim" />
                                </SimpleItem>
                                <SimpleItem dataField={"IlkTaksitTarihi"} editorOptions={{
                                    readOnly: true,
                                    onInitialized: (e) => {
                                        this.textBoxIlkTaksitTarihi = e.component;
                                    },
                                    ref: (ref) => { this.textBoxIlkTaksitTarihi = ref }
                                }} editorType={"dxDateBox"}

                                >
                                    <Label text="İlk Taksit Tarihi" />
                                </SimpleItem>
                                <SimpleItem dataField={"Pesinat"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? false : true,
                                    buttons: [{
                                        location: "after",
                                        name: "btnPesinatMakbuz",
                                        options: {
                                            text: "Peşinat Makbuz",
                                            icon: "print",
                                            type: "default",
                                            hint: "Peşinat Makbuz",
                                            disabled: false,
                                            onClick: () => {
                                                var pesinatMakbuzData = {
                                                    PesinatMiktari: this.formDataOdemePlaniDetay.Pesinat,
                                                    OgrenciId: this.state.OgrenciId
                                                };
                                                service.post(otomasyonApi + "kayitkabul/pesinatmakbuzyazdir",
                                                    pesinatMakbuzData,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    })
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            alert("Peşinat Makbuzu oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile peşinat makbuzunuzu bilgisayarınıza indiriniz</a>", "Bilgi");

                                                        }
                                                        else {
                                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                        }
                                                    })
                                                    .catch(err => {
                                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                    });
                                            }
                                        }
                                    }]
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Peşinat" />
                                </SimpleItem>
                                <SimpleItem dataField={"OdemeTuru"} editorOptions={{
                                    readOnly: true
                                }}>
                                    <Label text="Ödeme Türü" />
                                </SimpleItem>
                                <SimpleItem dataField={"OdenecekToplamTutar"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: true
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Ödenecek T. Tutar" />
                                </SimpleItem>
                                <SimpleItem dataField={"TahsilatDurumu"} editorOptions={{
                                    readOnly: true
                                }}>
                                    <Label text="Tahsilat Durumu" />
                                </SimpleItem>
                                <SimpleItem dataField={"KalanTutar"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: true,
                                    onInitialized: this.onInitializedTextBoxKalanTutar,
                                    ref: (ref) => this.textBoxKalanTutar = ref
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Kalan Tutar" />
                                </SimpleItem>
                                <SimpleItem dataField={"TaksitTutar"} editorOptions={{
                                    format: "#,##0.##",
                                    readOnly: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? false : true,
                                    buttons: [{
                                        location: "after",
                                        name: "btnTaksitTutaraGoreYapilandir",
                                        options: {
                                            text: "Yeniden Taksitlendir",
                                            icon: "orderedlist",
                                            type: "default",
                                            visible: ["Sube", "SubeMuhasebe", "SubeKayitMuhasebe", "GenelMerkez"].includes(Globals.service.Rol) ? true : false,
                                            hint: "Yeniden Taksitlendir",
                                            onClick: () => {
                                                let result = confirm("<i><font color='red'>Ödeme planınızı taksit tutarına göre yapılandırmak istediğinize emin misiniz?</font></i>", "Ödeme Planı Tekrar Oluşturma Onay");
                                                result.then((dialogResult) => {
                                                    if (dialogResult === true) {
                                                        var yeniTaksitPlaniData = {
                                                            OdemePlaniId: this.formDataOdemePlaniDetay.Id,
                                                            KalanTutar: this.formDataOdemePlaniDetay.KalanTutar,
                                                            TaksitTutar: this.formDataOdemePlaniDetay.TaksitTutar
                                                        };
                                                        service.post(otomasyonApi + "kayitkabul/taksittutarinagoreyenidenyapilandir",
                                                            yeniTaksitPlaniData,
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            })
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    notify("Taksit planı tekrar yapılandırıldı", "success", 5000);
                                                                    this.popupOdemePlaniDetayGizle();
                                                                }
                                                                else {
                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                                }
                                                            }).catch(err => {
                                                                notify(err, "error", 5000);
                                                            });
                                                    }
                                                });

                                            }
                                        }
                                    }]
                                }} editorType={"dxNumberBox"}>
                                    <Label text="Taksit Tutar" />
                                </SimpleItem>
                                <SimpleItem dataField={"Aciklama"} colSpan={2} editorOptions={{
                                    readOnly: true,

                                }} editorType={"dxTextBox"}>
                                    <Label text="Açıklama" />
                                </SimpleItem>

                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Taksitler">
                                <DataGrid
                                    id="grdTaksit"
                                    showBorders={true}
                                    showRowLines={true}
                                    dataSource={this.taksitDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    onInitialized={this.onInitializedGridTaksit}
                                    onToolbarPreparing={this.onToolbarPreparingGridTaksit}
                                    onFocusedRowChanged={this.onSelectionChangedGridTaksit}
                                    onCellPrepared={(e) => {
                                        if (e.rowType === "data" && e.column.command === "edit") {
                                            var isEditing = e.row.isEditing;

                                            if (isEditing) {
                                                jquery(e.cellElement.firstChild).on("click", () => {
                                                    if (e.row.data.Durum === "Ödendi" | e.row.data.Durum === "Kısmi Tahsilat") {
                                                        this.gridTaksit.instance.cancelEditData();
                                                    } else {
                                                        var data = {
                                                            TaksitId: e.row.data.Id,
                                                            Tarih: e.row.data.Tarih,
                                                            Tutar: e.row.data.Tutar,
                                                            KalanTutar: this.textBoxKalanTutar.instance().option("value")
                                                        };
                                                        if (e.row.data.TaksitNo === 1) {
                                                            this.textBoxIlkTaksitTarihi.instance().option("value", e.row.data.Tarih);
                                                        }
                                                        service.post(otomasyonApi + "kayitkabul/taksitSatiriDuzenle", data, {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    this.gridTaksit.instance.refresh();
                                                                } else {
                                                                    alert("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "Hata");
                                                                }
                                                            })
                                                            .catch(err => {
                                                                alert("Teknik bir hata oluştu, hata ayrıntısı:" + err, "Hata");
                                                            });

                                                    }

                                                });
                                            }
                                        }
                                    }}
                                    onEditorPreparing={(e) => {
                                        if (e.dataField === "Tarih" && e.parentType === "dataRow") {
                                            e.editorOptions.disabled = (e.row.data && e.row.data.Durum === "Ödendi");
                                        }
                                        if (e.dataField === "Tutar" && e.parentType === "dataRow") {
                                            e.editorOptions.disabled = (e.row.data && e.row.data.Durum === "Ödendi");
                                        }
                                    }}
                                    ref={(ref) => this.gridTaksit = ref}
                                >
                                    <Editing
                                        mode="row"
                                        allowUpdating={true}
                                    />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Kayıt No" visible={true} allowEditing={false} />
                                    <Column dataField={'TaksitNo'} caption="Taksit No" dataType={"number"} allowEditing={false} />
                                    <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                                    {/* <Column dataField={'TahsilatTarihi'} caption="Tahsilat Tarihi" dataType={"date"}
                                        calculateCellValue={(rowData) => {
                                            if (rowData.TahsilatTarihi === "0001-01-01T00:00:00") {
                                                return null;
                                            }
                                            else
                                                return new Date(rowData.TahsilatTarihi);
                                        }}
                                        allowEditing={false} /> */}
                                    <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} format={"#,##0.##"} />
                                    <Column dataField={'ToplamTahsilat'} caption="Tahsilat" dataType={"number"} format={"#,##0.##"} allowEditing={false} />
                                    <Column dataField={'Durum'} caption="Durum" dataType={"string"} allowEditing={false} />
                                    <Column dataField="Yayin_Adi" allowEditing={false} caption=""
                                        width={20}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        cellRender={durumRender}
                                    />
                                </DataGrid>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                </Popup>
                {/* #endregion */}

                {/* #region makbuz türü seçimi */}
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"40%"}
                    onHiding={this.popupMakbuzGizle} visible={this.state.makbuzVisible}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Makbuz Yazdır', type: 'success', hint: 'Makbuz Yazdır', icon: 'check'
                                    , onClick: () => {
                                        const validateStatus = this.formMakbuz.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            service.get(otomasyonApi + "kayitkabul/taksitmakbuzbas/" + this.formMakbuzData.TaksitId + "/" + this.formMakbuzData.MakbuzTuru,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                .then(res => {
                                                    if (res.data.Success) {
                                                        alert("Makbuzunuz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile makbuzunuzu bilgisayarınıza indiriniz</a>", "Bilgi");

                                                    }
                                                    else {
                                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                    }
                                                })
                                                .catch(err => {
                                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                });

                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupMakbuzGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <Form colCount={1} formData={this.formMakbuzData} onInitialized={this.onInitializedFormMakbuz} ref={(ref) => this.formMakbuz = ref}>
                            <GroupItem cssClass="first-group" colCount={2} caption="Makbuz Türü Seçimi">
                                <SimpleItem dataField={"MakbuzTuru"} editorType="dxSelectBox" editorOptions={{
                                    items: [{
                                        Value: 1, Name: "Makbuz 1(Detaylı)"
                                    },
                                    {
                                        Value: 2, Name: "Makbuz 2(Detaysız)"
                                    },
                                    {
                                        Value: 3, Name: "Makbuz 3(A5)"
                                    }],
                                    valueExpr: "Value",
                                    displayExpr: "Name",
                                    onValueChanged: (e) => {
                                        this.formTahsilatData.MakbuzTuru = e.value;
                                    }
                                }}>
                                    <Label text="Makbuz türü"></Label>
                                    <RequiredRule message="Makbuz türü alanı zorunludur" />
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                </Popup>
                {/* #endregion */}

                {/* #region tahsilat */}
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"40%"}
                    onHiding={this.popupTahsilatGizle} visible={this.state.tahsilatVisible}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Tahsilat Yap', type: 'success', hint: 'Tahsilat Yap', icon: 'check'
                                    , onClick: () => {
                                        const validateStatus = this.formTahsilat.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            var t = new Date(this.formTahsilatData.TahsilatTarihi);
                                            t.setHours(t.getHours() + 3);
                                            this.formTahsilatData.TahsilatTarihi = t;
                                            this.formTahsilatData.Tahsilat = this.formTahsilatData.Tahsilat.toFixed(3);
                                            service.post(otomasyonApi + "kayitkabul/tahsilatyap",
                                                this.formTahsilatData,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                }
                                            ).then(async (res) => {
                                                if (res.data.Success) {
                                                    await this.gridTaksit.instance.refresh();
                                                    await service.get(otomasyonApi + "kayitkabul/gettoplamtahsilat/" + this.satirTaksit.OdemePlani.Id, {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    })
                                                        .then(res => {
                                                            const kalanOdeme = parseFloat(res.data.Data);
                                                            this.textBoxKalanTutar.instance().option("value", kalanOdeme);
                                                            if (this.gridTaksit.instance !== null) {
                                                                this.gridTaksit.instance.clearSelection();
                                                                this.satirTaksit = null;
                                                                this.gridTaksit.instance.option("focusedRowIndex", -1);
                                                            }
                                                            this.setState({
                                                                tahsilatVisible: false
                                                            })
                                                        });


                                                }
                                                else {
                                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                }
                                            })
                                                .catch(err => {
                                                    alert(err, "Hata");
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupTahsilatGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <Form colCount={1} formData={this.formTahsilatData} onInitialized={this.onInitializedFormTahsilat} ref={(ref) => this.formTahsilat = ref}>
                            <GroupItem cssClass="first-group" colCount={2} caption="Tahsilat Girişi">
                                <SimpleItem dataField={"TaksitOdemeTuru"} editorType="dxSelectBox" editorOptions={
                                    {
                                        dataSource: new DataSource({
                                            loadMode: "raw",
                                            store: createStore({
                                                key: "Value",
                                                loadParams: {
                                                    enumName: "TaksitOdemeTuru"
                                                },
                                                loadUrl: otomasyonApi + 'common/getenums',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    };
                                                }
                                            })
                                        }),
                                        valueExpr: "Value",
                                        displayExpr: "Name",
                                        showClearButton: true
                                    }
                                }>
                                    <Label text="Taksit ödeme türü"></Label>
                                    <RequiredRule message="Taksit ödeme türü alanı zorunludur" />
                                </SimpleItem>
                                <SimpleItem dataField={"BankaId"} editorType="dxSelectBox" editorOptions={
                                    {
                                        dataSource: new DataSource({
                                            loadMode: "raw",
                                            store: createStore({
                                                key: "Id",
                                                loadUrl: otomasyonApi + 'geneltanim/listbankatanimbykullanici',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    };
                                                }
                                            })
                                        }),
                                        valueExpr: "Id",
                                        displayExpr: "Adi",
                                        showClearButton: true
                                    }
                                }>
                                    <Label text="Banka"></Label>
                                </SimpleItem>
                                <SimpleItem dataField={"Tahsilat"} editorType="dxNumberBox" editorOptions={{
                                    format: "#,##0.##"
                                }}>
                                    <Label text="Tahsilat Tutarı"></Label>
                                    <RequiredRule message="Tahsilat Tutar alanı zorunludur" />
                                </SimpleItem>

                                <SimpleItem dataField={"TahsilatTarihi"} editorType={"dxDateBox"} editorOptions={{
                                    type: "datetime"
                                }}>
                                    <Label text="Tahsilat Tarihi" />
                                    <RequiredRule message="Tahsilat tarihi alanı zorunludur" />
                                </SimpleItem>
                                <SimpleItem dataField="SlipNo" colSpan={2}>
                                    <Label text="SlipNo"></Label>
                                </SimpleItem>
                                <SimpleItem dataField="Aciklama" colSpan={2}>
                                    <Label text="Açıklama"></Label>
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                </Popup>
                {/* #endregion */}

                {/* #region tahsilat detay*/}
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"40%"}
                    onHiding={this.popupTahsilatDetayGizle} visible={this.state.tahsilatDetayVisible}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupTahsilatDetayGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <Form colCount={1}>
                            <GroupItem cssClass="first-group" colCount={2} caption="Tahsilat Detayı">
                                <DataGrid
                                    id="grdTahsilatDetay"
                                    showBorders={true}
                                    showRowLines={true}
                                    dataSource={this.tahsilatDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    onFocusedRowChanged={this.onSelectionChangedGridTaksitTahsilat}
                                    onInitialized={this.onInitializedGridTaksitTahsilat}
                                    onToolbarPreparing={this.onToolbarPreparingGridTaksitTahsilat}
                                    ref={(ref) => this.gridTaksitTahsilat = ref}
                                >

                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Kayıt No" visible={true} allowEditing={false} />
                                    <Column dataField={'TaksitNo'} caption="Taksit No" dataType={"number"} allowEditing={false} />
                                    <Column dataField={'TahsilatTarihi'} caption="Tahsilat Tarihi" dataType={"datetime"}
                                        calculateCellValue={(rowData) => {
                                            if (rowData.TahsilatTarihi === "0001-01-01T00:00:00") {
                                                return null;
                                            }
                                            else
                                                return new Date(rowData.TahsilatTarihi);
                                        }}
                                        allowEditing={false} />
                                    <Column dataField={'Tahsilat'} caption="Tahsilat" dataType={"number"} format={"#,##0.##"} allowEditing={false} />
                                    <Column dataField={'OdemeTuru'} caption="Durum" dataType={"string"} allowEditing={false} />
                                    <Column dataField={'BankaAdi'} caption="Banka" dataType={"string"} allowEditing={false} />
                                    <Column dataField={'Aciklama'} caption="Açıklama" dataType={"string"} allowEditing={false} />
                                    <Column dataField={'SlipNo'} caption="SlipNo" dataType={"string"} allowEditing={false} />
                                </DataGrid>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                </Popup>
                {/* #endregion */}


                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"90%"} height={"90%"}
                    visible={this.state.gorusmeVisible}
                    title={this.state.gorusmeBaslik}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => {
                                        this.popupGorusmeGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <form id="form" onSubmit={(e) => {
                                    this.formGorusmeData.Ogrenci.Id = this.state.OgrenciId;
                                    service.post(otomasyonApi + "kayitkabul/gorusmenotuekle", this.formGorusmeData, {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    })
                                        .then((res) => {
                                            if (res.data.Success) {
                                                notify("Görüşme notunuz eklendi", "success", 5000);
                                                this.ogrenciFilter.ogrenciId = this.state.OgrenciId;
                                                this.grdiGorusmeNotu.instance.refresh();
                                            } else {
                                                notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                            }
                                        })
                                        .catch(err => {
                                            if (err.response) {
                                                var resError = JSON.parse(err.response.request.response);
                                                var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                var dictionary = Object.keys(resError.errors);
                                                dictionary.forEach((satir, index) => {
                                                    mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                });
                                                mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                alert(mesaj);
                                                // client received an error response (5xx, 4xx)
                                            } else if (err.request) {
                                                // client never received a response, or request never left
                                            } else {
                                                // anything else
                                            }
                                        });
                                    e.preventDefault();
                                }}>
                                    <Form colCount={1} formData={this.formGorusmeData} onInitialized={this.onInitializedFormGorusme} ref={(ref) => this.formGorusme = ref}>
                                        <GroupItem cssClass="first-group" colCount={2} caption="Görüşme Notu">
                                            <SimpleItem dataField={"Tarih"} editorType={"dxDateBox"}>
                                                <Label text="Görüşme Tarihi" />
                                            </SimpleItem>
                                            <SimpleItem dataField={"IsVeliGorsun"} editorType={"dxCheckBox"}>
                                                <Label text="Veli Görsün" />
                                            </SimpleItem>
                                            <SimpleItem dataField="Aciklama" colSpan={2}>
                                                <Label text="Görüşme notu"></Label>
                                                <RequiredRule message="Görüşme notu alanı zorunludur" />
                                            </SimpleItem>

                                        </GroupItem>
                                        <ButtonItem horizontalAlignment="left"
                                            buttonOptions={{
                                                text: "Ekle",
                                                hint: "Ekle",
                                                type: "success",
                                                icon: "add",
                                                useSubmitBehavior: true
                                            }}
                                        />
                                    </Form><br></br>
                                </form>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <DataGrid
                                    id="grdGorusmeNotu"
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.gorusmeNotuDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    export={{
                                        enabled: true,
                                        fileName: "notlar"
                                    }}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    onInitialized={this.onInitializedGridGorusmeNotu}
                                    onToolbarPreparing={this.onToolbarPreparingGridGorusmeNotu}
                                    onFocusedRowChanged={this.onSelectionChangedGridGorusmeNotu}
                                    ref={(ref) => this.grdiGorusmeNotu = ref}
                                >
                                    <Export
                                        fileName={'Notlar'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                    <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                                    <Column dataField={'KullaniciAdi'} caption="Gerçekleştiren" />
                                    <Column dataField={'Aciklama'} caption="Görüşme Notu" hidingPriority={0} dataType={"string"} />
                                    <Column dataField={'IsVeliGorsun'} caption="Veli Görsün" hidingPriority={0} dataType={"boolean"} />
                                </DataGrid>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.subeDegisimVisible}
                    title={"Seçili öğrencilerin şube bilgilerini değiştir"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.gridMainSatirIptal();
                                        await this.popupSubeDegisimGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">

                                <Form colCount={1} formData={this.formSubeDegisimiData} onInitialized={this.onInitializedFormSubeDegisimi} ref={(ref) => this.formSubeDegisimi = ref}>
                                    <GroupItem cssClass="first-group" colCount={2}>
                                        <SimpleItem dataField={"SinifSeviyeId"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                dataSource: new DataSource({
                                                    loadMode: "raw",
                                                    store: createStore({
                                                        key: "Id",
                                                        loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                                                        onBeforeSend: (method, ajaxOptions) => {
                                                            ajaxOptions.headers = {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            };
                                                        }
                                                    })
                                                }),
                                                valueExpr: "Id",
                                                displayExpr: "Adi",
                                                onValueChanged: async (e) => {
                                                    this.selectBoxSubeDegisimiSinifSube.instance().option("value", null);
                                                    var dataSource = this.selectBoxSubeDegisimiSinifSube.instance().option("dataSource");
                                                    dataSource.filter(["SeviyeId", "=", e.value]);
                                                    await dataSource.load();
                                                    this.selectBoxSubeDegisimiSinifSube.instance().option("dataSource", dataSource);
                                                }

                                            }}
                                        >
                                            <RequiredRule message="Sınıf seviyesi alanı zorunludur" />
                                            <Label text="Sınıf seviyesi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SubeId"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                dataSource: new DataSource({
                                                    loadMode: "raw",
                                                    store: createStore({
                                                        key: "Id",
                                                        loadUrl: otomasyonApi + "geneltanim/listsinifsubebykullanici",
                                                        onBeforeSend: (method, ajaxOptions) => {
                                                            ajaxOptions.headers = {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            };
                                                        }
                                                    })
                                                }),
                                                valueExpr: "Id",
                                                displayExpr: "Adi",
                                                onInitialized: this.onInitializedSelectBoxSubeDegisimiSinifSube,
                                                ref: (ref) => this.selectBoxSubeDegisimiSinifSube = ref

                                            }}
                                        >
                                            <RequiredRule message="Sınıf şubesi alanı zorunludur" />
                                            <Label text="Sınıf şubesi" />
                                        </SimpleItem>

                                    </GroupItem>
                                    <ButtonItem horizontalAlignment="left"
                                        buttonOptions={{
                                            text: "Şube Seçimini Değiştir",
                                            hint: "Ekle",
                                            type: "success",
                                            icon: "pulldown",
                                            onClick: () => {
                                                let ogrenciData = [];
                                                let ogrenciInfoData = "şube bilgisini değiştirmek istediğiniz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                                this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                                    ogrenciData.push({
                                                        OgrenciId: parseInt(row)
                                                    })
                                                });
                                                ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";
                                                this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                                    ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                                });
                                                ogrenciInfoData += "</div>";
                                                this.formSubeDegisimiData.Ogrenciler = ogrenciData;
                                                let result = confirm(ogrenciInfoData, "Şube Değiştirme Onay");
                                                result.then((dialogResult) => {
                                                    if (dialogResult === true) {

                                                        service.post(otomasyonApi + "kayitkabul/subesecimidegistir", this.formSubeDegisimiData, {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                            .then(async (res) => {
                                                                if (res.data.Success) {
                                                                    notify("Görüşme notunuz eklendi", "success", 5000);
                                                                    await this.gridMainSatirIptal();
                                                                    await this.gridMain.instance.refresh().done(() => {

                                                                        this.popupSubeDegisimGizle();
                                                                    });
                                                                } else {
                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                                }
                                                            })
                                                            .catch(err => {
                                                                if (err.response) {
                                                                    var resError = JSON.parse(err.response.request.response);
                                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                    var dictionary = Object.keys(resError.errors);
                                                                    dictionary.forEach((satir, index) => {
                                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                    });
                                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                    alert(mesaj);
                                                                    // client received an error response (5xx, 4xx)
                                                                } else if (err.request) {
                                                                    // client never received a response, or request never left
                                                                } else {
                                                                    // anything else
                                                                }
                                                            });
                                                        // e.preventDefault();
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </Form><br></br>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.danismanDegisimiVisible}
                    title={"Seçili öğrencilerin danışman bilgilerini değiştir"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.gridMainSatirIptal();
                                        await this.popupDanismanDegisimGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">

                                <Form colCount={1} formData={this.formDanismanDegisimiData} onInitialized={this.onInitializedFormDanismanDegisimi} ref={(ref) => this.formDanismanDegisimi = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField={"DanismanId"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                dataSource: new DataSource({
                                                    loadMode: "raw",
                                                    store: createStore({
                                                        key: "Id",
                                                        loadUrl: sinavApi + 'kullanici/listdanismanbykullanici',
                                                        onBeforeSend: (method, ajaxOptions) => {
                                                            ajaxOptions.headers = {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            };
                                                        }
                                                    })
                                                }),
                                                valueExpr: "Id",
                                                displayExpr: (item) => {
                                                    if (!item) {
                                                        return "";
                                                    }
                                                    else {
                                                        return item.Adi + " " + item.Soyadi + "(" + item.Brans + ")";
                                                    }
                                                },

                                            }}
                                        >
                                            <RequiredRule message="Danışman alanı zorunludur" />
                                            <Label text="Danışman" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <ButtonItem horizontalAlignment="left"
                                        buttonOptions={{
                                            text: "Danışman Seçimini Değiştir",
                                            hint: "Ekle",
                                            type: "success",
                                            icon: "pulldown",
                                            onClick: () => {
                                                let ogrenciData = [];
                                                let ogrenciInfoData = "danışman seçimini değiştirmek istediğiniz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                                this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                                    ogrenciData.push({
                                                        OgrenciId: parseInt(row)
                                                    })
                                                });
                                                ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";
                                                this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                                    ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                                });
                                                ogrenciInfoData += "</div>";
                                                this.formDanismanDegisimiData.Ogrenciler = ogrenciData;
                                                let result = confirm(ogrenciInfoData, "Danışman Değiştirme Onay");
                                                result.then((dialogResult) => {
                                                    if (dialogResult === true) {
                                                        service.post(otomasyonApi + "kayitkabul/danismansecimidegistir", this.formDanismanDegisimiData, {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                            .then(async (res) => {
                                                                if (res.data.Success) {
                                                                    notify("Danışman atamaları yapıldı.", "success", 5000);
                                                                    await this.gridMainSatirIptal();
                                                                    await this.gridMain.instance.refresh().done(() => {

                                                                        this.popupDanismanDegisimGizle();
                                                                    });
                                                                } else {
                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                                }
                                                            })
                                                            .catch(err => {
                                                                if (err.response) {
                                                                    var resError = JSON.parse(err.response.request.response);
                                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                    var dictionary = Object.keys(resError.errors);
                                                                    dictionary.forEach((satir, index) => {
                                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                    });
                                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                    alert(mesaj);
                                                                    // client received an error response (5xx, 4xx)
                                                                } else if (err.request) {
                                                                    // client never received a response, or request never left
                                                                } else {
                                                                    // anything else
                                                                }
                                                            });
                                                        //e.preventDefault();
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.normalSmsVisible}
                    title={"Kurum Sms Ekranı"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Sms Gönder',
                                    type: 'success',
                                    hint: 'Sms Gönder',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: true,
                                    onClick: async (e) => {
                                        let messageInfo = "Seçtiğiniz kriterlere göre kurum öğrenci veya kurum öğrenci velilerine sms gönderilecektir.<br/>";
                                        let result = confirm(messageInfo, "Kurum Sms Gönderim Onayı(Öğrenci veya Öğrenci Veli)");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.setState({
                                                    toolBarDisabled: true
                                                });
                                                service.post(otomasyonApi + "sms/postNormalSmsGonder", this.formNormalSmsData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupNormalSmsGizle();
                                                            notify("Gönderim işleminiz gerçekleşti", "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupNormalSmsGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formNormalSmsData} onInitialized={this.onInitializedFormNormalSms} ref={(ref) => this.formNormalSms = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField={"GonderimTipi"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                items: [
                                                    { Name: "Öğrencilere Gönder", Value: 1 },
                                                    { Name: "Velilere Gönder", Value: 2 },
                                                    { Name: "Öğrenci ve Velilere Gönder", Value: 3 },
                                                    { Name: "Öğretmenlere Gönder", Value: 4 },

                                                ],
                                                valueExpr: "Value",
                                                displayExpr: "Name",
                                                onValueChanged: (e) => {
                                                    if (e.value === 2 | e.value === 3) {
                                                        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox.instance().option("visible", true);
                                                        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox.instance().option("value", false);
                                                    }
                                                    else {
                                                        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox.instance().option("visible", false);
                                                        this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox.instance().option("value", false);
                                                    }
                                                }
                                            }}
                                        >
                                            <RequiredRule message="GonderimTipi alanı zorunludur" />
                                            <Label text="Gönderim Tipi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Mesaj"} editorType={"dxTextBox"} editorOptions={{
                                            onInitialized: (e) => {
                                                this.normalSmsSecilenOgrenciMesajTextBox = e.component;
                                            },
                                            ref: (ref) => { this.normalSmsSecilenOgrenciMesajTextBox = ref },
                                            onKeyUp: (e) => {
                                                document.getElementById("divKarekterSayisiNormalSms").innerText = this.normalSmsSecilenOgrenciMesajTextBox.instance().option("text").length;
                                            }
                                        }}>
                                            <RequiredRule message="Mesaj alanı zorunludur" />
                                            <Label text="Mesaj" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"VeliCepTelEkstra"} editorType={"dxCheckBox"}
                                            editorOptions={{
                                                text: "Veli Cep Tel 2' ye de gitsin",
                                                visible: false,
                                                onInitialized: this.onInitializedSeciliOgrenciVeliKurumSmsCheckBox,
                                                ref: (ref) => { this.checkBoxSeciliOgrenciVeliKurumSmsCheckBox = ref }
                                            }}
                                        >
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label>Mesaj Karekter Sayısı:</label>
                                            </div>
                                            <div className="col-md-6" id="divKarekterSayisiNormalSms" style={{ textAlign: "left" }}>

                                            </div>
                                        </div>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.smsSinavVisible}
                    title={"Seçili Öğrencilere Sınav Sms Gönder"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Seçili Öğrencilere Sınav Sms Gönder',
                                    type: 'success',
                                    hint: 'Seçili Öğrencilere Sınav Sms Gönder',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: true,
                                    onClick: async (e) => {

                                        let ogrenciData = [];
                                        let ogrenciInfoData = "Sms gönderilecek olan ve seçmiş olduğunuz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                        this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                            ogrenciData.push({
                                                OgrenciId: parseInt(row)
                                            })
                                        });
                                        ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";
                                        this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                            ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                        });
                                        ogrenciInfoData += "</div>";
                                        this.formSinavSmsData.Ogrenciler = ogrenciData;
                                        let result = confirm(
                                            ogrenciInfoData,
                                            "Sms Gönderim Onayı");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.setState({
                                                    toolBarDisabled: true
                                                });
                                                service.post(otomasyonApi + "sms/postsecilenogrencisinavsonucugonder", this.formSinavSmsData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupSmsSinavGizle();
                                                            notify("Gönderim işleminiz gerçekleşti", "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupSmsSinavGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formSinavSmsData} onInitialized={this.onInitializedFormSinavSms} ref={(ref) => this.formSinavSms = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField={"YayinId"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.yayinDataSource,
                                                keyExpr: "Id",
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                showClearButton: true,
                                                onInitialized: this.onInitializedSelectBoxYayin,
                                                ref: (ref) => { this.selectBoxYayin = ref },
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null | e.value === undefined) {

                                                    }
                                                    else {
                                                        this.yayinfilter.YayinId = e.value;
                                                        this.sinavTuruFilter.YayinId = e.value;
                                                        this.sinavTuruDataSource.load();

                                                    }

                                                }
                                            }}>
                                            <Label text="Yayın" />
                                            <RequiredRule message="Yayın alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinavTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruDataSource,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                onInitialized: this.onInitializedSelectBoxSinavTuru,
                                                ref: (ref) => { this.selectBoxSinavTuru = ref },
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null) {
                                                        return;
                                                    }
                                                    else {
                                                        this.sinavTuruFilter.SinavTuruId = e.value;
                                                        this.merkeziSinavDataSource.load();
                                                    }
                                                }
                                            }}>
                                            <Label text="Sınav Türü" />
                                            <RequiredRule message="Sınav türü alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MerkeziSinavId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.merkeziSinavDataSource,
                                                displayExpr: (item) => {
                                                    if (!item) {
                                                        return "";
                                                    }
                                                    else {
                                                        return item.Kodu + " - " + item.Adi;
                                                    }
                                                },
                                                valueExpr: "Id",
                                                itemTemplate: (itemData, itemIndex, itemElement) => {
                                                    return "<div style='color:" + itemData.Renk + "'>" + itemData.Kodu + "-" + itemData.Adi + "</div>";
                                                },
                                                onInitialized: this.onInitializedSelectBoxMerkeziSinav,
                                                ref: (ref) => { this.selectBoxMerkeziSinav = ref }
                                            }}>
                                            <Label text="Sınav" />
                                            <RequiredRule message="Merkez sınav alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"GonderimTipi"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                items: [
                                                    { Name: "Öğrencilere Gönder", Value: 1 },
                                                    { Name: "Velilere Gönder", Value: 2 },
                                                    { Name: "Öğrenci ve Velilere Gönder", Value: 3 }

                                                ],
                                                valueExpr: "Value",
                                                displayExpr: "Name",
                                                onValueChanged: (e) => {
                                                    if (e.value === 2 | e.value === 3) {
                                                        this.checkBoxSeciliOgrenciVeliSmsCheckBox.instance().option("visible", true);
                                                        this.checkBoxSeciliOgrenciVeliSmsCheckBox.instance().option("value", false);
                                                    }
                                                    else {
                                                        this.checkBoxSeciliOgrenciVeliSmsCheckBox.instance().option("visible", false);
                                                        this.checkBoxSeciliOgrenciVeliSmsCheckBox.instance().option("value", false);
                                                    }
                                                }
                                            }}
                                        >
                                            <RequiredRule message="GonderimTipi alanı zorunludur" />
                                            <Label text="Gönderim Tipi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"VeliCepTelEkstra"} editorType={"dxCheckBox"}
                                            editorOptions={{
                                                text: "Veli Cep Tel 2' ye de gitsin",
                                                visible: false,
                                                onInitialized: this.onInitializedSeciliOgrenciVeliSmsCheckBox,
                                                ref: (ref) => { this.checkBoxSeciliOgrenciVeliSmsCheckBox = ref }
                                            }}
                                        >
                                        </SimpleItem>
                                    </GroupItem>

                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.secilenOgrenciNormalSmsVisible}
                    title={"Seçili Öğrenci Sms ve Bildirim Ekranı"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Bildirim Gönder',
                                    type: 'success',
                                    hint: 'Bildirim Gönder',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: true,
                                    onClick: async (e) => {
                                        let ogrenciData = [];
                                        let ogrenciInfoData = "Bildirim gönderilecek olan ve seçmiş olduğunuz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                        this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                            ogrenciData.push({
                                                OgrenciId: parseInt(row)
                                            })
                                        });
                                        ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";

                                        this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                            ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                        });
                                        ogrenciInfoData += "</div>";
                                        let result = confirm(ogrenciInfoData, "Bildirim Gönderim Onayı");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.formSecilenOgrenciNormalSmsData.Ogrenciler = ogrenciData;
                                                this.setState({
                                                    toolBarDisabled: true
                                                });

                                                service.post(otomasyonApi + "sms/postsecilenogrencilerebildirimgonder", this.formSecilenOgrenciNormalSmsData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupSecilenOgrenciNormalSmsGizle();
                                                            notify("Bildirim gönderim işleminiz gerçekleşti", "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });

                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Sms Gönder',
                                    type: 'success',
                                    hint: 'Sms Gönder',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: true,
                                    onClick: async (e) => {
                                        let ogrenciData = [];
                                        let ogrenciInfoData = "Sms gönderilecek olan ve seçmiş olduğunuz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                        this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                            ogrenciData.push({
                                                OgrenciId: parseInt(row)
                                            })
                                        });
                                        ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";

                                        this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                            ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                        });
                                        ogrenciInfoData += "</div>";
                                        let result = confirm(ogrenciInfoData, "Sms Gönderim Onayı");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.formSecilenOgrenciNormalSmsData.Ogrenciler = ogrenciData;
                                                this.setState({
                                                    toolBarDisabled: true
                                                });

                                                service.post(otomasyonApi + "sms/postSecilenOgrenciNormalSmsGonder", this.formSecilenOgrenciNormalSmsData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupSecilenOgrenciNormalSmsGizle();
                                                            notify("Gönderim işleminiz gerçekleşti", "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });

                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupSecilenOgrenciNormalSmsGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formSecilenOgrenciNormalSmsData} onInitialized={this.onInitializedFormSecilenOgrenciNormalSms} ref={(ref) => this.formSecilenOgrenciNormalSms = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField={"GonderimTipi"} editorType={"dxSelectBox"}
                                            editorOptions={{
                                                items: [
                                                    { Name: "Öğrencilere Gönder", Value: 1 },
                                                    { Name: "Velilere Gönder", Value: 2 },
                                                    { Name: "Öğrenci ve Velilere Gönder", Value: 3 }

                                                ],
                                                valueExpr: "Value",
                                                displayExpr: "Name",
                                                onValueChanged: (e) => {
                                                    if (e.value === 2 | e.value === 3) {
                                                        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox.instance().option("visible", true);
                                                        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox.instance().option("value", false);
                                                    }
                                                    else {
                                                        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox.instance().option("visible", false);
                                                        this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox.instance().option("value", false);
                                                    }
                                                }
                                            }}
                                        >
                                            <RequiredRule message="GonderimTipi alanı zorunludur" />
                                            <Label text="Gönderim Tipi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Mesaj"} editorType={"dxTextBox"} editorOptions={{
                                            onInitialized: (e) => {
                                                this.normalSmsMesajTextBox = e.component;
                                            },
                                            ref: (ref) => { this.normalSmsMesajTextBox = ref },
                                            onKeyUp: (e) => {
                                                document.getElementById("divKarekterSayisi").innerText = this.normalSmsMesajTextBox.instance().option("text").length;
                                            }
                                        }}>
                                            <RequiredRule message="Mesaj alanı zorunludur" />
                                            <Label text="Mesaj" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"VeliCepTelEkstra"} editorType={"dxCheckBox"}
                                            editorOptions={{
                                                text: "Veli Cep Tel 2' ye de gitsin",
                                                visible: false,
                                                onInitialized: this.onInitializedSeciliOgrenciVeliNormalSmsCheckBox,
                                                ref: (ref) => { this.checkBoxSeciliOgrenciVeliNormalSmsCheckBox = ref }
                                            }}
                                        >
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label>Mesaj Karekter Sayısı:</label>
                                            </div>
                                            <div className="col-md-6" id="divKarekterSayisi" style={{ textAlign: "left" }}>

                                            </div>
                                        </div>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.ogrenciAktarVisible}
                    title={"Öğrenci Aktarma İşlemi"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Öğrenci Aktar',
                                    type: 'success',
                                    hint: 'Öğrenci Aktar',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: Globals.service.Rol !== 'SubeOgretmen' ? true : false,
                                    onClick: async (e) => {
                                        let ogrenciData = [];
                                        let ogrenciInfoData = "yeni dönemekatarmak istediğiniz öğrenci bilgileri aşağıda ki gibidir.<br/>";

                                        this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                            ogrenciData.push({
                                                OgrenciId: parseInt(row)
                                            })
                                        });
                                        ogrenciInfoData += "<div style='max-height:100px;height:100px;overflow-y: scroll;'>";
                                        this.gridMain.instance.getSelectedRowsData().forEach((row, index) => {
                                            ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                        });
                                        ogrenciInfoData += "</div>";
                                        this.formOgrenciAktarData.Ogrenciler = ogrenciData;
                                        let result = confirm(ogrenciInfoData, "Öğrencileri yeni döneme aktarma onayı");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.setState({
                                                    toolBarDisabled: true
                                                });
                                                service.post(otomasyonApi + "ogrenci/secilenogrencileridigerdonemeaktar", this.formOgrenciAktarData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupOgrenciAktarGizle();
                                                            alert(res.data.Data, "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupOgrenciAktarGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formOgrenciAktarData} onInitialized={this.onInitializedFormOgrenciAktar} ref={(ref) => this.formOgrenciAktar = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.donemDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi"
                                        }}>
                                            <Label text="Dönem Seçiniz" />
                                            <RequiredRule message="Dönem alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"OgrenciNoAktar"} editorType={"dxCheckBox"}>
                                            <Label text="Öğrenci no aktarılsın mı?" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.ogrenciDonemBilgiVisible}
                    title={"Öğrenci Dönem Bilgisi"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupOgrenciDonemBilgiGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <DataGrid
                                    id="grdOgrenciDonem"
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.ogrenciDonemDataSource}
                                    onInitialized={this.onInitializedGridOgrenciDonemBilgi}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    export={{
                                        enabled: true,
                                        fileName: "ogrencidonembilgisi"
                                    }}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    ref={(ref) => this.gridOgrenciDonemBilgi = ref}
                                >
                                    <Export
                                        fileName={'OgrenciDonemAdi'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'DonemAdi'} caption="Dönem Adı" />
                                    <Column dataField={'SinifAdi'} caption="Sınıf Adı" />
                                    <Column dataField={'OgrenciNo'} caption="Öğrenci No" />
                                </DataGrid>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"90%"} height={"90%"}
                    visible={this.state.smsRaporVisible}
                    title={"Sms Raporu"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupSmsRaporGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <DataGrid
                                    id="grdSmsRapor"
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.smsRaporDataSource}
                                    onInitialized={(e) => {
                                        this.gridSmsRapor = e.component;
                                    }}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    export={{
                                        enabled: true,
                                        fileName: "smsRapor"
                                    }}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    ref={(ref) => this.gridSmsRapor = ref}
                                >
                                    <Export
                                        fileName={'OgrenciSmsRapor'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'BulkId'} caption="BulkId" width={100} />
                                    <Column dataField={'Durum'} caption="Durum" width={100} />
                                    <Column dataField={'Tarih'} caption="Tarih" dataType={"datetime"} width={150} />
                                    <Column dataField={'Mesaj'} caption="Mesaj" />
                                </DataGrid>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"90%"} height={"90%"}
                    visible={this.state.devamsizlikRaporVisible}
                    title={"Devamsızlık Raporu"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupDevamsizlikRaporGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-10">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.devamsizlikUstRaporDataSource}
                                    onInitialized={(e) => {
                                        this.gridDevamsizlikUstRapor = e.component;
                                    }}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    export={{
                                        enabled: true,
                                        fileName: "devamsizlikustrapor"
                                    }}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    ref={(ref) => this.gridDevamsizlikUstRapor = ref}
                                >
                                    <Export
                                        fileName={'OgrenciDevamsizlikUstRapor'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />

                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'Sube'} caption="Şube" width={75} />
                                    <Column dataField={'Adi'} caption="Ad" />
                                    <Column dataField={'Soyadi'} caption="Soyad" />
                                    <Column dataField={'OgrenciNo'} caption="Öğrenci N." dataType={"number"} />
                                    <Column dataField={'Durum'} caption="Durum" width={100} />
                                    <Column dataField={'Toplam'} dataType={"number"} caption="T. Sayı" />
                                </DataGrid>

                            </div>
                        </div>
                        <div className="row" style={{ height: "10px" }}>
                            <div className="col-md-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.devamsizlikAltRaporDataSource}
                                    onInitialized={(e) => {
                                        this.gridDevamsizlikAltRapor = e.component;
                                    }}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    export={{
                                        enabled: true,
                                        fileName: "smsRapor"
                                    }}
                                    selection={
                                        {
                                            mode: 'single'
                                        }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    ref={(ref) => this.gridDevamsizlikAltRapor = ref}
                                >
                                    <Export
                                        fileName={'OgrenciDevamsizlikAltRapor'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'Sube'} caption="Şube" />
                                    <Column dataField={'Adi'} caption="Ad" />
                                    <Column dataField={'Soyadi'} caption="Soyad" />
                                    <Column dataField={'OgrenciNo'} caption="Öğrenci N." dataType={"number"} />
                                    <Column dataField={'Tarih'} caption="Tarih" dataType={"datetime"} />
                                    <Column dataField={'VeliCepTelefonu'} caption="V. Cep Telefonu" />
                                    <Column dataField={'DersNo'} caption="Ders N." dataType={"number"} />
                                    <Column dataField={'Turu'} caption="Durum" />
                                </DataGrid>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}
export default OgrenciKayit;
function durumRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Durum === undefined || data.data.Durum === null || data.data.Durum === "") {
        return <p></p>;
    }
    else if (data.data.Durum === "Ödendi") {
        return <i style={{ color: "white", backgroundColor: "green" }} className="icon dx-icon-check"></i>;
    }
    else if (data.data.Durum === "Kısmi Tahsilat") {
        return <i style={{ color: "black", backgroundColor: "yellow" }} className="icon dx-icon-minus"></i>;
    }
    else {
        return <i style={{ color: "white", backgroundColor: "red" }} className="icon dx-icon-close"></i>;
    }
}
function cellRender(data) {
    let pictureData = data.data.Fotograf;
    if (!!pictureData) {
        return <img src={pictureData} style={{ width: "64px", height: "64px" }} title={data.data.Adi + " " + data.data.Soyadi} alt={data.data.Adi + " " + data.data.Soyadi} />;
    }
    else {
        return null;
    }
}
function addImage(url, workbook, worksheet, excelCell, resolve) {

    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    // xhr.mode = "no-cors";
    // xhr.setRequestHeader();
    xhr.onload = async function () {
        var reader = new FileReader();
        await reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
            var base64data = reader.result;
            const image = workbook.addImage({
                base64: base64data,
                extension: 'png',
                width: 64,
                height: 64
            })
            worksheet.getRow(excelCell.row).height = 64;
            worksheet.getRow(excelCell.row).width = 64;
            worksheet.addImage(image, {
                tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
                br: { col: excelCell.col, row: excelCell.row }
            })
            resolve();
        }
    }
    xhr.onerror = function () {
        console.error('could not add image to excel cell')
    };
    xhr.send();
}


