import React from 'react';
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import DataGrid, {
    Export, ColumnChooser, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel, Summary, GroupItem, SortByGroupSummaryInfo
} from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import DateBox from 'devextreme-react/date-box';
export default class KurumTahsilatRaporu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toolbarDisabled: false,
            baslangicTarihi: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0, 0, 0),
            bitisTarihi: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDay() + 1, 23, 59, 59, 0)
        };
        this.mainDataSource = new DataSource(
            {
                filter: [["TahsilatTarihi", ">=", this.state.baslangicTarihi], "and", ["TahsilatTarihi", "<=", this.state.bitisTarihi]],
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/listkurumtahsilatraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }
    render() {
        return (

            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Rapor İşlemleri>>Kurum Tahsilat Raporu"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Başlangıç Tarihi
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <DateBox defaultValue={this.state.baslangicTarihi}
                                type="datetime"
                                onValueChanged={(e) => {
                                    this.setState({
                                        baslangicTarihi: e.value
                                    })
                                }} />
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Bitiş Tarihi
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <DateBox defaultValue={this.state.bitisTarihi}
                                type="datetime"
                                onValueChanged={(e) => {
                                    this.setState({
                                        bitisTarihi: e.value
                                    });
                                    this.mainDataSource.filter([["TahsilatTarihi", ">=", this.state.baslangicTarihi], "and", ["TahsilatTarihi", "<=", this.state.bitisTarihi]]);
                                    this.mainDataSource.load();
                                    this.gridMain.instance.refresh();
                                }} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12' style={{ minHeight: "20px" }}>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">


                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.mainDataSource}
                                remoteOperations={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                            icon: "datafield",
                                            type: "success",
                                            text: "Tüm Tahsilatlar",
                                            onClick: () => {
                                                this.mainDataSource.filter(null);
                                                this.gridMain.instance.refresh();
                                            }
                                        }
                                    });
                                }}
                                onInitialized={this.onInitialized}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Grouping contextMenuEnabled={true} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <Export enabled={true} fileName={'KurumTahsilatRaporu'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} enabled={false} />
                                <Pager showPageSizeSelector={false} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                <Column dataField={'OgrenciNo'} caption="Öğrenci No" allowGrouping={true} />
                                <Column dataField={'Adi'} caption="Adı" />
                                <Column dataField={'Soyadi'} caption="Soyadı" />
                                <Column dataField={'VeliAdSoyad'} caption="Velisi" />
                                <Column dataField={'VeliCepTelefonu'} caption="V. Cep Telefonu" />
                                <Column dataField={'TaksitNo'} caption="Taksit No" />
                                <Column dataField={'TahsilatTarihi'} caption="Tahsilat Tarihi" dataType={"date"} />
                                <Column dataField={'Tahsilat'} caption="Tahsilat" dataType="number" format={"#,##0.##"} />
                                <Column dataField={'TahsilatTuru'} caption="Tahsilat Türü" />
                                <Column dataField={'SlipNo'} caption="Slip No" />
                                <Column dataField={'BankaAdi'} caption="Banka" />
                                <Summary>
                                    <GroupItem
                                        column="Tahsilat"
                                        summaryType="sum"
                                        valueFormat="#,##0.##"
                                        displayFormat="Toplam Tahsilat: {0}"
                                        showInGroupFooter={true} />
                                </Summary>
                            </DataGrid>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
