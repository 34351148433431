
import * as React from "react";
import notify from 'devextreme/ui/notify';
import { Drawer, Toolbar } from 'devextreme-react';
import auth from '../Auth';
import LeftMenu from '../components/shared/LeftMenu';
import Globals from '../Globals';
import trMessages from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.carmine.compact.css';
import { Popup } from 'devextreme-react';
import service from 'axios';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import { authApi, otomasyonApi, sinavApi, yayinGatewayUrl } from "../Util";
import { connect } from 'react-redux';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import { confirm, alert, custom } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { setRemoteSystemInfo } from "../redux/actions/mainSystemAction";
import { setKepsSystemShortCodes } from "../redux/actions/kepsSystemAction";
import { setSanalSinavSystemInfo } from '../redux/actions/sanalSinavSystemAction';
import DataGrid, { Column, Editing, LoadPanel } from 'devextreme-react/data-grid';
const mapDispatchToProps = (dispatch) => {
    return {
        setRemoteSystem: (data) => dispatch(setRemoteSystemInfo(data)),
        setShortCodes: (data) => dispatch(setKepsSystemShortCodes(data)),
        setSanalSystemInfo: (data) => dispatch(setSanalSinavSystemInfo(data)),
    }
}
const mapStateToProps = state => ({
    remoteSystem: state.remoteSystem.info,
    shortCodes: state.kepsSystem.shortCodes
});
let kisaYollar = [];
class AuthorizedLayout extends React.Component {
    constructor(props) {
        super(props);
        this.onInitializedToolbar = this.onInitializedToolbar.bind(this);
        this.onInitializedFormDonemSecimi = this.onInitializedFormDonemSecimi.bind(this);
        this.gridSinavaKatilanOgrenciler = null;
        this.state = {
            onOpen: true,
            vposition: "left",
            mainConnected: this.props.remoteSystem.connectionState,
            //seciliTabId: 0,
            actionType: 0,
            popup: false,
            acikRizaBeyaniPopup: false,
            popupDonemSecim: false,
            popupSms: false,
            popupDonemArasi: false,
            donemGecisDisabled: false,
            telefon: ""
        };

        document.title = window.ayarlar.sistemAdi;
        this.toolbar = null;
        this.formDonemSecimi = null;
        this.formDonemData = {
            DonemId: null,
            KullaniciId: Globals.service.Kullanici.Id
        }
        this.formSmsData = {
            Telefon: "",
            Mesaj: ""
        };
        this.remoteDonemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: yayinGatewayUrl + 'auth/auth/getdonemler',
                })
            });
        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });
        this.sinavaKatilanOgrenciler = [];
        this.sinavaKatilanOgrenciDataSource = new DataSource({
            onUpdated: function (key, values) {
                console.log(key);
                console.log(values);
                // var item = myArray.find(i => i.id === key);
                // Object.assign(item, values);
                // localStorage.setItem('myArray', JSON.stringify(myArray));
            },
            store: {
                type: 'array',
                key: 'Id',
                data: this.sinavaKatilanOgrenciler
            },
            paginate: false
        });
        this.toolbarItems = [

        ];

        this.profilMenu = [];
        if (window.ayarlar.projeturu === "otomasyon") {
            this.profilMenu = [
                { 'name': 'Ayarlar', 'id': 1 },
                { 'name': this.props.remoteSystem.connectionState ? "M.S.S Bağlanıldı" : "M.S.S Bağlanılamadı", 'id': 2 },
                { 'name': 'Sms Gönder', 'id': 4 },
                { 'name': 'Oturumu Kapat', 'id': 3 }
            ];
        }
        else {
            this.profilMenu = [
                { 'name': 'Ayarlar', 'id': 1 },
                { 'name': 'Oturumu Kapat', 'id': 3 }
            ];
        }
        service.get(sinavApi + "kullanici/getfavoriler",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    kisaYollar = [];
                    if (res.data.Data.length > 0) {
                        res.data.Data.forEach((satir) => {
                            kisaYollar.push({
                                widget: 'dxButton', location: 'before',
                                options: {
                                    icon: satir.Icon,
                                    hint: satir.Hint,
                                    onClick: () => {
                                        window.location.href = "/" + satir.Url;
                                    }
                                }
                            });
                        });
                        this.props.setShortCodes(kisaYollar);
                    }
                }
            }).catch(err => {
                notify("Teknik bir hata oluştu,hata ayrıntısı:" + err.ErrorMessage, 5000);
            })
        loadMessages(trMessages);
        locale(navigator.language);
        if (window.ayarlar.rizaBeyanFormuGoster) {
            service.get(otomasyonApi + "common/getBeyanKontrol",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success && res.data.Data !== true) {
                        this.setState({
                            popup: true,
                            acikRizaBeyaniPopup: false
                        });
                    }
                    else if (!res.data.Success) {
                        notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(err => {
                    notify(err, 'error', 5000);
                });
        }

    }



    async onClickSignOut(e) {
        notify(`${e.component.option('text')} ile portaldan çıkıyorsunuz.`, 'success', 500);

        auth.logout(() => {
            try {
                localStorage.removeItem("oturum");
                localStorage.removeItem("session");
                localStorage.removeItem("initialized");
            } catch (error) {
            }

        });
        this.props.children.props.history.push("/login")
    }

    async aydinlatmaMetniGizle(e) {
        this.setState({
            popup: false,
            acikRizaBeyaniPopup: true
        });
    }

    async onClickProfile(e) {
        notify(`${e.component.option('text')} ile portalınızı kişiselleştirebilirsiniz.`, 'success', 500);
        this.setState({ actionType: 2 });
    }

    onInitializedToolbar(e) {
        this.toolbar = e.component;
    }
    onInitializedFormDonemSecimi(e) {
        this.formDonemSecimi = e.component;
    }

    getItems() {
        let items = [];
        items = [{ widget: 'dxButton', location: 'before', options: { icon: 'menu', onClick: () => (this.setState({ onOpen: !this.state.onOpen })) } },
        { widget: 'dxButton', location: 'before', options: { icon: 'codeblock', onClick: () => (this.setState({ vposition: this.state.vposition === "left" ? "right" : "left" })) } },
        {
            widget: 'dxButton', location: 'before', options: {
                visible: ["SubeOgretmen", "SubeOgrenci", "GenelMerkez"].includes(Globals.service.Rol), text: "Tekno Öğretim", icon: 'user', onClick: () => {
                    window.location.href = "https://sinav.mobilogretim.com/keps.php?token=Y2F0Y2hlciUyMHdvbmclMjBsb3ZlJTIGHTYSHAMCVmslspip%C4%9Ft%C3%BCx%C4%9F%C3%BCi%C3%A7ci&k_uid=" + Globals.service.Kullanici.KurumId + "-" + Globals.service.Kullanici.Id;
                }
            }
        },
        {
            widget: 'dxButton', location: 'before', options: {
                icon: 'home', onClick: () => {
                    if (Globals.service.Rol === "SubeKayitKabul") {
                        this.props.children.props.history.push("/kayitkabuldashboard");
                    }
                    else if (Globals.service.Rol === "SubeMuhasebe") {
                        this.props.children.props.history.push("/kayitkabuldashboard");
                    }
                    else if (Globals.service.Rol === "Sube") {
                        this.props.children.props.history.push("/kayitkabuldashboard");
                    }
                    else if (Globals.service.Rol === "GenelMerkez") {
                        this.props.children.props.history.push("/kayitkabuldashboard");
                    }
                    else if (Globals.service.Rol === "SubeKayitMuhasebe") {
                        this.props.children.props.history.push("/kayitkabuldashboard");
                    }
                    else if (Globals.service.Rol === "SubeVeli") {
                        this.props.children.props.history.push("/velidashboard");
                    }
                    else if (Globals.service.Rol === "SubeOgrenci") {
                        window.clearInterval(window.x);
                        this.props.children.props.history.push("/ogrencidashboard");
                    }
                    else {
                        this.props.children.props.history.push("/home");
                    }

                }
            }
        },
        // {
        //     location: 'after',
        //     locateInMenu: 'never',
        //     widget: 'dxButton',
        //     options: {
        //         visible: window.ayarlar.projeturu === "otomasyon" & (Globals.service.Rol !== "SubeVeli") ? true : false,
        //         icon: 'globe',
        //         text: this.props.remoteSystem.connectionState ? "Merkezi Sınav Sistemine Bağlanıldı" : "Merkezi Sınav Sistemine Bağlanılamadı",
        //         type: this.props.remoteSystem.connectionState ? "success" : "danger",
        //         onClick: () => {
        //             this.setState({
        //                 popupDonemSecim: true
        //             })
        //         }
        //     }
        // },

        { location: 'center', locateInMenu: 'never', template: () => { return '<div class=\'toolbar-label\'><b>' + window.ayarlar.sistemAdi + '</b>(' + Globals.service.DonemAdi + ' - Aktif Dönemi)</div>'; } },
        {
            location: 'center',
            widget: 'dxButton',
            options: {
                text: "Dönemler Arası Geçiş",
                type: "success",
                icon: "export",
                onClick: async () => {
                    this.donemDataSource.filter(["Id", "<>", Globals.service.DonemId]);
                    await this.donemDataSource.load().then(() => {
                        this.setState({
                            popupDonemArasi: true
                        });
                    });

                }
            }
        },
        {
            location: 'after',
            widget: 'dxDropDownButton',
            options: {
                displayExpr: 'name',
                keyExpr: 'id',
                selectedItemKey: 1,
                stylingMode: 'text',
                useSelectMode: false,
                splitButton: true,
                text: Globals.service.AdSoyad,
                items: this.profilMenu,
                width: 150,
                onItemClick: (e) => {
                    if (e.itemData.id === 1) {
                        this.props.children.props.history.push("/ayarlar");
                    }
                    else if (e.itemData.id === 2) {
                        this.setState({
                            popupDonemSecim: true
                        })
                    }
                    else if (e.itemData.id === 3) {
                        this.props.setRemoteSystem({
                            connectionState: false,
                            remoteToken: ""
                        });
                        this.props.setShortCodes([]);
                        this.props.setSanalSystemInfo({});
                        this.onClickSignOut(e);
                    }
                    else if (e.itemData.id === 4) {
                        this.setState({
                            popupSms: true
                        }, () => {
                            this.getSinavaKatilanOgrenciler();
                        })
                    }
                }
            }
        }

        ];

        this.props.shortCodes.forEach((x, i) => {
            items.push(x);
        });
        return items;
    }

    async getSinavaKatilanOgrenciler() {
        await service.get(sinavApi + "ortak/sinavakatilanogrenciler",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(async (resAlan) => {
                if (resAlan.data.Success) {
                    var existStore = this.sinavaKatilanOgrenciDataSource.store();
                    existStore.clear();
                    await this.gridSinavaKatilanOgrenciler.instance.refresh().then(() => {
                        resAlan.data.Data.forEach((satir, index) => {
                            this.sinavaKatilanOgrenci = {
                                Id: satir.Id,
                                Adi: satir.Adi,
                                Soyadi: satir.Soyadi,
                                SinifKodu: satir.SinifKodu,
                                OgrenciCepTel: satir.OgrenciCepTel
                            };
                            var store = this.sinavaKatilanOgrenciDataSource.store();
                            store.insert(this.sinavaKatilanOgrenci);
                        });

                    })
                    await this.gridSinavaKatilanOgrenciler.instance.refresh();
                }
                else {
                    notify(resAlan.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    render() {
        return (
            <React.Fragment>

                <div className={'dx-theme-background-color'}>
                    <div className="container" style={{ minWidth: "100%" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Toolbar items={this.getItems()} onInitialized={this.onInitializedToolbar} ref={(ref) => this.toolbar = ref} />
                            </div>
                        </div>
                        <div className="row" style={{ minHeight: "10px" }}></div>
                        <div className="row">
                            <div className="col-md-12">
                                <Drawer
                                    opened={this.state.onOpen}
                                    openedStateMode={'shrink'}
                                    position={this.state.vposition}
                                    revealMode={'slide'}
                                    component={LeftMenu}
                                    height={"auto"}
                                    closeOnOutsideClick={true}>
                                    <div>
                                        {this.props.children}
                                    </div>
                                </Drawer>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.popup} width={"100%"} height={"100%"}
                    title="ARK YAZILIM KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ AYDINLATMA METNİ"
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton',
                                toolbar: "bottom",
                                options: {
                                    text: 'Aydınlatma metnini okudum,kendi rızamla onayladım', type: 'success', hint: 'Aydınlatma metnini okudum,kendi rızamla onayladım', icon: 'check'
                                    , onClick: (e) => {
                                        this.aydinlatmaMetniGizle();

                                    }
                                }

                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-6">
                                <React.Fragment>

                                    <p>Kişisel verileriniz bizim i&ccedil;in &ouml;nemlidir!</p>
                                    <p>Kişisel verilerin işlenmesinde başta &ouml;zel hayatın gizliliği olmak &uuml;zere kişilerin temel hak ve &ouml;zg&uuml;rl&uuml;klerinin korunmasını ama&ccedil;layan 7 Nisan 2016 tarih ve 29677 Sayılı Resm&icirc; Gazete&rsquo;de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK" veya "Kanun")&rsquo;nun "Veri Sorumlusunun Aydınlatma Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;" başlıklı 10. maddesi ile 10 Mart 2018 tarih ve 30356 sayılı Resm&icirc; Gazete&rsquo;de yayımlanan Aydınlatma Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ uyarınca işbu Aydınlatma Metni veri sorumlusu sıfatıyla ARK YAZILIM tarafından hazırlanmıştır. Bu aydınlatma metni ile işlenen kişisel verileriniz hakkında sizi bilgilendirmeyi ve 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında veri sorumlusu sıfatının gerektirdiği aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirmeyi hedeflemekteyiz.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>1.VERİ SORUMLUSU</strong></p>
                                    <p>ARK YAZILIM; toplumun her kesiminde yazılım hizmeti sunmayı misyon edinen, &ccedil;ağdaş yenilikleri takip eden ve bu yeniliklere &ouml;nc&uuml;l&uuml;k etmeyi hedefleyen, eğitim konusunda &ldquo;Orion&rdquo; sınav değerlendirme sistemini geliştiren 2016 tarihinde kurulmuş bir yazılım firmasıdır.</p>
                                    <p>A&ccedil;ıklanan hizmetin yerine getirilmesi esnasında işlenen kişisel verileriniz bakımından, ARK YAZILIM, Kanun uyarınca "Veri Sorumlusu" sıfatını haiz olup tarafımıza aşağıda yer verilen iletişim bilgileri aracılığıyla ulaşmanız m&uuml;mk&uuml;nd&uuml;r.</p>
                                    <p>Adres: Tatlıkuyu Mahallesi 1317. Sokak No 10 Kat 2 &nbsp;&nbsp;41400 Gebze / KOCAELİ</p>
                                    <p>Telefon: 0262 642 00 02</p>
                                    <p>İnternet Adresi: <a href="http://www.arkyazilim.com">http://www.arkyazilim.com</a></p>
                                    <p>E-posta Adresi: <a href="mailto:info@arkyazilim.com">info@arkyazilim.com</a></p>
                                    <p>&nbsp;</p>
                                    <p><strong>2.İŞLENEN KİŞİSEL VERİLER</strong></p>
                                    <p>Kimlik Bilgileriniz: Adı-soyadı, T.C. kimlik numarası, cinsiyeti, doğum yeri, doğum tarihi;</p>
                                    <p>&Ouml;ğrenci Kimlik Bilgileriniz: Okul numarası, velisinin adı-soyadı, veli yakınlık derecesi;</p>
                                    <p>İletişim Bilgileriniz: Telefon numarası, e-posta adresi, veli telefon numarası, adres;</p>
                                    <p>İletişim Kayıtlarınız: Şik&acirc;yet, &ouml;neri ve taleplere ilişkin yazılı ve elektronik mesaj i&ccedil;eriğiniz, şik&acirc;yet başvurusunda bulunmanız halinde şik&acirc;yete konu ettiğiniz ticari elektronik iletinin g&ouml;rsel &ouml;rneği;</p>
                                    <p>Kanun h&uuml;km&uuml; gereği ger&ccedil;ek kişilerin bilgilerinin doğru ve g&uuml;ncel tutulması esastır. Ark Yazılım, hi&ccedil;bir koşulda sunulan yanlış bilgiden sorumlu tutulamaz.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>3.KİŞİSEL VERİLERİN İŞLENME AMA&Ccedil;LARI</strong></p>
                                    <p>Paylaştığınız kişisel veriler;</p>
                                    <p>3.1-M&uuml;şteri &ouml;ğrenciler hakkında yapılan devamsızlık durumunun ve sınav sonu&ccedil;larının veliye aktarılması i&ccedil;in,</p>
                                    <p>3.2-M&uuml;şteri ile akdedilen s&ouml;zleşme kapsamında uyuşmazlık &ccedil;ıkması halinde hukuki yollara ve senede dayalı icra yoluna başvurabilmek ve doğabilecek uyuşmazlıklarda delili olarak kullanılabilmesi i&ccedil;in;</p>
                                    <p>3.3-M&uuml;şterilerimize verdiğimiz hizmetlerin gereklerini, s&ouml;zleşmenin ve teknolojinin gereklerine uygun şekilde yapabilmek, sunulan &uuml;r&uuml;n ve hizmetlerimizi geliştirebilmek i&ccedil;in,</p>
                                    <p>3.4- S&ouml;zleşmesel ve yasal y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin tam ve gereği gibi ifa edilebilmesi i&ccedil;in,</p>
                                    <p>3.5- Kamu g&uuml;venliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu g&ouml;revlilerine bilgi verebilmek i&ccedil;in;</p>
                                    <p>3.6- Şik&acirc;yet, &ouml;neri ve taleplerin y&ouml;netiminin sağlanması ve ilgililerine işlem sonucu hakkında tarafınıza bilgi verilmesi i&ccedil;in,</p>
                                    <p>6698 sayılı KVKK ve ilgili ikincil d&uuml;zenlemelere uygun olarak işlenecektir.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>4.İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</strong></p>
                                    <p>Kişisel verileriniz; Kanun&rsquo;un kişisel verilerin aktarılması h&uuml;k&uuml;mleri kapsamında işbu Aydınlatma Metni&rsquo;nin 3. maddesinde yer alan ama&ccedil;larla yurt i&ccedil;indeki kanunen yetkili kamu resmi kurum ve kuruluşlar, kolluk kuvvetleri, mahkemeler ve icra m&uuml;d&uuml;rl&uuml;kleri ile paylaşılabilecektir.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>5.KİŞİSEL VERİLERİN TOPLANMA Y&Ouml;NTEMLERİ VE HUKUKİ SEBEPLERİ</strong></p>
                                    <p>Bu kişisel veriler, Kanun&rsquo;un 5. Maddesinde belirtilen &ldquo;veri sorumlusunun hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirebilmesi i&ccedil;in zorunlu olması; bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri işlemenin zorunlu olması; ilgili kişinin temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaati i&ccedil;in zorunlu olması&rdquo; hukuki sebeplerine dayalı olarak <u>veri entegrasyonu</u> yoluyla <u>otomatik</u> olarak işlenmektedir.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>6.KİŞİSEL VERİLERİ İŞLENEN İLGİLİ KİŞİNİN HAKLARI</strong></p>
                                    <p>Kanun&rsquo;un &ldquo;ilgili kişinin haklarını d&uuml;zenleyen&rdquo; 11. Maddesi kapsamında kişisel veri sahibi Firmamıza(veri sorumlusu) kendisiyle ilgili;</p>
                                    <ul>
                                        <li>a) Kişisel verinin işlenip işlenmediğini &ouml;ğrenme,</li>
                                        <li>b) Kişisel verileri işlenmişse; buna ilişkin bilgi talep etme,</li>
                                        <li>c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</li>
                                        <li>ç) Yurt i&ccedil;inde veya yurt dışında kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</li>
                                        <li>d) Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme,</li>
                                        <li>e) Kişisel verinizin işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde, verilerin silinmesini veya yok edilmesini isteme,</li>
                                        <li>f) Kişisel verilerin d&uuml;zeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</li>
                                        <li>g) İşlenen kişisel verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle veri sahibinin aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,</li>
                                        <li>ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması h&acirc;linde zararın giderilmesini talep etme haklarına sahiptir.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p><strong>7.HAK VE TALEPLERİNİZ İ&Ccedil;İN BİZİMLE İLETİŞİME GE&Ccedil;MEK İSTERSENİZ:</strong></p>
                                    <p>Yukarıda belirtilen haklar kapsamında ilgili taleplerinizi, "Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe" g&ouml;re dilek&ccedil;e veya noter kanalı ile " Tatlıkuyu Mah. 1317. Sokak No 10 Kat 2 Gebze / KOCAELİ " adresinde yer alan firmamıza yazılı olarak veya firmamıza daha &ouml;nce bildirilen ve sistemlerimizde kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle <a href="mailto:info@arkyazilim.com">info@arkyazilim.com</a> e-posta adresi aracılığıyla tarafımıza iletebilirsiniz.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>VERİ SORUMLUSU </strong></p>
                                    <p><strong>ARK YAZILIM</strong></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </React.Fragment>
                            </div>
                        </div>

                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.acikRizaBeyaniPopup} width={"100%"} height={"100%"}
                    title="VERİ SAHİBİNİN AÇIK RIZA BEYAN FORMU"
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton',
                                toolbar: "bottom",
                                options: {
                                    text: 'Muvafakat ettiğimi kabul, beyan ve taahhüt ederim', type: 'success', hint: 'Muvafakat ettiğimi kabul, beyan ve taahhüt ederim', icon: 'check'
                                    , onClick: (e) => {
                                        service.post(otomasyonApi + "common/beyanver",
                                            null,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            })
                                            .then(res => {
                                                if (res.data.Success) {
                                                    this.setState({
                                                        popup: false,
                                                        acikRizaBeyaniPopup: false
                                                    });
                                                }
                                                else
                                                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                            })

                                    }
                                }

                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-6">
                                <React.Fragment>

                                    <p><strong>Bu a&ccedil;ık rıza metni size, tarafınıza aydınlatma yapıldıktan sonra sunulmuştur. L&uuml;tfen &ouml;nce aydınlatma metnini okuduğunuzdan ve bu metne &ouml;zg&uuml;r iradenizle onay verdiğinizden emin olunuz. Bu metne konu kişisel verilerin işlenmesine a&ccedil;ık rıza verdikten sonra, dilediğiniz zaman a&ccedil;ık rızanızı geri alabilirsiniz. Kişisel verilerinizi &ouml;nemsiyor ve koruyoruz</strong>.</p>
                                    <p>İşbu Bilgilendirme Yazısını okuduğumu ve 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında, kendimin, velimin ve/veya velisi olduğum &ccedil;ocuğumun kişisel verilerinin <strong><em>ARK YAZILIM</em></strong> ile t&uuml;m iştirakleri tarafından yasadaki esaslar &ccedil;er&ccedil;evesinde toplanmasına, kaydedilmesine, işlenmesine, saklanmasına peşinen <strong>muvafakat ettiğimi kabul, beyan ve taahh&uuml;t ederim.</strong></p>
                                    <p>6698 sayılı &ldquo;Kişisel Verilerin Korunması Kanunu&rdquo; gereğince, kişisel verilerimin, &ouml;zel nitelikli kişisel verilerimin işlenmesine, tarafımca s&ouml;zl&uuml;/yazılı ve/veya elektronik ortamda verilen kimliğimi belirleyen veya belirlemeye yarayanlar da dahil olmak &uuml;zere her t&uuml;rl&uuml; kişisel verimin, <strong><em>ARK YAZILIM</em></strong> ile t&uuml;m iştirakleri tarafından işlenmesine, kayıt s&ouml;zleşmesinin imzalanması amacıyla yazılı bilgilerimin, ilgili mevzuatlar kapsamında paylaşımın gerekli olduğu diğer kişi ve kuruluşlar ile paylaşılmasına; kişisel veriler, &ouml;zel nitelikli kişisel verilerin, 6698 sayılı &ldquo;Kişisel Verilerin Korunması Kanunu&rsquo; nda tanımlanan kapsamlar ile aşağıda detayları verilen kişisel ve &ouml;zel nitelikli kişisel verilerin işlenmesine ve kullanılmasına kendim, velim ve/veya velisi olduğum &ccedil;ocuğum adına muvafakat ettiğimi kabul, beyan ve taahh&uuml;t ederim.</p>
                                    <ul>
                                        <li>Kimliği belirli veya belirlenebilir bir ger&ccedil;ek kişiye ait olduğu a&ccedil;ık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir par&ccedil;ası olarak otomatik olmayan şekilde işlenen; kişinin kimliğine dair bilgilerin bulunduğu verilerdir; ad-soyad, T.C. Kimlik numarası, anne adı-baba adı, doğum yeri, doğum tarihi, cinsiyet, okul numarası, veli yakınlık derecesi gibi bilgileri i&ccedil;eren ehliyet, n&uuml;fus c&uuml;zdanı ve pasaport gibi belgeler ile imza bilgisi vb. bilgiler,</li>
                                        <li>Kimliği belirli veya belirlenebilir bir ger&ccedil;ek kişiye ait olduğu a&ccedil;ık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir par&ccedil;ası olarak otomatik olmayan şekilde işlenen; telefon numarası, adres, e-mail adresi, veli telefon numarası gibi bilgiler,</li>
                                        <li>ARK YAZILIM&rsquo;ın geliştirdiği sınav değerlendirme sistemi olan &ldquo;Orion&rdquo; sistemindeki sınav sonu&ccedil;ları- devamsızlık- iletişim bilgileri-&ouml;l&ccedil;me değerlendirme-&ouml;ğrenci işlemleri ile ilgili veri ve bilgiler,</li>
                                        <li>Kimliği belirli veya belirlenebilir bir ger&ccedil;ek kişiye ait olduğu a&ccedil;ık olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin bir par&ccedil;ası olarak otomatik olmayan şekilde işlenen; ARK YAZILIM firmasına y&ouml;neltilmiş olan her t&uuml;rl&uuml; talep veya şikayetin alınması ve değerlendirilmesine ilişkin kişisel veriler,</li>
                                    </ul>
                                    <p>ARK YAZILIM ile t&uuml;m iştirakleri tarafından Kişisel Verilerin Korunması ve İşlenmesi Hakkında Bilgilendirme metnini ve haklarımı okudum ve kendim, velim ve/veya velisi olduğum &ccedil;ocuğum adına kabul ediyorum.</p>
                                    <p>Kişisel verilerinizin işlenmesine y&ouml;nelik tercihinizi belirtiniz.</p>
                                </React.Fragment>
                            </div>
                        </div>

                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} title={"Dönemi seçiniz"}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.popupDonemSecim}
                    width="100%" height={"100%"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Bağlan',
                                    type: 'success',
                                    hint: 'Bağlan',
                                    icon: 'globe',
                                    onClick: (e) => {
                                        service.get(Globals.service.Rol === 'SubeOgrenci' ? otomasyonApi + "entegrasyon/authenticateogrenci/" + this.formDonemData.DonemId : otomasyonApi + "entegrasyon/authenticate/" + this.formDonemData.DonemId, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(async res => {
                                                if (res.data.Success) {
                                                    this.props.setRemoteSystem(Object.assign({}, {
                                                        connectionState: true,
                                                        remoteToken: res.data.Data
                                                    }));
                                                    window.location.href = window.location.pathname;
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                            .catch(err => {
                                                notify(err, "error", 5000);
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'runner'
                                    , onClick: () => {
                                        this.setState({
                                            popupDonemSecim: false
                                        });
                                    }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form formData={this.formDonemData} onInitialized={this.onInitializedFormDonemSecimi} ref={(ref) => this.formDonemSecimi = ref}
                                        showValidationSummary={true}
                                    >
                                        <GroupItem cssClass="first-group" colCount={1}>
                                            <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                                dataSource: this.remoteDonemDataSource,
                                                valueExpr: "Id",
                                                displayExpr: "Adi"
                                            }}>
                                                <Label text="Dönem Seçiniz" />
                                                <RequiredRule message="Dönem alanı zorunludur" />
                                            </SimpleItem>

                                        </GroupItem>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} title={"Dönemi seçiniz"}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.popupDonemArasi}
                    width="50%" height={"50%"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Geçiş Yap',
                                    type: 'success',
                                    hint: 'Geçiş Yap',
                                    icon: 'runner',
                                    disabled: this.state.donemGecisDisabled,
                                    onClick: (e) => {
                                        this.setState({
                                            donemGecisDisabled: true
                                        });
                                        service.post(authApi + "Auth/termauthenticate"
                                            , this.formDonemData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            })
                                            .then(res => {
                                                auth.login(() => {
                                                    localStorage.removeItem("oturum");
                                                    localStorage.removeItem("session");
                                                    localStorage.removeItem("initialized");
                                                    var localStoregeData = {};
                                                    res.data.AdSoyad = res.data.Kullanici.Adi + " " + res.data.Kullanici.Soyadi;
                                                    res.data.Menu = res.data.Kullanici.KullaniciMenuler;
                                                    res.data.Rol = res.data.Kullanici.Rol;
                                                    res.data.Id = res.data.Kullanici.Id;
                                                    res.data.DonemId = this.formDonemData.DonemId;
                                                    res.data.DonemAdi = res.data.Kullanici.DonemAdi;
                                                    localStorage.setItem("oturum", JSON.stringify(res.data));
                                                    Globals.service = localStoregeData;
                                                    this.props.setRemoteSystem({
                                                        connectionState: false,
                                                        remoteToken: ""
                                                    });
                                                    if (Globals.service.Rol === "SubeKayitKabul") {
                                                        window.location.href = "/kayitkabuldashboard";
                                                    }
                                                    else if (Globals.service.Rol === "SubeMuhasebe") {
                                                        window.location.href = "/kayitkabuldashboard";
                                                    }
                                                    else if (Globals.service.Rol === "SubeKayitMuhasebe") {
                                                        window.location.href = "/kayitkabuldashboard";
                                                    }
                                                    else if (Globals.service.Rol === "Sube") {
                                                        window.location.href = "/kayitkabuldashboard";
                                                    }
                                                    else if (Globals.service.Rol === "GenelMerkez") {
                                                        window.location.href = "/kayitkabuldashboard";
                                                    }
                                                    else if (Globals.service.Rol === "SubeVeli") {
                                                        window.location.href = "/velidashboard";
                                                    }
                                                    else if (Globals.service.Rol === "SubeOgrenci") {
                                                        window.location.href = "/ogrencidashboard";
                                                    }

                                                    else if (Globals.service.Rol === "AdminOtomasyon") {
                                                        window.location.href = "/otomasyondashboard";
                                                    }
                                                    else {
                                                        window.location.href = "/home";
                                                    }
                                                });


                                            })
                                            .catch((error) => {
                                                // Error 😨
                                                this.setState({
                                                    donemGecisDisabled: false,
                                                    popupDonemArasi: false
                                                });
                                                notify("Geçiş yapacağınız dönemde aynı kullanıcı bilgileriniz olmalıdır.", "error");
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'runner'
                                    , onClick: () => {
                                        this.setState({
                                            popupDonemArasi: false
                                        });
                                    }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form formData={this.formDonemData} onInitialized={this.onInitializedFormDonemSecimi} ref={(ref) => this.formDonemSecimi = ref}
                                        showValidationSummary={true}
                                    >
                                        <GroupItem cssClass="first-group" colCount={1}>
                                            <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                                dataSource: this.donemDataSource,
                                                valueExpr: "Id",
                                                displayExpr: "Adi"
                                            }}>
                                                <Label text="Dönem Seçiniz" />
                                                <RequiredRule message="Dönem alanı zorunludur" />
                                            </SimpleItem>

                                        </GroupItem>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.popupSms}
                    title={"Sms gönderim ekranı"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Sms Gönder',
                                    type: 'success',
                                    hint: 'Sms Gönder',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    visible: true,
                                    onClick: async (e) => {
                                        let numbers = this.formSmsData.TelefonNo.split(',');
                                        console.log(numbers)
                                        if (numbers === undefined) {
                                            alert("Girdiğiniz telefon numarası alanında problem var");
                                        }
                                        else {
                                            var errorMessages = "";
                                            numbers.forEach((number) => {
                                                if (number.length > 10) {
                                                    errorMessages += "Girmiş olduğunuz, " + number + " 10 karekterden büyük olamaz<br/>";
                                                }
                                                else if (number.length < 10) {
                                                    errorMessages += "Girmiş olduğunuz, " + number + " 10 karekterden olamaz<br/> ";
                                                }
                                                else if (isNaN(number)) {
                                                    errorMessages += "Girmiş olduğunuz, " + number + " bir telefon numarası değil<br/>";
                                                }
                                            });
                                            if (errorMessages !== "") {
                                                alert("<div style='overflow:scroll-y;height:150px;width:auto;'>" + errorMessages + "</div>")
                                            } else {
                                                let messageInfo = "Girdiğiniz bilgilere göre telefon numaralarına sms gönderilecektir.<br/>";
                                                let result = confirm(messageInfo, "Sms gönderim onayı");
                                                result.then((dialogResult) => {
                                                    if (dialogResult === true) {
                                                        service.post(otomasyonApi + "sms/postserbestsmsgonder", this.formSmsData, {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                            .then(async (res) => {
                                                                if (res.data.Success) {
                                                                    await this.setState({
                                                                        popupSms: false
                                                                    });
                                                                    notify("Gönderim işleminiz gerçekleşti", "success", 5000);
                                                                } else {
                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                                }
                                                            })
                                                            .catch(err => {
                                                                if (err.response) {
                                                                    var resError = JSON.parse(err.response.request.response);
                                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                    var dictionary = Object.keys(resError.errors);
                                                                    dictionary.forEach((satir, index) => {
                                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                    });
                                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                    alert(mesaj);
                                                                    // client received an error response (5xx, 4xx)
                                                                } else if (err.request) {
                                                                    // client never received a response, or request never left
                                                                } else {
                                                                    // anything else
                                                                }
                                                            });
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        this.setState({
                                            popupSms: false
                                        })
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formSmsData} onInitialized={this.onInitializedFormNormalSms} ref={(ref) => this.formNormalSms = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField={"TelefonNo"} editorType={"dxTextBox"} editorOptions={{
                                            value: this.state.telefon,
                                            placeholder: "Birden çok numaraya sms göndermek için numara aralarına virgül koyabilirsiniz"
                                        }}>
                                            <RequiredRule message="Telefon No zorunludur" />
                                            <Label text="Telefon No" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Mesaj"} editorType={"dxTextBox"} editorOptions={{
                                            onInitialized: (e) => {
                                                this.normalSmsSecilenOgrenciMesajTextBox = e.component;
                                            },
                                            ref: (ref) => { this.normalSmsSecilenOgrenciMesajTextBox = ref },
                                            onKeyUp: (e) => {
                                                document.getElementById("divKarekterSayisiNormalSms").innerText = this.normalSmsSecilenOgrenciMesajTextBox.instance().option("text").length;
                                            }
                                        }}>
                                            <RequiredRule message="Mesaj alanı zorunludur" />
                                            <Label text="Mesaj" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label>Mesaj Karekter Sayısı:</label>
                                            </div>
                                            <div className="col-md-6" id="divKarekterSayisiNormalSms" style={{ textAlign: "left" }}>

                                            </div>
                                        </div>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.sinavaKatilanOgrenciDataSource}
                                                columnHidingEnabled={true}
                                                key={"Id"}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridSinavaKatilanOgrenciler = ref}
                                                onInitialized={(e) => {
                                                    this.gridSinavaKatilanOgrenciler = e.component;
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: "after",
                                                            widget: "dxButton",
                                                            options: {
                                                                icon: "plus",
                                                                type: "default",
                                                                onClick: async () => {
                                                                    this.formSmsData.Telefon = "";
                                                                    let data = this.gridSinavaKatilanOgrenciler.instance.getSelectedRowsData();
                                                                    if (data.length > 0) {

                                                                        data.forEach((row) => {
                                                                            if (!this.formSmsData.Telefon.includes(row.OgrenciCepTel)) {
                                                                                if (row.OgrenciCepTel.startsWith('0')) {
                                                                                    row.OgrenciCepTel = row.OgrenciCepTel.slice(1);
                                                                                }

                                                                                this.formSmsData.Telefon += row.OgrenciCepTel + ",";
                                                                            }
                                                                        });
                                                                    }
                                                                    if (this.formSmsData.Telefon.slice(-1) === ",") {
                                                                        this.formSmsData.Telefon = this.formSmsData.Telefon.slice(0, -1);//son karekteri temizler
                                                                    }
                                                                    this.setState({ "telefon": this.formSmsData.Telefon });
                                                                }
                                                            }
                                                        },
                                                    )
                                                }}
                                                selection={{ mode: 'multiple' }}
                                                showCheckBoxesMode={true}
                                            >
                                                <Editing mode={'row'} useIcons={true} allowUpdating={true} />
                                                <LoadPanel enabled={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'Adi'} caption="Ad" allowEditing={false} />
                                                <Column dataField={'Soyadi'} caption="Soyad" dataType="string" allowEditing={false} />
                                                <Column dataField={'SinifKodu'} caption="Sınıf" dataType="string" allowEditing={false} />
                                                <Column dataField={'OgrenciCepTel'} caption="CepTelefonu" dataType="string" allowEditing={true} />
                                            </DataGrid>
                                        </SimpleItem>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedLayout);