import React from 'react';
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import DataGrid, {
    Export, ColumnChooser, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Sorting
} from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
export default class KurumTaksitTahsilatRaporu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbarDisabled: false,
            baslangicTarihi: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0, 0, 0),
            bitisTarihi: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDay() + 1, 23, 59, 59, 0)
        };
        this.mainDataSource = new DataSource(
            {
                filter: [["Tarih", ">=", this.state.baslangicTarihi], "and", ["Tarih", "<=", this.state.bitisTarihi]],
                paginate: true,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/listkurumtaksittahsilatraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }
    render() {
        return (

            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Rapor İşlemleri>>Kurum Taksit Tahsilat Raporu"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Başlangıç Tarihi
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <DateBox defaultValue={this.state.baslangicTarihi}
                                type="datetime"
                                onValueChanged={(e) => {
                                    this.setState({
                                        baslangicTarihi: e.value
                                    })
                                }} />
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Bitiş Tarihi
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "5px" }}>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <DateBox defaultValue={this.state.bitisTarihi}
                                type="datetime"
                                onValueChanged={(e) => {
                                    this.setState({
                                        bitisTarihi: e.value
                                    });
                                    this.mainDataSource.filter([["Tarih", ">=", this.state.baslangicTarihi], "and", ["Tarih", "<=", this.state.bitisTarihi]]);
                                    this.mainDataSource.load();
                                    this.gridMain.instance.refresh();
                                }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">


                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.mainDataSource}
                                remoteOperations={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onToolbarPreparing={(e) => {
                                    e.toolbarOptions.items.unshift(
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                            disabled: this.state.toolbarDisabled,
                                            options: {
                                                icon: "email",
                                                type: "success",
                                                text: "Taksit Hatırlat",
                                                onClick: () => {
                                                    this.setState({
                                                        toolbarDisabled: true
                                                    });
                                                    var basTarih = new Date(this.state.baslangicTarihi);
                                                    basTarih.setHours(basTarih.getHours() + 3);
                                                    var bitTarih = new Date(this.state.bitisTarihi);
                                                    bitTarih.setHours(bitTarih.getHours() + 3);
                                                    this.dateFilter = {
                                                        Tarih1: basTarih,
                                                        Tarih2: bitTarih
                                                    }
                                                    service.post(otomasyonApi + "sms/posttaksithatirlatmasmsgonder", this.dateFilter,
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                        .then(res => {
                                                            if (res.data.Success) {
                                                                this.setState({
                                                                    toolbarDisabled: false
                                                                });
                                                                notify(res.data.Data, "success", 5000);
                                                            }
                                                            else {
                                                                this.setState({
                                                                    toolbarDisabled: false
                                                                });
                                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                            }
                                                        })
                                                        .catch(err => {
                                                            this.setState({
                                                                toolbarDisabled: false
                                                            });
                                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                        });
                                                }
                                            }
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                                            disabled: this.state.toolbarDisabled,
                                            options: {
                                                icon: "email",
                                                type: "success",
                                                text: "Gecikmiş Taksitleri Hatırlat",
                                                onClick: () => {
                                                    this.setState({
                                                        toolbarDisabled: true
                                                    });
                                                    var basTarih = new Date(this.state.baslangicTarihi);
                                                    basTarih.setHours(basTarih.getHours() + 3);
                                                    var bitTarih = new Date(this.state.bitisTarihi);
                                                    bitTarih.setHours(bitTarih.getHours() + 3);
                                                    this.dateFilter = {
                                                        Tarih1: basTarih,
                                                        Tarih2: bitTarih
                                                    }
                                                    service.post(otomasyonApi + "sms/posttaksitgecikmesmsgonder", this.dateFilter,
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                        .then(res => {
                                                            if (res.data.Success) {
                                                                this.setState({
                                                                    toolbarDisabled: false
                                                                });
                                                                notify(res.data.Data, "success", 5000);
                                                            }
                                                            else {
                                                                this.setState({
                                                                    toolbarDisabled: false
                                                                });
                                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                            }
                                                        })
                                                        .catch(err => {
                                                            this.setState({
                                                                toolbarDisabled: false
                                                            });
                                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                        });
                                                }
                                            }
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            options: {
                                                icon: "datafield",
                                                type: "success",
                                                text: "Tüm Kayıtlar",
                                                onClick: () => {
                                                    this.mainDataSource.filter(null);
                                                    this.gridMain.instance.refresh();
                                                }
                                            }
                                        }
                                    );
                                }}
                                onInitialized={this.onInitialized}
                                onSelectionChanged={this.onSelectionChanged}

                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export enabled={true} fileName={'KurumTaksitTahsilatRaporu'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} enabled={false} />
                                <Pager showPageSizeSelector={false} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                <Column dataField="Id" caption=""
                                    width={40}
                                    cellRender={linkWhatsAppRender}
                                    allowExporting={false}
                                />
                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                <Column dataField={'GecikmeDurumu'} caption="Gecikme Durumu" />
                                <Column dataField={'OgrenciNo'} caption="Öğrenci No" />
                                <Column dataField={'AdiSoyadi'} caption="Adı Soyadı" />
                                <Column dataField={'VeliAdSoyad'} caption="Velisi" />
                                <Column dataField={'VeliCepTelefonu'} caption="V. Cep Telefonu" />
                                <Column dataField={'CepTelefonu'} caption="Cep Telefonu" visible={false} showInColumnChooser={true} />
                                <Column dataField={'Tarih'} caption="Tarih" dataType="date" allowSorting={true} />
                                <Column dataField={'Tutar'} caption="Tutar" dataType="number" allowSorting={true} format={"#,##0.##"} />
                                <Column dataField={'Tahsilat'} caption="Tahsilat" dataType="number" format={"#,##0.##"} />
                                <Column dataField={'OdenmeDurumu'} caption="Ödeme Durumu" />
                                <Sorting mode="multiple" /> {/* or "multiple" | "none" */}
                            </DataGrid>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function linkWhatsAppRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.VeliCepTelefonu === undefined || data.data.VeliCepTelefonu === null || data.data.VeliCepTelefonu === "") {
        return <img src="/icons/whatsapp.png" alt="Whatspp da paylaş" onClick={() => {
            alert("Bu öğrencimizin veli cep telefonu bilgisi olmadığından paylaşım yapılamıyor.<br/> Öğrenci kayıt ekranından veli cep telefonu ekleyebilirsiniz.");
        }} />;
    }
    else {
        var date = new Date(data.data.Tarih);
        if (data.data.GecikmeDurumu === "Taskit gecikmiş") {
            return <a rel="noopener noreferrer"
                href={"https://api.whatsapp.com/send?phone=+90" + data.data.VeliCepTelefonu + "&text=Sayın " + data.data.VeliAdSoyad + ",  " + data.data.AdiSoyadi + " isimli öğrencimizin " + date.toLocaleDateString() + " tarihli gecikmiş taksit ödemesini hatırlatmak isteriz"}
                target="_blank"
                data-action="share/whatsapp/share">
                <img src="/icons/whatsapp.png" alt="Whatsapp ile paylaş" /></a>;
        }
        else if (data.data.GecikmeDurumu === "Taksit tarihi gelmemiş") {
            return <a rel="noopener noreferrer"
                href={"https://api.whatsapp.com/send?phone=+90" + data.data.VeliCepTelefonu + "&text=Sayın " + data.data.VeliAdSoyad + ",  " + data.data.AdiSoyadi + " isimli öğrencimizin " + date.toLocaleDateString() + " tarihli taksit ödemesini hatırlatmak isteriz"}
                target="_blank"
                data-action="share/whatsapp/share">
                <img src="/icons/whatsapp.png" alt="Whatsapp ile paylaş" /></a>;
        }
        else {
            return "";
        }
    }
}