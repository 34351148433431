import * as React from "react";
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import { alert, confirm } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { Button } from 'devextreme-react/button';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, RequiredRule, Export, Selection, Scrolling, Position

} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import ArrayStore from 'devextreme/data/array_store';
import Toolbar, { Item } from 'devextreme-react/toolbar';
export default class DevamsizlikKayit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toolbarDisabled: false,
            popupDevamsizlikDurumVisible: false,
            subeDevamsizlikDurumData: []
        };
        this.formDevamsizlik = null;
        this.gridDevamsizlik = null;
        this.gridOgrenci = null;
        this.gridKonu = null;
        this.gridDers = null;
        this.dropDownBoxOgrenci = null;
        this.dropDownBoxKonu = null;
        this.dropDownBoxDers = null;
        this.selectBoxSinifSube = null;
        this.selectBoxSinifSeviye = null;
        this.toolTipDevamsizlikInfo = null;
        this.satir = null;
        const dersData = [
            {
                Id: 1,
                Adi: "1. Ders"
            },
            {
                Id: 2,
                Adi: "2. Ders"
            },
            {
                Id: 3,
                Adi: "3. Ders"
            },
            {
                Id: 4,
                Adi: "4. Ders"
            },
            {
                Id: 5,
                Adi: "5. Ders"
            },
            {
                Id: 6,
                Adi: "6. Ders"
            },
            {
                Id: 7,
                Adi: "7. Ders"
            },
            {
                Id: 8,
                Adi: "8. Ders"
            },
            {
                Id: 9,
                Adi: "9. Ders"
            },
            {
                Id: 10,
                Adi: "10. Ders"
            }
        ];
        this.dersler = new ArrayStore({
            key: 'Id',
            data: dersData,
            // Other ArrayStore properties go here
        });

        // this.ogrenciDataGridRender = this.ogrenciDataGridRender.bind(this);
        //this.konuDataGridRender = this.konuDataGridRender.bind(this);
        this.dersDataGridRender = this.dersDataGridRender.bind(this);

        this.onInitializedFormDevamsizlik = this.onInitializedFormDevamsizlik.bind(this);
        this.onInitializedGridDevamsizlik = this.onInitializedGridDevamsizlik.bind(this);


        this.onInitializedDropDownBoxDers = this.onInitializedDropDownBoxDers.bind(this);

        this.onInitializedSelectBoxSinifSube = this.onInitializedSelectBoxSinifSube.bind(this);
        this.onInitializedSelectBoxSinifSeviye = this.onInitializedSelectBoxSinifSeviye.bind(this);

        this.onInitializedGridOgrenci = this.onInitializedGridOgrenci.bind(this);
        this.onInitializedGridDers = this.onInitializedGridDers.bind(this);

        this.onOgrenciDataGridSelectionChanged = this.onOgrenciDataGridSelectionChanged.bind(this);
        this.onDersDataGridSelectionChanged = this.onDersDataGridSelectionChanged.bind(this);

        this.onDropDownBoxDersValueChanged = this.onDropDownBoxDersValueChanged.bind(this);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.gridMainSatirIptal = this.gridMainSatirIptal.bind(this);

        this.sinifSeviyeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.seviyeFilter = {
            seviyeId: 0
        };

        this.sinifSubeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifsubebykullanici',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });



        this.ogrenciFilter = {
            subeId: 0,
            seviyeId: 0
        };

        this.ogrenciDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciFilter,
                    loadUrl: otomasyonApi + 'ogrenci/getKurumOgrencileriBySubeIdSeviyeId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        var tx = new Date();
        tx.setHours(tx.getHours() + 3)


        this.devamsizlikFilter = { tarih: tx.toJSON() };
        this.devamsizlikuDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "IdGuid",
                store: createStore({
                    key: "IdGuid",
                    loadParams: this.devamsizlikFilter,
                    loadUrl: otomasyonApi + 'devamsizlik/list',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.formData = {
            Dersler: [],
            SinifSeviye: { Id: null },
            SubeId: null,
            Konular: [],
            Tip: null,
            Tur: null,
            Icerik: null,
            Tarih: tx.toJSON(),
            Ogrenciler: [],
            EgitimTuruId: null
        }

        this.gridDersBoxValue = [];
        this.gridOgrenciBoxValue = [];
    }

    popupSubeDevamsizlikDurumuGizle() {
        this.setState({
            popupDevamsizlikDurumVisible: false
        })
    }
    onInitializedFormDevamsizlik(e) {
        this.formDevamsizlik = e.component;
    }

    onInitializedGridDevamsizlik(e) {
        this.gridDevamsizlik = e.component;
    }

    onInitializedDropDownBoxDers(e) {
        this.dropDownBoxDers = e.component;
    }

    onInitializedGridOgrenci(e) {
        this.gridOgrenci = e.value;
    }

    onInitializedGridDers(e) {
        this.gridDers = e.component;
    }

    onInitializedSelectBoxSinifSube(e) {
        this.selectBoxSinifSube = e.component;
    }

    onInitializedSelectBoxSinifSeviye(e) {
        this.selectBoxSinifSeviye = e.component;
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onOgrenciDataGridSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
            this.gridOgrenciBoxValue = [];
        }
        else {
            this.gridOgrenciBoxValue = e.selectedRowKeys;
        }

    }

    onDersDataGridSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
            this.gridDersBoxValue = [];
        }
        else {
            this.gridDersBoxValue = e.selectedRowKeys;
        }
        this.dropDownBoxDers.instance.option("value", this.gridDersBoxValue);
    }




    onDropDownBoxDersValueChanged(e) {
        if (this.gridDersBoxValue === e.value) {
            return;
        }
        else {
            this.gridDersBoxValue = e.value || [];
            this.dropDownBoxDers.instance.option("value", this.gridDersBoxValue);
        }
    }

    gridMainSatirIptal() {
        if (this.gridDevamsizlik.instance !== null) {
            this.gridDevamsizlik.instance.clearSelection();
            this.gridDevamsizlik.instance.option("focusedRowIndex", -1);
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Tüm kayıtları getir', icon: 'event',
                    text: "Tüm kayıtları getir",
                    onClick: () => {
                        var allDate = new Date(1900, 1, 1, 1, 1, 1, 1);
                        this.devamsizlikFilter.tarih = allDate.toJSON();
                        this.gridDevamsizlik.instance.refresh();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    text: "Devamsızlık satırı sil",
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Devamsızlık verisini silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(otomasyonApi + "devamsizlik/sil",
                                        satir,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                this.gridDevamsizlik.instance.refresh();
                                                this.gridMainSatirIptal();
                                            }
                                            else
                                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                        });
                                }
                            });
                        }
                    }
                }
            }
        );
    }

    dersmSmsGonder(dersAdlari) {
        let dersler = [];
        this.gridDersBoxValue.forEach((satir) => {
            dersler.push({
                Id: satir
            });
        });
        debugger;
        const data = {
            Tarih: this.formData.Tarih,
            DersNo: dersler,
            DersAdi: dersAdlari === undefined ? [] : dersAdlari,
            EgitimTuruId: this.formData.EgitimTuruId === null ? 0 : this.formData.EgitimTuruId,
            SeviyeId: this.formData.SinifSeviye.Id === null ? 0 : this.formData.SinifSeviye.Id,
            SubeId: this.formData.SubeId === null ? 0 : this.formData.SubeId,
        };
        // this.setState({
        //     toolbarDisabled: true
        // });
        service.post(otomasyonApi + "sms/postdevamsizliksmsgonder", data,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    // this.setState({
                    //     toolbarDisabled: false
                    // });
                    notify(res.data.Data, "success", 5000);
                }
                else {
                    // this.setState({
                    //     toolbarDisabled: false
                    // });
                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                }
            })
            .catch(err => {
                // this.setState({
                //     toolbarDisabled: false
                // });
                alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
            });
    }
    dersBildirimGonder(dersAdlari) {
        let dersler = [];
        this.gridDersBoxValue.forEach((satir) => {
            dersler.push({
                Id: satir
            });
        });
        debugger;
        const data = {
            Tarih: this.formData.Tarih,
            DersNo: dersler,
            DersAdi: dersAdlari === undefined ? [] : dersAdlari,
            EgitimTuruId: this.formData.EgitimTuruId === null ? 0 : this.formData.EgitimTuruId,
            SeviyeId: this.formData.SinifSeviye.Id === null ? 0 : this.formData.SinifSeviye.Id,
            SubeId: this.formData.SubeId === null ? 0 : this.formData.SubeId,
        };
        // this.setState({
        //     toolbarDisabled: true
        // });
        service.post(otomasyonApi + "sms/postdevamsizlikbildirimgonder", data,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    // this.setState({
                    //     toolbarDisabled: false
                    // });
                    notify(res.data.Data, "success", 5000);
                }
                else {
                    // this.setState({
                    //     toolbarDisabled: false
                    // });
                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                }
            })
            .catch(err => {
                // this.setState({
                //     toolbarDisabled: false
                // });
                alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
            });
    }
    render() {
        return (
            <React.Fragment>


                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Devamsızlık İşlemleri>>Devamsızlık Kayıt"}</h4>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-12">


                            <Form formData={this.formData} onInitialized={this.onInitializedFormDevamsizlik} ref={(ref) => this.formDevamsizlik = ref}>
                                <GroupItem cssClass="first-group" colCount={3} caption="Devamsızlık Bilgileri">
                                    <SimpleItem dataField={"Ders.Id"}>
                                        <DropDownBox
                                            valueExpr="Id"
                                            deferRendering={false}
                                            displayExpr="Adi"
                                            showClearButton={true}
                                            dataSource={this.dersler}
                                            contentRender={this.dersDataGridRender}
                                            onValueChanged={this.onDropDownBoxDersValueChanged}
                                            onInitialized={this.onInitializedDropDownBoxDers}
                                            ref={(ref) => { this.dropDownBoxDers = ref }}
                                        />
                                        <Label text="Ders"></Label>
                                        <RequiredRule message="Ders alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"EgitimTuruId"} editorType={"dxSelectBox"} editorOptions={{
                                        dataSource: new DataSource({
                                            loadMode: "raw",
                                            store: createStore({
                                                key: "Id",
                                                loadUrl: otomasyonApi + 'geneltanim/listegitimturu',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    };
                                                }
                                            })
                                        }),
                                        valueExpr: "Id",
                                        displayExpr: "Adi",
                                        showClearButton: true
                                    }}>
                                        <Label text="Eğitim Türü"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"SinifSeviye.Id"} editorType={"dxSelectBox"} editorOptions={{
                                        dataSource: this.sinifSeviyeDataSource,
                                        valueExpr: "Id",
                                        displayExpr: "Adi",
                                        onInitialized: this.onInitializedSelectBoxSinifSeviye,
                                        ref: (ref) => this.selectBoxSinifSeviye = ref,
                                        onValueChanged: async (e) => {
                                            this.selectBoxSinifSube.instance().option("value", null);
                                            var dataSource = this.selectBoxSinifSube.instance().option("dataSource");
                                            dataSource.filter(["SeviyeId", "=", e.value]);
                                            await dataSource.load().done(() => {
                                                this.selectBoxSinifSube.instance().option("dataSource", dataSource);
                                            });
                                            this.seviyeFilter.seviyeId = e.value;
                                            this.ogrenciFilter.seviyeId = e.value;
                                            this.ogrenciFilter.subeId = 0;
                                            this.formData.SinifSeviye.Id = e.value;
                                        }
                                    }}>
                                        <Label text="Seviye"></Label>
                                        <RequiredRule message="Seviye alanı zorunludur"></RequiredRule>
                                    </SimpleItem>
                                    <SimpleItem dataField={"SubeId"} editorType={"dxSelectBox"} editorOptions={{
                                        dataSource: this.sinifSubeDataSource,
                                        valueExpr: "Id",
                                        displayExpr: "Adi",
                                        onInitialized: this.onInitializedSelectBoxSinifSube,
                                        ref: (ref) => this.selectBoxSinifSube = ref,
                                        onValueChanged: (e) => {
                                            this.ogrenciFilter.subeId = e.value;
                                            this.gridOgrenci.instance.refresh();
                                            this.formData.SubeId = e.value;
                                        }
                                    }}>
                                        <Label text="Şube"></Label>
                                        <RequiredRule message="Şube alanı zorunludur"></RequiredRule>
                                    </SimpleItem>
                                    <SimpleItem dataField={"Tur"} editorType={"dxSelectBox"}
                                        editorOptions={{
                                            showClearButton: true,
                                            dataSource: new DataSource({
                                                loadMode: "raw",
                                                store: createStore({
                                                    key: "Value",
                                                    loadParams: {
                                                        enumName: "DevamsizlikTuru"
                                                    },
                                                    loadUrl: otomasyonApi + 'common/getenums',
                                                    onBeforeSend: (method, ajaxOptions) => {
                                                        ajaxOptions.headers = {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        };
                                                    }
                                                })
                                            }),
                                            valueExpr: "Value",
                                            displayExpr: "Name"
                                        }}
                                    >
                                        <Label text="Durum"></Label>
                                        <RequiredRule message="Durum alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"Tarih"} editorType={"dxDateBox"}
                                        editorOptions={{
                                            value: new Date(),
                                            onValueChanged: (e) => {
                                                try {
                                                    var t = new Date(e.value);
                                                    t.setHours(t.getHours() + 3);
                                                    this.formData.Tarih = t;
                                                    this.devamsizlikFilter.tarih = t.toJSON();
                                                    this.gridDevamsizlik.instance.refresh();
                                                    this.gridMainSatirIptal();
                                                } catch (error) {

                                                }

                                            }
                                        }}
                                    >
                                        <Label text="Tarih"></Label>
                                        <RequiredRule message="Tarih alanı zorunludur" />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem cssClass="first-group" colCount={1} caption="İşlemler">
                                    <SimpleItem>
                                        <Toolbar>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    width: 120,
                                                    text: "Ekle",
                                                    type: "success",
                                                    onClick: () => {
                                                        const validateStatus = this.formDevamsizlik.instance.validate();
                                                        if (!validateStatus.isValid) {
                                                            return;
                                                        }
                                                        else {
                                                            // if (this.formData.Konu.Id === "" || this.formData.Konu.Id === undefined || this.formData.Konu.Id === null) {
                                                            //     this.formData.Konu.Id = 0;
                                                            // }
                                                            let dersler = [];
                                                            this.gridDersBoxValue.forEach((satir) => {
                                                                dersler.push({
                                                                    Id: satir
                                                                });
                                                            })

                                                            var ogrenciler = [];
                                                            this.gridOgrenciBoxValue.forEach((satir) => {
                                                                ogrenciler.push({
                                                                    Id: satir
                                                                })
                                                            });
                                                            this.formData.Ogrenciler = ogrenciler;
                                                            this.formData.Dersler = dersler;
                                                            if (dersler.length === 0) {
                                                                alert("En az bir ders seçmelisiniz");
                                                                return;
                                                            }
                                                            if (ogrenciler.length === 0) {
                                                                alert("En az bir öğrenci seçmelisiniz");
                                                                return;
                                                            }
                                                            service.post(otomasyonApi + "devamsizlik/ekle", this.formData, {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            })
                                                                .then(async (res) => {
                                                                    if (res.data.Success) {
                                                                        notify("Devamsızlık eklendi", "success", 5000);
                                                                        this.gridDevamsizlik.instance.refresh();

                                                                    } else {
                                                                        notify(res.data.ErrorMessage, "error", 5000);
                                                                    }
                                                                })
                                                                .catch(err => {
                                                                    if (err.response) {
                                                                        var resError = JSON.parse(err.response.request.response);
                                                                        var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                        var dictionary = Object.keys(resError.errors);
                                                                        dictionary.forEach((satir, index) => {
                                                                            mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                        });
                                                                        mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                        mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                        mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                        alert(mesaj);
                                                                        // client received an error response (5xx, 4xx)
                                                                    } else if (err.request) {
                                                                        // client never received a response, or request never left
                                                                    } else {
                                                                        // anything else
                                                                    }
                                                                })
                                                        }
                                                    }
                                                }}
                                            >
                                            </Item>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    text: "Ders SMS Gönder",
                                                    icon: "email",
                                                    type: "success",
                                                    disabled: this.state.toolbarDisabled,
                                                    elementAttr: {
                                                        style: "margin-left:10px"
                                                    },
                                                    onClick: () => {
                                                        let dersAdlari = prompt("Ders adı girmek ister misiniz? Böylece göndereceğiniz mesajda ders no yerine ders adı veya girdiğiniz metin görüntülenecektir.");
                                                        debugger;
                                                        if (dersAdlari !== null) {
                                                            alert("Ders no yerine göndereceğiniz sms de girdiğiniz '" + dersAdlari + "' ders adları gözükecektir")
                                                                .then(() => {
                                                                    this.dersmSmsGonder(dersAdlari);
                                                                })
                                                        }
                                                        // else {
                                                        //     alert("Herhangi birşey girmediğinizden seçtiğiniz 'Ders no' bilgisi sms üzerinde gözükecektir").then(() => {
                                                        //         this.dersmSmsGonder();
                                                        //     });
                                                        // }


                                                    }
                                                }}
                                            >
                                            </Item>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    text: "Ders Bildirim",
                                                    icon: "email",
                                                    type: "success",
                                                    disabled: this.state.toolbarDisabled,
                                                    elementAttr: {
                                                        style: "margin-left:10px"
                                                    },
                                                    onClick: () => {
                                                        let dersAdlari = prompt("Ders adı girmek ister misiniz? Böylece göndereceğiniz bildirimde ders no yerine ders adı veya girdiğiniz metin görüntülenecektir.");
                                                        debugger;
                                                        if (dersAdlari !== null) {
                                                            alert("Ders no yerine göndereceğiniz bildirim de girdiğiniz '" + dersAdlari + "' ders adları gözükecektir")
                                                                .then(() => {
                                                                    this.dersBildirimGonder(dersAdlari);
                                                                })
                                                        }
                                                        // else {
                                                        //     alert("Herhangi birşey girmediğinizden seçtiğiniz 'Ders no' bilgisi sms üzerinde gözükecektir").then(() => {
                                                        //         this.dersmSmsGonder();
                                                        //     });
                                                        // }


                                                    }
                                                }}
                                            >
                                            </Item>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    text: "Günlük SMS Gönder",
                                                    icon: "email",
                                                    type: "success",
                                                    disabled: this.state.toolbarDisabled,
                                                    elementAttr: {
                                                        style: "margin-left:10px"
                                                    },
                                                    onClick: () => {
                                                        const data = {
                                                            Tarih: this.formData.Tarih,
                                                            DersNo: this.formData.Dersler,
                                                            EgitimTuruId: this.formData.EgitimTuruId === null ? 0 : this.formData.EgitimTuruId
                                                        };
                                                        // this.setState({
                                                        //     toolbarDisabled: true
                                                        // });
                                                        service.post(otomasyonApi + "sms/postgunlukdevamsizliksmsgonder", data,
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            })
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    // this.setState({
                                                                    //     toolbarDisabled: false
                                                                    // });
                                                                    notify(res.data.Data, "success", 5000);
                                                                }
                                                                else {
                                                                    // this.setState({
                                                                    //     toolbarDisabled: false
                                                                    // });
                                                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                                }
                                                            })
                                                            .catch(err => {
                                                                // this.setState({
                                                                //     toolbarDisabled: false
                                                                // });
                                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                            });
                                                    }
                                                }}
                                            >

                                            </Item>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    text: "Günlük Bildirim",
                                                    icon: "email",
                                                    type: "success",
                                                    disabled: this.state.toolbarDisabled,
                                                    elementAttr: {
                                                        style: "margin-left:10px"
                                                    },
                                                    onClick: () => {
                                                        const data = {
                                                            Tarih: this.formData.Tarih,
                                                            DersNo: this.formData.Dersler,
                                                            EgitimTuruId: this.formData.EgitimTuruId === null ? 0 : this.formData.EgitimTuruId
                                                        };
                                                        // this.setState({
                                                        //     toolbarDisabled: true
                                                        // });
                                                        service.post(otomasyonApi + "sms/postgunlukdevamsizlikbildirimgonder", data,
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            })
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    // this.setState({
                                                                    //     toolbarDisabled: false
                                                                    // });
                                                                    notify(res.data.Data, "success", 5000);
                                                                }
                                                                else {
                                                                    // this.setState({
                                                                    //     toolbarDisabled: false
                                                                    // });
                                                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                                }
                                                            })
                                                            .catch(err => {
                                                                // this.setState({
                                                                //     toolbarDisabled: false
                                                                // });
                                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                            });
                                                    }
                                                }}
                                            >

                                            </Item>
                                            <Item
                                                location={"before"}
                                                widget="dxButton"
                                                options={{
                                                    text: "Şube Devamsızlık Raporu",
                                                    type: "success",
                                                    onClick: () => {
                                                        let dersler = [];
                                                        this.gridDersBoxValue.forEach((satir) => {
                                                            dersler.push({
                                                                Id: satir
                                                            });
                                                        })
                                                        if (dersler.length === 0) {
                                                            alert("En az 1 ders seçiniz");
                                                            return;
                                                        }
                                                        const data = {
                                                            Dersler: dersler,
                                                            Tarih: this.formData.Tarih,
                                                            KurumId: Globals.service.Kullanici.KurumId
                                                        };
                                                        service.post(otomasyonApi + "devamsizlik/listsinifseviyedevamsizlikraporu", data,
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            })
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    this.setState({
                                                                        subeDevamsizlikDurumData: res.data.Data,
                                                                        popupDevamsizlikDurumVisible: true
                                                                    });
                                                                }
                                                                else {
                                                                    // this.setState({
                                                                    //     toolbarDisabled: false
                                                                    // });
                                                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                                                }
                                                            })
                                                            .catch(err => {
                                                                // this.setState({
                                                                //     toolbarDisabled: false
                                                                // });
                                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                                            });
                                                    }
                                                }}
                                            >

                                            </Item>
                                        </Toolbar>
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem cssClass="first-group" colCount={1} caption="Öğrenciler">
                                    <SimpleItem>
                                        <DataGrid
                                            dataSource={this.ogrenciDataSource}
                                            hoverStateEnabled={true}
                                            onSelectionChanged={this.onOgrenciDataGridSelectionChanged}
                                            columns={["Adi", "Soyadi", "OgrenciNo"]}
                                            onInitialized={this.onInitializedGridOgrenci}
                                            ref={(ref) => { this.gridOgrenci = ref }}
                                        >
                                            <Selection mode="multiple" />
                                            <Scrolling mode="virtual" />
                                            <Paging enabled={true} pageSize={10} />
                                            <FilterRow visible={true} />
                                        </DataGrid>
                                    </SimpleItem>
                                </GroupItem>
                            </Form>

                            <DataGrid
                                id="grdTaksit"
                                showBorders={true}
                                showRowLines={true}
                                remoteOperations={true}
                                dataSource={this.devamsizlikuDataSource}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onInitialized={this.onInitializedGridDevamsizlik}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                key={"IdGuid"}
                                selection={
                                    {
                                        mode: 'single'
                                    }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="IdGuid"
                                ref={(ref) => this.gridDevamsizlik = ref}
                            >
                                <Export
                                    enabled={true}
                                    fileName={'Devamsizliklar'}
                                    allowExportSelectedData={false}
                                    texts={
                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                    }
                                />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'IdGuid'} caption="Kayıt No" visible={false} allowExporting={false} />
                                <Column dataField="DersNo" caption=""
                                    width={40}
                                    allowSorting={false}
                                    allowFiltering={false}
                                    cellRender={linkWhatsAppRender}
                                    allowExporting={false}
                                />
                                <Column dataField={'DersNoMetin'} caption="Ders No" dataType={"string"} />
                                <Column dataField={'SubeAdi'} caption="Şube" dataType={"string"} />
                                <Column dataField={'AdSoyad'} caption="Öğrenci" dataType={"string"} />
                                <Column dataField={'VeliAdSoyad'} caption="VeliAdSoyad" dataType={"string"} visible={false} />
                                <Column dataField={'VeliCepTelefonu'} caption="VeliCepTelefonu" dataType={"string"} visible={false} />
                                <Column dataField={'Turu'} caption="Tür" />
                                <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                                <Column dataField={'Kullanici'} caption="Kullanıcı" dataType={"string"} />
                            </DataGrid>

                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"90%"} height={"90%"}
                    visible={this.state.popupDevamsizlikDurumVisible}
                    title={"Şube Devamsızlık Raporu"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupSubeDevamsizlikDurumuGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-10">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={false}
                                    dataSource={this.state.subeDevamsizlikDurumData}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    export={{
                                        enabled: true,
                                        fileName: "devamsizliksubedurumrapor"
                                    }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                >
                                    <Export
                                        fileName={'OgrenciDevamsizlikUstRapor'}
                                        allowExportSelectedData={false}
                                        texts={
                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                        }
                                    />

                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'Sube'} caption="Şube" width={75} />
                                    <Column dataField={'Sinif'} caption="Sınıf" />
                                    <Column dataField={'Sayi'} caption="Sayı" />
                                </DataGrid>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }


    dersDataGridRender() {
        return (
            <DataGrid
                dataSource={this.dersler}
                hoverStateEnabled={true}
                onSelectionChanged={this.onDersDataGridSelectionChanged}
                columns={["Adi"]}
                onInitialized={this.onInitializedGridDers}
                ref={(ref) => { this.gridDers = ref }}
            >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }



}
function linkWhatsAppRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.VeliCepTelefonu === undefined || data.data.VeliCepTelefonu === null || data.data.VeliCepTelefonu === "") {
        return <img src="/icons/whatsapp.png" alt="Whatspp da paylaş" onClick={() => {
            alert("Bu öğrencimizin veli cep telefonu bilgisi olmadığından paylaşım yapılamıyor.<br/> Öğrenci kayıt ekranından veli cep telefonu ekleyebilirsiniz.");
        }} />;
    }
    else {
        var htmlData = "";
        htmlData += "https://api.whatsapp.com/send?phone= +90";
        htmlData += data.data.VeliCepTelefonu;
        htmlData += "&text=Sayın ";
        htmlData += data.data.VeliAdSoyad;
        htmlData += ",  ";
        htmlData += data.data.AdSoyad;
        htmlData += " isimli öğrencimiz ";
        htmlData += new Date(data.data.Tarih).toLocaleDateString();
        htmlData += " tarihinde ";
        htmlData += data.data.DersNoMetin;
        htmlData += ". derse *";
        htmlData += data.data.Turu;
        htmlData += "*. ";
        htmlData += " ";

        htmlData += " ";
        return <a rel="noopener noreferrer"
            href={htmlData}
            target="_blank"
            data-action="share/whatsapp/share">
            <img src="/icons/whatsapp.png" alt="Whatsapp ile paylaş" /></a>;
    }
}