import React from 'react';
import service from 'axios';
import { karneApi, sinavApi, authApi } from '../Util';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Form, {
    ButtonItem,
    GroupItem,
    SimpleItem,
    Label,
    RequiredRule
} from 'devextreme-react/form';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
class OgrenciSinavSonucPage extends React.Component {
    constructor(props) {
        super(props);
        this.yayin = 0;

        // this.onInitializedSelectBoxDonem = this.onInitializedSelectBoxDonem.bind(this);
        // this.selectBoxDonem = null;
        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });

        if (!!props.match.params.yayinParam) {
            this.yayin = props.match.params.yayinParam;
        }
        else {
            this.yayin = 0;
        }

        this.yayinfilter = { YayinId: this.yayin };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    key: "SinavTuru.Id",
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ogrenci/GetOgrenciSinavTurleriByYayinId'
                })
            });

        this.sinavTuruFilter = {
            YayinId: 0,
            SinavTuruId: 0,
            DonemId: 0
        };


        this.merkeziSinavDataSource = new DataSource(
            {
                filter: ["Degerlendirilebilir", "=", true],
                paginate: false,
                store: createStore({
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'ogrenci/getogrencimerkezisinavlar'
                })
            });

        this.ilFilter = {
            SinavId: 0
        };

        this.ilDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    loadParams: this.ilFilter,
                    loadUrl: sinavApi + 'ogrenci/getogrenciiller'
                })
            });

        this.ilceFilter = {
            IlId: 0,
            SinavId: 0
        };

        this.ilceDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    loadParams: this.ilceFilter,
                    loadUrl: sinavApi + 'ogrenci/getogrenciilceler'
                })
            });

        this.kurumFilter = {
            IlId: 0,
            IlceId: 0,
            MerkeziSinavId: 0
        };

        this.kurumDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    loadParams: this.kurumFilter,
                    loadUrl: sinavApi + 'ogrenci/getogrencikurumlar'
                })
            });

        this.selectBoxMerkeziSinav = null;
        this.selectBoxIl = null;
        this.selectBoxIlce = null;
        this.selectBoxKurum = null;
        this.onInitializedSelectBoxMerkeziSinav = this.onInitializedSelectBoxMerkeziSinav.bind(this);
        this.onInitializedSelectBoxIl = this.onInitializedSelectBoxIl.bind(this);
        this.onInitializedSelectBoxIlce = this.onInitializedSelectBoxIlce.bind(this);
        this.onInitializedSelectBoxKurum = this.onInitializedSelectBoxKurum.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buttonOptions = {
            text: 'Karne oluştur',
            type: 'success',
            useSubmitBehavior: true
        };
        this.formData = {
            SinavTuruId: null,
            YayinId: null,
            MerkeziSinavId: null,
            IlId: null,
            IlceId: null,
            KurumId: null,
            OgrenciNo: null
        }
    }

    handleSubmit(e) {
        service.post(karneApi + "sonucarayuz/getkarne",
            {
                OgrenciNo: this.formData.OgrenciNo,
                KurumId: this.formData.KurumId,
                SinavTuruId: this.formData.SinavTuruId,
                MerkeziSinavId: this.formData.MerkeziSinavId
            })
            .then(res => {
                if (res.data.Success) {
                    alert("Karneniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile karnenizi bilgisayarınıza indiriniz</a>", "Bilgi");
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            }).catch(error => {
                notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz", "error", 5000);
            });
        e.preventDefault();
    };

    onInitializedSelectBoxMerkeziSinav(e) {
        this.selectBoxMerkeziSinav = e.component;
    }

    onInitializedSelectBoxIl(e) {
        this.selectBoxIl = e.component;
    }

    onInitializedSelectBoxIlce(e) {
        this.selectBoxIlce = e.component;
    }

    onInitializedSelectBoxKurum(e) {
        this.selectBoxKurum = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <div id="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form id="form" onSubmit={this.handleSubmit}>
                                <Form
                                    formData={this.formData}
                                    readOnly={false}
                                    showColonAfterLabel={true}
                                    showValidationSummary={true}
                                >
                                    <GroupItem caption="Sınav Bilgileri" colCount={1}>
                                        <SimpleItem dataField={"DonemId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.donemDataSource,
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                loadPanel: true,
                                                onValueChanged: (e) => {
                                                    if (!!e.value) {
                                                        this.sinavTuruFilter.DonemId = e.value;
                                                    }
                                                }
                                            }}>
                                            <RequiredRule message="Sınav türü alanı zorunludur" />
                                            <Label text="Dönem" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinavTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruDataSource,
                                                searchEnabled: true,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                loadPanel: true,
                                                onValueChanged: (e) => {
                                                    if (!!e.value) {
                                                        this.sinavTuruFilter.SinavTuruId = e.value;
                                                        this.sinavTuruFilter.YayinId = this.yayin;
                                                        this.merkeziSinavDataSource.load();
                                                        this.selectBoxMerkeziSinav.instance().option("value", null);
                                                    }
                                                }
                                            }}>
                                            <RequiredRule message="Sınav türü alanı zorunludur" />
                                            <Label text="Sınav Türü" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MerkeziSinavId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.merkeziSinavDataSource,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxMerkeziSinav,
                                                ref: (ref) => { this.selectBoxMerkeziSinav = ref },
                                                onValueChanged: (e) => {
                                                    if (!!e.value) {
                                                        this.kurumFilter.MerkeziSinavId = e.value;
                                                        this.ilFilter.SinavId = e.value;
                                                        this.ilceFilter.SinavId = e.value;
                                                        this.ilDataSource.load();
                                                        this.selectBoxIl.instance().option("value", null);
                                                        this.selectBoxIlce.instance().option("value", null);
                                                        this.selectBoxKurum.instance().option("value", null);

                                                    }
                                                }
                                            }}>
                                            <Label text="Sınav" />
                                            <RequiredRule message="Merkez sınav alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"IlId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.ilDataSource,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxIl,
                                                ref: (ref) => { this.selectBoxIl = ref },
                                                onValueChanged: (e) => {
                                                    if (!!e.value) {
                                                        this.ilceFilter.IlId = e.value;
                                                        this.kurumFilter.IlId = this.selectBoxIl.instance().option("value");
                                                        this.ilceDataSource.load();
                                                        this.selectBoxIlce.instance().option("value", null);
                                                    }
                                                }
                                            }}>
                                            <Label text="İl" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"IlceId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.ilceDataSource,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxIlce,
                                                ref: (ref) => { this.selectBoxIlce = ref },
                                                onValueChanged: (e) => {
                                                    if (!!e.value) {

                                                        this.kurumFilter.IlceId = this.selectBoxIlce.instance().option("value");
                                                        this.kurumDataSource.load();
                                                    }
                                                }
                                            }}>
                                            <Label text="İlçe" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KurumId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.kurumDataSource,
                                                displayExpr: "KurumAdi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxKurum,
                                                ref: (ref) => { this.selectBoxKurum = ref }
                                            }}>
                                            <RequiredRule message="Kurum alanı zorunludur" />
                                            <Label text="Kurum" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"OgrenciNo"} editorOptions={{ placeholder: " Sınavda kodladığınız numara" }}
                                            editorType="dxNumberBox">
                                            <RequiredRule message="Öğrenci no alanı zorunludur" />
                                            <Label text="Öğrenci No" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <ButtonItem horizontalAlignment="left"
                                        buttonOptions={this.buttonOptions}
                                    />
                                </Form>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default OgrenciSinavSonucPage;