import * as React from "react";
import { sinavApi, authApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import auth from "../../Auth";
import DataGrid, {
    Scrolling, Selection, Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import TreeList from 'devextreme-react/tree-list';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

function copyRichText(text) {
    const listener = function (ev) {
        ev.preventDefault();
        ev.clipboardData.setData('text/html', text);
        ev.clipboardData.setData('text/plain', text);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    notify("Bilgiler kopyalandı", "success", 5000);
}
export default class YayineviKullaniciIslemleri extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            kullaniciAktarVisible: false,
            kullaniciYayinYetkilendirme: false
        };
        this.gridYayin = null;
        this.gridMain = null;
        this.gridYayinMain = null;
        this.gridKurum = null;
        this.gridMerkezKullaniciYetki = null;
        this.formKullanici = null;
        this.formKullaniciAktar = null;
        this.selectBoxRol = null;
        this.satir = null;
        this.yayinlar = [];
        this.kurumlar = [];
        this.yayinYetkileriArrayList = [];
        this.ililceYetkileriArrayList = [];
        this.kurumYetkileriArrayList = [];
        this.formData = {
        }
        this.sinavKullanici = {};
        this.secilenKullanicilar = [];
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionGridMainChanged = this.onSelectionGridMainChanged.bind(this);
        this.onSelectionGridYayinChanged = this.onSelectionGridYayinChanged.bind(this);
        this.onSelectionGridMerkeziKullaniciYetkiChanged = this.onSelectionGridMerkeziKullaniciYetkiChanged.bind(this);
        this.onSelectionGridKurumChanged = this.onSelectionGridKurumChanged.bind(this);
        this.onInitializedGridMain = this.onInitializedGridMain.bind(this);
        this.onInitializedGridKurum = this.onInitializedGridKurum.bind(this);
        this.onInitializedGridYayin = this.onInitializedGridYayin.bind(this);
        this.onInitializedGridYayinMain = this.onInitializedGridYayinMain.bind(this);
        this.onInitializedGridMerkezKullaniciYetki = this.onInitializedGridMerkezKullaniciYetki.bind(this);
        this.onInitializedSelectBoxRol = this.onInitializedSelectBoxRol.bind(this);
        this.onInitializedFormKullanici = this.onInitializedFormKullanici.bind(this);
        this.onInitializedFormKullaniciAktar = this.onInitializedFormKullaniciAktar.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.popupGizle = () => {
            this.setState({ popup: false });
        };
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.arrayList = null;
        this.flag = false;
        this.secilenIlIlceler = [];
        this.secilenYayinlar = [];
        this.secilenKurumlar = [];
        this.KullaniciYayinYetki = [];
        this.MerkezKullaniciYetki = [];
        this.MerkeziKurumYetki = [];
        this.KullaniciSinavDetay = {};
        this.kullaniciDataSource = new DataSource(
            {

                paginate: false,
                requireTotalCount: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kullanici/getKullanicilar',

                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.rolDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'rol/roller',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.merkezKullaniciYetkiDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'ortak/gettumilveilceler',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.kurumDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });

        this.popupKullaniciAktarGizle = () => {
            this.setState({
                kullaniciAktarVisible: false
            });
        }

        this.popupYayinYetkilendirmeGizle = () => {
            this.setState({
                kullaniciYayinYetkilendirme: false
            });
        }

        this.formKullaniciAktarData = {
            Kullanicilar: [],
            toolBarDisabled: false,
            GecmisDonemId: Globals.service.DonemId,
            DonemId: null,
        };
        this.sinifSeviyeleri = window.ayarlar.SinifSeviyeleri;
    }


    async popupAc() {

        this.setState({
            popup: true
        });
    }

    async yeni() {
        this.formData = {
            Id: 0,
            Rol_Id: null,
            Deleted: false,
            RowVersion: 0,
            KullaniciAdi: null,
            Sifre: null,
            Email: null,
            Adi: null,
            Soyadi: null,
            SmsBilgi: false,
            MailBilgi: false,
            Tel: null,
            Gsm: null,
            Resim: null,
            SinifSeviyeId: 0
        };
    }

    async dataClear() {
        this.secilenIlIlceler = [];
        this.secilenYayinlar = [];
        this.secilenKurumlar = [];
        this.KullaniciYayinYetki = [];
        this.MerkezKullaniciYetki = [];
        this.MerkeziKurumYetki = [];
        this.KullaniciSinavDetay = {};
        await this.gridYayin.instance.clearSelection();
        await this.gridYayinSatirIptal();
        await this.gridMerkezKullaniciYetki.instance.clearSelection();
        await this.gridMerkezKullaniciYetkiSatirIptal();
        await this.gridKurum.instance.clearSelection();
        await this.gridKurumSatirIptal();
    }

    async getKullanici(id) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getKullanici/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    res.data.Data = res.data.Data[0];
                    this.formData = {
                        Id: res.data.Data.Id,
                        Rol_Id: res.data.Data.Rol_Id,
                        Deleted: res.data.Data.Deleted,
                        RowVersion: res.data.Data.RowVersion,
                        KullaniciAdi: res.data.Data.KullaniciAdi,
                        Sifre: res.data.Data.Sifre,
                        Email: res.data.Data.Email,
                        Adi: res.data.Data.Adi,
                        Soyadi: res.data.Data.Soyadi,
                        SmsBilgi: res.data.Data.SmsBilgi,
                        MailBilgi: res.data.Data.MailBilgi,
                        Tel: res.data.Data.Tel,
                        Gsm: res.data.Data.Gsm,
                        Resim: res.data.Data.Logo,
                        Logo: res.data.Data.Logo,
                        VarsayilanLogo: res.data.Data.VarsayilanLogo,
                        SinifSeviyeId: 0
                    };
                    resolve("Kullanıcı bilgileri getirildi.");
                }
            }).catch(() => {
                reject("Teknik bir hata oluştu,Kullanıcı bilgileri getirilemedi");
            });
        });
    }

    async getYayinYetkileriByKullanici(kullaniciId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getYayinYetkileriByKullanici/" + kullaniciId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.yayinYetkileriArrayList = [];
                    res.data.Data.forEach((item, index) => {
                        this.yayinYetkileriArrayList.push(item.Yayin_Id)
                    });
                    resolve("Yayın yetkileri getirildi.");
                }
            }).catch(() => {
                reject("Teknik bir hata oluştu, yayın yetkileri getirilemedi");
            });
        });
    }

    async getKullaniciIlveIlceYetkileri(kullaniciId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getMerkezKullaniciYetkileriByKullanici/" + kullaniciId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.ililceYetkileriArrayList = [];
                    res.data.Data.forEach((item, index) => {
                        this.ililceYetkileriArrayList.push(item.Ilce_Id)
                    });
                    resolve("İl ve İlçe  yetkileri getirildi.");
                }
            }).catch(() => {
                reject("Teknik bir hata oluştu, il ve ilçe  yetkileri getirilemedi");
            });
        })
    }

    async getMerkezKurumYetkileriByKullanici(kullaniciId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getMerkezKurumYetkileriByKullanici/" + kullaniciId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
                .then(res => {
                    if (res.data.Success) {
                        this.kurumYetkileriArrayList = [];
                        res.data.Data.forEach((item, index) => {
                            this.kurumYetkileriArrayList.push(item.Kurum.Id)
                        });
                        resolve("Kurum yetkileri verildi.");
                    }
                }).catch(() => {
                    reject("Teknik bir hata oluştu, kurum bilgileri getirilemedi");
                });
        });
    }

    async duzenleClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor") {
                alert("Üzgünüz bu işlem için yetkiniz yok");
            }
            else {
                try {
                    await this.dataClear();
                    this.formData = {};
                    await this.getKullanici(satir.Id);
                    await this.rolDataSource.load();
                    if (this.selectBoxRol !== null && this.selectBoxRol !== undefined) {
                        await this.selectBoxRol.instance().option("value", this.formData.Rol_Id);
                    }

                    const rol = this.rolDataSource.items().filter(x => x.Id === this.formData.Rol_Id)[0];
                    if (rol.Adi !== "Kurum" && rol.Adi !== "Ogrenci") {
                        await this.getKullaniciIlveIlceYetkileri(satir.Id).then(() => {
                            if (this.gridMerkezKullaniciYetki !== null) {
                                if (this.gridMerkezKullaniciYetki.instance !== undefined && this.gridMerkezKullaniciYetki.instance !== null) {
                                    this.gridMerkezKullaniciYetki.instance.selectRows(this.ililceYetkileriArrayList);
                                    this.gridMerkezKullaniciYetki.instance.refresh();
                                }
                            }
                        })
                    }
                    else {
                        await this.getMerkezKurumYetkileriByKullanici(satir.Id).then(() => {
                            if (this.gridKurum !== null) {
                                if (this.gridKurum.instance !== undefined && this.gridKurum.instance !== null) {
                                    this.gridKurum.instance.selectRows(this.kurumYetkileriArrayList);
                                    this.gridKurum.instance.refresh();
                                }
                            }
                        });
                    }
                    await this.getYayinYetkileriByKullanici(satir.Id).then(() => {
                        if (this.gridYayin !== null) {
                            if (this.gridYayin.instance !== undefined && this.gridYayin.instance !== null) {
                                this.gridYayin.instance.selectRows(this.yayinYetkileriArrayList);
                            }
                        }
                    })

                } catch (error) {
                    console.log(error);
                }
                await this.popupAc();

            }
        }
    }

    async sil(satir) {
        service.post(sinavApi + 'kullanici/KullaniciSil/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {

                    service.post(sinavApi + "kullanici/KullaniciSil/" + satir.Id,
                        null, {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    })
                        .then(res => {
                            if (res.data.Success) {
                                notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                this.gridMain.instance.refresh().then(() => {
                                    this.gridMainSatirIptal();
                                });
                            }
                            else {
                                notify(res.data.ErrorMessage, "error", 5000);
                            }
                        });

                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            });
    }

    async aktifYap(satir) {
        service.post(sinavApi + 'kullanici/AktifYap/' + satir.Id, null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh().then(() => {
                        this.gridMainSatirIptal();
                    });
                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    async pasifYap(satir) {
        service.post(sinavApi + 'kullanici/PasifYap/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh().then(() => {
                        this.gridMainSatirIptal();
                    });
                    notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    onUploaded(e) {
        if (e.value.length > 0) {
            this.formData.Resim = e.value[0].name;
            notify("Logonuz yüklendi", "success", 5000);
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "share",
                    type: "success",
                    hint: "Kullanıcı yayın yetkilendirme işlemleri",
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                this.setState({
                                    kullaniciYayinYetkilendirme: true
                                });
                            }
                        }
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "importselected",
                    type: "success",
                    onClick: async () => {
                        this.donemDataSource.filter(["Id", "<>", Globals.service.DonemId]);
                        await this.donemDataSource.load();
                        this.setState({
                            kullaniciAktarVisible: true
                        });
                    }
                }
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnGecisYap"
                    },
                    icon: "user",
                    type: "success",
                    hint: "seçtiğiniz kullanıcının ekranına geçiş yapar",
                    onClick: () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            try {
                                localStorage.removeItem("oturum");
                                localStorage.removeItem("session");
                                localStorage.removeItem("initialized");
                            } catch (error) {
                            }
                            const data = {
                                KullaniciAdi: this.satir.KullaniciAdi,
                                Sifre: this.satir.Sifre,
                                Donem: { Id: this.satir.Donem_Id }
                            }
                            service.post(authApi + "Auth/authenticate"
                                , data)
                                .then(res => {
                                    auth.login(() => {


                                        var localStoregeData = {};
                                        res.data.AdSoyad = res.data.Kullanici.Adi + " " + res.data.Kullanici.Soyadi;
                                        res.data.Menu = res.data.Kullanici.KullaniciMenuler;
                                        res.data.Rol = res.data.Kullanici.Rol;
                                        res.data.Id = res.data.Kullanici.Id;
                                        res.data.DonemId = res.data.Kullanici.Donem.Id;
                                        res.data.DonemAdi = res.data.Kullanici.DonemAdi;
                                        res.data.KurumId = res.data.Kullanici.KurumId;
                                        localStorage.setItem("oturum", JSON.stringify(res.data));
                                        // Globals.AdSoyad = localStoregeData.Kullanici.Adi + " " + localStoregeData.Kullanici.Soyadi;
                                        // Globals.menuler = localStoregeData.Kullanici.KullaniciMenuler;
                                        Globals.service = localStoregeData;
                                        // Globals.Token = localStoregeData.Token;
                                        // Globals.Rol = localStoregeData.Kullanici.Rol;
                                        if (Globals.service.Rol === "SubeKayitKabul") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeMuhasebe") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeKayitMuhasebe") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "Sube") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "GenelMerkez") {
                                            this.props.history.push("/kayitkabuldashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeVeli") {
                                            this.props.history.push("/velidashboard");
                                        }
                                        else if (Globals.service.Rol === "SubeOgrenci") {
                                            window.location.href = "/ogrencidashboard";
                                        }
                                        else {
                                            this.props.history.push("/home");
                                        }

                                    });
                                })
                                .catch((error) => {
                                    // Error 😨
                                    if (error.response) {
                                        notify(error.response.data.message, 'error', 5000);
                                    } else if (error.request) {
                                        notify(error.request, 'error', 5000);
                                    } else {
                                        notify("Teknik bir hat oluştu", 'error', 5000);
                                    }

                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add',
                    onClick: async () => {
                        if (Globals.service.Rol === "Doküman" | Globals.service.Rol === "Rapor") {
                            alert("Üzgünüz bu işlem için yetkiniz yok");
                        }
                        else {

                            await this.yeni();
                            await this.dataClear();
                            await this.popupAc();
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit',
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Kullanıcı Bilgilerini Kopyala', icon: 'copy',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let kopyalananData = window.ayarlar.mainUrl + "  ";
                            kopyalananData += "Kullanıcı adınız:" + satir.KullaniciAdi + "  ";
                            kopyalananData += "Şifreniz:" + satir.Sifre + "";
                            copyRichText(kopyalananData);
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.sil(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.pasifYap(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.aktifYap(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionGridKurumChanged() {
        if (this.gridKurum !== null) {
            if (this.gridKurum.instance !== undefined && this.gridKurum.instance !== null) {
                this.secilenKurumlar = this.gridKurum.instance.getSelectedRowsData();
            }
        }

    }

    onSelectionGridMerkeziKullaniciYetkiChanged() {
        if (this.gridMerkezKullaniciYetki !== null) {
            if (this.gridMerkezKullaniciYetki.instance !== null && this.gridMerkezKullaniciYetki.instance !== undefined) {
                this.secilenIlIlceler = this.gridMerkezKullaniciYetki.instance.getSelectedRowsData();
            }
        }
    }

    onInitializedGridMain(e) {
        this.gridMain = e.component;
    }

    onInitializedGridYayin(e) {
        this.gridYayin = e.component;

    }

    onInitializedGridYayinMain(e) {
        this.gridYayinMain = e.component;
    }

    onInitializedGridKurum(e) {
        this.gridKurum = e.component;
    }

    onInitializedGridMerkezKullaniciYetki(e) {
        this.gridMerkezKullaniciYetki = e.component;
    }

    onInitializedSelectBoxRol(e) {
        this.selectBoxRol = e.component;
    }

    onInitializedFormKullanici(e) {
        this.formKullanici = e.component;
    }

    onSelectionGridMainChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onSelectionGridYayinChanged() {
        if (this.gridYayin !== null) {
            if (this.gridYayin.instance !== null && this.gridYayin.instance !== undefined) {
                this.secilenYayinlar = this.gridYayin.instance.getSelectedRowsData();
            }
        }
    }

    onInitializedFormKullaniciAktar(e) {
        this.formKullaniciAktar = e.component;
    }

    async gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }

    async gridYayinSatirIptal() {
        if (this.gridYayin.instance !== null) {
            this.gridYayin.instance.clearSelection();
            this.gridYayin.instance.option("focusedRowIndex", -1);
        }
    }

    async gridMerkezKullaniciYetkiSatirIptal() {
        if (this.gridMerkezKullaniciYetki.instance !== null) {
            this.gridMerkezKullaniciYetki.instance.clearSelection();
            this.gridMerkezKullaniciYetki.instance.option("focusedRowIndex", -1);
        }
    }

    async gridKurumSatirIptal() {
        if (this.gridKurum.instance !== null) {
            this.gridKurum.instance.clearSelection();
            this.gridKurum.instance.option("focusedRowIndex", -1);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.kullaniciDataSource}
                                remoteOperations={false}
                                repaintChangesOnly={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'multiple' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={true}
                                showCheckBoxesMode={true}
                                keyExpr="Id"
                                paging={{
                                    enabled: true
                                }}
                                pager={{
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                    showInfo: true
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitialized={this.onInitializedGridMain}
                                onSelectionChanged={this.onSelectionGridMainChanged}
                                onSelectedRowKeysChange={(e) => {
                                    this.secilenKullanicilar = [];
                                    if (e.length > 0) {
                                        e.forEach((satir) => {
                                            this.secilenKullanicilar.push({
                                                KullaniciId: satir
                                            });
                                        });
                                    }
                                }}
                                ref={(ref) => this.gridMain = ref}

                            >

                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Id" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" visible={false} showInColumnChooser={true} />
                                <Column dataField={'DonemAdi'} caption="Dönem" dataType="string" />
                                <Column dataField={'Il_Adi'} caption="İl" dataType="string" />
                                <Column dataField={'KullaniciAdi'} caption="K. Adı" />
                                <Column dataField={'Email'} caption="Email" />
                                <Column dataField={'Adi'} caption="Adı" />
                                <Column dataField={'Soyadi'} caption="Soyadı" />
                                <Column dataField={'Sifre'} caption="Şifre" />
                                <Column dataField={'Rol'} caption="Rol" />
                                <Column dataField={'Gsm'} caption="Gsm" visible={false} />
                                <Column dataField={'Tel'} caption="Tel" visible={false} />
                                <Column dataField={'SmsBilgi'} caption="Sms Bilgi" dataType="boolean" visible={false} showInColumnChooser={true} />
                                <Column dataField={'MailBilgi'} caption="Mail Bilgi" dataType="boolean" visible={false} showInColumnChooser={true} />
                                <Column dataField={'Donem_Id'} caption="Dönem Id" dataType="number" visible={false} showInColumnChooser={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        const validateStatus = this.formKullanici.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            const kullaniciRolu = this.selectBoxRol.instance().option("text");
                                            if (kullaniciRolu === 'AltBayi' | kullaniciRolu === 'Bayi') {
                                                this.secilenKurumlar = [];
                                                this.MerkeziKurumYetki = [];

                                            }
                                            else if (kullaniciRolu === 'Kurum' || kullaniciRolu === 'Ogrenci') {
                                                this.secilenIlIlceler = [];
                                                this.MerkezKullaniciYetki = [];

                                            }
                                            else {
                                                this.secilenKurumlar = [];
                                                this.MerkeziKurumYetki = [];

                                            }

                                            this.KullaniciYayinYetki = [];

                                            this.secilenYayinlar.forEach((item, index) => {
                                                this.KullaniciYayinYetki.push(
                                                    {
                                                        Yayin: { Id: item.Id },
                                                        Kullanici: { Id: 0 }
                                                    }
                                                );
                                            });

                                            if (this.formData.Resim !== undefined) {
                                                this.formData.Logo = this.formData.Resim;
                                            }

                                            this.MerkezKullaniciYetki = [];
                                            this.secilenIlIlceler.forEach((item, index) => {
                                                this.MerkezKullaniciYetki.push({
                                                    Yayin: { Id: 0 },
                                                    Kullanici: { Id: 0 },
                                                    Il: { Id: item.Il_Id },
                                                    Ilce: { Id: item.Ilce_Id }
                                                });
                                            });

                                            this.MerkeziKurumYetki = [];
                                            this.secilenKurumlar.forEach((item, index) => {
                                                this.MerkeziKurumYetki.push({
                                                    Kurum: { Id: item.Id }
                                                });
                                            });

                                            this.sinavKullanici = {
                                                Kullanici: this.formData,
                                                KullaniciSinavDetay: this.KullaniciSinavDetay,
                                                KullaniciYayinYetki: this.KullaniciYayinYetki,
                                                MerkezKullaniciYetki: this.MerkezKullaniciYetki,
                                                MerkeziKurumYetki: this.MerkeziKurumYetki
                                            };

                                            service.post(sinavApi + 'kullanici/KullaniciSave',
                                                this.sinavKullanici,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                }
                                            )
                                                .then(res => {
                                                    if (res.data.Success) {

                                                        this.gridMain.instance.refresh().then(() => {
                                                            this.setState({
                                                                popup: false
                                                            });
                                                        });
                                                    }
                                                    else {
                                                        this.sinavKullanici = {
                                                            Kullanici: this.formData,
                                                            KullaniciSinavDetay: this.KullaniciSinavDetay,
                                                            KullaniciYayinYetki: this.KullaniciYayinYetki,
                                                            MerkezKullaniciYetki: this.MerkezKullaniciYetki,
                                                            MerkeziKurumYetki: this.MerkeziKurumYetki
                                                        };
                                                        notify(res.data.ErrorMessage, 'error', 5000);
                                                    }

                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData} onInitialized={this.onInitializedFormKullanici} ref={(ref) => this.formKullanici = ref}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Kullanıcı Bilgileri">
                                        <SimpleItem dataField={"Email"} isRequired={true}>
                                            <Label text="Email" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KullaniciAdi"} isRequired={true}>
                                            <Label text="K. Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Sifre"} isRequired={true} editorType="dxTextBox">
                                            <Label text="Şifre" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Adi"} isRequired={true}>
                                            <Label text="Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Soyadi"} isRequired={true}>
                                            <Label text="Soyadı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Tel"}>
                                            <Label text="Telefon" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Gsm"}>
                                            <Label text="Gsm" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SmsBilgi"} editorType="dxCheckBox">
                                            <Label text="Sms Bilgi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MailBilgi"} editorType="dxCheckBox">
                                            <Label text="Mail Bilgi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinifSeviyeId"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinifSeviyeleri,
                                                searchEnabled: false,
                                                visible: (window.ayarlar.isDijitalPlatForm ? true : false),
                                                displayExpr: "Name",
                                                valueExpr: "Value",
                                                onInitialized: (e) => {
                                                    this.selectSinifSeviye = e.component;
                                                },
                                                onValueChanged: (e) => {
                                                    this.formData.SinifSeviyeId = parseInt(e.value);
                                                },
                                                ref: (ref) => { this.selectSinifSeviye = ref }
                                            }}>
                                            <Label text="Sınıf Seviyesi" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-grup" colCount={3}>
                                        <SimpleItem dataField={"Rol_Id"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.rolDataSource,
                                                remoteOperations: true,
                                                searchEnabled: false,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxRol,
                                                ref: (ref) => { this.selectBoxRol = ref },
                                                onValueChanged: (e) => {
                                                    var text = e.component.option("text");
                                                    if (text === "Kurum" | text === "Ogrenci") {

                                                        this.kurumDataSource.filter(null);
                                                        if (this.gridKurum !== null) {
                                                            if (this.gridKurum.instance !== undefined) {
                                                                this.gridKurum.instance.refresh().then(() => {
                                                                    this.secilenKurumlar = [];
                                                                });
                                                            }
                                                        }

                                                        this.merkezKullaniciYetkiDataSource.filter(["Id", "=", 0]);
                                                        if (this.gridMerkezKullaniciYetki !== null) {
                                                            if (this.gridMerkezKullaniciYetki.instance !== undefined) {
                                                                this.gridMerkezKullaniciYetki.instance.refresh().then(() => {
                                                                    this.secilenIlIlceler = [];
                                                                });
                                                            }
                                                        }


                                                    }
                                                    else if (text === "Bayi" | text === "AltBayi") {
                                                        this.kurumDataSource.filter(["Id", "=", 0]);
                                                        if (this.gridKurum !== null) {
                                                            if (this.gridKurum.instance !== undefined) {
                                                                this.gridKurum.instance.refresh().then(() => {
                                                                    this.secilenKurumlar = [];
                                                                });
                                                            }
                                                        }

                                                        this.merkezKullaniciYetkiDataSource.filter(null);
                                                        if (this.gridMerkezKullaniciYetki !== null) {
                                                            if (this.gridMerkezKullaniciYetki.instance !== undefined) {
                                                                this.gridMerkezKullaniciYetki.instance.refresh().then(() => {
                                                                    this.secilenIlIlceler = [];
                                                                });
                                                            }
                                                        }
                                                    }
                                                }
                                            }}  >
                                            <Label text="Rol" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"File"}
                                            editorType="dxFileUploader"
                                            editorOptions={{
                                                uploadUrl: sinavApi + "kullanici/LogoUpload",
                                                uploadMode: 'instantly',
                                                uploadHeaders: this.headers,
                                                name: 'myFile',
                                                onValueChanged: this.onUploaded
                                            }}>
                                            <Label text="Logo seçiniz" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"VarsayilanLogo"} editorType="dxCheckBox">
                                            <Label text="Varsayılan Logo" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={2} caption="İl-İlçe-Yayın Yetkileri">
                                        <SimpleItem>
                                            <h4>İl-İlçe Yetkileri</h4>
                                            <TreeList
                                                dataSource={this.merkezKullaniciYetkiDataSource}
                                                showRowLines={true}
                                                showBorders={true}
                                                columnAutoWidth={true}
                                                keyExpr="Id"
                                                parentIdExpr="ParentId"
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                onInitialized={this.onInitializedGridMerkezKullaniciYetki}
                                                onSelectionChanged={this.onSelectionGridMerkeziKullaniciYetkiChanged}
                                                ref={(ref) => this.gridMerkezKullaniciYetki = ref}
                                            >
                                                <Paging enabled={true} />
                                                <Scrolling mode={"standart"} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={"[20,50, 80]"} showInfo={true} />
                                                <Selection recursive={true} mode="multiple" />
                                                <Column dataField="Il_Adi" caption="İl Adı" />
                                                <Column dataField="Ilce_Adi" caption="İlçe Adı" />
                                            </TreeList>

                                        </SimpleItem>
                                        <SimpleItem>
                                            <h4>Yayın Yetkileri</h4>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.yayinDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                onInitialized={this.onInitializedGridYayin}
                                                onSelectionChanged={this.onSelectionGridYayinChanged}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridYayin = ref}
                                            >

                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Selection mode="multiple"
                                                    selectAllMode="allPages"
                                                    showCheckBoxesMode="onClick" />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'Adi'} caption="Yayın" />
                                            </DataGrid>

                                        </SimpleItem>

                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1} caption="Kurum Yetkileri">
                                        <SimpleItem>
                                            <h4>Kurum Yetkileri</h4>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.kurumDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={false}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={false}
                                                onSelectionChanged={this.onSelectionGridKurumChanged}
                                                onInitialized={this.onInitializedGridKurum}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridKurum = ref}
                                            >

                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Selection mode="multiple"
                                                    selectAllMode="allPages"
                                                    showCheckBoxesMode="onClick" />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'ilAdi'} caption="İl" />
                                                <Column dataField={'ilceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" dataType="string" />
                                            </DataGrid>
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.kullaniciAktarVisible}
                    title={"Kullanıcı Aktarma İşlemi"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kullanıcı Aktar',
                                    type: 'success',
                                    hint: 'Kullanıcı Aktar',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    onClick: async (e) => {
                                        this.formKullaniciAktarData.Kullanicilar = this.secilenKullanicilar;
                                        this.setState({
                                            toolBarDisabled: true
                                        });
                                        service.post(sinavApi + "kullanici/secilenkullanicilariyenidonemeaktar", this.formKullaniciAktarData, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(async (res) => {
                                                if (res.data.Success) {
                                                    await this.setState({
                                                        toolBarDisabled: false
                                                    });
                                                    await this.popupKullaniciAktarGizle();
                                                    notify("Aktarım işleminiz gerçekleşti", "success", 5000);
                                                } else {
                                                    this.setState({
                                                        toolBarDisabled: false
                                                    });
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    toolBarDisabled: false
                                                });
                                                if (err.response) {
                                                    var resError = JSON.parse(err.response.request.response);
                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                    var dictionary = Object.keys(resError.errors);
                                                    dictionary.forEach((satir, index) => {
                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                    });
                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                    alert(mesaj);
                                                    // client received an error response (5xx, 4xx)
                                                } else if (err.request) {
                                                    // client never received a response, or request never left
                                                } else {
                                                    // anything else
                                                }
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupKullaniciAktarGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formKullaniciAktarData} onInitialized={this.onInitializedFormKullaniciAktar} ref={(ref) => this.formKullaniciAktar = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.donemDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi"
                                        }}>
                                            <Label text="Dönem Seçiniz" />
                                            <RequiredRule message="Dönem alanı zorunludur" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.kullaniciYayinYetkilendirme}
                    title={"Kullanıcı Yayın Yetkilendirme İşlemi"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kullanıcı Yayın Yetkilendir',
                                    type: 'success',
                                    hint: 'Kullanıcı Yayın Yetkilendir',
                                    icon: 'check',
                                    disabled: this.state.toolBarDisabled,
                                    onClick: async (e) => {
                                        let yayinRows = [];

                                        this.gridYayinMain.instance.getSelectedRowsData().forEach((row, index) => {
                                            yayinRows.push({
                                                Id: row.Id
                                            });
                                            //ogrenciInfoData += index + 1 + ". " + row.Adi + " " + row.Soyadi + "<br/>";
                                        });
                                        let data = {
                                            KullaniciId: this.satir.Id,
                                            Yayinlar: yayinRows
                                        };

                                        this.setState({
                                            toolBarDisabled: true
                                        });
                                        service.post(sinavApi + "kullanici/secilenkullanicilarayayinyetkisiver", data, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(async (res) => {
                                                if (res.data.Success) {
                                                    await this.setState({
                                                        toolBarDisabled: false
                                                    });
                                                    await this.popupYayinYetkilendirmeGizle();
                                                    notify("Kullanıcı yetkilendirme işleminiz gerçekleşti", "success", 5000);
                                                } else {
                                                    this.setState({
                                                        toolBarDisabled: false
                                                    });
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    toolBarDisabled: false
                                                });
                                                if (err.response) {
                                                    var resError = JSON.parse(err.response.request.response);
                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                    var dictionary = Object.keys(resError.errors);
                                                    dictionary.forEach((satir, index) => {
                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                    });
                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                    alert(mesaj);
                                                    // client received an error response (5xx, 4xx)
                                                } else if (err.request) {
                                                    // client never received a response, or request never left
                                                } else {
                                                    // anything else
                                                }
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupYayinYetkilendirmeGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    dataSource={this.yayinDataSource}
                                    remoteOperations={false}
                                    repaintChangesOnly={true}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={{ mode: 'multiple' }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    rowAlternationEnabled={true}
                                    showCheckBoxesMode={true}
                                    keyExpr="Id"
                                    paging={{
                                        enabled: true
                                    }}
                                    pager={{
                                        showPageSizeSelector: true,
                                        allowedPageSizes: [5, 10, 50, 75, 100],
                                        showInfo: true
                                    }}
                                    onInitialized={this.onInitializedGridYayinMain}
                                    ref={(ref) => this.gridYayinMain = ref}

                                >

                                    <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                    <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                    <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" visible={false} showInColumnChooser={true} />
                                    <Column dataField={'Adi'} caption="Yayın" dataType="string" />
                                </DataGrid>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

            </React.Fragment>

        );
    }
}
