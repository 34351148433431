import React from 'react';
import { otomasyonApi } from '../../Util';
import Globals from '../../Globals';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { ColumnFixing, Column, Paging, Pager, LoadPanel } from 'devextreme-react/data-grid';
class VeliDashboardTaksitTahsilatDetailView extends React.Component {
    constructor(props) {
        super(props);
        this.onInitialized = this.onInitialized.bind(this);
        this.yetkiGrid = null;
        this.yetkilerDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadParams: { odemePlaniId: props.data.key },
                    loadUrl: otomasyonApi + 'kayitkabul/listtaksitbyodemeplani',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }
    onInitialized(e) {
        this.yetkiGrid = e.component;
    }
    render() {
        return (
            <React.Fragment>
                <DataGrid
                    showBorders={true}
                    showRowLines={true}
                    dataSource={this.yetkilerDataSource}
                    onInitialized={this.onInitialized}
                    remoteOperations={true}
                    repaintChangesOnly={true}
                    columnHidingEnabled={true}
                    columnAutoWidth={true}
                    selection={{ mode: 'none' }}
                    autoNavigateToFocusedRow={true}
                    focusedRowEnabled={true}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                    keyExpr="Id"
                    paging={{
                        pageSize: 20
                    }}
                    pager={{
                        showPageSizeSelector: true,
                        allowedPageSizes: [5, 10, 50, 75, 100],
                        showInfo: true
                    }}
                    ref={(ref) => this.yetkiGrid = ref}
                >
                    <ColumnFixing enabled={true} />
                    <LoadPanel enabled={true} />
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                    <Column dataField={'TaksitNo'} caption="Taksit No" dataType={"number"} />
                    <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                    <Column dataField={'TahsilatTarihi'} caption="Tahsilat Tarihi" dataType={"date"}
                        calculateCellValue={(rowData) => {
                            if (rowData.TahsilatTarihi === "0001-01-01T00:00:00") {
                                return null;
                            }
                            else
                                return new Date(rowData.TahsilatTarihi);
                        }}
                    />
                    <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} format={"#,##0.##"} />
                    <Column dataField={'Tahsilat'} caption="Tahsilat" dataType={"number"} format={"#,##0.##"} />
                    <Column dataField={'Durum'} caption="Durum" dataType={"string"} />
                    <Column dataField={'OdemeTuru'} caption="Ödeme Türü" dataType={"string"} />
                    <Column dataField={'Aciklama'} caption="Açıklama" hidingPriority={0} dataType={"string"} />
                </DataGrid>
            </React.Fragment>
        );
    }
}
export default VeliDashboardTaksitTahsilatDetailView;