import * as React from "react";
import { sinavApi, otomasyonApi } from '../../Util';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import Globals from '../../Globals';
import service from 'axios';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';




export default class ExcelKullaniciAktar extends React.Component {
    constructor(props) {
        super(props)

        this.formData = {
            KurumId: 0,
            DosyaAdi: "",
            IsVeliKullaniciAc: false
        }
        this.onUploaded = this.onUploaded.bind(this);
        this.headers = {
            'Authorization': 'Bearer ' + Globals.service.Token
        };
        this.saveButtonOptions = {
            text: 'Aktarma İşlemini Başlat',
            icon: 'check',
            hint: 'Aktarma İşlemini Başlat',
            type: "success",
            onClick: () => {
                service.post(otomasyonApi + 'KayitKabul/ExcelKullaniciAktar', this.formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        alert(res.data.Data, "Bilgi");
                    }
                    else
                        notify(res.data.ErrorMessage, "error", 5000);
                })
                    .catch((error) => {
                        // Error 😨
                        notify("Teknik bir hata oluştu.formu göndermeden tüm alanları doldurduğunuza emin olunuz," + error, 'error', 5000);
                    });
            }
        };
        this.kurumDataSource = new DataSource(
            {
                loadMode: "raw",
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Şablon dosyası yüklenemedi," + res.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            this.formData.DosyaAdi = res.Data;
            notify("Şablon dosyası yüklendi", "success", 5000);
            e.component.reset();
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Excel Kullanıcı Aktar"}</h4>
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "10px" }}>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <a href="/sablonlar/KepsOgrenciYukleSablon.xlsx" target="_blank">Örnek şablonu bilgisayarınıza indirmek için tıklayınız.</a><br></br>
                            İndirdiğiniz şablon dosyasında "Sınıf Seviyesi ve Bölüm Adları" sayfasında gerekli açıklamaları lütfen okuyunuz
                        </div>
                    </div>
                    <div className="row" style={{ minHeight: "10px" }}>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <Form formData={this.formData}>
                                <GroupItem cssClass="first-group" colCount={1}>
                                    <SimpleItem dataField={"IsVeliKullaniciAc"}
                                        editorType="dxCheckBox">
                                        <Label text="Velilere Kullanıcı Açma" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"KurumId"}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: this.kurumDataSource,
                                            remoteOperations: true,
                                            searchEnabled: true,
                                            displayExpr: "KurumAdi",
                                            showClearButton: true,
                                            valueExpr: "Id"
                                        }}>
                                        <Label text="Kurum Seçiniz" />
                                    </SimpleItem>

                                </GroupItem>
                                <GroupItem cssClass="first-group" colCount={1} caption="Öğrenci-Veli aktarım şablonunu seçiniz(İşlem bitene kadar bekleyiniz.)">
                                    <SimpleItem dataField={"File"}
                                        editorType="dxFileUploader"
                                        editorOptions={{
                                            uploadUrl: otomasyonApi + "kayitkabul/excelkullaniciaktarsablonupload",
                                            uploadMode: 'instantly',
                                            uploadHeaders: this.headers,
                                            accept: '*',
                                            maxFileSize: 4000000,
                                            name: 'myFile',
                                            onUploaded: this.onUploaded
                                        }}>
                                        <Label text="Şablon seçiniz" />
                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                            <Toolbar>
                                <Item location="after"
                                    widget="dxButton"
                                    options={this.saveButtonOptions} />
                            </Toolbar>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}