import React from "react";
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
export default class OgrenciHataRapor extends React.Component {
    render() {
        return (
            <React.Fragment>
                <DataGrid
                    showBorders={true}
                    showRowLines={true}
                    dataSource={this.props.Data}
                    columnHidingEnabled={true}
                    filterPanel={{ visible: true }}
                    filterRow={{ visible: true }}
                    columnAutoWidth={true}
                    selection={{ mode: 'single' }}
                    autoNavigateToFocusedRow={true}
                    focusedRowEnabled={true}
                    hoverStateEnabled={true}
                    keyExpr="KonuAdi"
                    onToolbarPreparing={this.onToolbarPreparing}
                    onInitialized={this.onInitialized}
                    onSelectionChanged={this.onSelectionChanged}
                    ref={(ref) => this.gridMain = ref}
                >
                    <Export enabled={true} fileName={'HatalarimRaporu'} allowExportSelectedData={false} />
                    <ColumnChooser enabled={true} />
                    <Editing mode={'popup'} />
                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                    <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                    <ColumnFixing enabled={true} />
                    <LoadPanel enabled={true} />
                    <FilterRow visible={false} applyFilter={'auto'} />
                    <Paging defaultPageSize={15} enabled={true} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                    <Column dataField={'DersAdi'} caption="DersAdi" />
                    <Column dataField={'KonuAdi'} caption="Konu" visible={true} />
                    <Column dataField={'soruSayisi'} caption="Soru S." dataType={"number"} />
                    <Column dataField={'cozumSayisi'} caption="Çözüm S." dataType={"number"} />
                    <Column dataField={'dogruSayisi'} caption="Doğru S." dataType={"number"} />
                    <Column dataField={'yanlisSayisi'} caption="Yanlış S." dataType={"number"} />
                    <Column dataField={'bosSayisi'} caption="Boş S." dataType={"number"} />
                    <Column dataField={'dogruYuzde'} caption="Doğru Yüzde" dataType={"number"} format={"#,##0.##"} />
                    <Column dataField={'yanlisYuzde'} caption="Yanlış Yüzde" dataType={"number"} format={"#,##0.##"} />
                    <Column dataField={'cozumYuzde'} caption="Çözüm Yüzde" dataType={"number"} format={"#,##0.##"} />
                </DataGrid>

            </React.Fragment>
        );

    }
}