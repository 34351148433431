import React from 'react';
import { sinavApi, sinavRealApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
    , KeyboardNavigation
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { LoadPanel as MyLoad } from 'devextreme-react/load-panel';
const position = { of: '#cevapanahtariformpanel' };

export default class CevapAnahtariTanimi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cevapAnahtarlari: [],
            yayinlar: [],
            sinavTurleri: [],
            merkeziSinavlar: [],
            secilenYayin: null,
            secilenOptikForm: null,
            secilenSinavturu: null,
            secilenMerkeziSinav: null,
            secilenKitapcikTuru: null,
            secilenKitapcikEslestirme: false,
            secilenAktarilacakKitapcikTuru: null,
            loadPanelVisible: false,
            GirilenCevapAnahtari: [],
            popup: false,
            selectedItemKeys: [],
            kitapcikBirlestirDisabled: false,
            loadingText: "",
            soruEslestir: false,
            soruEslestirDisabled: false
        };

        this.popupForm = null;
        this.mainDataGrid = null;
        this.satir = null;
        this.cevapAnahtariDataGrid = null;
        this.selectBoxYayin = null;
        this.selectBoxMerkeziSinav = null;
        this.selectBoxSinavTuru = null;
        this.yeniEkleClick = this.yeniEkleClick.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.KitapcikSil = this.KitapcikSil.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxMerkeziSinav = this.onInitializedSelectBoxMerkeziSinav.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.popupGizle = () => {
            this.setState(
                {
                    popup: false,
                    soruEslestir: false,
                    soruEslestirDisabled: false
                });
        };

        this.kitapcikTurleri = [
            { Deger: "A" },
            { Deger: "B" },
            { Deger: "C" },
            { Deger: "D" },
            { Deger: "AA" },
            { Deger: "AB" },
            { Deger: "BA" },
            { Deger: "BB" },

        ];

        this.aktarilacakKitapcikTurleri = [
            { Display: "A" },
            { Display: "B" },
            { Display: "C" },
            { Display: "D" },
            { Display: "A,B" },
            { Display: "C,D" },
            { Display: "AA" },
            { Display: "AB" },
            { Display: "BA" },
            { Display: "BB" },
        ];

        this.formData = {
            Yayin: { Id: null },
            Sinav: { Id: null },
            SinavTuru: { Id: null },
            KitapcikTuru: null,
            KitapcikEslestirme: false,
            AktarilacakKitapcikTuru: null
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.mainDataSource = new DataSource(
            {
                loadMode: "raw",
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Sinav_Id",
                    loadUrl: sinavApi + 'cevapanahtari/cevapanahtarlari',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruFilter = {
            YayinId: 0,
            SinavTuruId: 0
        };

        this.merkeziSinavDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'merkezisinav/sgemerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Cevap anahtarınız yüklenemedi," + res.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Cevap anahtarınız yüklendi ve kaydetme işlemi başarı ile gerçekleştirildi.", "success", 5000);
            alert("Cevap anahtarınız yüklendi ve kaydetme işlemi başarı ile gerçekleştirildi.<font style='color:red'><br/><b>Tekrar kaydet butonunu kullanmanıza gerek yoktur.</b></font>", "Bilgi");
            this.refreshData();
            e.component.reset();
        }
    }

    refreshData() {
        this.mainDataGrid.instance.refresh();
    }

    yeniEkleClick() {
        service.get(sinavApi + "yayin/getYayinlarByYetki",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(resYayin => {
                if (resYayin.data.Success) {
                    this.setState({
                        yayinlar: resYayin.data.Data
                    });
                    this.formData = {
                        Id: 0,
                        Yayin_Id: 0,
                        SinavTuru_Id: 0,
                        OptikForm_Id: 0
                    };
                    this.setState({
                        popup: true,
                        secilenSinavTuru: null,
                        secilenYayin: null,
                        secilenOptikForm: null,
                        secilenMerkeziSinav: null,
                        secilenKitapcikTuru: null,
                        GirilenCevapAnahtari: []
                    });
                }
            });

    }

    async duzenleClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.yayinfilter.YayinId = satir.Yayin_Id;
            this.sinavTuruFilter.Yayin_Id = satir.Yayin_Id;
            this.sinavTuruFilter.SinavTuruId = satir.SinavTuru_Id;
            this.yayinDataSource.load();
            await this.yayinDataSource.load();
            await this.sinavTuruDataSource.load();
            await this.merkeziSinavDataSource.load();
            await this.selectBoxYayin.instance().option("value", satir.Yayin_Id);
            await this.selectBoxSinavTuru.instance().option("value", satir.SinavTuru_Id);
            await this.selectBoxMerkeziSinav.instance().option("value", satir.Sinav_Id);

            await this.setState({
                secilenMerkeziSinav: satir.Sinav_Id,
                secilenYayin: satir.Yayin_Id,
                secilenOptikForm: satir.OptikForm_Id,
                secilenSinavTuru: satir.SinavTuru_Id,
                secilenKitapcikTuru: null,
                GirilenCevapAnahtari: [],
                kitapcikBirlestirDisabled: satir.SinavTuru_Id === 7 || satir.SinavTuru_Id === 6 ? false : true,
                popup: true,
                soruEslestir: this.selectBoxSinavTuru.instance().option("text") === 'TYT' | this.selectBoxSinavTuru.instance().option("text") === 'AYT' ? true : false
            });
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    onClick: this.yeniEkleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after',
                widget: 'dxDropDownButton',
                options: {
                    displayExpr: 'name',
                    keyExpr: 'id',
                    selectedItemKey: 1,
                    stylingMode: 'text',
                    useSelectMode: false,
                    splitButton: true,
                    text: "Bütün Kitapçıkları Sil",
                    icon: "remove",
                    items: [
                        { 'name': 'A Kitapçığını Sil', 'id': 1, "icon": "remove" },
                        { 'name': 'B Kitapçığını Sil', 'id': 2, "icon": "remove" },
                        { 'name': 'C Kitapçığını Sil', 'id': 3, "icon": "remove" },
                        { 'name': 'D Kitapçığını Sil', 'id': 4, "icon": "remove" },
                        { 'name': 'AA Kitapçığını Sil', 'id': 5, "icon": "remove" },
                        { 'name': 'AB Kitapçığını Sil', 'id': 6, "icon": "remove" },
                        { 'name': 'BA Kitapçığını Sil', 'id': 7, "icon": "remove" },
                        { 'name': 'BB Kitapçığını Sil', 'id': 8, "icon": "remove" },
                    ],
                    onButtonClick: (e) => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(sinavApi + "cevapanahtari/cevapanahtarisilbymerkezisinavid/" + satir.Sinav_Id,
                                        null,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                this.refreshData();
                                            }
                                            else
                                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                        })
                                }
                            });
                        }
                    },
                    onItemClick: (e) => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        switch (e.itemData.id) {
                            case 1:
                                this.KitapcikSil(satir.Sinav_Id, "A");
                                break;
                            case 2:
                                this.KitapcikSil(satir.Sinav_Id, "B");
                                break;
                            case 3:
                                this.KitapcikSil(satir.Sinav_Id, "C");
                                break;
                            case 4:
                                this.KitapcikSil(satir.Sinav_Id, "D");
                                break;
                            case 5:
                                this.KitapcikSil(satir.Sinav_Id, "AA");
                                break;
                            case 6:
                                this.KitapcikSil(satir.Sinav_Id, "AB");
                                break;
                            case 7:
                                this.KitapcikSil(satir.Sinav_Id, "BA");
                                break;
                            case 8:
                                this.KitapcikSil(satir.Sinav_Id, "BB");
                                break;
                            default:
                                break;
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.refreshData();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    KitapcikSil(MerkeziSinavId, KitapcikTuru) {

        let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
        result.then((dialogResult) => {
            if (dialogResult === true) {
                service.post(sinavApi + "cevapanahtari/cevapanahtarisilbykitapcikturu/" + MerkeziSinavId + "/" + KitapcikTuru,
                    null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    })
                    .then(res => {
                        if (res.data.Success) {
                            this.refreshData();
                        }
                        else
                            notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                    });
            }
        });
    }

    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }

    onInitializedSelectBoxMerkeziSinav(e) {
        this.selectBoxMerkeziSinav = e.component;
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div id="cevapanahtariformpanel">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.loadingText}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    dataSource={this.mainDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={{ mode: 'single' }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Sinav_Id"
                                    onToolbarPreparing={this.onToolbarPreparing}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={(ref) => this.mainDataGrid = ref}
                                >
                                    <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <Editing mode={'popup'} />
                                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                    <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                    <ColumnFixing enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Sinav_Id'} caption="Kayıt No" visible={false} />
                                    <Column dataField={'OptikForm_Id'} caption="Optik Form Id" visible={false} />
                                    <Column dataField={'Yayin_Id'} caption="Yayın Id" visible={false} />
                                    <Column dataField={'Yayin_Adi'} caption="Yayın" />
                                    <Column dataField={'SinavTuruAdi'} caption="Sınav Türü" />
                                    <Column dataField={'OptikForm_Adi'} caption="Optik Form" />
                                    <Column dataField={'Sinav_Adi'} caption="Sınav" />
                                </DataGrid>

                            </div>
                        </div>
                        <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                            close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                            onHiding={this.popupGizle} visible={this.state.popup}
                            toolbarItems={
                                [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Soru Eşleştir',
                                            type: 'success',
                                            hint: 'Soru Eşleştir',
                                            icon: 'refresh',
                                            disabled: this.state.soruEslestirDisabled,
                                            visible: this.state.soruEslestir,
                                            onClick: () => {
                                                this.setState({
                                                    soruEslestirDisabled: true
                                                });
                                                const data = {
                                                    SinavTuru: this.selectBoxSinavTuru.instance().option("text"),
                                                    MerkeziSinavId: this.state.secilenMerkeziSinav
                                                };
                                                service.post(sinavApi + 'cevapanahtari/sorueslestir',
                                                    data,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    }
                                                ).then(res => {
                                                    this.setState({
                                                        soruEslestirDisabled: false
                                                    });
                                                    if (res.data.Success) {
                                                        this.setState({
                                                            popup: false
                                                        })
                                                        this.refreshData();
                                                    }
                                                    else {
                                                        notify(res.data.ErrorMessage, 'error', 5000);
                                                    }

                                                })
                                                    .catch(error => {
                                                        this.setState({
                                                            soruEslestirDisabled: true
                                                        });
                                                        notify(error, 'error', 5000);
                                                    });

                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Çift Oturum Cevap Anahtarı Oluştur',
                                            type: 'success',
                                            hint: 'Çift Oturum Cevap Anahtarı Oluştur',
                                            icon: 'check',
                                            disabled: this.state.kitapcikBirlestirDisabled
                                            , onClick: (e) => {
                                                if (this.state.secilenMerkeziSinav !== null) {
                                                    let result = confirm("<i>Bu işlem LGS sınavlarında kitapçık türlerini çoğaltacaktır, onaylıyor musunuz?</i>", "Onay İşlemi");
                                                    result.then((dialogResult) => {
                                                        if (dialogResult === true) {
                                                            service.post(sinavApi + 'cevapanahtari/cevapanahtariLgsBirlestir/' + this.state.secilenMerkeziSinav,
                                                                null,
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                }
                                                            ).then(res => {
                                                                if (res.data.Success) {
                                                                    this.setState({
                                                                        popup: false
                                                                    })
                                                                    this.refreshData();
                                                                }
                                                                else {
                                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                                }

                                                            });
                                                        }
                                                    });
                                                }
                                                else {
                                                    alert("Lütfen LGS sınavı seçiniz.", "Dikkat")
                                                }
                                            }

                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                            , onClick: (e) => {

                                                service.post(sinavApi + 'cevapanahtari/cevapanahtarisave/' + this.state.secilenMerkeziSinav + "/" + this.state.secilenYayin,
                                                    this.state.GirilenCevapAnahtari,
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    }
                                                ).then(res => {
                                                    if (res.data.Success) {
                                                        this.setState({
                                                            popup: false
                                                        })
                                                        this.refreshData();
                                                    }
                                                    else {
                                                        notify(res.data.ErrorMessage, 'error', 5000);
                                                    }

                                                }).catch(err => {
                                                    if (err.response) {
                                                        var resError = JSON.parse(err.response.request.response);
                                                        var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                        var dictionary = Object.keys(resError.errors);
                                                        dictionary.forEach((satir, index) => {
                                                            mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                        });
                                                        mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                        mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                        mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                        alert(mesaj);
                                                        // client received an error response (5xx, 4xx)
                                                    } else if (err.request) {
                                                        // client never received a response, or request never left
                                                    } else {
                                                        // anything else
                                                    }
                                                });
                                            }
                                        }

                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'runner'
                                            , onClick: () => { this.popupGizle(); }
                                        }
                                    }

                                ]
                            }
                        >
                            <Position my={'center'} at={'center'} of={window} />
                            <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form formData={this.formData}>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Bilgi">
                                                <SimpleItem dataField={"Yayin.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        dataSource: this.yayinDataSource,
                                                        searchEnabled: true,
                                                        displayExpr: "Adi",
                                                        valueExpr: "Id",
                                                        onInitialized: this.onInitializedSelectBoxYayin,
                                                        ref: (ref) => { this.selectBoxYayin = ref },
                                                        onValueChanged: (e) => {
                                                            this.setState({
                                                                secilenYayin: e.value
                                                            });
                                                            if (this.state.secilenYayin !== null) {
                                                                this.selectBoxSinavTuru.instance().option("value", null);
                                                                this.selectBoxMerkeziSinav.instance().option("value", null);
                                                                this.sinavTuruFilter.YayinId = e.value;
                                                                this.yayinfilter.YayinId = e.value;
                                                                this.sinavTuruDataSource.load();
                                                            }
                                                        }
                                                    }}>
                                                    <Label text="Yayın" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"SinavTuru.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        dataSource: this.sinavTuruDataSource,
                                                        searchEnabled: true,
                                                        displayExpr: "SinavTuruAdi",
                                                        valueExpr: "SinavTuru.Id",
                                                        onInitialized: this.onInitializedSelectBoxSinavTuru,
                                                        ref: (ref) => { this.selectBoxSinavTuru = ref },
                                                        onValueChanged: (e) => {
                                                            if (e.value === "" || e.value === null) {
                                                                return;
                                                            }

                                                            this.selectBoxMerkeziSinav.instance().option("value", null);
                                                            const sinavTuruText = this.selectBoxSinavTuru.instance().option("text");

                                                            this.setState({
                                                                secilenSinavTuru: e.value,
                                                                kitapcikBirlestirDisabled: sinavTuruText === "8.SINIF" ? false : true
                                                            });

                                                            if (
                                                                this.state.secilenSinavTuru !== null &&
                                                                this.state.secilenSinavturu !== undefined &&
                                                                this.state.secilenYayin !== null &&
                                                                this.state.secilenYayin !== undefined
                                                            ) {
                                                                this.sinavTuruFilter.SinavTuruId = e.value;
                                                                this.merkeziSinavDataSource.load();
                                                            }

                                                        }
                                                    }}>
                                                    <Label text="Sınav Türü" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"Sinav.Id"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        dataSource: this.merkeziSinavDataSource,
                                                        searchEnabled: true,
                                                        displayExpr: "Adi",
                                                        valueExpr: "Id",
                                                        onInitialized: this.onInitializedSelectBoxMerkeziSinav,
                                                        ref: (ref) => { this.selectBoxMerkeziSinav = ref },
                                                        onValueChanged: (e) => {
                                                            if (e.value === "" || e.value === null) {
                                                                return;
                                                            }

                                                            this.setState({ secilenMerkeziSinav: e.value });

                                                        }
                                                    }}>
                                                    <Label text="Sınav" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"KitapcikTuru"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        items: this.kitapcikTurleri,
                                                        searchEnabled: false,
                                                        value: this.state.secilenKitapcikTuru,
                                                        displayExpr: "Deger",
                                                        valueExpr: "Deger",
                                                        onValueChanged: (e) => {
                                                            if (e.value === "" || e.value === null) {
                                                                return;
                                                            }

                                                            this.setState({ secilenKitapcikTuru: e.value });

                                                            if (this.state.secilenKitapcikTuru !== null) {
                                                                const url = sinavApi + "cevapanahtari/getCevapAnahtari/" + this.state.secilenKitapcikTuru + "/" + this.state.secilenMerkeziSinav;

                                                                service.get(url, {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                })
                                                                    .then(res => {
                                                                        if (res.data.Success) {
                                                                            this.setState({ GirilenCevapAnahtari: res.data.Data });
                                                                        } else {
                                                                            notify(res.data.ErrorMessage, "error", 5000);
                                                                        }
                                                                    })
                                                            }

                                                        }
                                                    }}>
                                                    <Label text="Kitapçık Türü" />
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Cevap Anahtarı Yükleme İşlemleri">
                                                <SimpleItem dataField={"KitapcikEslestirme"} editorType="dxCheckBox" editorOptions={{
                                                    onValueChanged: (e) => {
                                                        this.setState({
                                                            secilenKitapcikEslestirme: e.value
                                                        })
                                                    }
                                                }}>
                                                    <Label text="Kitapçık Eşleştirme" />
                                                </SimpleItem>
                                                <SimpleItem dataField={"File"}
                                                    editorType="dxFileUploader"
                                                    editorOptions={{
                                                        uploadUrl: sinavApi + "cevapanahtari/CevapAnahtariUpload?MerkeziSinavId=" + this.state.secilenMerkeziSinav + "&KitapcikEslestirme=" + this.state.secilenKitapcikEslestirme,
                                                        uploadMode: 'instantly',
                                                        uploadHeaders: this.headers,
                                                        accept: '*',
                                                        maxFileSize: 4000000,
                                                        name: 'myFile',
                                                        onUploaded: this.onUploaded
                                                    }}>
                                                    <Label text="Excel dosyasını seçiniz" />
                                                </SimpleItem>

                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={2} caption="Cevap Anahtarı Aktarma İşlemleri">
                                                <SimpleItem dataField={"AktarilacakKitapcikTuru"}
                                                    editorType="dxSelectBox"
                                                    editorOptions={{
                                                        items: this.aktarilacakKitapcikTurleri,
                                                        searchEnabled: false,
                                                        value: this.state.secilenAktarilacakKitapcikTuru,
                                                        displayExpr: "Display",
                                                        valueExpr: "Display",
                                                        onValueChanged: (e) => {
                                                            this.setState({
                                                                secilenAktarilacakKitapcikTuru: e.value
                                                            })
                                                        }
                                                    }}>
                                                    <Label text="Kitapçık Türü" />
                                                </SimpleItem>
                                                <SimpleItem editorType="dxButton"
                                                    editorOptions={{
                                                        text: 'Cevap Anahtarını Aktar',
                                                        type: 'success',
                                                        hint: 'Cevap Anahtarını Aktar',
                                                        icon: 'exportxlsx',
                                                        onClick: () => {
                                                            const kitapcikTurleri = [];

                                                            const getKitapcikTurleri = (kitapcikTuru) => {
                                                                if (kitapcikTuru === "A,B") {
                                                                    return [{ KitapcikTuru: "A" }, { KitapcikTuru: "B" }];
                                                                } else if (kitapcikTuru === "C,D") {
                                                                    return [{ KitapcikTuru: "C" }, { KitapcikTuru: "D" }];
                                                                } else {
                                                                    return [{ KitapcikTuru: kitapcikTuru }];
                                                                }
                                                            };

                                                            kitapcikTurleri.push(...getKitapcikTurleri(this.state.secilenAktarilacakKitapcikTuru));

                                                            service.post(
                                                                sinavApi + 'cevapanahtari/getCevapAnahtariAktar/' + this.state.secilenMerkeziSinav,
                                                                kitapcikTurleri,
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                }
                                                            )
                                                                .then(res => {
                                                                    if (res.data.Success) {
                                                                        window.location.href = sinavRealApi + res.data.Data;
                                                                    } else {
                                                                        notify("", 'error', 5000);
                                                                    }
                                                                });

                                                        }
                                                    }}>

                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem cssClass="first-group" colCount={1} caption="Cevap Anahtarı">
                                                <SimpleItem>
                                                    <DataGrid
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        dataSource={this.state.GirilenCevapAnahtari}
                                                        columnHidingEnabled={true}
                                                        keyExpr="SiraNo"
                                                        width="100%"
                                                        onEditorPreparing={(e) => {
                                                            if (e.parentType === "dataRow" && e.dataField === "DC1") {
                                                                // Mevcut işlemler ...

                                                                e.editorOptions.onKeyUp = (event) => {
                                                                    if (event.event.key === 'A' || event.event.key === 'B' || event.event.key === 'C' || event.event.key === 'D' || event.event.key === 'E' || event.event.key === 'X' || event.event.key === 'I') {
                                                                        e.setValue(event.event.key);
                                                                        setTimeout(() => {
                                                                            const newRowIdx = e.component.getRowIndexByKey(e.row.key) + 1;
                                                                            const columnIndex = e.component.getVisibleColumnIndex('DC1');
                                                                            if (newRowIdx < e.component.totalCount()) {
                                                                                e.component.closeEditCell();
                                                                                e.component.editCell(newRowIdx, columnIndex);
                                                                                e.component.cellValue(newRowIdx, columnIndex, "");
                                                                            }
                                                                        }, 50);
                                                                    }
                                                                };
                                                            }
                                                        }}



                                                    // onEditorPreparing={(e) => {
                                                    //     if (e.parentType === "dataRow" && e.dataField === "DC1") {
                                                    //         e.editorOptions.onKeyPress = (event) => {
                                                    //             if (event.key === 'A' || event.key === 'B' || event.key === 'C' || event.key === 'D' || event.key === 'E' || event.key === 'X' || event.key === 'I') {
                                                    //                 setTimeout(() => {
                                                    //                     const newRowIdx = e.component.getRowIndexByKey(e.row.key) + 1;
                                                    //                     if (newRowIdx < e.component.totalCount()) {
                                                    //                         e.component.closeEditCell();
                                                    //                         e.component.editCell(newRowIdx, e.columnIndex);
                                                    //                         e.component.cellValue(newRowIdx, e.columnIndex, "");
                                                    //                     }
                                                    //                 }, 50);
                                                    //             }
                                                    //         };
                                                    //         // e.editorOptions.onValueChanged = args => {
                                                    //         //     if (args.value !== "A" | args.value !== "B" | args.value !== "C" | args.value !== "D" | args.value !== "E" | args.value !== "I" | args.value !== "X") {
                                                    //         //         e.component.instance().option("value", "X");
                                                    //         //         e.setValue("X");
                                                    //         //     }
                                                    //         //     if (args.value === "I") {
                                                    //         //         e.component.instance().option("value", "I");
                                                    //         //         e.setValue("I");
                                                    //         //     }
                                                    //         //     if (args.value === "X") {
                                                    //         //         e.component.instance().option("value", "X");
                                                    //         //         e.setValue("X");
                                                    //         //     }
                                                    //         //     if (args.value === "A") {
                                                    //         //         e.component.instance().option("value", "A");
                                                    //         //         e.setValue("A");
                                                    //         //     }
                                                    //         //     if (args.value === "B") {
                                                    //         //         e.component.instance().option("value", "B");
                                                    //         //         e.setValue("B");
                                                    //         //     }
                                                    //         //     if (args.value === "C") {
                                                    //         //         e.component.instance().option("value", "C");
                                                    //         //         e.setValue("C");
                                                    //         //     }
                                                    //         //     if (args.value === "D") {
                                                    //         //         e.component.instance().option("value", "D");
                                                    //         //         e.setValue("D");
                                                    //         //     }
                                                    //         //     if (args.value === "E") {
                                                    //         //         e.component.instance().option("value", "E");
                                                    //         //         e.setValue("E");
                                                    //         //     }
                                                    //         // };
                                                    //     }
                                                    // }}
                                                    >
                                                        <Editing mode={'cell'} useIcons={false} allowUpdating={true} allowDeleting={false} />
                                                        <KeyboardNavigation
                                                            editOnKeyPress={true}
                                                            enterKeyAction="startEdit"
                                                            enterKeyDirection="column" />
                                                        <LoadPanel enabled={true} />
                                                        <ColumnFixing enabled={true} />
                                                        <Paging pageSize={350}></Paging>
                                                        <Column dataField={'Id'} caption="Id" visible={false} />
                                                        <Column dataField={'DersAdi'} allowEditing={false} caption="Ders" width="150" />
                                                        <Column dataField={'DersSimge'} allowEditing={false} caption="Simge" visible={false} />
                                                        <Column dataField={'KitapcikTuru'} allowEditing={false} width="100" caption="Kitapçık" />
                                                        <Column dataField={'SoruNo'} allowEditing={false} width="100" caption="Soru No" dataType="number" />
                                                        <Column dataField={'DC1'} caption="DC1" width="50" allowEditing={true} dataType="string" />
                                                        <Column dataField={'DC2'} caption="DC2" width="50" dataType="string" />
                                                        <Column dataField={'DC3'} caption="DC3" width="50" dataType="string" />
                                                        <Column dataField={'EslestirilenSoruNo'} width="200" caption="Eşleştirilen S. N." dataType="number" />
                                                        <Column dataField={'KonuAdi'} caption="Konu" width="150" dataType="string" />
                                                    </DataGrid>
                                                </SimpleItem>
                                            </GroupItem>
                                        </Form>
                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <MyLoad
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}