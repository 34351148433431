import * as React from "react";
import Accordion from 'devextreme-react/accordion';
import DuyuruTitle from '../components/shared/DuyuruTitle';
import DuyuruItem from '../components/shared/DuyuruItem';
import DokumanTitle from '../components/shared/DokumanTitle';
import DokumanItem from '../components/shared/DokumanItem';
import Globals from '../Globals';
import notify from 'devextreme/ui/notify';
import { sinavApi, sinavRealApi } from '../Util';
import service from 'axios';
import Tabs from 'devextreme-react/tabs';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import FileManager, { Permissions } from 'devextreme-react/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { custom } from 'devextreme/ui/dialog';
const allowedFileExtensions = [".fmt", ".xls", ".xlsx", ".pdf", ".doc", ".docx", ".rar", ".zip", ".bldm"];

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.tumCevapAnahtarlari = [];
        this.state = {
            duyurular: [],
            dokumanlar: [],
            cevapAnahtarlari: [],
            tabs: ['Duyurular', 'Fmt Dosyaları', 'Cevap Anahtarları'],
            tabSelectedIndex: 0,
        }
        this.secilenDosya = "";
        this.selectBoxYayin = null;
        this.selectBoxSinavTuru = null;
        this.renderDetay = this.renderDetay.bind(this);
        this.renderTabDetay = this.renderTabDetay.bind(this);
        this.tabsSelectionChanged = this.tabsSelectionChanged.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.remoteProvider = new RemoteFileSystemProvider({
            beforeAjaxSend: function ({ headers, formData, xhrFields }) {
                headers.Authorization = 'Bearer ' + Globals.service.Token;
            },
            beforeSubmit: function ({ formData }) {

                // var x = document.getElementsByName("__RequestVerificationToken");
                formData.value = {
                    headers: {
                        Authorization: 'Bearer ' + Globals.service.Token
                    }
                }
            },
            requestHeaders: {
                Authorization: 'Bearer ' + Globals.service.Token
            },
            endpointUrl: sinavApi + "dokuman/filesystemcheck"
        });
    }


    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }

    async componentDidMount() {
        service.get(sinavApi + "duyuru/duyurularByAktif",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        duyurular: res.data.Data
                    });
                }
                else
                    notify(res.data.ErrorMessage, "error", 5000);
            });
        service.get(sinavApi + "dokuman/getDashboardDokumanlar",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    var data = [];
                    res.data.Data.forEach((item, index) => {
                        if (data.length === 0) {
                            item.Dosyalar = [{ Dosya: res.data.Data[index].Dosya }];
                            data.push(item);
                        }
                        else if (data.filter(x => x.Adi === res.data.Data[index].Adi).length > 0) {
                            var dosya = data.find(k => k.Adi === res.data.Data[index].Adi);

                            for (let y = 0; y < dosya.Dosyalar.length; y++) {
                                if (dosya.Dosyalar[y].Dosya !== res.data.Data[index].Dosya) {
                                    dosya.Dosyalar.push({ Dosya: res.data.Data[index].Dosya });
                                }
                            }

                            data.find(k => k.Adi === res.data.Data[index].Adi).Dosyalar = dosya.Dosyalar;
                        }
                        else {
                            item.Dosyalar = [{ Dosya: res.data.Data[index].Dosya }];
                            data.push(item);
                        }
                    });
                    this.setState({
                        dokumanlar: data.filter(x => x.DokumanTuru === 1),
                        cevapAnahtarlari: data.filter(x => x.DokumanTuru === 2)
                    });
                    this.tumCevapAnahtarlari = data.filter(x => x.DokumanTuru === 2);
                }
                else
                    notify(res.data.ErrorMessage, "error", 5000);
            });
    }

    tabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            this.setState({
                tabSelectedIndex: parseInt(args.value)
            });
        }
    }

    renderTabDetay(tabIndex) {
        //Dökümanlar --0
        if (tabIndex === 0) {
            return (
                <React.Fragment>
                    <div style={{ marginTop: "10px" }}>
                        <Accordion
                            dataSource={this.state.duyurular}
                            collapsible={false}
                            multiple={false}
                            animationDuration={0}
                            itemTitleRender={DuyuruTitle}
                            visible={Globals.Rol === "Ogrenci" ? false : true}
                            itemRender={DuyuruItem}
                        />
                    </div>
                </React.Fragment>
            );
        }
        else if (tabIndex === 1) {

            return (
                <React.Fragment>
                    <div style={{ marginTop: "10px" }}>
                        <Accordion
                            dataSource={this.state.dokumanlar}
                            collapsible={false}
                            multiple={false}
                            visible={Globals.Rol === "Ogrenci" ? false : true}
                            animationDuration={0}
                            itemTitleRender={DokumanTitle}
                            itemRender={DokumanItem}
                        />
                    </div>
                </React.Fragment>
            )
        }
        else if (tabIndex === 2) {
            return (
                <React.Fragment>
                    <FileManager
                        fileSystemProvider={this.remoteProvider}
                        allowedFileExtensions={allowedFileExtensions}
                        selectionMode="single"
                        rootFolderName="Ana Dizin"
                        toolbar={{
                            fileSelectionItems: [
                                {
                                    widget: 'dxButton',
                                    location: 'before',
                                    options: {
                                        icon: "download",
                                        text: "İndir",
                                        type: "danger"
                                    },
                                    onClick: () => {
                                        if (!!this.secilenDosya) {
                                            let myDialog = custom({
                                                title: "Bilgi",
                                                messageHtml: "<a href='" + sinavRealApi + "RootFiles/" + this.secilenDosya + "' target='_blank'>Dosyanızı indirmek için tıklayınız </a>",
                                                buttons: [{
                                                    text: "Bu Pencereyi Kapat",
                                                    onClick: (e) => {
                                                        return "Ok";
                                                    }
                                                }]
                                            });
                                            myDialog.show().then((dialogResult) => {
                                            });
                                        }
                                    }
                                },

                            ]
                        }}
                        upload={{
                            maxFileSize: 1000000,
                            chunkSize: 500000
                        }}
                        onSelectedFileOpened={() => {
                            if (!!this.secilenDosya) {
                                let myDialog = custom({
                                    title: "Bilgi",
                                    messageHtml: "<a href='" + sinavRealApi + "RootFiles/" + this.secilenDosya + "' target='_blank'>Dosyanızı indirmek için tıklayınız </a>",
                                    buttons: [{
                                        text: "Bu Pencereyi Kapat",
                                        onClick: (e) => {
                                            return "Ok";
                                        }
                                    }]
                                });
                                myDialog.show().then((dialogResult) => {
                                });
                            }
                        }}
                        onSelectionChanged={(e) => {
                            if (e.selectedItems.length > 0) {
                                if (e.selectedItems[0].size > 0) {

                                    this.secilenDosya = e.selectedItems[0].path;
                                }

                            }
                            else {
                                this.secilenDosya = "";

                            }
                        }}
                    >
                        <Permissions
                            create={Globals.service.Rol === "Admin" ? true : false}
                            copy={Globals.service.Rol === "Admin" ? true : false}
                            move={Globals.service.Rol === "Admin" ? true : false}
                            delete={Globals.service.Rol === "Admin" | Globals.service.Rol === "Yayin" ? true : false}
                            rename={Globals.service.Rol === "Admin" | Globals.service.Rol === "Yayin" ? true : false}
                            upload={Globals.service.Rol === "Admin" | Globals.service.Rol === "Yayin" ? true : false}
                        >
                        </Permissions>
                    </FileManager>
                </React.Fragment>
            );
        }
    }

    renderDetay() {
        return (
            <div>
                <Tabs selectedIndex={this.state.tabSelectedIndex} dataSource={this.state.tabs} onOptionChanged={this.tabsSelectionChanged} />
                {this.renderTabDetay(this.state.tabSelectedIndex)}
            </div>
        );
    }
    render() {

        if (Globals.Rol === "Ogrenci") {
            return (<React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>&nbsp;Sayfanıza hoş geldiniz.</p>
                        </div>
                    </div>
                </div>

            </React.Fragment>);
        }
        else {
            return (
                <React.Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {this.renderDetay()}
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        }
    }
}