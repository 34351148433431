import React, { Component } from 'react';
import { sinavApi, errorMessage, errorMessageWithKonum } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
export default class DokumanIslemleri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            secilenYayin: null,
            secilenDokuman: null,
            secilenDokumanTuru: null,
            secilenSinavTuru: null,
            secilenMerkeziSinav: null,
            yayinlar: [],
            sinavTurleri: [],
            merkeziSinavlar: [],
            dokumanlar: [],
            disabled: true,
            loadingText: "Dökümanlar yükleniyor...",
            toolbarDisabled: false
        };
        this.satir = null;
        this.mainDataGrid = null;
        this.dokumanTurleri = [
            { Id: 1, Adi: "Fmt" },
            { Id: 2, Adi: "Cevap Anahtari" },
            { Id: 3, Adi: "Genel" }
        ];
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.kayitYenile = this.kayitYenile.bind(this);
        this.kayitAktifYap = this.kayitAktifYap.bind(this);
        this.kayitPasifYap = this.kayitPasifYap.bind(this);
        this.kayitYeni = this.kayitYeni.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.kayitSil = this.kayitSil.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.popupGizle = () => {
            this.setState({ popup: false, toolbarDisabled: false });
            this.satirIptal();
        };
        this.popupAc = () => {
            this.setState({
                popup: true,
                toolbarDisabled: true
            });
        };
        this.formData = {
            YayinId: 0,
            SinavTuruId: 0,
            MerkeziSinavId: 0,
            DokumanTuru: 0,
            Adi: "",
            Deleted: false,
            RowVersion: 0
        }
        this.onUploaded = this.onUploaded.bind(this);
        this.kayitYenile();
    }

    //#region form fonksiyonları

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Dökümanınız yüklenemedi," + res.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Dökümanınız yüklendi", "success", 5000);
            this.setState({
                secilenDokuman: res.Data
            });
            e.component.reset();
        }
    }

    //#endregion

    //#region servis fonksiyonları
    async getDokuman(id) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "dokuman/getDokuman/" + id,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            secilenDokuman: res.data.Data.Dosya,
                            secilenDokumanTuru: res.data.Data.DokumanTuru
                        });
                        resolve("seçilen döküman düzenlenmek için açılıyor...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "döküman satırı"));
                })
        });
    }

    async getYetkiliYayinlar() {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "yayin/getYayinlarByYetki",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            yayinlar: res.data.Data,
                        });
                        resolve("yayın bilgileri getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "yetkili yayın bilgileri"));
                });
        })
    }

    async getSinavturleri(yayinId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "dokuman/GetSinavTurleriByYayinId/" + yayinId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            sinavTurleri: res.data.Data,
                        });
                        resolve("sınav türü bilgileri getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(errorMessageWithKonum(error, "sınav türleri"));
                })
        })
    }

    async getMerkeziSinavlar(yayinId, sinavTuruId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "dokuman/getMerkeziSinavlar/" + yayinId + "/" + sinavTuruId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            merkeziSinavlar: res.data.Data
                        });
                        resolve("Sınav bilgileri getirildi");
                    } else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(errorMessageWithKonum(error, "merkezi sınavlar"));
                })
        });
    }
    //#endregion

    //#region toolbar fonksiyonları 
    async kayitYeni() {
        return await new Promise((resolve, reject) => {
            service.get(sinavApi + "yayin/getYayinlarByYetki",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(resYayin => {
                    if (resYayin.data.Success) {
                        this.formData = {
                            YayinId: 0,
                            MerkeziSinavId: 0,
                            SinavTuruId: 0,
                            Adi: "",
                            Dosya: null,
                            DokumanTuru: null,
                            Deleted: false,
                            RowVersion: 0
                        };
                        this.setState({
                            yayinlar: resYayin.data.Data,
                            secilenDokumanTuru: null,
                            secilenDokuman: null,
                            secilenSinavTuru: null,
                            secilenYayin: null,
                            secilenMerkeziSinav: null
                        });
                        resolve("döküman ekle formu açılıyor...");
                    }
                    else {
                        notify(resYayin.data.ErrorMessage, "error", 5000);
                        reject(resYayin.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "döküman yeni"));
                });
        }).then(() => { this.popupAc() })
            .catch(error => { alert(error) });
    }

    async kayitDuzenle() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let hataMesajlari = "";
            let isError = false;
            await this.getDokuman(satir.Id).then(res => {
            })
                .catch(error => {
                    hataMesajlari += error + "<br/>";
                    isError = true;
                });


            await this.getYetkiliYayinlar().then(res => {
            }).catch(error => {
                hataMesajlari += error + "<br/>";
                isError = true;
            });


            await this.getSinavturleri(satir.YayinId).then(res => {
            }).catch(error => {
                hataMesajlari += error + "<br/>";
                isError = true;
            });

            await this.getMerkeziSinavlar(satir.YayinId, satir.SinavTuruId).then(res => {
            }).catch(error => {
                hataMesajlari += error + "<br/>";
                isError = true;
            });

            this.setState({
                loadingText: "",
                toolbarDisabled: false,
                secilenDokuman: satir.Dosya,
                secilenDokumanTuru: satir.DokumanTuru,
                secilenMerkeziSinav: satir.MerkeziSinavId,
                secilenYayin: satir.YayinId,
                secilenSinavTuru: satir.SinavTuruId
            });

            this.formData = satir;

            if (isError) {
                alert(hataMesajlari);
            }
            else
                this.popupAc();
        }
    }

    async kayitYenile() {

        return await new Promise((resolve, reject) => {
            this.setState(
                {
                    loadingText: "Dökümanlar yükleniyor...",
                    toolbarDisabled: true
                });
            service.get(sinavApi + "dokuman/getDokumanlar",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            dokumanlar: res.data.Data,
                            loadingText: "",
                            toolbarDisabled: false
                        });
                        resolve("dökümanlar getirildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    notify("Teknik bir hata oluştu, dökümanlar yüklenemedi", "error", 5000);
                    reject(errorMessageWithKonum(error, "döküman bilgileri"))
                })
        }).then(res => {
            this.setState({
                loadingText: "",
                toolbarDisabled: false
            });
            this.satirIptal();
        }).catch(error => {
            alert(error);
            this.setState({
                loadingText: "",
                toolbarDisabled: false
            });
        });
    }

    async kayitAktifYap() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            return await new Promise((resolve, reject) => {
                this.setState({ toolbarDisabled: true, loadingText: "Kaydınız aktif hale getiriliyor..." });
                service.post(sinavApi + 'dokuman/AktifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                        resolve("Kaydınız aktif hale getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessage(error));
                })
            }).then(res => {
                this.kayitYenile();
            }).catch(error => {
                alert(error);
            });
        }
    }

    async kayitPasifYap() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({ toolbarDisabled: true, loadingText: "Kaydınız pasif hale getiriliyor..." });
            return await new Promise((resolve, reject) => {
                service.post(sinavApi + 'dokuman/PasifYap/' + satir.Id,
                    null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                        resolve("Kaydınız pasif hale getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessage(error));
                })
            }).then(res => {
                this.kayitYenile();
            }).catch(error => {
                alert(error);
            });

        }
    }

    //await anahtarı confirm kullanıdığı için kabul edilmiyor oyüzden ayrı bir fonksiyon olarak ekledim
    async sil(id) {
        return await new Promise((resolve, reject) => {
            service.post(sinavApi + "dokuman/dokumanSil/" + id,
                null, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    notify("Kaydınız silindi.", 'success', 5000);
                    resolve("Kaydınız silindi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                reject(errorMessage(error));
            })
        }).then(res => {
            this.kayitYenile();
        }).catch(error => {
            alert(error);
        });
    }
    async kayitSil() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.setState({ toolbarDisabled: true, loadingText: "Kaydınız siliniyor..." });
                    this.sil(satir.Id);

                }
                else
                    this.satirIptal();

            });
        }
    }

    //#endregion

    //#region grid fonksiyonları

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitYeni
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitDuzenle
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'remove',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitSil
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitPasifYap
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitAktifYap
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitYenile
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }

    //seçilen satırı iptal etmek için kullanıyoruz
    async satirIptal() {
        this.mainDataGrid.instance.clearSelection();
        this.mainDataGrid.instance.option("focusedRowIndex", -1);
    }

    //#endregion


    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.loadingText}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.state.dokumanlar}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                paging={{
                                    pageSize: 20
                                }}
                                pager={{
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                    showInfo: true
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onInitialized={this.onInitialized}
                                ref={(ref) => this.mainDataGrid = ref}
                            >
                                <Export enabled={true} fileName={'Dökümanlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'YayinId'} caption="YayınId" visible={false} />
                                <Column dataField={'SinavTuruId'} caption="SinavTuruId" visible={false} />
                                <Column dataField={'MerkeziSinavId'} caption="MerkeziSinavId" visible={false} />
                                <Column dataField={'DokumanTuru'} caption="DokümanTur" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" />
                                <Column dataField={'YayinAdi'} caption="Yayın" />
                                <Column dataField={'SinavTuruAdi'} caption="Sınav Turu" />
                                <Column dataField={'SinavAdi'} caption="Sınav" />
                                <Column dataField={'Tur'} caption="Doküman Türü" />
                                <Column dataField={'Adi'} caption="Döküman" />
                            </DataGrid>

                        </div>
                    </div>

                </div>

                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {

                                        this.formData.Yayin = { Id: this.state.secilenYayin };
                                        this.formData.SinavTuru = { Id: this.state.secilenSinavTuru };
                                        this.formData.MerkeziSinav = { Id: this.state.secilenMerkeziSinav };
                                        this.formData.DokumanTuru = this.state.secilenDokumanTuru;
                                        this.formData.Dosya = this.state.secilenDokuman;
                                        service.post(sinavApi + "dokuman/dokumanSave",
                                            this.formData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }).then(res => {
                                                if (res.data.Success) {
                                                    this.setState({
                                                        popup: false
                                                    })
                                                    this.kayitYenile();
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Doküman  Bilgileri">
                                        <SimpleItem dataField={"DokumanTuru"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.dokumanTurleri,
                                                searchEnabled: true,
                                                value: this.state.secilenDokumanTuru,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onValueChanged: (e) => {
                                                    this.setState({
                                                        secilenDokumanTuru: e.value
                                                    });
                                                    if (this.state.secilenDokumanTuru === 2)
                                                        this.setState({ disabled: false });
                                                    else
                                                        this.setState({ disabled: true });
                                                }
                                            }}>
                                            <Label text="Döküman Türü" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Adi"}>
                                            <Label text="Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"YayinId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.state.yayinlar,
                                                searchEnabled: true,
                                                value: this.state.secilenYayin,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onValueChanged: (e) => {
                                                    this.setState({
                                                        secilenYayin: e.value
                                                    });
                                                    service.get(sinavApi + "dokuman/GetSinavTurleriByYayinId/" + e.value,
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                        .then(res => {
                                                            if (res.data.Success) {
                                                                this.setState({
                                                                    secilenYayin: e.value,
                                                                    sinavTurleri: res.data.Data
                                                                });

                                                            }
                                                            else
                                                                notify(res.data.ErrorMessage, "error", 5000);
                                                        });
                                                }
                                            }}>
                                            <Label text="Yayın" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Cevap Anahtarı Bilgileri">

                                        <SimpleItem dataField={"SinavTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                items: this.state.sinavTurleri,
                                                searchEnabled: true,
                                                value: this.state.secilenSinavTuru,
                                                displayExpr: "SinavTuruAdi",
                                                disabled: this.state.disabled,
                                                valueExpr: "SinavTuru_Id",
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null)
                                                        return;
                                                    else {
                                                        this.setState({
                                                            secilenSinavTuru: e.value
                                                        });

                                                        if (this.state.secilenYayin !== null && this.state.secilenSinavTuru !== null) {
                                                            service.get(sinavApi + "dokuman/getMerkeziSinavlar/" + this.state.secilenYayin + "/" + this.state.secilenSinavTuru,
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                })
                                                                .then(res => {
                                                                    if (res.data.Success) {
                                                                        this.setState({
                                                                            merkeziSinavlar: res.data.Data
                                                                        });
                                                                    }
                                                                    else
                                                                        notify(res.data.ErrorMessage, "error", 5000);
                                                                });

                                                        }
                                                    }
                                                }
                                            }}>
                                            <Label text="Sınav Türü" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MerkeziSinavId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                items: this.state.merkeziSinavlar,
                                                searchEnabled: true,
                                                value: this.state.secilenMerkeziSinav,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                disabled: this.state.disabled,
                                                onValueChanged: (e) => {
                                                    this.setState({
                                                        secilenMerkeziSinav: e.value
                                                    });
                                                }
                                            }}>
                                            <Label text="Sınav" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1} caption="Dosya Bilgileri">
                                        <SimpleItem dataField={"File"}
                                            editorType="dxFileUploader"
                                            editorOptions={{
                                                uploadUrl: sinavApi + "dokuman/DokumanUpload?DokumanTuru=" + this.state.secilenDokumanTuru,
                                                uploadMode: 'instantly',
                                                uploadHeaders: this.headers,
                                                accept: '*',
                                                multiple: true,
                                                maxFileSize: 4000000,
                                                name: 'myFile',
                                                onUploaded: this.onUploaded
                                            }}>
                                            <Label text="Döküman dosyasını seçiniz" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>


            </React.Fragment>
        );
    }
}