import React from 'react';
import { karneApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import { withRouter } from 'react-router';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { confirm, alert } from 'devextreme/ui/dialog';
class OgrenciSinavSonuclari extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            karneler: [],
            listeKarneText: "Karne Oluştur",
            listeKarneDisabled: false
        };
        this.satir = null;
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.listeVeKarneClick = this.listeVeKarneClick.bind(this);
        //this.sinavSil = this.sinavSil.bind(this);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(

            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: this.state.listeKarneText, icon: 'exportpdf',
                    text: this.state.listeKarneText,
                    disabled: this.state.listeKarneDisabled,
                    onClick: this.listeVeKarneClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    text: "Yenile",
                    onClick: (e) => {
                        this.refreshData();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: (e) => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    listeVeKarneClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({
                listeKarneText: "!Bekleyiniz",
                listeKarneDisabled: true
            });
            service.get(karneApi + "ogrenci/GetKarne/" + satir.KullaniciId + "/" + satir.Id)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            listeKarneText: "Karne Oluştur",
                            listeKarneDisabled: false
                        });
                        this.refreshData();
                    }
                    else {
                        this.setState({
                            listeKarneText: "Karne Oluştur",
                            listeKarneDisabled: false
                        });
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                }).catch(error => {
                    this.setState({
                        listeKarneText: "Karne Oluştur",
                        listeKarneDisabled: false
                    });
                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz", "error", 5000);
                });
        }
    }

    sinavSil() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Sınav verilerini silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    service.post(karneApi + "ogrenci/OgrenciKarneSil/" + satir.ListeKarneId,
                        null,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + Globals.service.Token
                            }
                        })
                        .then(res => {
                            if (res.data.Success) {
                                this.refreshData();
                            }
                            else
                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                        });
                }
            });
        }
    }

    async refreshData() {
        service.get(karneApi + "ogrenci/getKarneler/" + Globals.service.Id, null)
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        karneler: res.data.Data
                    })
                }
                else
                    notify(res.data.ErrorMessage, "error", 5000);
            });
    }

    async componentDidMount() {
        this.refreshData();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <DataGrid
                            showBorders={true}
                            showRowLines={true}
                            dataSource={this.state.karneler}
                            columnHidingEnabled={true}
                            filterPanel={{ visible: true }}
                            filterRow={{ visible: true }}
                            columnAutoWidth={true}
                            selection={{ mode: 'single' }}
                            autoNavigateToFocusedRow={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            keyExpr="Id"
                            paging={{
                                pageSize: 20
                            }}
                            pager={{
                                showPageSizeSelector: true,
                                allowedPageSizes: [5, 10, 50, 75, 100],
                                showInfo: true
                            }}
                            onToolbarPreparing={this.onToolbarPreparing}
                            onSelectionChanged={this.onSelectionChanged}
                            ref={(ref) => this.mainDataGrid = ref}
                        >
                            <Export enabled={true} fileName={'Karneler'} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <Editing mode={'popup'} />
                            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                            <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                            <ColumnFixing enabled={true} />
                            <LoadPanel enabled={true} />
                            <FilterRow visible={false} applyFilter={'auto'} />
                            <Paging defaultPageSize={15} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                            <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                            <Column dataField="Yayin_Adi" caption=""
                                width={40}
                                allowSorting={false}
                                cellRender={linkRender}
                            />
                            <Column dataField={'KullaniciId'} caption="KullaniciId" visible={false} />
                            <Column dataField={'ListeKarneId'} caption="ListeKarneId" visible={false} />
                            <Column dataField={'Kurum_Id'} caption="KurumId" visible={false} />
                            <Column dataField={'Yayin_Adi'} caption="Yayın" />
                            <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                            <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                            <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                            <Column dataField={'Il_Adi'} caption="İl" />
                            <Column dataField={'Ilce_Adi'} caption="İlçe" />
                            <Column dataField={'Kurum_Adi'} caption="Kurum" />
                            <Column dataField={'Created'} caption="Tarih" dataType="date" format={
                                "dd/MM/yyyy HH:mm:ss"
                            } />
                            <Column dataField={'DosyaTuru'} caption="DosyaTuru" visible={false} />
                            <Column dataField={'Dosya'} caption="Dosya" visible={false} />
                        </DataGrid>

                    </div>
                </div>
            </div>

        );
    }
}
function linkRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Dosya === undefined || data.data.Dosya === null || data.data.Dosya === "") {
        return <p></p>;
    }
    else {
        return <a rel="noopener noreferrer" href={data.data.Dosya} target="_blank">
            <img src="/icons/download.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}
export default withRouter(OgrenciSinavSonuclari);