import { Route, Redirect } from "react-router";
import * as React from "react";
import Globals from "../../Globals";
const AppRoute = ({ component: Component, layout: Layout, path: Path, ...rest }) => {

    var isLoginPath = Path === "/login";
    var isRegisterPath = Path === "/register";
    if (Globals.isAuthenticated && isLoginPath) {
        return <Redirect to="/" />;
    }
    else if (!Globals.isAuthenticated && !isLoginPath && !isRegisterPath) {
        return <Redirect to="/login" />;
    }
    else if (!Globals.isAuthenticated && isRegisterPath) {
        return <Redirect to="/register" />;
    }
    var c = Path.substring(1);
    if (c) {
        if (c === "register") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "ogrencidashboard") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "home") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "sinavsonucu/:yayinParam") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "eaogrencilogin/:KurumId/:KullaniciId/:Sifre") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "eavelilogin/:KurumId/:OgrenciKullaniciId/:KullaniciId/:Sifre") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }
        else if (c === "SanalSinif/SanalOptikSinav") {
            return <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />;
        }

        var myMenu = Globals.service.Menu.find(x => x.Href === c);

        if (myMenu === null | myMenu === undefined) {
            if (Path === "/" |
                Path === "/login" |
                Path === "/sinavsonucu/:yayinParam" |
                Path === "/register" |
                Path === "/kayitkabuldashboard" |
                Path === "/otomasyondashboard" |
                Path === "/velidashboard" |
                Path === "/ogrencidashboard" |
                Path === "/home" |
                Path === "/ayarlar" |
                Path === "/eaogrencilogin/:KurumId/:KullaniciId/:Sifre" |
                Path === "/eavelilogin/:KurumId/:OgrenciKullaniciId/:KullaniciId/:Sifre") {

            }
            else {
                return <Redirect to="/login" />;

            }
        }
    }
    //console.log(Globals.service.Menu.filter(x => x.Href === Path.substring(1)));
    // else if (Globals.service.Menu.filter(x => x.Href === Path.substring(1)).length === 0 && Path !== "/login" && Path !== "/") {
    //     alert("bu sayfayı görme yetkiniz yok");
    //     return <Redirect to="/login" />;
    // }
    return <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />;
}

export default AppRoute;