import React from 'react';
import service from 'axios';
import { Link } from 'react-router-dom';
import DataGrid, {
  Selection, Column, Paging, Pager
} from 'devextreme-react/data-grid';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { authApi } from '../Util';
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.SinifSubeler = [
      { Sube: "Yok" },
      { Sube: "Mezun" },
      { Sube: "A" },
      { Sube: "B" },
      { Sube: "C" },
      { Sube: "Ç" },
      { Sube: "D" },
      { Sube: "E" },
      { Sube: "F" },
      { Sube: "G" },
      { Sube: "H" },
      { Sube: "I" },
      { Sube: "İ" },
      { Sube: "J" },
      { Sube: "K" },
      { Sube: "L" },
      { Sube: "M" },
      { Sube: "N" },
      { Sube: "O" },
      { Sube: "Ö" },
      { Sube: "P" },
      { Sube: "R" },
      { Sube: "S" },
      { Sube: "Ş" },
      { Sube: "T" },
      { Sube: "U" },
      { Sube: "Ü" },
      { Sube: "V" },
      { Sube: "Y" },
      { Sube: "Z" }]

    this.state = {
      kurumlar: [],
      yayinlar: [],
      sinavTurleri: [],
      secilenYayin: null,
      secilenKurum: null,
      secilenSinavTuru: null,
      secilenSube: null,
      yayinMesaj: "Yayınlar yükleniyor...",
      kurumMesaj: "Kurumlar yükleniyor...",
      sinifMesaj: "Sınıflar yükleniyor..."
    }
    this.secilenYayinlar = [];
    this.dataGridYayin = null;
    this.KullaniciYayinYetki = [];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.yayinSecimiChanged = this.yayinSecimiChanged.bind(this);
    this.formData = {
      KullaniciAdi: "",
      Sifre: "",
      Adi: "",
      Soyadi: "",
      OgrenciNo: 0,
      Tel: "",
      Gsm: "",
      SmsBilgi: false,
      MailBilgi: false,
      SinifId: 0,
      KurumId: 0,
      Sube: "",
      VeliAd: "",
      VeliSoyad: "",
      VeliGsm: "",
      VeliEmail: "",
      SecilenYayinlar: []
    };

    this.buttonOptions = {
      text: 'Kayıt Ol',
      type: 'success',
      useSubmitBehavior: true
    };

    this.passwordOptions = {
      mode: 'password'
    };
  }

  async componentDidMount() {


    await this.getYayinlar().then(() => { }).catch(error => {
      notify(error, "error", 2000);
    });

    await this.getSiniflar().then(() => { }).catch(error => {
      notify(error, "error", 2000);
    });

    await this.getKurumlar().then(() => { }).catch(error => {
      notify(error, "error", 2000);
    });

  }


  yayinSecimiChanged() {
    this.secilenYayinlar = this.dataGridYayin.instance.getSelectedRowsData();
  }

  getYayinlar() {
    return new Promise((resolve, reject) => {
      this.setState({
        yayinMesaj: "Yayınlar yükleniyor..."
      });
      service.get(authApi + "auth/getYayinlar")
        .then(res => {
          if (res.data.Success) {
            this.setState({
              yayinlar: res.data.Data,
              yayinMesaj: ""
            });
            this.setState({ yayinMesaj: "" });
            resolve("Yayın bilgileri getirildi.");

          }
          else
            reject(res.data.ErrorMessage);
        }).catch(() => {
          this.setState({ yayinMesaj: "" });
          reject("Teknik bir hata oluştu,yayın bilgileri getirilemedi");
        });
    })
  }

  getSiniflar() {
    return new Promise((resolve, reject) => {
      this.setState({
        sinifMesaj: "Sınıflar yükleniyor..."
      });
      service.get(authApi + "auth/GetSinavTurleri", null)
        .then(res => {
          if (res.data.Success) {
            this.setState({
              sinavTurleri: res.data.Data,
              sinifMesaj: "Seçiniz"
            });
            resolve("Sınıf bilgileri getirildi");
          }
          else {
            this.setState({ sinifMesaj: res.data.ErrorMessage });
            reject(res.data.ErrorMessage);
          }
        })
        .catch(() => {
          this.setState({ sinifMesaj: "Sınıf bilgileri getirilemedi" });
          reject("Teknik bir hata oluştu,sınıf bilgileri getirilemedi");
        });
    });
  }

  getKurumlar() {
    return new Promise((resolve, reject) => {
      this.setState({ kurumMesaj: "Kurumlar yükleniyor..." });
      service.get(authApi + "auth/GetKurumlar",
        null)
        .then(res => {
          if (res.data.Success) {
            this.setState({
              kurumlar: res.data.Data,
              kurumMesaj: "Seçiniz"
            });
            resolve("Kurum bilgileri getirildi");
          }
          else {
            this.setState({ kurumMesaj: res.data.ErrorMessage });
            reject(res.data.ErrorMessage);
          }
        })
        .catch(() => {
          this.setState({ kurumMesaj: "Kurum bilgisi getirilemedi" });
          reject("Teknik bir hata oluştu,kurum bilgileri getirilemedi")
        });
    });
  }

  handleSubmit(e) {
    this.formData.SecilenYayinlar = this.secilenYayinlar;
    service.post(authApi + "Auth/register"
      , this.formData)
      .then(res => {
        if (res.data.Success) {
          notify("Kullanıcınız oluşturuldu, şimdi giriş sayfasına yönlendiriliyorsunuz", "success", 5000);
          window.setTimeout(() => {
            this.props.history.push("/login");
          }, 5000);
        }
        else
          notify("Bir hata oluştu:" + res.data.ErrorMessage, "success", 5000);

      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          notify(error.response.data.message, 'error', 5000);
        } else if (error.request) {
          notify(error.request, 'error', 5000);
        } else {
          notify("Teknik bir hata oluştu.", 'error', 5000);
        }

      });
    e.preventDefault();
  };

  render() {
    return (
      <div id="container">
        <div className="row">
          <div className="col-md-12">

            <form id="form" onSubmit={this.handleSubmit}>
              <Form
                formData={this.formData}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true}
              >
                <GroupItem caption="Giriş Bilgileri" colCount={2}>
                  <SimpleItem dataField="KullaniciAdi" editorType="dxTextBox">
                    <Label text="Kullanıcı Adı" />
                    <RequiredRule message="Kullanıcı adı alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="Sifre" editorType="dxTextBox" editorOptions={this.passwordOptions}>
                    <Label text="Şifre" />
                    <RequiredRule message="Şifre alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="Email" editorType="dxTextBox">
                    <Label text="Email" />
                    <RequiredRule message="Email alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="OgrenciNo" editorType="dxNumberBox">
                    <Label text="Öğrenci No" />
                    <RequiredRule message="Öğrenci no alanı zorunludur" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="İletişim Bilgileri" colCount={2}>
                  <SimpleItem dataField="Adi" editorType="dxTextBox">
                    <Label text="Ad" />
                    <RequiredRule message="Ad alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="Soyadi" editorType="dxTextBox">
                    <Label text="Soyad" />
                    <RequiredRule message="Soyad alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="Tel" editorType="dxTextBox"
                    editorOptions={
                      {
                        mask: "(X00) 000-0000",
                        maskRules: { "X": /[01-9]/ }
                      }
                    }>
                    <Label text="Telefon" />
                  </SimpleItem>
                  <SimpleItem dataField="Gsm" editorType="dxTextBox"
                    editorOptions={
                      {
                        mask: "(X00) 000-0000",
                        maskRules: { "X": /[01-9]/ }
                      }
                    }>
                    <Label text="Gsm" />
                    <RequiredRule message="Gsm alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="SmsBilgi" editorType="dxCheckBox">
                    <Label text="Sms Bilgi" />
                  </SimpleItem>
                  <SimpleItem dataField="MailBilgi" editorType="dxCheckBox">
                    <Label text="Mail Bilgi" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="Sınıf-Şube Bilgileri" colCount={2}>

                  <SimpleItem dataField={"SinifId"}
                    editorType="dxSelectBox"
                    editorOptions={{
                      placeholder: this.state.sinifMesaj,
                      items: this.state.sinavTurleri,
                      searchEnabled: true,
                      value: this.state.secilenSinavTuru,
                      displayExpr: "Adi",
                      valueExpr: "Id",
                      loadPanel: true,

                      onValueChanged: (e) => {
                        if (e.value === "" | e.value === null)
                          return;
                        else {
                          this.setState({
                            secilenSinavTuru: e.value
                          });
                        }
                      }
                    }}>

                    <RequiredRule message="Sınıf alanı zorunludur" />
                    <Label text="Sınıf" />

                  </SimpleItem>
                  <SimpleItem dataField={"Sube"}
                    editorType="dxSelectBox"
                    editorOptions={{
                      placeholder: "Şube Seçiniz",
                      items: this.SinifSubeler,
                      searchEnabled: true,
                      value: this.state.secilenSube,
                      displayExpr: "Sube",
                      valueExpr: "Sube",
                      onValueChanged: (e) => {
                        if (e.value === "" | e.value === null)
                          return;
                        else {
                          this.setState({
                            secilenSube: e.value
                          });
                        }
                      }
                    }}>
                    <RequiredRule message="Şube alanı zorunludur" />
                    <Label text="Şube" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="Kurum Bilgileri" colCount={1}>

                  <SimpleItem dataField={"KurumId"}
                    editorType="dxSelectBox"
                    editorOptions={{
                      placeholder: this.state.kurumMesaj,
                      dataSource: this.state.kurumlar,
                      searchEnabled: true,
                      value: this.state.secilenKurum,
                      displayExpr: "KurumAdi",
                      showClearButton: true,
                      valueExpr: "Id",
                      onKeyUp: (e) => {
                        const deger = e.component.instance()._getOptionValue().text;
                        if (deger.toString().length <= 4)
                          return;
                        else {

                          service.get(authApi + "auth/GetKurumlar?term=" + deger,
                            null)
                            .then(res => {
                              if (res.data.Success) {
                                this.setState({
                                  kurumlar: res.data.Data
                                });
                                e.component.instance().open();
                              }
                              else {

                                notify(res.data.ErrorMessage, "error", 2000);
                              }
                            });
                        }
                      },
                      onValueChanged: (e) => {
                        this.setState({
                          secilenKurum: e.value
                        });
                      }
                    }}>
                    <Label text="Kurum Seçiniz" />

                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="Veli Bilgileri" colCount={2}>
                  <SimpleItem dataField="VeliAd" editorType="dxTextBox">
                    <Label text="Ad" />
                    <RequiredRule message="Ad alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="VeliSoyad" editorType="dxTextBox">
                    <Label text="Soyad" />
                    <RequiredRule message="Soyad alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="VeliGsm" editorType="dxTextBox"
                    editorOptions={
                      {
                        mask: "(X00) 000-0000",
                        maskRules: { "X": /[01-9]/ }
                      }
                    }>
                    <Label text="Gsm" />
                    <RequiredRule message="Gsm alanı zorunludur" />
                  </SimpleItem>
                  <SimpleItem dataField="VeliEmail" editorType="dxTextBox">
                    <Label text="Email" />
                    <RequiredRule message="Email alanı zorunludur" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="Yayın Yetkileri(Bu kısım çok önemli işlem yapmak istediğiniz yayınları seçiniz)" colCount={1}>
                  <SimpleItem>
                    {this.state.yayinMesaj}
                    <DataGrid
                      showBorders={true}
                      showRowLines={true}
                      dataSource={this.state.yayinlar}
                      repaintChangesOnly={true}
                      columnHidingEnabled={true}
                      columnAutoWidth={true}
                      autoNavigateToFocusedRow={true}
                      focusedRowEnabled={true}
                      hoverStateEnabled={true}
                      rowAlternationEnabled={true}
                      onSelectionChanged={this.yayinSecimiChanged}
                      keyExpr="Id"
                      paging={{
                        pageSize: 20
                      }}
                      pager={{
                        showPageSizeSelector: true,
                        allowedPageSizes: [5, 10, 50, 75, 100],
                        showInfo: true
                      }}
                      ref={(ref) => this.dataGridYayin = ref}
                    >

                      <Paging defaultPageSize={15} />
                      <Selection mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick" />
                      <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                      <Column dataField={'Id'} caption="Id" visible={false} />
                      <Column dataField={'Adi'} caption="Yayın" />
                    </DataGrid>

                  </SimpleItem>
                </GroupItem>
                <ButtonItem horizontalAlignment="left"
                  buttonOptions={this.buttonOptions}
                />
              </Form>
              <br /><br />
              <Link target="_self" to={"/login"} >Giriş sayfasına git </Link>
              <br /><br />
              <h6>Orion Sınav Değerlendirme Sistemi - <Link target="_blank" to={"//www.arkyazilim.com"} >Arkyazılım </Link></h6>
            </form>

          </div>
        </div>
      </div>

    );
  }
}
export default RegisterPage;