import { KepsSystemActionTypes } from "../constants/action-types";

const initialState = {
    shortCodes: []
};
export const kepsSystemReducer = (state = initialState, action) => {
    switch (action.type) {

        case KepsSystemActionTypes.SET_SHORT_CODES:
            return { ...state, shortCodes: Object.assign([], action.payload) };
        default:
            return state;
    }
}