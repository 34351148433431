import * as React from "react";
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import DataGrid, {
    ColumnFixing, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, GroupItem, Export
    , Summary
} from 'devextreme-react/data-grid';
export default class KurumGiderRaporu extends React.Component {
    constructor(props) {
        super(props);
        this.gridKurumGider = null;
        this.satir = null;
        this.onInitializedGridKurumGider = this.onInitializedGridKurumGider.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

        this.giderDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/listkurumgiderraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });


    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridKurumGider.instance.refresh();
                    }
                }
            }
        );
    }

    onInitializedGridKurumGider(e) {
        this.gridKurumGider = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Rapor İşlemleri>>Kurum Gider Raporu"}</h4>

                            <DataGrid
                                id="grdKurumGider"
                                showBorders={true}
                                showRowLines={true}
                                remoteOperations={true}
                                dataSource={this.giderDataSource}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitialized={this.onInitializedGridKurumGider}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                key={"Id"}
                                selection={
                                    {
                                        mode: 'single'
                                    }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                groupPanel={true}
                                ref={(ref) => this.gridKurumGider = ref}
                            >
                                <Export
                                    enabled={true}
                                    fileName={'KurumGiderler'}
                                    allowExportSelectedData={false}
                                    texts={
                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                    }
                                />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={false} allowExporting={false} />
                                <Column dataField={'Yil'} caption="Yıl" groupIndex={0} />
                                <Column dataField={'Ay'} caption="Ay" groupIndex={1} />
                                <Column dataField={'Gun'} caption="Gün" />
                                <Column dataField={'Gerceklestiren'} caption="Gerçekleştiren" />
                                <Column dataField={'Aciklama'} caption="Açıklama" />
                                <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} format={"#,##0.##"} />
                                <Summary>
                                    <GroupItem
                                        column="Tutar"
                                        summaryType="sum"


                                    />

                                </Summary>
                            </DataGrid>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
