import * as React from "react";
import { sinavApi } from '../../Util';
import notify from 'devextreme/ui/notify';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { LoadPanel as MyLoad } from 'devextreme-react/load-panel';
import Globals from '../../Globals';
const position = { of: '#kurumformpanel' };
export default class KurumAktarmaIslemleri extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loadPanelVisible: false
        }
        this.onUploaded = this.onUploaded.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
    }
    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Result.Value.Success) {
            notify("Şablon aktarımı yapılamadı," + res.Result.Value.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Şablon aktarımı yapıldı", "success", 5000);
            e.component.reset();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="kurumformpanel">
                    <Form formData={this.formData}>
                        <GroupItem cssClass="first-group" colCount={1} caption="İl ve İlçe şablonunu seçiniz(İşlem bitene kadar bekleyiniz.)">
                            <SimpleItem dataField={"File"}
                                editorType="dxFileUploader"
                                editorOptions={{
                                    uploadUrl: sinavApi + "kurumaktarma/ililceSablonUpload",
                                    uploadMode: 'instantly',
                                    uploadHeaders: this.headers,
                                    accept: '*',
                                    maxFileSize: 4000000,
                                    name: 'myFile',
                                    onUploaded: this.onUploaded
                                }}>
                                <Label text="Şablon seçiniz" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="first-group" colCount={1} caption="Kurum aktarım şablonunu seçiniz(İşlem bitene kadar bekleyiniz.)">
                            <SimpleItem dataField={"File"}
                                editorType="dxFileUploader"
                                editorOptions={{
                                    uploadUrl: sinavApi + "kurumaktarma/KurumSablonUpload",
                                    uploadMode: 'instantly',
                                    uploadHeaders: this.headers,
                                    accept: '*',
                                    maxFileSize: 4000000,
                                    name: 'myFile',
                                    onUploaded: this.onUploaded
                                }}>
                                <Label text="Şablon seçiniz" />
                            </SimpleItem>
                        </GroupItem>

                    </Form>
                </div>
                <MyLoad
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </React.Fragment>
        );
    }
}