import * as React from "react";
import { otomasyonApi, GetStore } from '../../Util';
import DataGrid, {
    Export, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel
} from 'devextreme-react/data-grid';
import Tabs from 'devextreme-react/tabs';
export default class OtomasyonGenelTanimlar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelectedIndex: 0
        };
        this.renderDetay = this.renderDetay.bind(this);
        this.renderTabDetay = this.renderTabDetay.bind(this);
        this.tabsSelectionChanged = this.tabsSelectionChanged.bind(this);

        this.tabs = ["Meslekler", "Yakınlık", "Bölümler", "Sınıf Seviyeleri", "Eğitim Türleri"];

        this.meslekDataSource = GetStore(
            "Id"
            , "OtomasyonMeslek"
            , otomasyonApi + "geneltanim/listmeslek"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.yakinlikDataSource = GetStore(
            "Id"
            , "OtomasyonYakinlik"
            , otomasyonApi + "geneltanim/listyakinlik"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.bolumDataSource = GetStore(
            "Id"
            , "OtomasyonBolum"
            , otomasyonApi + "geneltanim/listbolum"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.sinifSeviyeDataSource = GetStore(
            "Id"
            , "OtomasyonSinifSeviye"
            , otomasyonApi + "geneltanim/listsinifseviye"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.egitimTuruDataSource = GetStore(
            "Id"
            , "OtomasyonEgitimTuru"
            , otomasyonApi + "geneltanim/listegitimturu"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

    }

    tabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            this.setState({
                tabSelectedIndex: parseInt(args.value)
            });
        }
    }

    renderDetay() {
        return (
            <React.Fragment>
                <Tabs selectedIndex={this.state.tabSelectedIndex} dataSource={this.tabs} onOptionChanged={this.tabsSelectionChanged} />
                {this.renderTabDetay(this.state.tabSelectedIndex)}
            </React.Fragment>
        );
    }

    renderTabDetay(tabIndex) {
        if (tabIndex === 0) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.meslekDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                    >
                        <Export enabled={true} fileName={'Meslekler'} allowExportSelectedData={false} />
                        <Editing mode={'row'} allowAdding={true} allowDeleting={true} allowUpdating={true} useIcons={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 1) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        showRowLines={true}
                        dataSource={this.yakinlikDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                    >
                        <Export enabled={true} fileName={'Yakınlıklar'} allowExportSelectedData={false} />
                        <Editing mode={'row'} allowAdding={true} allowDeleting={true} allowUpdating={true} useIcons={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 2) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        showRowLines={true}
                        dataSource={this.bolumDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                    >
                        <Export enabled={true} fileName={'Bolumler'} allowExportSelectedData={false} />
                        <Editing mode={'row'} allowAdding={true} allowDeleting={true} allowUpdating={true} useIcons={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı" />
                        <Column dataField={'Sira'} caption="Sıra" />
                    </DataGrid>
                </div>
            )
        }
        else if (tabIndex === 3) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        showRowLines={true}
                        dataSource={this.sinifSeviyeDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                    >
                        <Export enabled={true} fileName={'Sinifseviyeleri'} allowExportSelectedData={false} />
                        <Editing mode={'row'} allowAdding={true} allowDeleting={true} allowUpdating={true} useIcons={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı" />
                        <Column dataField={'Sira'} caption="Sıra" />
                    </DataGrid>
                </div>
            )
        }
        else if (tabIndex === 4) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        showRowLines={true}
                        dataSource={this.egitimTuruDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                    >
                        <Export enabled={true} fileName={'Egitimturleri'} allowExportSelectedData={false} />
                        <Editing mode={'row'} allowAdding={true} allowDeleting={true} allowUpdating={true} useIcons={true} />
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı" />
                    </DataGrid>
                </div>
            )
        }

    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12" style={{ padding: "10px 10px 10px 10px" }}>
                        {this.renderDetay()}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}