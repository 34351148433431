import * as React from "react";
import { ToastContainer } from "react-toastify";

export default class GuestLayout extends React.Component {
    constructor(props) {
        super(props);
        document.title = window.ayarlar.sistemAdi;
    }
    render() {
        return <div id="guestLayout" className="layout">
            <div className="container container-content">
                {this.props.children}
            </div>
            <ToastContainer />
        </div>;
    }
}