import React, { useEffect, useState } from "react";
import service from 'axios';
import Globals from "../../Globals";
import { yayinSinavApi, sinavApi } from "../../Util";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import '../../sanaloptik.css'
import '../../bootstrap-material.css';
import '../../uikit.css';
import { setSanalSinavSystemInfo } from '../../redux/actions/sanalSinavSystemAction'
import notify from "devextreme/ui/notify";
let cevapDizisi = [];
let soru = {};
let saat = 0;
let dakika = 0;
let saniye = 0;
let soruIndex = 1;
let isBaslangic = false;
let isBaslangicCounter = false;
let liseKontrol = true;
const SanalOptikPage = () => {
    const merkeziSistem = useSelector(state => state.remoteSystem.info);
    const sanalSinav = useSelector(state => state.sanalSinavSystem.info);
    const [sorular, setSorular] = useState([]);
    const [filtrenelenenSorular, setFiltrelenenSorular] = useState([]);
    const [filtreler, setFiltreler] = useState([]);
    const [cevaplar, setCevap] = useState("");
    const [ilkokulKontrol, setilkokulKontrol] = useState(true);
    const [liseKontrol, setliseKontrol] = useState(true);
    const [process, setProcess] = useState(false);
    const dispatch = useDispatch();
    const getSinavApi = useCallback(() => {
        let endpoint = "";
        endpoint = (merkeziSistem.connectionState && sanalSinav.MerkeziSistem) ? yayinSinavApi : sinavApi;
        return endpoint;
    }, [merkeziSistem.connectionState, sanalSinav.MerkeziSistem]);

    const getHeaders = useCallback(() => {
        return {
            'Authorization': ((merkeziSistem.connectionState && sanalSinav.MerkeziSistem))
                ? 'Bearer ' + merkeziSistem.remoteToken
                : 'Bearer ' + Globals.service.Token
        }
    }, [merkeziSistem.connectionState, merkeziSistem.remoteToken, sanalSinav.MerkeziSistem]);

    useEffect(() => {
        service.get(getSinavApi() + "merkezisinav/getsanalsinavsorulari/{merkeziSinavId}/{kitapcikTuru}".replace("{merkeziSinavId}", sanalSinav.MerkeziSinavId).replace("{kitapcikTuru}", sanalSinav.KitapcikTuru),
            {
                headers: getHeaders()
            }).then(res => {
                setSorular(res.data.Data);
            })
    }, [getHeaders, getSinavApi, sanalSinav.KitapcikTuru, sanalSinav.MerkeziSinavId])

    useEffect(() => {
        service.get(getSinavApi() + "merkezisinav/getCevap/{sanalSinavId}".replace("{sanalSinavId}", sanalSinav.SanalSinavId),
            {
                headers: getHeaders()
            }).then(res => {
                setCevap(res.data.Data);
            })
    }, [getHeaders, getSinavApi, sanalSinav.SanalSinavId]);

    useEffect(() => {
        let filterButtons = [];
        sorular.forEach((soru, index) => {
            if (filterButtons.filter(x => x.DersAdi === soru.DersAdi).length === 0) {
                filterButtons.push(soru);
            }
        });
        setFiltreler(filterButtons);
    }, [sorular])

    useEffect(() => {
        console.log("çalıştı");
        cevapDizisi = cevaplar.split("");
        for (var i = 0; i < sorular.length; i++) {
            if (cevapDizisi[i] !== "*") {
                if (cevapDizisi[i] === " ")
                    sorular[i].VerilenCevap = "Bos";
                else
                    sorular[i].VerilenCevap = cevapDizisi[i];
            }
        }
        let sorularX = sorular;
        setSorular(Object.assign([], sorularX));
    }, [cevaplar, sorular]);

    useEffect(() => {
        if (sanalSinav.SinavTuru === "TYT" | sanalSinav.SinavTuru === "AYT" | sanalSinav.SinavTuru === "9.SINIF" | sanalSinav.SinavTuru === "10.SINIF" | sanalSinav.SinavTuru === "11.SINIF") {
            setliseKontrol(true);
            setilkokulKontrol(true);
        }
        else if (sanalSinav.SinavTuru === "8.SINIF" | sanalSinav.SinavTuru === "7.SINIF" | sanalSinav.SinavTuru === "6.SINIF" | sanalSinav.SinavTuru === "5.SINIF" | sanalSinav.SinavTuru === "4.SINIF") {
            setliseKontrol(false);
            setilkokulKontrol(true);
        }
        else if (sanalSinav.SinavTuru === "3.SINIF" | sanalSinav.SinavTuru === "2.SINIF") {
            setliseKontrol(false);
            setilkokulKontrol(false);
        }
    }, [sanalSinav.SinavTuru])

    const soruRender = filtrenelenenSorular.map((soru, index) => {
        return (
            <React.Fragment>

                <div className="demo-inline-spacing">
                    <button className="btn btn-info btn-sm btn-round">&nbsp;{soru.SoruNo} .Soru&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "A" ? "red" : "white" }} onClick={async () => await SoruIsaretle(soru, 'A')}>&nbsp;A&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "B" ? "red" : "white" }} onClick={() => SoruIsaretle(soru, 'B')}>&nbsp;B&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "C" ? "red" : "white" }} onClick={() => SoruIsaretle(soru, 'C')}>&nbsp;C&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "D" ? "red" : "white", visibility: ilkokulKontrol ? "visible" : "hidden" }} onClick={() => SoruIsaretle(soru, 'D')}>&nbsp;D&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "E" ? "red" : "white", visibility: liseKontrol ? "visible" : "hidden" }} onClick={() => SoruIsaretle(soru, 'E')}>&nbsp;E&nbsp;</button>
                    <button className='btn btn-primary btn-sm btn-round' style={{ backgroundColor: soru.VerilenCevap === "Bos" ? "red" : "white" }} onClick={() => SoruIsaretle(soru, 'Bos')}>&nbsp;Boş&nbsp;</button>
                    {/* <button class="btn btn-warning btn-sm btn-round" ng-show="process"><img src="@Url.Content("~/assets/img/work.gif")" style="max-width:24px!important" />&nbsp;cevap işaretleniyor</button> */}
                </div>

            </React.Fragment>
        );
    });

    const filtreRender = filtreler.map((filtre, index) => {
        return (
            <React.Fragment>
                <button className="btn btn-sm btn-round"
                    style={{ border: "1px solid darkgrey", color: "white", backgroundColor: "darkgrey" }} onClick={() => {
                        filtrele(filtre);
                    }}>{filtre.DersAdi}</button>&nbsp;&nbsp;
            </React.Fragment>
        );
    });

    const filtrele = useCallback((filtre) => {
        if (filtre !== undefined) {
            soru.DersAdi = filtre.DersAdi;
            document.getElementById("filtreAdi").innerText = filtre.DersAdi.toUpperCase();
            setFiltrelenenSorular(sorular.filter(x => x.DersAdi === filtre.DersAdi).sort((a, b) => (a.SiraNo > b.SiraNo ? 1 : -1)));
        }
    }, [sorular]);

    useEffect(() => {
        if (!isBaslangic && sorular.length > 0) {
            filtrele(sorular.sort((a, b) => (a.SiraNo > b.SiraNo ? 1 : -1))[0]);
            isBaslangic = true;
        }

    }, [filtrele, sorular]);

    const SoruIsaretle = async (soru, isaret) => {
        let filtrelenenSorularX = Object.assign([], filtrenelenenSorular);
        for (var i = 0; i < filtrelenenSorularX.length; i++) {
            if (soru.SiraNo === filtrelenenSorularX[i].SiraNo) {
                filtrelenenSorularX[i].VerilenCevap = isaret;
            }
        }

        let sorularX = Object.assign([], sorular);
        for (var y = 0; y < sorularX.length; y++) {
            if (soru.SiraNo === sorularX[y].SiraNo) {
                sorularX[y].VerilenCevap = isaret;
            }
        }
        const data = {
            SanalSinavId: sanalSinav.SanalSinavId,
            Cevap: isaret,
            SiraNo: soru.SiraNo,
            KalanSure: ((saat * 60) + (dakika))
        }
        notify("cevap kaydediliyor...", "info", 1000);
        service.post(getSinavApi() + "merkezisinav/sanalsinavcevapkaydet", data,
            {
                headers: getHeaders()
            }).then(async () => {
                notify("cevap kaydedildi", "success", 1000);

                await setFiltrelenenSorular(filtrelenenSorularX);
                await setSorular(sorular);
                let copySanalsinav = sanalSinav;
                copySanalsinav.KalanSure = ((saat * 60) + (dakika));
                dispatch(setSanalSinavSystemInfo(copySanalsinav));
            })
            .catch((err) => {
                notify("Teknik bir hata oluştu," + err, "error", 5000)
            })

    }

    const tytGrupla = useCallback(() => {
        let sosyal = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Tarih").length; i++) {
            sorular.filter(x => x.DersSimge === "Tarih")[i].SoruNo = sosyal;
            sosyal++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Cogr").length; i++) {
            sorular.filter(x => x.DersSimge === "Cogr")[i].SoruNo = sosyal;
            sosyal++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Fels").length; i++) {
            sorular.filter(x => x.DersSimge === "Fels")[i].SoruNo = sosyal;
            sosyal++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "DinK").length; i++) {
            sorular.filter(x => x.DersSimge === "DinK")[i].SoruNo = sosyal;
            sosyal++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "SFels").length; i++) {
            sorular.filter(x => x.DersSimge === "SFels")[i].SoruNo = sosyal;
            sosyal++;
        }
        let fen = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Fizik").length; i++) {
            sorular.filter(x => x.DersSimge === "Fizik")[i].SoruNo = fen;
            fen++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Kimya").length; i++) {
            sorular.filter(x => x.DersSimge === "Kimya")[i].SoruNo = fen;
            fen++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Biyo").length; i++) {
            sorular.filter(x => x.DersSimge === "Biyo")[i].SoruNo = fen;
            fen++;
        }
        setSorular(sorular);
    }, [sorular]);

    const aytGrupla = useCallback(() => {
        let edebiyat = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Edb").length; i++) {
            sorular.filter(x => x.DersSimge === "Edb")[i].SoruNo = edebiyat;
            edebiyat++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Trh").length; i++) {
            sorular.filter(x => x.DersSimge === "Trh")[i].SoruNo = edebiyat;
            edebiyat++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Cog").length; i++) {
            sorular.filter(x => x.DersSimge === "Cog")[i].SoruNo = edebiyat;
            edebiyat++;
        }
        let sosyal2 = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Tar").length; i++) {
            sorular.filter(x => x.DersSimge === "Tar")[i].SoruNo = sosyal2;
            sosyal2++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Cgr").length; i++) {
            sorular.filter(x => x.DersSimge === "Cgr")[i].SoruNo = sosyal2;
            sosyal2++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Fel").length; i++) {
            sorular.filter(x => x.DersSimge === "Fel")[i].SoruNo = sosyal2;
            sosyal2++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Din").length; i++) {
            sorular.filter(x => x.DersSimge === "Din")[i].SoruNo = sosyal2;
            sosyal2++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Fls").length; i++) {
            sorular.filter(x => x.DersSimge === "Fls")[i].SoruNo = sosyal2;
            sosyal2++;
        }
        let fen = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Fiz").length; i++) {
            sorular.filter(x => x.DersSimge === "Fiz")[i].SoruNo = fen;
            fen++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Kim").length; i++) {
            sorular.filter(x => x.DersSimge === "Kim")[i].SoruNo = fen;
            fen++;
        }
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Biy").length; i++) {
            sorular.filter(x => x.DersSimge === "Biy")[i].SoruNo = fen;
            fen++;
        }
        setSorular(sorular);
    }, [sorular]);

    const matSirala = useCallback(() => {
        let mat = 1;
        for (var i = 0; i < sorular.filter(x => x.DersSimge === "Mtm").length; i++) {
            sorular.filter(x => x.DersSimge === "Mtm")[i].SoruNo = mat;
            mat++;
        }
        setSorular(sorular);
    }, [sorular]);

    useEffect(() => {
        if (sanalSinav.SinavTuru === "TYT") {
            tytGrupla();
        }
        if (sanalSinav.SinavTuru === "AYT" | sanalSinav.SinavTuru === "9.SINIF" | sanalSinav.SinavTuru === "10.SINIF" | sanalSinav.SinavTuru === "11.SINIF") {
            aytGrupla();
        }
        matSirala();
    }, [sanalSinav.SinavTuru, tytGrupla, aytGrupla, matSirala]);

    const timer = useCallback(() => {
        const myDate = new Date();
        myDate.setMinutes(myDate.getMinutes() + sanalSinav.KalanSure);
        var countDownDate = myDate;

        if (!isBaslangicCounter) {
            window.x = setInterval(function () {

                // Açılan sayfanın tarih ve zamanı
                var now = new Date().getTime();

                // farkı bul
                var distance = countDownDate - now;

                //saat,dakika ve zaman hesapla
                saat = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                dakika = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                saniye = Math.floor((distance % (1000 * 60)) / 1000);

                //ekrana değerleri bas
                document.getElementById("vakit").innerHTML = saat + " saat "
                    + dakika + " dakika " + saniye + " saniye ";

                // zaman bitince bir işlem yap
                if (distance < 0) {
                    clearInterval(window.x);
                    document.getElementById("vakit").innerHTML = "süreniz doldu";

                    const data = {
                        SanalSinavId: sanalSinav.SanalSinavId,
                        KitapcikTuru: sanalSinav.KitapcikTuru,
                        KurumKodu: sanalSinav.KurumKodu,
                        OgrenciNo: sanalSinav.OgrenciNo,
                        MerkeziSinavId: sanalSinav.MerkeziSinavId,
                        SinavTuru: sanalSinav.SinavTuru,
                        YayinId: sanalSinav.YayinId,
                        Ad: Globals.service.Kullanici.Adi,
                        Soyad: Globals.service.Kullanici.Soyadi,
                        Telefon: Globals.service.Kullanici.Tel

                    };
                    service.post(getSinavApi() + "merkezisinav/sanalsinavibitir", data,
                        {
                            headers: getHeaders()
                        }).then(async () => {
                            notify("Sınavınız değerlendirildi", "success", 1000);
                        })
                        .catch((err) => {
                            notify("Teknik bir hata oluştu," + err, "error", 5000)
                        })

                }
                isBaslangicCounter = true;
            }, 1000);
        }
        // Saniyede bir güncelleniyor

    }, [sanalSinav.KalanSure,
    sanalSinav.SanalSinavId,
    sanalSinav.KitapcikTuru,
    sanalSinav.KurumKodu,
    sanalSinav.OgrenciNo,
    sanalSinav.MerkeziSinavId,
    sanalSinav.SinavTuru,
    sanalSinav.YayinId,
        getHeaders,
        getSinavApi
    ]);
    useEffect(() => {
        timer();
    }, [timer])
    return (
        <React.Fragment>
            <div className="layout-wrapper layout-2">
                <div className="layout-inner">
                    <div className="layout-content">
                        <div className="container-fluid flex-grow-1 container-p-y">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="text-muted small mt-0 mb-4 d-block breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#!"><i className="feather icon-clock"></i></a></li>
                                            <li className="breadcrumb-item"><a href="#!"><span id="vakit"></span></a></li>
                                            <li className="breadcrumb-item"><a href="#!">&nbsp;</a></li>
                                            <li>
                                                <button className="btn btn-primary btn-sm btn-round" onClick={() => {
                                                    const data = {
                                                        SanalSinavId: sanalSinav.SanalSinavId,
                                                        KitapcikTuru: sanalSinav.KitapcikTuru,
                                                        KurumKodu: sanalSinav.KurumKodu,
                                                        OgrenciNo: sanalSinav.OgrenciNo,
                                                        MerkeziSinavId: sanalSinav.MerkeziSinavId,
                                                        SinavTuru: sanalSinav.SinavTuru,
                                                        YayinId: sanalSinav.YayinId,
                                                        Ad: Globals.service.Kullanici.Adi,
                                                        Soyad: Globals.service.Kullanici.Soyadi,
                                                        Telefon: Globals.service.Kullanici.Tel

                                                    };
                                                    service.post(getSinavApi() + "merkezisinav/sanalsinavibitir", data,
                                                        {
                                                            headers: getHeaders()
                                                        }).then(async () => {
                                                            notify("Sınavınız değerlendirildi", "success", 1000);
                                                        })
                                                        .catch((err) => {
                                                            notify("Teknik bir hata oluştu," + err, "error", 5000)
                                                        })
                                                }}>Denemeyİ Bİtİr</button>
                                            </li>
                                            <li style={{ display: process === false ? "none" : "" }}><img src="/images/work.gif" style={{ maxWidth: "24px", minHeight: "24px" }} alt="process" />&nbsp;işlem yapılıyor...</li>
                                        </ol>
                                    </div>
                                    <div className="text-muted small mt-0 mb-4 d-block breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#!"><b>Seçilen Ders Grubu:</b><span style={{ fontWeight: "bolder" }} id="filtreAdi"></span></a></li>
                                        </ol>
                                    </div>

                                    <div className="demo-inline-spacing">
                                        {filtreRender}
                                    </div>

                                    <div className="row" style={{ minHeight: "10px!important" }}>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {soruRender}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default SanalOptikPage;