import * as React from "react";
import { sinavApi, otomasyonApi, authApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Selection, Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
export default class Kullanici extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            drpBransVisible: false,
            kullaniciAktarVisible: false,
            toolBarDisabled: false
        };
        this.selectBoxBrans = null;
        this.gridYayin = null;
        this.gridMain = null;
        this.gridKurum = null;
        this.gridMerkezKullaniciYetki = null;
        this.formKullanici = null;
        this.formKullaniciAktar = null;
        this.selectBoxRol = null;
        this.satir = null;
        this.yayinlar = [];
        this.kurumlar = [];
        this.formData = {
        }

        this.formKullaniciAktarData = {
            Kullanicilar: [],
            GecmisDonemId: Globals.service.DonemId,
            DonemId: null
        };
        this.sinavKullanici = {};
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionGridMainChanged = this.onSelectionGridMainChanged.bind(this);
        this.onSelectionGridYayinChanged = this.onSelectionGridYayinChanged.bind(this);
        this.onSelectionGridKurumChanged = this.onSelectionGridKurumChanged.bind(this);
        this.onInitializedGridMain = this.onInitializedGridMain.bind(this);
        this.onInitializedGridKurum = this.onInitializedGridKurum.bind(this);
        this.onInitializedGridYayin = this.onInitializedGridYayin.bind(this);
        this.onInitializedSelectBoxRol = this.onInitializedSelectBoxRol.bind(this);
        this.onInitializedSelectBoxBrans = this.onInitializedSelectBoxBrans.bind(this);
        this.onInitializedFormKullanici = this.onInitializedFormKullanici.bind(this);

        this.duzenleClick = this.duzenleClick.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.popupGizle = () => {
            this.setState({ popup: false });
        };
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.arrayList = null;
        this.flag = false;
        this.secilenYayinlar = [];
        this.secilenKurumlar = [];
        this.KullaniciYayinYetki = [];
        this.MerkeziKurumYetki = [];

        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });

        this.kullaniciDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kullanici/getOtomasyonKullaniciEkrani',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.bransDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'geneltanim/listbransbykullanici',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.rolDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'rol/roller',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.kurumDataSource = new DataSource(
            {
                cacheRawData: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });


    }


    async popupAc() {
        return new Promise(resolve => {
            this.setState({
                popup: true
            });
            resolve(true);
        });
    }

    popupKullaniciAktarGizle() {
        this.setState({
            kullaniciAktarVisible: false
        });
    }

    async yeni() {
        this.formData = {
            Id: 0,
            Rol_Id: null,
            BransId: null,
            Deleted: false,
            RowVersion: 0,
            KullaniciAdi: null,
            Sifre: null,
            Email: null,
            Adi: null,
            Soyadi: null,
            SmsBilgi: false,
            MailBilgi: false,
            Tel: null,
            Gsm: null,
            Resim: null
        };
    }

    async dataClear() {
        this.secilenYayinlar = [];
        this.secilenKurumlar = [];
        this.KullaniciYayinYetki = [];
        this.MerkeziKurumYetki = [];
        await this.gridYayin.instance.clearSelection();
        await this.gridYayinSatirIptal();
        await this.gridKurum.instance.clearSelection();
        await this.gridKurumSatirIptal();
    }

    async getKullanici(id) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getKullanici/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    res.data.Data = res.data.Data[0];
                    this.formData = {
                        Id: res.data.Data.Id,
                        Rol_Id: res.data.Data.Rol_Id,
                        Deleted: res.data.Data.Deleted,
                        BransId: res.data.Data.BransId,
                        RowVersion: res.data.Data.RowVersion,
                        KullaniciAdi: res.data.Data.KullaniciAdi,
                        Sifre: res.data.Data.Sifre,
                        Email: res.data.Data.Email,
                        Adi: res.data.Data.Adi,
                        Soyadi: res.data.Data.Soyadi,
                        SmsBilgi: res.data.Data.SmsBilgi,
                        MailBilgi: res.data.Data.MailBilgi,
                        Tel: res.data.Data.Tel,
                        Gsm: res.data.Data.Gsm,
                        Resim: res.data.Data.Logo,
                        Logo: res.data.Data.Logo,
                        VarsayilanLogo: res.data.Data.VarsayilanLogo
                    };
                    this.selectBoxBrans.instance().option("value", this.formData.BransId);
                    resolve("Kullanıcı bilgileri getirildi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                }
            }).catch(() => {
                reject("Teknik bir hata oluştu,Kullanıcı bilgileri getirilemedi");
            });
        });
    }

    async getYayinYetkileriByKullanici(kullaniciId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getYayinYetkileriByKullanici/" + kullaniciId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    let arrayList = [];
                    res.data.Data.forEach((item, index) => {
                        arrayList.push(item.Yayin_Id)
                    });
                    this.gridYayin.instance.selectRows(arrayList);
                    resolve("Yayın yetkileri getirildi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                }
            }).catch(() => {
                reject("Teknik bir hata oluştu, yayın yetkileri getirilemedi");
            });
        });
    }

    async getMerkezKurumYetkileriByKullanici(kullaniciId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "kullanici/getMerkezKurumYetkileriByKullanici/" + kullaniciId, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
                .then(res => {
                    if (res.data.Success) {
                        var arrayList = [];
                        res.data.Data.forEach((item, index) => {
                            arrayList.push(item.Kurum.Id)
                        });
                        this.gridKurum.instance.selectRows(arrayList);
                        this.gridKurum.instance.refresh();
                        resolve("Kurum yetkileri verildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 500);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(() => {
                    reject("Teknik bir hata oluştu, kurum bilgileri getirilemedi");
                });
        });
    }

    async duzenleClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor") {
                alert("Üzgünüz bu işlem için yetkiniz yok");
            }
            else {
                await this.dataClear();
                this.formData = {};
                await this.getKullanici(satir.Id).catch(err => {
                    notify(err, "error", 5000);
                });

                await this.rolDataSource.load().done(async () => {
                    await this.getMerkezKurumYetkileriByKullanici(satir.Id).then(() => {

                    }).catch(error => {
                        notify(error, "error", 2000);
                    });
                });
                await this.getYayinYetkileriByKullanici(satir.Id).then(() => {
                }).catch(error => {
                    notify(error, "error", 2000);
                });


                await this.popupAc();

            }
        }
    }

    async sil(satir) {
        service.post(sinavApi + 'kullanici/KullaniciSil/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {

                    service.post(sinavApi + "kullanici/KullaniciSil/" + satir.Id,
                        null, {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    })
                        .then(res => {
                            if (res.data.Success) {
                                notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                this.gridMain.instance.refresh().then(() => {
                                    this.gridMainSatirIptal();
                                });
                            }
                            else {
                                notify(res.data.ErrorMessage, "error", 5000);
                            }
                        });

                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            });
    }

    async aktifYap(satir) {
        service.post(sinavApi + 'kullanici/AktifYap/' + satir.Id, null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh().then(() => {
                        this.gridMainSatirIptal();
                    });
                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    async pasifYap(satir) {
        service.post(sinavApi + 'kullanici/PasifYap/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh().then(() => {
                        this.gridMainSatirIptal();
                    });
                    notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    onUploaded(e) {
        if (e.value.length > 0) {
            this.formData.Resim = e.value[0].name;
            notify("Logonuz yüklendi", "success", 5000);
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Kullanıcı Aktar', icon: 'export',
                    onClick: async () => {
                        if (this.satir === null | this.satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                        }
                        else {
                            this.donemDataSource.filter(["Id", "<>", Globals.service.DonemId]);
                            await this.donemDataSource.load();
                            this.setState({
                                kullaniciAktarVisible: true
                            });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add',
                    onClick: async () => {
                        if (Globals.service.Rol === "Doküman" | Globals.service.Rol === "Rapor") {
                            alert("Üzgünüz bu işlem için yetkiniz yok");
                        }
                        else {

                            await this.yeni();
                            await this.dataClear();
                            await this.popupAc();
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit',
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.sil(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.pasifYap(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Döküman" | Globals.service.Rol === "Rapor" | Globals.service.Rol === "Döküman") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        this.aktifYap(satir);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionGridKurumChanged() {
        this.secilenKurumlar = this.gridKurum.instance.getSelectedRowsData();
    }

    onInitializedSelectBoxBrans(e) {
        this.selectBoxBrans = e.component;
    }



    onInitializedGridMain(e) {
        this.gridMain = e.component;
    }

    onInitializedGridYayin(e) {
        this.gridYayin = e.component;
    }

    onInitializedGridKurum(e) {
        this.gridKurum = e.component;
    }

    onInitializedSelectBoxRol(e) {
        this.selectBoxRol = e.component;
    }

    onInitializedFormKullanici(e) {
        this.formKullanici = e.component;
    }

    onSelectionGridMainChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onSelectionGridYayinChanged() {
        this.secilenYayinlar = this.gridYayin.instance.getSelectedRowsData();
    }

    async gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }

    async gridYayinSatirIptal() {
        if (this.gridYayin.instance !== null) {
            this.gridYayin.instance.clearSelection();
            this.gridYayin.instance.option("focusedRowIndex", -1);
        }
    }

    async gridKurumSatirIptal() {
        if (this.gridKurum.instance !== null) {
            this.gridKurum.instance.clearSelection();
            this.gridKurum.instance.option("focusedRowIndex", -1);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Kullanıcılar"}</h4>
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.kullaniciDataSource}
                                remoteOperations={true}
                                repaintChangesOnly={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={true}
                                keyExpr="Id"
                                paging={{
                                    enabled: true
                                }}
                                pager={{
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                    showInfo: true
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitialized={this.onInitializedGridMain}
                                onSelectionChanged={this.onSelectionGridMainChanged}
                                ref={(ref) => this.gridMain = ref}

                            >
                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Id" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" />
                                <Column dataField={'Rol'} caption="Rol" />
                                <Column dataField={'KullaniciAdi'} caption="K. Adı" />
                                <Column dataField={'Email'} caption="Email" />
                                <Column dataField={'Adi'} caption="Adı" />
                                <Column dataField={'Soyadi'} caption="Soyadı" />
                                <Column dataField={'Sifre'} caption="Şifre" />
                                <Column dataField={'Gsm'} caption="Gsm" visible={false} />
                                <Column dataField={'Tel'} caption="Tel" visible={false} />
                                <Column dataField={'SmsBilgi'} caption="Sms Bilgi" dataType="boolean" />
                                <Column dataField={'MailBilgi'} caption="Mail Bilgi" dataType="boolean" />
                            </DataGrid>
                        </div>
                    </div>
                </div>


                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    title="Otomasyon Kullanıcıları" width={"100%"} height={"100%"}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        const validateStatus = this.formKullanici.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            if (this.formData.BransId === null | this.formData.BransId === undefined) {
                                                this.formData.BransId = 0;
                                            }
                                            this.MerkeziKurumYetki = [];
                                            this.KullaniciYayinYetki = [];
                                            this.secilenYayinlar.forEach((item, index) => {
                                                this.KullaniciYayinYetki.push(
                                                    {
                                                        Yayin: { Id: item.Id },
                                                        Kullanici: { Id: 0 }
                                                    }
                                                );
                                            });

                                            if (this.formData.Resim !== undefined) {
                                                this.formData.Logo = this.formData.Resim;
                                            }

                                            this.MerkeziKurumYetki = [];
                                            this.secilenKurumlar.forEach((item, index) => {
                                                this.MerkeziKurumYetki.push({
                                                    Kurum: { Id: item.Id }
                                                });
                                            });

                                            this.sinavKullanici = {
                                                Kullanici: this.formData,
                                                KullaniciYayinYetki: this.KullaniciYayinYetki,
                                                MerkeziKurumYetki: this.MerkeziKurumYetki
                                            };

                                            service.post(sinavApi + 'kullanici/otomasyonkullanicisave',
                                                this.sinavKullanici,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                }
                                            )
                                                .then(res => {
                                                    if (res.data.Success) {

                                                        this.gridMain.instance.refresh().then(() => {
                                                            this.setState({
                                                                popup: false
                                                            });
                                                        });
                                                    }
                                                    else {
                                                        this.sinavKullanici = {
                                                            Kullanici: this.formData,
                                                            KullaniciYayinYetki: this.KullaniciYayinYetki,
                                                            MerkeziKurumYetki: this.MerkeziKurumYetki
                                                        };
                                                        notify(res.data.ErrorMessage, 'error', 5000);
                                                    }

                                                }).catch(err => {
                                                    if (err.response) {
                                                        var resError = JSON.parse(err.response.request.response);
                                                        var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                        var dictionary = Object.keys(resError.errors);
                                                        dictionary.forEach((satir, index) => {
                                                            mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                        });
                                                        mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                        mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                        mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                        alert(mesaj);
                                                    } else {
                                                        notify(err, "error", 5000);
                                                    }
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'KApat', icon: 'close'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData} onInitialized={this.onInitializedFormKullanici} ref={(ref) => this.formKullanici = ref}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Kullanıcı Bilgileri">
                                        <SimpleItem dataField={"Email"} isRequired={true}>
                                            <Label text="Email" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KullaniciAdi"} isRequired={true}>
                                            <Label text="K. Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Sifre"} isRequired={true} editorType="dxTextBox">
                                            <Label text="Şifre" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Adi"} isRequired={true}>
                                            <Label text="Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Soyadi"} isRequired={true}>
                                            <Label text="Soyadı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Tel"}>
                                            <Label text="Telefon" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Gsm"}>
                                            <Label text="Gsm" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SmsBilgi"} editorType="dxCheckBox">
                                            <Label text="Sms Bilgi" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MailBilgi"} editorType="dxCheckBox">
                                            <Label text="Mail Bilgi" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-grup" colCount={3}>
                                        <SimpleItem dataField={"Rol_Id"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.rolDataSource,
                                                remoteOperations: false,
                                                searchEnabled: false,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxRol,
                                                ref: (ref) => { this.selectBoxRol = ref },
                                                onValueChanged: (e) => {

                                                    var rol = this.selectBoxRol.instance().option("text");
                                                    if (rol === "SubeOgretmen") {
                                                        this.selectBoxBrans.instance().option("value", this.formData.BransId);
                                                        this.setState({
                                                            drpBransVisible: true
                                                        });
                                                    }
                                                    else {
                                                        this.selectBoxBrans.instance().option("value", null);
                                                        this.setState({
                                                            drpBransVisible: false
                                                        });
                                                    }
                                                }
                                            }}  >
                                            <Label text="Rol" />
                                        </SimpleItem>
                                        <SimpleItem
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.bransDataSource,
                                                remoteOperations: false,
                                                searchEnabled: false,
                                                disabled: !this.state.drpBransVisible,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxBrans,
                                                ref: (ref) => { this.selectBoxBrans = ref },
                                                onValueChanged: (e) => {
                                                    this.formData.BransId = e.value;
                                                }
                                            }}  >
                                            <Label text="Branş" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"File"}
                                            editorType="dxFileUploader"
                                            editorOptions={{
                                                uploadUrl: sinavApi + "kullanici/LogoUpload",
                                                uploadMode: 'instantly',
                                                uploadHeaders: this.headers,
                                                name: 'myFile',
                                                onValueChanged: this.onUploaded
                                            }}>
                                            <Label text="Logo seçiniz" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"VarsayilanLogo"} editorType="dxCheckBox">
                                            <Label text="Varsayılan Logo" />
                                        </SimpleItem>
                                    </GroupItem>

                                    <GroupItem cssClass="first-group" colCount={2} caption="Yetkiler">
                                        <SimpleItem>
                                            <h4>Yayın Yetkileri</h4>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.yayinDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                onInitialized={this.onInitializedGridYayin}
                                                onSelectionChanged={this.onSelectionGridYayinChanged}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridYayin = ref}
                                            >

                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Selection mode="multiple"
                                                    selectAllMode="allPages"
                                                    showCheckBoxesMode="onClick" />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'Adi'} caption="Yayın" />
                                            </DataGrid>
                                        </SimpleItem>
                                        <SimpleItem>
                                            <h4>Kurum Yetkileri</h4>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.kurumDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={false}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={false}
                                                onSelectionChanged={this.onSelectionGridKurumChanged}
                                                onInitialized={this.onInitializedGridKurum}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridKurum = ref}
                                            >

                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Selection mode="multiple"
                                                    selectAllMode="allPages"
                                                    showCheckBoxesMode="onClick" />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'ilAdi'} caption="İl" />
                                                <Column dataField={'ilceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" dataType="string" />
                                            </DataGrid>
                                        </SimpleItem>

                                    </GroupItem>


                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"50%"} height={"50%"}
                    visible={this.state.kullaniciAktarVisible}
                    title={"Kullanıcı Aktarma İşlemi"}
                    toolbarItems={
                        [

                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kullanıcı Aktar',
                                    type: 'success',
                                    hint: 'Kullanıcı Aktar',
                                    icon: 'check',
                                    toolBarDisabled: false,
                                    onClick: async (e) => {
                                        let kullaniciData = [];
                                        this.gridMain.instance.getSelectedRowKeys().forEach((row) => {
                                            kullaniciData.push({
                                                KullaniciId: parseInt(row)
                                            })
                                        });
                                        console.log(kullaniciData);
                                        this.formKullaniciAktarData.Kullanicilar = kullaniciData;
                                        let result = confirm("Kullanıcıyı aktarmak istiyor musunuz?", "Kullanıcı yeni döneme aktarma onayı");
                                        result.then((dialogResult) => {
                                            if (dialogResult === true) {
                                                this.setState({
                                                    toolBarDisabled: true
                                                });
                                                service.post(sinavApi + "kullanici/otomasyonkullaniciaktar", this.formKullaniciAktarData, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            await this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            await this.popupKullaniciAktarGizle();
                                                            alert(res.data.Data, "success", 5000);
                                                        } else {
                                                            this.setState({
                                                                toolBarDisabled: false
                                                            });
                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        this.setState({
                                                            toolBarDisabled: false
                                                        });
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.popupKullaniciAktarGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formKullaniciAktarData} onInitialized={(e) => {
                                    this.formKullaniciAktar = e.component;
                                }} ref={(ref) => this.formKullaniciAktar = ref}>
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.donemDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi"
                                        }}>
                                            <Label text="Dönem Seçiniz" />
                                            <RequiredRule message="Dönem alanı zorunludur" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>

        );
    }
}
