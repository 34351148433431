import React from "react";
import DataGrid, {
    Export, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, GroupPanel, Summary

} from 'devextreme-react/data-grid';
import { otomasyonApi } from '../Util';
import Globals from '../Globals';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Form, { GroupItem, TabbedItem, TabPanelOptions, Tab, SimpleItem, Label } from 'devextreme-react/form';
export default class OtomasyonAdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.gridAdminKurumRaporu = null;
        this.gridSinavGonderenKurumlar = null;
        this.gridSinavBasariDurum = null;
        this.formDashboard = null;
        this.sinavFilter = { merkeziSinavId: 0 };
        this.adminKurumRaporuDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/getadminkurumraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.sinavGonderenKurumDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/getadminmerkezisinavgonderenkurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.merkeziSinavDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "MerkeziSinavId",
                store: createStore({
                    key: "MerkeziSinavId",
                    loadUrl: otomasyonApi + 'dashboard/getadminmerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.kurumBasariDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.sinavFilter,
                    loadUrl: otomasyonApi + 'dashboard/getadminkurumbasarilari',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Form colCount={1} width={"100%"} ref={(ref) => this.formDashboard = ref}>
                                <GroupItem cssClass="first-group" colCount={1}>
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} />
                                        <Tab title="Kurum Öğrenci Sayıları">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.adminKurumRaporuDataSource}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                keyExpr="Id"
                                                paging={{
                                                    pageSize: 20
                                                }}
                                                pager={{
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                                    showInfo: true
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                text: "Yenile",
                                                                onClick: (e) => {
                                                                    this.gridAdminKurumRaporu.instance.refresh();
                                                                }
                                                            }
                                                        });
                                                }}
                                                ref={(ref) => this.gridAdminKurumRaporu = ref}
                                            >
                                                <Export enabled={true} fileName={'KurumRaporu'} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <HeaderFilter visible={true}></HeaderFilter>
                                                <FilterPanel visible={true}></FilterPanel>
                                                <FilterRow visible={true} applyFilter={'auto'} />
                                                <GroupPanel visible={true}></GroupPanel>
                                                <Paging defaultPageSize={15} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                <Column dataField={'SinifSeviyesiSira'} caption="Sınıf Seviyesi" visible={true} groupIndex={0} customizeText={(row) => {
                                                    if (row.value === 0) {
                                                        return "ANAOKULU"
                                                    }
                                                    else if (row.value === 4) {
                                                        return "4. SINIF"
                                                    }
                                                    else if (row.value === 5) {
                                                        return "5. SINIF"
                                                    }
                                                    else if (row.value === 6) {
                                                        return "6. SINIF"
                                                    }
                                                    else if (row.value === 7) {
                                                        return "7. SINIF"
                                                    }
                                                    else if (row.value === 8) {
                                                        return "8. SINIF"
                                                    }
                                                    else if (row.value === 9) {
                                                        return "9. SINIF"
                                                    }
                                                    else if (row.value === 10) {
                                                        return "10. SINIF"
                                                    }
                                                    else if (row.value === 11) {
                                                        return "11. SINIF"
                                                    }
                                                    else if (row.value === 12) {
                                                        return "12. SINIF"
                                                    }
                                                    else if (row.value === 13) {
                                                        return "MEZUN"
                                                    }
                                                }} />
                                                <Column dataField={'DonemAdi'} caption="Dönem" />
                                                <Column dataField={'IlAdi'} caption="İl" />
                                                <Column dataField={'IlceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                                <Column dataField={'KayitliOgrenciSayisi'} caption="Öğrenci Sayısı" />
                                                <Summary>
                                                    <GroupItem
                                                        column="KayitliOgrenciSayisi"
                                                        summaryType="sum"
                                                    />

                                                </Summary>
                                            </DataGrid>
                                        </Tab>
                                        <Tab title="Kurum Sınav Gönderen Öğrenci Sayıları">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.sinavGonderenKurumDataSource}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                keyExpr="Id"
                                                paging={{
                                                    pageSize: 20
                                                }}
                                                pager={{
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                                    showInfo: true
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                text: "Yenile",
                                                                onClick: (e) => {
                                                                    this.gridSinavGonderenKurumlar.instance.refresh();
                                                                }
                                                            }
                                                        });
                                                }}
                                                ref={(ref) => this.gridSinavGonderenKurumlar = ref}
                                            >
                                                <Export enabled={true} fileName={'SinavGonderenKurumlar'} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <HeaderFilter visible={true}></HeaderFilter>
                                                <FilterPanel visible={true}></FilterPanel>
                                                <FilterRow visible={true} applyFilter={'auto'} />
                                                <GroupPanel visible={true}></GroupPanel>
                                                <Paging defaultPageSize={15} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                <Column dataField={'IlAdi'} caption="İl" />
                                                <Column dataField={'IlceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                                <Column dataField={'OgrenciSayisi'} caption="Öğrenci Sayısı" />
                                            </DataGrid>
                                        </Tab>
                                        <Tab title="Sınava Göre Başarı Durumları">
                                            <Form colCount={1} width={"100%"}>
                                                <GroupItem cssClass="first-group" colCount={1}>
                                                    <SimpleItem dataField={"MerkeziSinavId"}
                                                        editorType="dxSelectBox"
                                                        editorOptions={{
                                                            dataSource: this.merkeziSinavDataSource,
                                                            searchEnabled: true,
                                                            displayExpr: "MerkeziSinavAdi",
                                                            showClearButton: true,
                                                            valueExpr: "MerkeziSinavId",
                                                            onValueChanged: (e) => {
                                                                this.sinavFilter.merkeziSinavId = parseInt(e.value);
                                                                this.gridSinavBasariDurum.instance.refresh();
                                                            }
                                                        }}>
                                                        <Label text="Sınav Seçiniz" />
                                                    </SimpleItem>
                                                </GroupItem>
                                                <GroupItem cssClass="first-group" colCount={1}>
                                                    <SimpleItem>
                                                        <DataGrid
                                                            showBorders={true}
                                                            showRowLines={true}
                                                            dataSource={this.kurumBasariDataSource}
                                                            columnHidingEnabled={true}
                                                            filterPanel={{ visible: true }}
                                                            filterRow={{ visible: true }}
                                                            columnAutoWidth={true}
                                                            selection={{ mode: 'single' }}
                                                            autoNavigateToFocusedRow={true}
                                                            focusedRowEnabled={true}
                                                            hoverStateEnabled={true}
                                                            keyExpr="Id"
                                                            paging={{
                                                                pageSize: 20
                                                            }}
                                                            pager={{
                                                                showPageSizeSelector: true,
                                                                allowedPageSizes: [5, 10, 50, 75, 100],
                                                                showInfo: true
                                                            }}
                                                            onToolbarPreparing={(e) => {
                                                                e.toolbarOptions.items.unshift(
                                                                    {
                                                                        location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                            type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                            text: "Yenile",
                                                                            onClick: (e) => {
                                                                                this.gridSinavBasariDurum.instance.refresh();
                                                                            }
                                                                        }
                                                                    });
                                                            }}
                                                            ref={(ref) => this.gridSinavBasariDurum = ref}
                                                        >
                                                            <Export enabled={true} fileName={'SinavBasariDurumu'} allowExportSelectedData={false} />
                                                            <ColumnChooser enabled={true} />
                                                            <LoadPanel enabled={true} />
                                                            <HeaderFilter visible={true}></HeaderFilter>
                                                            <FilterPanel visible={true}></FilterPanel>
                                                            <FilterRow visible={true} applyFilter={'auto'} />
                                                            <GroupPanel visible={true}></GroupPanel>
                                                            <Paging defaultPageSize={15} />
                                                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                            <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                            <Column dataField={'IlAdi'} caption="İl" />
                                                            <Column dataField={'IlceAdi'} caption="İlçe" />
                                                            <Column dataField={'KurumAdi'} caption="Kurum" />
                                                            <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                                                            <Column dataField={'SinavAdi'} caption="Sınav" />
                                                            <Column dataField={'PuanTuruKodu'} caption="Puan Türü Kodu" />
                                                            <Column dataField={'Puan'} caption="Puan" />
                                                        </DataGrid>
                                                    </SimpleItem>
                                                </GroupItem>
                                            </Form>
                                        </Tab>
                                    </TabbedItem>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}