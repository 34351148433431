import React from 'react';
import Form, {
    GroupItem,
    SimpleItem,
    Label,
    RequiredRule
} from 'devextreme-react/form';
import service from 'axios';
import Globals from '../Globals';
import notify from 'devextreme/ui/notify';
import { authApi, otomasyonApi, sinavApi } from '../Util';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { confirm, alert } from 'devextreme/ui/dialog';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, { Column, Paging, Pager } from 'devextreme-react/data-grid';
import { connect } from 'react-redux';
import { setKepsSystemShortCodes } from "../redux/actions/kepsSystemAction";
import { Button } from 'devextreme-react';
const mapDispatchToProps = (dispatch) => {
    return {
        setShortCodes: (data) => dispatch(setKepsSystemShortCodes(data))
    }
}


let kisayollar = [];

class AyarlarPage extends React.Component {

    constructor(props) {
        super(props);
        this.formSifre = null;
        this.formApi = null;
        this.formSmsApi = null;
        this.satir = null;
        this.dataGridKisayol = null;
        this.formShortCut = null;
        this.onInitializedFormSifre = this.onInitializedFormSifre.bind(this);
        this.onInitializedFormApiSifre = this.onInitializedFormApiSifre.bind(this);
        this.onInitializedGridKisayol = this.onInitializedGridKisayol.bind(this);
        this.onInitializedFormShortCut = this.onInitializedFormShortCut.bind(this);
        this.onInitializedFormSmsApi = this.onInitializedFormSmsApi.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleApiSubmit = this.handleApiSubmit.bind(this);
        this.handleSmsApiSubmit = this.handleSmsApiSubmit.bind(this);
        this.handleShortCutSubmit = this.handleShortCutSubmit.bind(this);
        this.formProfilSifre = {
            Sifre1: "",
            Sifre2: "",
            Mail: "",
            Tel: "",
            Gsm: ""
        };
        this.formProfilApiSifre = {
            ApiKullaniciAdi: "",
            ApiSifre: ""
        };

        this.formShortCutData = {
            Icon: "",
            EkranId: null,

        };
        this.shortCodeDatasource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "KurumId",
                    loadUrl: sinavApi + 'kullanici/getyetkiliekranlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.formSmsApiData = {
            SmsMusteriNo: "",
            SmsKullaniciAdi: "",
            SmsSifre: "",
            SmsOrjinator: "",
            SmsUrl: "",
            SmsSaglayici: -1
        };

        this.dataSourceFavoriler = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kullanici/listfavoriler',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.getSmsSettings();
    }

    onInitializedFormSifre(e) {
        this.formSifre = e.component;
    }

    onInitializedFormApiSifre(e) {
        this.formApi = e.component;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    text: "Kısayolu Sil",
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Kısayolunuz silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(sinavApi + "kullanici/favorisil/" + satir.Id,
                                        null,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(async (res) => {
                                            if (res.data.Success) {
                                                await this.dataGridKisayol.instance.refresh();
                                                await this.dataSourceFavoriler.load().then(async () => {
                                                    kisayollar = [];
                                                    this.dataSourceFavoriler.items().forEach((satir) => {
                                                        kisayollar.push({
                                                            widget: 'dxButton', location: 'before',
                                                            options: {
                                                                icon: satir.Icon,
                                                                hint: satir.Hint,
                                                                onClick: () => {
                                                                    window.location.href = "/" + satir.Url;
                                                                }
                                                            }
                                                        });
                                                    });
                                                    this.props.setShortCodes(kisayollar);
                                                })
                                            }
                                            else
                                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                        });
                                }
                            });
                        }

                    }
                }
            })
    }

    onInitializedFormShortCut(e) {
        this.formShortCut = e.component;
    }

    onInitializedGridKisayol(e) {
        this.dataGridKisayol = e.component;
    }

    onInitializedFormSmsApi(e) {
        this.formSmsApi = e.component;
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    handleSubmit(e) {
        const validateStatus = this.formSifre.instance.validate();
        if (!validateStatus.isValid) {
            e.preventDefault();
            return;
        }
        else {
            service.post(authApi + "auth/sifremidegistir", this.formProfilSifre,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify(res.data.Data, "success", 5000);
                    } else {
                        notify("Teknik bir hata oluştu, hata ayrıntısı," + res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(err => {
                    notify("Teknik bir hata oluştu, hata ayrıntısı," + err, "error", 5000);
                })
            e.preventDefault();
        }

    }

    handleApiSubmit(e) {
        const validateStatus = this.formApi.instance.validate();
        if (!validateStatus.isValid) {
            e.preventDefault();
            return;
        }
        else {
            service.post(authApi + "auth/apibilgisikaydet", this.formProfilApiSifre,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify(res.data.Data, "success", 5000);
                    } else {
                        notify("Teknik bir hata oluştu, hata ayrıntısı," + res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(err => {
                    notify("Teknik bir hata oluştu, hata ayrıntısı," + err, "error", 5000);
                })
            e.preventDefault();
        }

    }

    handleShortCutSubmit(e) {
        const validateStatus = this.formShortCut.instance.validate();
        if (!validateStatus.isValid) {
            e.preventDefault();
            return;
        }
        else {
            service.post(sinavApi + "kullanici/kisayolekle", this.formShortCutData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(async (res) => {
                    if (res.data.Success) {
                        notify("Ekran kısayolu eklendi", "success", 5000);
                        await this.dataGridKisayol.instance.refresh();
                        await this.dataSourceFavoriler.load().then(async () => {
                            kisayollar = [];
                            this.dataSourceFavoriler.items().forEach((satir) => {
                                kisayollar.push({
                                    widget: 'dxButton', location: 'before',
                                    options: {
                                        icon: satir.Icon,
                                        hint: satir.Hint,
                                        onClick: () => {
                                            window.location.href = "/" + satir.Url;
                                        }
                                    }
                                });
                            });

                            this.props.setShortCodes(kisayollar);

                        })
                    } else {
                        notify("Teknik bir hata oluştu, hata ayrıntısı," + res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(err => {
                    notify("Teknik bir hata oluştu, hata ayrıntısı," + err, "error", 5000);
                })
            e.preventDefault();
        }

    }

    handleSmsApiSubmit(e) {
        const validateStatus = this.formSmsApi.instance.validate();
        if (!validateStatus.isValid) {
            e.preventDefault();
            return;
        }
        else {
            service.post(sinavApi + "kurum/kurumsmsbilgisikaydet", this.formSmsApiData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify(res.data.Data, "success", 5000);
                    } else {
                        notify("Teknik bir hata oluştu, hata ayrıntısı," + res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(err => {
                    notify("Teknik bir hata oluştu, hata ayrıntısı," + err, "error", 5000);
                });
            e.preventDefault();
        }
    }



    getSmsSettings() {
        service.get(sinavApi + "kurum/getsmsayarlari", {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        }).then(res => {
            if (res.data.Success) {
                const data = res.data.Data;
                this.formSmsApiData = {
                    SmsMusteriNo: data.SmsMusteriNo,
                    SmsKullaniciAdi: data.SmsKullaniciAdi,
                    SmsSifre: data.SmsSifre,
                    SmsOrjinator: data.SmsOrjinator,
                    SmsUrl: data.SmsUrl,
                    SmsSaglayici: data.SmsSaglayici
                };
                this.setState({});
            } else {
                notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            }
        })
            .catch(err => {
                notify("Teknik bir sunucu hatası oluştu," + err, "error", 5000);
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            <Button type='danger' icon='runner' text='Kapat' onClick={() => {
                                window.history.back();
                            }}></Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form id="form" onSubmit={this.handleSubmit}>
                                <Form
                                    onInitialized={this.onInitializedFormSifre}
                                    formData={this.formProfilSifre}
                                    readOnly={false}
                                    showColonAfterLabel={true}
                                    showValidationSummary={true}
                                    ref={(ref) => this.formSifre = ref}
                                >
                                    <GroupItem cssClass="first-group" caption="Şifre Değişikliği" colCount={2}>
                                        <SimpleItem dataField="Sifre1" editorType="dxTextBox" editorOptions={{
                                            mode: "password",
                                            defaultValue: ""
                                        }}>
                                            <Label text="Yeni şifre" />
                                            <RequiredRule message="Yeni şifre alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="Sifre2" editorType="dxTextBox" editorOptions={{
                                            mode: "password",
                                            defaultValue: ""
                                        }}>
                                            <Label text="Yeni şifre tekrar" />
                                            <RequiredRule message="Yeni şifre tekrar alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="Email" editorType="dxTextBox">
                                            <Label text="Email" />
                                            <RequiredRule message="Email alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="Tel" editorType="dxTextBox">
                                            <Label text="Tel" />
                                        </SimpleItem>
                                        <SimpleItem dataField="Gsm" editorType="dxTextBox">
                                            <Label text="Gsm" />
                                        </SimpleItem>
                                        <SimpleItem editorType="dxButton" editorOptions={{
                                            text: 'Değişiklikleri Kaydet',
                                            type: 'success',
                                            useSubmitBehavior: true
                                        }}>

                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form id="form" onSubmit={this.handleApiSubmit}>
                                <Form
                                    onInitialized={this.onInitializedFormApiSifre}
                                    formData={this.formProfilApiSifre}
                                    readOnly={false}
                                    visible={
                                        Globals.service.Rol === "GenelMerkez" ||
                                            Globals.service.Rol === "Sube" ||
                                            Globals.service.Rol === "SubeMuhasebe" ||
                                            Globals.service.Rol === "SubeKayitKabul" ||
                                            Globals.service.Rol === "SubeKayitMuhasebe" ? true : false}
                                    showColonAfterLabel={true}
                                    showValidationSummary={true}
                                    ref={(ref) => this.formApi = ref}
                                >
                                    <GroupItem cssClass="first-group" caption="Api Bilgileri(Merkezi sınav indirme için kullanılır)" colCount={2}>
                                        <SimpleItem dataField="ApiKullaniciAdi" editorType="dxTextBox" editorOptions={{
                                            defaultValue: ""
                                        }}>
                                            <Label text="Api kullanıcı adı" />
                                            <RequiredRule message="Api kullanıcı adı alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="ApiSifre" editorType="dxTextBox" editorOptions={{
                                            mode: "password",
                                            defaultValue: ""
                                        }}>
                                            <Label text="Api şifre" />
                                            <RequiredRule message="Api şifre alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem editorType="dxButton" editorOptions={{
                                            text: 'Bilgilerimi kaydet',
                                            type: 'success',
                                            useSubmitBehavior: true
                                        }}>

                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form id="form" onSubmit={this.handleSmsApiSubmit}>
                                <Form
                                    onInitialized={this.onInitializedFormSmsApi}
                                    formData={this.formSmsApiData}
                                    readOnly={false}
                                    visible={Globals.service.Rol === "Sube" ? true : false}
                                    showColonAfterLabel={true}
                                    showValidationSummary={true}
                                    ref={(ref) => this.formSmsApi = ref}
                                >
                                    <GroupItem cssClass="first-group" caption="Sms Bilgileri" colCount={2}>
                                        <SimpleItem dataField="SmsSaglayici" editorType="dxSelectBox" editorOptions={{
                                            dataSource: window.ayarlar.SmsProivders,
                                            displayExpr: "Name",
                                            valueExpr: "Value"
                                        }}>
                                            <Label text="Sms Sağlayıcı" />
                                            <RequiredRule message="Sms Sağlayıcı alanı zorunludur." />
                                        </SimpleItem>
                                        <SimpleItem dataField="SmsMusteriNo" editorType={"dxNumberBox"}>
                                            <Label text="Müşteri No" />
                                        </SimpleItem>
                                        <SimpleItem dataField="SmsKullaniciAdi">
                                            <Label text="Kullanıcı Adı" />
                                            <RequiredRule message="Kullanıcı adı  zorunludur." />
                                        </SimpleItem>
                                        <SimpleItem dataField="SmsSifre" editorType="dxTextBox" editorOptions={{
                                            mode: "password",
                                            defaultValue: ""
                                        }}>
                                            <Label text="Şifre" />
                                            <RequiredRule message="Şifre alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="SmsOrjinator">
                                            <Label text="Orjinator" />
                                            <RequiredRule message="Orjinator  zorunludur." />
                                        </SimpleItem>
                                        <SimpleItem dataField="SmsUrl">
                                            <Label text="Sağlayıcı Url" />
                                        </SimpleItem>
                                        <SimpleItem editorType="dxButton" editorOptions={{
                                            text: 'Bilgilerimi kaydet',
                                            type: 'success',
                                            useSubmitBehavior: true
                                        }}>

                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form id="form" onSubmit={this.handleShortCutSubmit}>
                                <Form
                                    onInitialized={this.onInitializedFormShortCut}
                                    formData={this.formShortCutData}
                                    readOnly={false}
                                    showColonAfterLabel={true}
                                    showValidationSummary={true}
                                    ref={(ref) => this.formShortCut = ref}
                                >
                                    <GroupItem cssClass="first-group" caption="Ekran Kısa Yolları" colCount={2}>
                                        <SimpleItem dataField="Icon" editorType="dxSelectBox" editorOptions={{
                                            dataSource: window.ayarlar.DevextremeIcons,
                                            valueExpr: "Value",
                                            displayExpr: "Name",
                                            itemTemplate: (itemData, itemIndex, itemElement) => {
                                                return "<div style='font-size:12pt'><i style='font-size:12pt;color:green' class='dx-icon-" + itemData.Name + "'></i> " + itemData.Name + "</div>";
                                            },
                                        }}>
                                            <Label text="İkon" />
                                            <RequiredRule message="İkon alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="EkranId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.shortCodeDatasource,
                                            valueExpr: "Id",
                                            displayExpr: "EkranAdi"
                                        }}>
                                            <Label text="Ekran" />
                                            <RequiredRule message="Ekran alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem editorType="dxButton" editorOptions={{
                                            text: 'Kısayollarıma Ekle',
                                            type: 'success',
                                            useSubmitBehavior: true
                                        }}>

                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" caption="Eklenen Kısa Yollar">
                                        <SimpleItem>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.dataSourceFavoriler}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                columnFixing={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                selection={{ mode: 'single' }}
                                                keyExpr="Id"
                                                paging={{
                                                    pageSize: 20
                                                }}
                                                pager={{
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                                    showInfo: true
                                                }}
                                                ref={(ref) => this.dataGridKisayol = ref}
                                                onInitialized={this.onInitializedGridKisayol}
                                                onToolbarPreparing={this.onToolbarPreparing}
                                                onSelectionChanged={this.onSelectionChanged}
                                                width="100%"
                                            >

                                                <Paging defaultPageSize={15} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'Hint'} caption="Ekran" hidingPriority="1" />
                                                <Column dataField={'Url'} caption="Href" hidingPriority="2" />
                                                <Column dataField={'Icon'} caption="İkon" hidingPriority="3" />

                                            </DataGrid>
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                    <div className="row" style={{ display: Globals.service.Rol === 'Admin' ? "" : "none" }}>
                        <div className="col-md-12">
                            <form id="formKullaniciAc">
                                <Form>
                                    <GroupItem cssClass="first-group" caption="Admin Patch İşlemleri" colCount={2}>

                                        <SimpleItem editorType="dxButton" editorOptions={{
                                            text: 'Kullanıcı Tablosunda Olan Kullanıcı Rol Tablosunda Olmayan Kullanıcıları Düzenle',
                                            type: 'success',
                                            onClick: () => {
                                                service.post(otomasyonApi + "kayitkabul/otomasyonogrencikullanicilariduzenle", null, {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                })
                                                    .then(async (res) => {
                                                        if (res.data.Success) {
                                                            alert("Düzenleme işlemi gerçekleştirildi.", "success", 5000);
                                                        } else {

                                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        if (err.response) {
                                                            var resError = JSON.parse(err.response.request.response);
                                                            var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                            var dictionary = Object.keys(resError.errors);
                                                            dictionary.forEach((satir, index) => {
                                                                mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                            });
                                                            mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                            mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                            mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                            alert(mesaj);
                                                            // client received an error response (5xx, 4xx)
                                                        } else if (err.request) {
                                                            // client never received a response, or request never left
                                                        } else {
                                                            // anything else
                                                        }
                                                    });
                                            }
                                        }}>

                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default connect(null, mapDispatchToProps)(AyarlarPage);