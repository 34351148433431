import React from 'react';
import service from 'axios';
import Globals from '../Globals.js';
import auth from "../Auth";
import themes from 'devextreme/ui/themes';
import { Link } from 'react-router-dom';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { authApi } from '../Util';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.onInitializedSelectBoxDonem = this.onInitializedSelectBoxDonem.bind(this);
    this.selectBoxDonem = null;
    this.donemDataSource = new DataSource(
      {
        loadMode: "raw",
        key: "Id",
        store: createStore({
          key: "Id",
          loadUrl: authApi + 'auth/getdonemler',
        })
      });
    try {
      localStorage.removeItem("oturum");
      localStorage.removeItem("session");
      localStorage.removeItem("initialized");
    } catch (error) {
    }
    const tema = localStorage.getItem("tema");//kullanıcı teması
    if (tema != null)
      themes.current(tema);

    document.title = window.ayarlar.sistemAdi;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formData = {
      KullaniciAdi: "",
      Sifre: "",
      Donem: { Id: null },
      GirisTuru: "Web"
    };

    this.buttonOptions = {
      text: 'Giriş',
      type: 'success',
      useSubmitBehavior: true
    };

    this.passwordOptions = {
      mode: 'password'
    };

    this.donemDataSource.load().then(res => {
      if (res.length > 0) {
        this.selectBoxDonem.instance().option("value", res[0].Id);
      }
    })

  }

  onInitializedSelectBoxDonem(e) {
    this.selectBoxDonem = e.component;
  }
  handleSubmit(e) {
    service.post(authApi + "Auth/authenticate"
      , this.formData)
      .then(res => {
        auth.login(() => {
          var localStoregeData = {};
          res.data.AdSoyad = res.data.Kullanici.Adi + " " + res.data.Kullanici.Soyadi;
          res.data.Menu = res.data.Kullanici.KullaniciMenuler;
          res.data.Rol = res.data.Kullanici.Rol;
          res.data.Id = res.data.Kullanici.Id;
          res.data.DonemId = this.formData.Donem.Id;
          res.data.DonemAdi = res.data.Kullanici.DonemAdi;
          localStorage.setItem("oturum", JSON.stringify(res.data));
          // Globals.AdSoyad = localStoregeData.Kullanici.Adi + " " + localStoregeData.Kullanici.Soyadi;
          // Globals.menuler = localStoregeData.Kullanici.KullaniciMenuler;
          Globals.service = localStoregeData;
          // Globals.Token = localStoregeData.Token;
          // Globals.Rol = localStoregeData.Kullanici.Rol;
          if (Globals.service.Rol === "SubeKayitKabul") {
            this.props.history.push("/kayitkabuldashboard");
          }
          else if (Globals.service.Rol === "SubeMuhasebe") {
            this.props.history.push("/kayitkabuldashboard");
          }
          else if (Globals.service.Rol === "SubeKayitMuhasebe") {
            this.props.history.push("/kayitkabuldashboard");
          }
          else if (Globals.service.Rol === "Sube") {
            this.props.history.push("/kayitkabuldashboard");
          }
          else if (Globals.service.Rol === "GenelMerkez") {
            this.props.history.push("/kayitkabuldashboard");
          }
          else if (Globals.service.Rol === "SubeVeli") {
            this.props.history.push("/velidashboard");
          }
          else if (Globals.service.Rol === "SubeOgrenci") {
            this.props.history.push("/ogrencidashboard");
          }
          else if (Globals.service.Rol === "SubeOgretmen" && this.formData.GirisTuru === "Mobil") {
            this.props.history.push("/mobile/index");
          }
          else if (Globals.service.Rol === "AdminOtomasyon") {
            this.props.history.push("/otomasyondashboard");
          }
          else {
            this.props.history.push("/home");
          }

        });
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          notify(error.response.data, 'error', 5000);
        } else if (error.request) {
          notify(error.request, 'error', 5000);
        } else {
          notify("Teknik bir hat oluştu", 'error', 5000);
        }

      });
    e.preventDefault();
  };

  getFirmaLink() {
    if (window.ayarlar.firmaLinkiGoster) {
      return <Link target="_blank" to={"//www.arkyazilim.com"} > Arkyazılım </Link>;
    } else {
      return "";
    }
  }

  getOgrenciKayitLink() {
    if (window.ayarlar.ogrenciKayitLinkiGoster) {
      return <Link target="_self" to={"/register"} > Öğrenci Kayıt ekranı için tıklayınız</Link>;
    } else {
      return "";
    }
  }

  getAppLink() {
    if (window.ayarlar.projeturu === "otomasyon") {
      return (
        <React.Fragment>
          <div className='row'>
            <div className='col-md-2'>
              <a href="https://apps.apple.com/tr/app/keps-mobi-l/id6446959268?l=tr" target='_blank' rel='noopener noreferrer'>
                <img src='/images/appstore.svg' alt="App Store' den indirin" style={{ border: "0px" }}></img>
              </a>
            </div>
            <div className='col-md-3'>
              <a href="https://play.google.com/store/apps/details?id=com.orion.kepsmaui" target='_blank' rel='noopener noreferrer'>
                <img src='/images/googleplay.svg' alt="Google Play' den indirin"></img>
              </a>
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return "";
    }
  }

  getTgDegerlendirmeErkani() {
    if (window.ayarlar.tgLink) {
      return (
        <React.Fragment>
          {
            <div style={{ fontSize: "24px" }} dangerouslySetInnerHTML={{ __html: window.ayarlar.tgLinkMetin }}></div>
          }
        </React.Fragment>
      );
    } else {
      return <React.Fragment>

      </React.Fragment>
    }
  }

  render() {
    return (
      <div id="container" className='container'>
        <form id="form" onSubmit={this.handleSubmit} style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Form
            formData={this.formData}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
          >
            <GroupItem caption="Kullanıcı Girişi">
              <SimpleItem dataField="KullaniciAdi" editorType="dxTextBox">
                <Label text="Kullanıcı Adı" />
                <RequiredRule message="Kullanıcı adı alanı zorunludur" />
              </SimpleItem>
              <SimpleItem dataField="Sifre" editorType="dxTextBox" editorOptions={this.passwordOptions}>
                <Label text="Şifre" />
                <RequiredRule message="Şifre alanı zorunludur" />
              </SimpleItem>
              <SimpleItem dataField="Donem.Id" editorType="dxSelectBox" editorOptions={{
                dataSource: this.donemDataSource,
                valueExpr: "Id",
                displayExpr: "Adi",
                onInitialized: this.onInitializedSelectBoxDonem,
                ref: (ref) => { this.selectBoxDonem = ref },

              }}>
                <Label text="Dönem Seçiniz" />
                <RequiredRule message="Dönem alanı zorunludur" />
              </SimpleItem>
              <SimpleItem dataField="GirisTuru" editorType="dxSelectBox" editorOptions={{
                dataSource: [{ Id: "Web", Value: "Web" },
                { Id: "Mobil", Value: "Mobil" }],
                valueExpr: "Id",
                displayExpr: "Value"

              }}>
                <Label text="Giriş Türü" />
              </SimpleItem>
            </GroupItem>
            <ButtonItem horizontalAlignment="left"
              buttonOptions={this.buttonOptions}
            />
          </Form>
          <br /><br />
          {this.getTgDegerlendirmeErkani()}
          <br /><br />
          {this.getOgrenciKayitLink()}
          <br /><br />
          {this.getFirmaLink()}
          <br /><br />
          <img src={"/" + window.ayarlar.sistemLogo} alt="Logomuz"></img>
          <br></br>
          {this.getAppLink()}

        </form>
      </div>
    );
  }
}
export default LoginPage;