import React from 'react';
import service from 'axios';
import Globals from '../Globals.js';
import auth from "../Auth";
import themes from 'devextreme/ui/themes';
import notify from 'devextreme/ui/notify';
import { authApi } from '../Util';
class EduAsistVeliLoginPage extends React.Component {
    constructor(props) {
        super(props);
        //KurumId/OgrenciKullaniciId/KullaniciId/Sifre
        const kurumId = props.match.params.KurumId;
        const ogrenciKullaniciId = props.match.params.OgrenciKullaniciId;
        const kullaniciId = props.match.params.KullaniciId;
        const sifre = "RXNhcyBpdGliYXLEsXlsYSBzw7Z6bMO8IGFub25pbSBoYWxrIGVkZWJpeWF0xLEgw7xyw7xuw7wgb2xhbiBtYXNhbA==";
        if (sifre !== props.match.params.Sifre) {
            notify("Kullanıcı adı veya şifre hatalı");
        }
        else {
            try {
                localStorage.removeItem("oturum");
                localStorage.removeItem("session");
                localStorage.removeItem("initialized");
            } catch (error) {
            }
            const tema = localStorage.getItem("tema");//kullanıcı teması
            if (tema != null)
                themes.current(tema);

            document.title = window.ayarlar.sistemAdi;
            this.formData = {
                KullaniciAdi: kurumId + "-" + ogrenciKullaniciId + "-" + kullaniciId + "-veli",
                Sifre: props.match.params.Sifre
            };
            if (sifre !== props.match.params.Sifre) {
                notify("Kullanıcı adı veya şifre hatalı");
            }
            else {
                service.post(authApi + "Auth/authenticate"
                    , this.formData)
                    .then(res => {
                        auth.login(() => {

                            var localStoregeData = {};
                            res.data.AdSoyad = res.data.Kullanici.Adi + " " + res.data.Kullanici.Soyadi;
                            res.data.Menu = res.data.Kullanici.KullaniciMenuler;
                            res.data.Rol = res.data.Kullanici.Rol;
                            res.data.Id = res.data.Kullanici.Id;
                            localStorage.setItem("oturum", JSON.stringify(res.data));
                            // Globals.AdSoyad = localStoregeData.Kullanici.Adi + " " + localStoregeData.Kullanici.Soyadi;
                            // Globals.menuler = localStoregeData.Kullanici.KullaniciMenuler;
                            Globals.service = localStoregeData;
                            // Globals.Token = localStoregeData.Token;
                            // Globals.Rol = localStoregeData.Kullanici.Rol;
                            if (Globals.service.Rol === "SubeKayitKabul") {
                                this.props.history.push("/kayitkabuldashboard");
                            }
                            else if (Globals.service.Rol === "SubeMuhasebe") {
                                this.props.history.push("/kayitkabuldashboard");
                            }
                            else if (Globals.service.Rol === "SubeKayitMuhasebe") {
                                this.props.history.push("/kayitkabuldashboard");
                            }
                            else if (Globals.service.Rol === "Sube") {
                                this.props.history.push("/kayitkabuldashboard");
                            }
                            else if (Globals.service.Rol === "GenelMerkez") {
                                this.props.history.push("/kayitkabuldashboard");
                            }
                            else if (Globals.service.Rol === "SubeVeli") {
                                this.props.history.push("/velidashboard");
                            }
                            else if (Globals.service.Rol === "SubeOgrenci") {
                                this.props.history.push("/ogrencidashboard");
                            }
                            else {
                                this.props.history.push("/home");
                            }

                        });
                    })
                    .catch((error) => {
                        // Error 😨
                        if (error.response) {
                            notify(error.response.data.message, 'error', 5000);
                        } else if (error.request) {
                            notify(error.request, 'error', 5000);
                        } else {
                            notify("Teknik bir hat oluştu", 'error', 5000);
                        }

                    });
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <b>Otomatik giriş yapılıyor...</b>
            </React.Fragment>
        );
    }
}
export default EduAsistVeliLoginPage;