import * as React from "react";
import { otomasyonApi, sinavApi } from '../../../Util';
import Globals from '../../../Globals';
import { alert, confirm } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { Button } from 'devextreme-react/button';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    ColumnFixing, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, RequiredRule, Export

} from 'devextreme-react/data-grid';
export default class GiderKayit extends React.Component {
    constructor(props) {
        super(props);
        this.formKurumGider = null;
        this.gridKurumGider = null;
        this.satir = null;
        this.onInitializedFormKurumGider = this.onInitializedFormKurumGider.bind(this);
        this.onInitializedGridKurumGider = this.onInitializedGridKurumGider.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.gridMainSatirIptal = this.gridMainSatirIptal.bind(this);
        var tx = new Date();
        tx.setHours(tx.getHours() + 3);
        this.giderDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'gider/list',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.formData = {
            KurumId: null,
            Gider: { Id: null },
            Tutar: 0,
            Tarih: tx.toJSON(),
            Aciklama: ""
        }
    }
    onInitializedFormKurumGider(e) {
        this.formKurumGider = e.component;
    }

    onInitializedGridKurumGider(e) {
        this.gridKurumGider = e.component;
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    gridMainSatirIptal() {
        if (this.gridKurumGider.instance !== null) {
            this.gridKurumGider.instance.clearSelection();
            this.gridKurumGider.instance.option("focusedRowIndex", -1);
        }
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Gider bilgisini silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(otomasyonApi + "gider/sil/" + satir.Id,
                                        null,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                this.gridKurumGider.instance.refresh();
                                                this.gridMainSatirIptal();
                                            }
                                            else
                                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                        });
                                }
                            });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridKurumGider.instance.refresh();
                    }
                }
            }
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Gider İşlemleri>>Gider Kayıt"}</h4>
                            <Form formData={this.formData} onInitialized={this.onInitializedFormKurumGider} ref={(ref) => this.formKurumGider = ref}>
                                <GroupItem cssClass="first-group" colCount={3} caption="Gider Bilgileri">
                                    <SimpleItem dataField={"KurumId"} isRequired={true} editorType={"dxSelectBox"}
                                        editorOptions={{
                                            showClearButton: true,
                                            dataSource: new DataSource({
                                                store: createStore({
                                                    key: "Id",
                                                    loadUrl: sinavApi + 'kurum/kurumlar',
                                                    onBeforeSend: (method, ajaxOptions) => {
                                                        ajaxOptions.headers = {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        };
                                                    }
                                                })
                                            }),
                                            remoteOperations: true,
                                            searchEnabled: true,
                                            searchMode: "contains",
                                            displayExpr: "KurumAdi",
                                            valueExpr: "Id"
                                        }}
                                    >
                                        <Label text="Kurum"></Label>
                                        <RequiredRule message="Kurum alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"Gider.Id"} isRequired={true} editorType={"dxSelectBox"}
                                        editorOptions={{
                                            showClearButton: true,
                                            dataSource: new DataSource({
                                                store: createStore({
                                                    key: "Id",
                                                    loadUrl: otomasyonApi + 'geneltanim/listgidertanimbykullanici',
                                                    onBeforeSend: (method, ajaxOptions) => {
                                                        ajaxOptions.headers = {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        };
                                                    }
                                                })
                                            }),
                                            remoteOperations: true,
                                            searchEnabled: true,
                                            searchMode: "contains",
                                            displayExpr: "Adi",
                                            valueExpr: "Id"
                                        }}
                                    >
                                        <Label text="Gider"></Label>
                                        <RequiredRule message="Gider alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"Tarih"} editorType={"dxDateBox"}
                                        editorOptions={{
                                            value: new Date(),
                                            onValueChanged: (e) => {
                                                var t = new Date(e.value);
                                                t.setHours(t.getHours() + 3);
                                                this.formData.Tarih = t;
                                            }
                                        }}
                                    >
                                        <Label text="Tarih"></Label>
                                        <RequiredRule message="Tarih alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"Tutar"} editorOptions={{
                                        format: "#,##0.##"
                                    }} editorType={"dxNumberBox"}>
                                        <Label text="Tutar" />
                                        <RequiredRule message="Tutar alanı zorunludur" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"Aciklama"} editorType={"dxTextBox"}>
                                        <Label text="Açıklama" />
                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                            <Button
                                width={120}
                                text="Ekle"
                                type="success"
                                onClick={() => {
                                    const validateStatus = this.formKurumGider.instance.validate();
                                    if (!validateStatus.isValid) {
                                        return;
                                    }
                                    else {

                                        service.post(otomasyonApi + "gider/ekle", this.formData, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(async (res) => {
                                                if (res.data.Success) {
                                                    notify("Gider kaydı eklendi", "success", 5000);
                                                    this.gridKurumGider.instance.refresh();

                                                } else {
                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                            .catch(err => {
                                                if (err.response) {
                                                    var resError = JSON.parse(err.response.request.response);
                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                    var dictionary = Object.keys(resError.errors);
                                                    dictionary.forEach((satir, index) => {
                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                    });
                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                    alert(mesaj);
                                                    // client received an error response (5xx, 4xx)
                                                } else if (err.request) {
                                                    // client never received a response, or request never left
                                                } else {
                                                    // anything else
                                                }
                                            })
                                    }
                                }}
                            />
                            <DataGrid
                                id="grdKurumGider"
                                showBorders={true}
                                showRowLines={true}
                                remoteOperations={true}
                                dataSource={this.giderDataSource}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onInitialized={this.onInitializedGridKurumGider}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                key={"Id"}
                                selection={
                                    {
                                        mode: 'single'
                                    }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                ref={(ref) => this.gridKurumGider = ref}
                            >
                                <Export
                                    enabled={true}
                                    fileName={'KurumGiderler'}
                                    allowExportSelectedData={false}
                                    texts={
                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                    }
                                />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={false} allowExporting={false} />
                                <Column dataField={'Gerceklestiren'} caption="Gerçekleştiren" dataType={"string"} />
                                <Column dataField={'GiderAdi'} caption="Gider" dataType={"string"} />
                                <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                                <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} format={"#,##0.##"} />
                                <Column dataField={'Aciklama'} caption="Açıklama" dataType={"string"} />
                            </DataGrid>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
