import React from 'react';
import service from 'axios';
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Form, { SimpleItem, Label } from 'devextreme-react/form';
export default class MobilKutuphane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Dosyalar: []
        }
        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadUrl: otomasyonApi + 'common/listsistemyayinlari',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.formMobilKutuphane = null;
        this.formData = {
            Yayin: { Id: null }
        };
    }

    renderLink(data) {
        if (!!data.Icon) {
            return (
                <React.Fragment>
                    <a href={data.Dosya}><img src={data.Icon} alt={data.Metin} style={{ width: "250px", height: "83px", minWidth: "250px", minHeight: "83px", maxWidth: "250px", maxHeight: "83px" }} /></a>
                </React.Fragment>
            );
        }
        else if (!!data.Dosya && (data.Icon === null | data.Icon === undefined)) {
            return (
                <React.Fragment>
                    <a href={data.Dosya} alt={data.Metin} target="_blank" rel="noopener noreferrer">{data.Metin}</a>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    {data.Metin}
                </React.Fragment>
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>İnteraktif İşlemler>>Mobil Kütüphane"}</h4>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: "20px" }}>
                        <div className='col-md-12'>
                            <Form formData={this.formData} ref={(ref) => this.formMobilKutuphane = ref}
                                showValidationSummary={true}
                            >
                                <SimpleItem dataField={"Yayin.Id"} isRequired={true}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.yayinDataSource,
                                        keyExpr: "Id",
                                        searchEnabled: true,
                                        displayExpr: "Adi",
                                        valueExpr: "Id",
                                        showClearButton: true,
                                        onInitialized: this.onInitializedSelectBoxYayin,
                                        ref: (ref) => { this.selectBoxYayin = ref },
                                        onValueChanged: (e) => {
                                            service.get(otomasyonApi + "common/listmobilkutuphane/" + e.value, {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }).then(res => {
                                                if (res.data.Success) {
                                                    this.setState({
                                                        Dosyalar: res.data.Data
                                                    })
                                                } else {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                                .catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                        }
                                    }}>
                                    <Label text="Yayın" />

                                </SimpleItem>
                            </Form>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {
                                this.state.Dosyalar.map((dosya, index) => {
                                    return (
                                        <div className='row' key={index}>
                                            {
                                                this.renderLink(dosya)
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};