import React from "react";
import OgrenciHataRapor from "./OgrenciHataRapor";
import service from 'axios';
import Globals from "../Globals";
import { karneApi } from "../Util";
import notify from "devextreme/ui/notify";
export default class VeliDersButon extends React.Component {
    constructor() {
        super();
        this.state = {
            data: []
        };

    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.props.Dersler.map((item, index) =>
                            <span> <button
                                style={{ left: "10px" }}
                                key={index.toString()}
                                className="btn btn-danger btn-sm btn-round"
                                onClick={(e) => {
                                    var data = {
                                        DersKodu: item.DersSimge,
                                        OgrenciId: this.props.OgrenciId
                                    };
                                    service.post(karneApi + "ogrenci/getveliogrencihataraporu", data,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                this.setState({
                                                    data: res.data.Data
                                                })
                                            } else {
                                                notify(res.data.ErrorMessage, "error", 5000);
                                            }
                                        })
                                        .catch(err => {
                                            notify(err, "error", 5000);
                                        });
                                }}
                            >    {item.DersAdi}    </button> </span>
                        )}
                    </div>
                </div>
                <div className="row" style={{ minHeight: "10px" }}>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <OgrenciHataRapor Data={this.state.data}></OgrenciHataRapor>
                    </div>
                </div>
            </React.Fragment>
        )

    }
}