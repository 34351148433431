/**
 * Contains global isomorphic session.
 */
export default class Globals {

    static isInitialized = false;
    constructor(props) {
        var isInitilazedLocalStorege = localStorage.getItem("initialized");
        if (isInitilazedLocalStorege === null | isInitilazedLocalStorege === undefined | isInitilazedLocalStorege === "{}") {
            localStorage.setItem("initialized", false);
        }

    }

    static isInitialized = localStorage.getItem("initialized");

    static session = {};

    static reset() {
        this.isInitialized = false;
        this.session = {};
    }

    static init(session) {
        // if (this.isInitialized) {
        //     throw Error("Globals is already initialized.");
        // }

        // this.session = (session || {
        //     public: {}, private: {}
        // });
        localStorage.setItem("initialized", true);
        this.isInitialized = localStorage.getItem("initialized");
    }

    static throwIfNotInitialized() {
        if (!this.isInitialized) {
            window.location.href = "/login";
            // throw Error("'Globals' is not initialized. You have to call 'Globals.init' before.");
        }
    }

    static getSession() {
        this.throwIfNotInitialized();
        var session = localStorage.getItem("session");
        if (session == null | session === undefined | session === "{}") {
            localStorage.setItem("session", JSON.stringify(this.session));
        }
        else {
            this.session = JSON.parse(localStorage.getItem("session"));
        }
        return this.session;
    }

    static setSession(session) {
        this.throwIfNotInitialized();
        // Update session object by the new data.
        this.session = { ...this.session, ...session };
    }

    static get service() {
        let currentSession = this.getSession();
        if (currentSession) {
            let publicSession = currentSession.public;
            if (publicSession) {
                return publicSession.service;
            } else {
                window.location.href = "/login"
                //throw Error("Globals: public session was not initialized.")
            }
        }
        throw Error("Globals: current session was not initialized.")
    }

    static set service(service) {
        var localStoregeData = localStorage.getItem("oturum");
        if (localStoregeData !== null | localStoregeData !== undefined | localStoregeData !== "{}") {
            service = JSON.parse(localStoregeData);
            this.setSession({ public: { service } });
        }
        else {
            this.setSession({ public: { service } });
        }

    }

    static get isAuthenticated() {
        var localStoregeData = localStorage.getItem("oturum");
        if (localStoregeData !== null | localStoregeData !== undefined | localStoregeData !== "{}") {
            return true
        }
        else {
            return false;
        }

    }
}