import React from 'react';
import { sinavApi, errorMessageWithKonum, otomasyonApi, yayinGatewayUrl, authApi, karneApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, Column, Editing, Paging, Pager, FilterRow, Grouping, GroupPanel, RequiredRule
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert, custom } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { LoadPanel } from 'devextreme-react/load-panel';
import { connect } from 'react-redux';
import { setRemoteSystemInfo } from '../../redux/actions/mainSystemAction';
import { setKepsSystemShortCodes } from '../../redux/actions/kepsSystemAction';
import { setSanalSinavSystemInfo } from '../../redux/actions/sanalSinavSystemAction';
const position = { of: '#sinav' };
const mapDispatchToProps = (dispatch) => {
    return {
        setRemoteSystem: (data) => dispatch(setRemoteSystemInfo(data)),
        setShortCodes: (data) => dispatch(setKepsSystemShortCodes(data)),
        setSanalSystemInfo: (data) => dispatch(setSanalSinavSystemInfo(data)),
    }
}
const mapStateToProps = state => ({
    remoteSystem: state.remoteSystem.info,
    shortCodes: state.kepsSystem.shortCodes
});
class SinavTanimi extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupMerkeziForm: false,
            popupSinavAktar: false,
            remoteToken: "",
            btnDwonloadDisabled: false,
            btnSinavAktarDisabled: false,
            loadPanelVisible: false,
            popupDonemSecim: false,
            merkeziSinavGoster: false,
            btnKopyalaState: false
        };
        this.popupForm = null;
        this.formMerkez = null;
        this.formSinavAktar = null;
        this.formDonemSecimi = null;
        this.gridMain = null;
        this.selectBoxYayin = null;
        this.selectBoxKullanici = null;
        this.selectBoxRemoteYayin = null;
        this.selectBoxLocalYayin = null;
        this.selectBoxSinavTuru = null;
        this.selectBoxRemoteSinavTuru = null;
        this.selectBoxLocalSinavTuru = null;
        this.selectBoxRemoteMerkeziSinav = null;
        this.selectBoxOptikForm = null;
        this.selectBoxKaynakKurum = null;
        this.selectBoxHedefKurum = null;
        this.satir = null;
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxKullanici = this.onInitializedSelectBoxKullanici.bind(this);
        this.onInitializedSelectBoxRemoteYayin = this.onInitializedSelectBoxRemoteYayin.bind(this);
        this.onInitializedSelectBoxLocalYayin = this.onInitializedSelectBoxLocalYayin.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.onInitializedSelectBoxRemoteSinavTuru = this.onInitializedSelectBoxRemoteSinavTuru.bind(this);
        this.onInitializedSelectBoxLocalSinavTuru = this.onInitializedSelectBoxLocalSinavTuru.bind(this);
        this.onInitializedSelectBoxOptikForm = this.onInitializedSelectBoxOptikForm.bind(this);
        this.onInitializedSelectBoxRemoteKurum = this.onInitializedSelectBoxRemoteKurum.bind(this);
        this.onInitializedSelectBoxLocalKurum = this.onInitializedSelectBoxLocalKurum.bind(this);
        this.onInitializedFormMerkeziSinav = this.onInitializedFormMerkeziSinav.bind(this);
        this.onInitializedSelectBoxRemoteMerkeziSinav = this.onInitializedSelectBoxRemoteMerkeziSinav.bind(this);
        this.onInitializedFormSinavAktar = this.onInitializedFormSinavAktar.bind(this);
        this.onInitializedFormDonemSecimi = this.onInitializedFormDonemSecimi.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridMainSatirIptal = this.gridMainSatirIptal.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.yeniEkleClick = this.yeniEkleClick.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.aktifYapClick = this.aktifYapClick.bind(this);
        this.pasifYapClick = this.pasifYapClick.bind(this);
        this.silClick = this.silClick.bind(this);
        this.sil = this.sil.bind(this);
        this.aktifYap = this.aktifYap.bind(this);
        this.pasifYap = this.pasifYap.bind(this);
        this.showLoadPanel = this.showLoadPanel.bind(this);
        this.hideLoadPanel = this.hideLoadPanel.bind(this);
        this.mainDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: (this.state.merkeziSinavGoster ? yayinGatewayUrl + "sinav/" : sinavApi) + 'merkezisinav/sinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.popupMerkeziFormGizle = async () => {
            this.setState({
                popupMerkeziForm: false
            });
        }

        this.popupSinavAktarGizle = async () => {
            this.setState({
                popupSinavAktar: false
            });
        }

        this.popupGizle = async () => {
            await this.gridMainSatirIptal();
            await this.setState(
                {
                    popup: false,
                    popupSinavIndirme: false
                }
            );
        };

        this.popupAc = () => {
            this.setState(
                {
                    popup: true,
                    loadingText: "",
                    toolbarDisabled: true
                });
        };

        this.popupSinavAktarAc = () => {
            this.setState(
                {
                    popupSinavAktar: true
                });
        };

        this.popupDonemEkraniAc = () => {
            this.setState({
                popupDonemSecim: true
            });
        }

        this.popupDonemEkraniGizle = () => {
            this.setState({
                popupDonemSecim: false
            });
        }

        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinRemoteDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: yayinGatewayUrl + 'sinav/yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                        };
                    }
                })
            });

        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinRemotefilter = { YayinId: 0 };
        this.sinavTuruRemoteDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.yayinRemotefilter,
                    loadUrl: yayinGatewayUrl + 'sinav/ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                        };
                    }
                })
            });

        this.sinavTuruFilter = {
            YayinId: 0,
            SinavTuruId: 0
        };

        this.optikFormDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'optikform/sgeoptikformlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruRemoteFilter = {
            YayinId: 0,
            SinavTuruId: 0,
            donemId: 0
        };

        this.merkeziSinavRemoteDataSource = new DataSource(
            {
                filter: ["Degerlendirilebilir", "=", true],
                paginate: false,
                store: createStore({
                    loadParams: this.sinavTuruRemoteFilter,
                    loadUrl: yayinGatewayUrl + 'sinav/merkezisinav/sgemerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                        };
                    }
                })
            });

        this.kurumDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.kurumRemoteDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: yayinGatewayUrl + 'sinav/kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                        };
                    }
                })
            });

        this.formMerkeziSinavData = {
            KaynakKurumId: null,
            HedefKurumId: null,
            KaynakYayinId: null,
            HedefYayinId: null,
            KaynakSinavTuruId: null,
            HedefSinavTuruId: null,
            MerkeziSinavId: null
        }

        this.formSinavAktarData = {
            DonemId: null,
            KurumId: null,
            KullaniciId: null,
            SinavId: null
        };

        this.formDonemData = {
            DonemId: null
        }

        this.donemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: authApi + 'auth/getdonemler',
                })
            });

        this.remoteDonemDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: yayinGatewayUrl + 'auth/auth/getdonemler',
                })
            });

        this.kullaniciFilter = {
            DonemId: 0
        };

        this.kullaniciDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadParams: this.kullaniciFilter,
                    loadUrl: sinavApi + 'kullanici/getdonemkullanicilari',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        console.log(this.props.remoteSystem.remoteToken);
    }


    //#region grid fonksiyonları

    onInitialized(e) {
        this.gridMain = e.component;
    }

    onInitializedFormMerkeziSinav(e) {
        this.formMerkez = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }

    onInitializedSelectBoxRemoteYayin(e) {
        this.selectBoxRemoteYayin = e.component;
    }

    onInitializedSelectBoxLocalYayin(e) {
        this.selectBoxLocalYayin = e.component;
    }

    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedSelectBoxRemoteSinavTuru(e) {
        this.selectBoxRemoteSinavTuru = e.component;
    }

    onInitializedSelectBoxLocalSinavTuru(e) {
        this.selectBoxLocalSinavTuru = e.component;
    }

    onInitializedSelectBoxRemoteMerkeziSinav(e) {
        this.selectBoxRemoteMerkeziSinav = e.component;
    }
    onInitializedSelectBoxOptikForm(e) {
        this.selectBoxOptikForm = e.component;
    }

    onInitializedFormSinavAktar(e) {
        this.formSinavAktar = e.component;
    }

    onInitializedFormDonemSecimi(e) {
        this.formDonemSecimi = e.component;
    }

    onInitializedSelectBoxKullanici(e) {
        this.selectBoxKullanici = e.component;
    }

    onInitializedSelectBoxLocalKurum(e) {
        this.selectBoxKaynakKurum = e.component;
    }

    onInitializedSelectBoxRemoteKurum(e) {
        this.selectBoxHedefKurum = e.component;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Sınavı Döneme Aktar',
                    width: 150,
                    text: this.state.merkeziSinavGoster ? "Merkezi Sınavlar" : "Local Sınavlar",
                    onClick: (e) => {

                        if (!this.state.merkeziSinavGoster) {

                            this.mainDataSource = new DataSource(
                                {
                                    paginate: true,
                                    pageSize: 100,
                                    store: createStore({
                                        key: "Id",
                                        loadUrl: yayinGatewayUrl + 'sinav/merkezisinav/sinavlar',
                                        onBeforeSend: (method, ajaxOptions) => {
                                            ajaxOptions.headers = {
                                                'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                                            };
                                        }
                                    })
                                });
                            this.gridMain.instance.option("dataSource", this.mainDataSource);
                            this.gridMain.instance.refresh();


                        }
                        else {

                            this.mainDataSource = new DataSource(
                                {
                                    paginate: true,
                                    pageSize: 100,
                                    store: createStore({
                                        key: "Id",
                                        loadUrl: sinavApi + 'merkezisinav/sinavlar',
                                        onBeforeSend: (method, ajaxOptions) => {
                                            ajaxOptions.headers = {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            };
                                        }
                                    })
                                });
                            this.gridMain.instance.option("dataSource", this.mainDataSource);
                            this.gridMain.instance.refresh();
                        }
                        this.setState({
                            merkeziSinavGoster: !this.state.merkeziSinavGoster
                        });
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Sınavı Döneme Aktar',
                    icon: 'exportselected',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            this.popupSinavAktarAc();
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Genel Soru Analizi',
                    icon: 'orderedlist',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            this.showLoadPanel();
                            service.get(karneApi + "analiz/genelsoruanalizi/" + satir.Id, {
                                timeout: 1200000,
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }

                            })
                                .then(res => {
                                    if (res.data.Success) {
                                        this.hideLoadPanel();
                                        alert("Genel soru analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile genel soru analizini bilgisayarınıza indiriniz</a>", "Bilgi");

                                    }
                                    else {
                                        this.hideLoadPanel();
                                        notify(res.data.ErrorMessage, 'error', 5000);
                                    }
                                })
                                .catch(err => {
                                    this.hideLoadPanel();
                                    notify(err, 'error', 5000);
                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Sınav Katılım',
                    icon: 'repeat',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            this.showLoadPanel();
                            service.get(sinavApi + "merkezisinav/getSinavKatilimRaporu/" + satir.Id, {
                                timeout: 1200000,
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }

                            })
                                .then(res => {
                                    if (res.data.Success) {
                                        this.hideLoadPanel();
                                        window.location.href = res.data.Data
                                    }
                                    else {
                                        this.hideLoadPanel();
                                        notify(res.data.ErrorMessage, 'error', 5000);
                                    }
                                })
                                .catch(err => {
                                    this.hideLoadPanel();
                                    notify(err, 'error', 5000);
                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Merkezi Sınav İndir',
                    icon: 'download',
                    onClick: async () => {
                        this.popupDonemEkraniAc();

                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    onClick: this.yeniEkleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    onClick: this.kayitDuzenle
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: this.silClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: this.pasifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: this.aktifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.refreshData();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }
    //#endregion

    //#region servis fonksiyonları
    async getSatir(satirId) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "merkezisinav/getsinav/" + satirId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(res => {
                    if (res.data.Success) {
                        this.formData = {
                            Id: res.data.Data.Id,
                            Yayin: { Id: res.data.Data.Yayin.Id },
                            SinavTuru: { Id: res.data.Data.SinavTuru.Id },
                            OptikForm: { Id: res.data.Data.OptikForm.Id },
                            Deleted: res.data.Data.Deleted,
                            RowVersion: res.data.Data.RowVersion,
                            Kodu: res.data.Data.Kodu,
                            Adi: res.data.Data.Adi,
                            Tarih: res.data.Data.Tarih,
                            Renk: res.data.Data.Renk,
                            DogruYanlis: res.data.Data.DogruYanlis,
                            SecmeliDers: res.data.Data.SecmeliDers,
                            Degerlendirilebilir: res.data.Data.Degerlendirilebilir,
                            Raporlanabilir: res.data.Data.Raporlanabilir,
                            OnlineSinav: res.data.Data.OnlineSinav,
                            SanalOptik: res.data.Data.SanalOptik,
                            Kds: res.data.Data.Kds
                        };

                        resolve("Sınav bilgisi getirildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 500);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "sınav"));
                });
        });
    }
    //#endregion

    //#region  toolbar fonksiyonları

    async refreshData() {
        this.gridMain.instance.refresh().then(() => {
            this.gridMainSatirIptal();
        });
    }

    async kayitDuzenle(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.formData = null;

            try {
                await this.getSatir(satir.Id).then(() => { }).catch(error => {
                    throw error;
                });

                this.yayinfilter.YayinId = this.formData.Yayin.Id;
                this.sinavTuruFilter.YayinId = this.formData.Yayin.Id;
                this.sinavTuruFilter.SinavTuruId = this.formData.SinavTuru.Id;

                await this.yayinDataSource.load().fail(error => {
                    throw error;
                });
                await this.sinavTuruDataSource.load().fail(error => {
                    throw error;
                });
                await this.optikFormDataSource.load().fail(error => {
                    throw error;
                });
                this.selectBoxYayin.instance().option("value", this.formData.Yayin.Id);
                this.selectBoxSinavTuru.instance().option("value", this.formData.SinavTuru.Id);
                this.selectBoxOptikForm.instance().option("value", this.formData.OptikForm.Id);
                this.setState({
                    btnKopyalaState: true
                })
                await this.popupAc();
            } catch (error) {
                throw error;
            }

        }
    }

    yeniEkleClick() {
        service.get(sinavApi + "yayin/getYayinlarByYetki",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(resYayin => {
                if (resYayin.data.Success) {
                    this.yayinlar = resYayin.data.Data;

                    this.formData = {
                        Id: 0,
                        Yayin: { Id: 0 },
                        SinavTuru: { Id: 0 },
                        OptikForm: { Id: 0 },
                        Kodu: 0,
                        Adi: "",
                        Tarih: null,
                        Renk: "",
                        DogruYanlis: 0,
                        SecmeliDers: false,
                        Degerlendirilebilir: false,
                        Raporlanabilir: false,
                        OnlineSinav: false,
                        SanalOptik: false,
                        Kds: false
                    };
                    this.setState({
                        btnKopyalaState: false,
                        popup: true
                    })
                }
                else {
                    notify(resYayin.data.ErrorMessage, "error", 5000);
                }
            });
    }

    silClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.sil(satir);
                }
            });
        }
    }

    aktifYapClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.aktifYap(satir);
                }
            });
        }
    }

    pasifYapClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.pasifYap(satir);
                }
            });
        }
    }

    //#endregion

    async sil(satir) {

        service.post(sinavApi + "merkezisinav/merkezisinavsil/" + satir.Id,
            null, {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        })
            .then(res => {
                if (res.data.Success) {
                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                    this.refreshData();
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            });
    }

    async aktifYap(satir) {

        service.post(sinavApi + 'merkezisinav/AktifYap/' + satir.Id, null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.refreshData();
                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    async pasifYap(satir) {

        service.post(sinavApi + 'merkezisinav/PasifYap/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.refreshData();
                    notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    hideLoadPanel() {
        this.setState({
            loadPanelVisible: false
        });
    }

    showLoadPanel() {
        this.setState({
            loadPanelVisible: true
        });
    }
    render() {
        return (
            <React.Fragment>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    onHiding={this.hideLoadPanel}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <div className="container" id="sinav">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Sınav İşlemleri>>Sınavlar"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.mainDataSource}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitialized={this.onInitialized}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" />
                                <Column dataField={'Yayin_Adi'} caption="Yayın" />
                                <Column dataField={'SinavTuru_Adi'} caption="Sınav Türü" />
                                <Column dataField={'Kodu'} caption="Sınav Kodu" />
                                <Column dataField={'Adi'} caption="Sınav Adı" />
                                <Column dataField={'Katilim'} caption="Katılım" />
                            </DataGrid>

                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kopyala', type: 'success', hint: 'Kopyala', icon: 'check',
                                    disabled: this.state.btnKopyalaState ? false : true
                                    , onClick: (e) => {
                                        service.post(sinavApi + 'merkezisinav/merkezisinavtanimkopyala',
                                            this.formData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        ).then(res => {
                                            if (res.data.Success) {

                                                this.refreshData();
                                                this.popupGizle();
                                            }
                                            else {
                                                notify(res.data.ErrorMessage, 'error', 5000);
                                            }

                                        }).catch(err => {
                                            if (err.response) {
                                                var resError = JSON.parse(err.response.request.response);
                                                var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                var dictionary = Object.keys(resError.errors);
                                                dictionary.forEach((satir, index) => {
                                                    mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                });
                                                mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                alert(mesaj);
                                                // client received an error response (5xx, 4xx)
                                            } else if (err.request) {
                                                // client never received a response, or request never left
                                            } else {
                                                // anything else
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        service.post(sinavApi + 'merkezisinav/merkeziSinavSave',
                                            this.formData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        ).then(res => {
                                            if (res.data.Success) {

                                                this.refreshData();
                                                this.popupGizle();
                                            }
                                            else {
                                                notify(res.data.ErrorMessage, 'error', 5000);
                                            }

                                        }).catch(err => {
                                            if (err.response) {
                                                var resError = JSON.parse(err.response.request.response);
                                                var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                var dictionary = Object.keys(resError.errors);
                                                dictionary.forEach((satir, index) => {
                                                    mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                });
                                                mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                alert(mesaj);
                                                // client received an error response (5xx, 4xx)
                                            } else if (err.request) {
                                                // client never received a response, or request never left
                                            } else {
                                                // anything else
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Sınav  Bilgileri">
                                        <SimpleItem dataField={"Yayin.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.yayinDataSource,
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                showClearButton: true,
                                                onInitialized: this.onInitializedSelectBoxYayin,
                                                ref: (ref) => { this.selectBoxYayin = ref },
                                                onValueChanged: async (e) => {

                                                    this.yayinfilter.YayinId = e.value;
                                                    this.sinavTuruFilter.YayinId = e.value;
                                                    this.sinavTuruDataSource.load();
                                                    this.selectBoxSinavTuru.instance().option("value", null);
                                                    this.selectBoxOptikForm.instance().option("value", null);
                                                }
                                            }}>
                                            <Label text="Yayın" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinavTuru.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruDataSource,
                                                searchEnabled: true,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                onInitialized: this.onInitializedSelectBoxSinavTuru,
                                                ref: (ref) => { this.selectBoxSinavTuru = ref },
                                                onValueChanged: async (e) => {
                                                    this.sinavTuruFilter.SinavTuruId = e.value;
                                                    this.optikFormDataSource.load();
                                                    this.selectBoxOptikForm.instance().option("value", null);
                                                }
                                            }}>
                                            <Label text="Sınav Türü" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"OptikForm.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.optikFormDataSource,
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxOptikForm,
                                                ref: (ref) => { this.selectBoxOptikForm = ref }
                                            }}>
                                            <Label text="Optik Form" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Kodu"} isRequired={true}>
                                            <Label text="Kodu" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Adi"} isRequired={true}>
                                            <Label text="Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"DogruYanlis"} isRequired={true}>
                                            <Label text="Doğru Yanlış" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Tarih"} isRequired={true} editorType="dxDateBox">
                                            <Label text="Tarih" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Renk"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={
                                                {
                                                    dataSource: [
                                                        { Name: "Siyah       ", Value: "#000000" },
                                                        { Name: "Mavi        ", Value: "#0000ff" },
                                                        { Name: "Kahverengi  ", Value: "#a52a2a" },
                                                        { Name: "Çikolata    ", Value: "#d2691e" },
                                                        { Name: "Kızıl       ", Value: "#dc143c" },
                                                        { Name: "Koyu Altın  ", Value: "#b8860b" },
                                                        { Name: "Koyu Yeşil  ", Value: "#006400" },
                                                        { Name: "Koyu Eflatun", Value: "#8b008b" },
                                                        { Name: "Koyu Kırmızı", Value: "#8b0000" },
                                                        { Name: "Derin Pembe ", Value: "#ff1493" },
                                                        { Name: "Çivit       ", Value: "#4b0082" },
                                                        { Name: "Kırmızı     ", Value: "#ff0000" },
                                                    ],
                                                    displayExpr: "Name",
                                                    valueExpr: "Value",
                                                    // fieldRender: RenkSecimFieldItem,
                                                    // itemRender: RenkSecimFieldItem,
                                                    itemTemplate: (itemData, itemIndex, itemElement) => {
                                                        //return "<font style='color:" + itemData.Value + ";'><b>" + itemData.Name + "</b></font>";
                                                        return "<div style='width: 16px; height: 16px; border: 1px solid #000;background-color:" + itemData.Value + "'></div>";
                                                    }
                                                }
                                            }>
                                            <Label text="Renk" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SecmeliDers"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Seçmeli Ders" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Degerlendirilebilir"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Değerlendirilebilir" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Raporlanabilir"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Raporlanabilir" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"OnlineSinav"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Online Sınav" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SanalOptik"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Sanal Optik" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SanalOptik"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Sanal Optik" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Kds"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Kds" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} title={"Merkezi Sınav İndirme Bilgileri"}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupMerkeziFormGizle} visible={this.state.popupMerkeziForm}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet',
                                    type: 'success',
                                    hint: 'Kaydet',
                                    icon: 'check',
                                    disabled: this.state.btnDwonloadDisabled,
                                    onClick: (e) => {
                                        let localSinavTuru = this.selectBoxLocalSinavTuru.instance().option("text");
                                        let remoteSinavTuru = this.selectBoxRemoteSinavTuru.instance().option("text");
                                        if (localSinavTuru !== remoteSinavTuru) {

                                            notify("Kaynak sınav türü ile hedef sınav türü farklı seçilmiş.", 'error', 5000);
                                            return;
                                        }
                                        var data = {
                                            Token: this.props.remoteSystem.remoteToken,
                                            RemoteMerkeziSinavId: this.formMerkeziSinavData.MerkeziSinavId,
                                            KaynakYayinId: this.formMerkeziSinavData.KaynakYayinId,
                                            KaynakSinavTuruId: this.formMerkeziSinavData.KaynakSinavTuruId,
                                            HedefYayinId: this.formMerkeziSinavData.HedefYayinId,
                                            HedefSinavTuruId: this.formMerkeziSinavData.HedefSinavTuruId,
                                            KaynakKurumId: this.formMerkeziSinavData.KaynakKurumId,
                                            HedefKurumId: this.formMerkeziSinavData.HedefKurumId
                                        };
                                        this.setState({
                                            btnDwonloadDisabled: true
                                        });
                                        service.post(otomasyonApi + 'entegrasyon/merkezisinavindir',
                                            data,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        ).then(async (res) => {
                                            if (res.data.Success) {

                                                await this.gridMain.instance.refresh();
                                                await this.setState({
                                                    btnDwonloadDisabled: false
                                                });
                                                await this.popupMerkeziFormGizle();
                                            }
                                            else {
                                                this.setState({
                                                    btnDwonloadDisabled: false
                                                });
                                                notify(res.data.ErrorMessage, 'error', 5000);
                                            }

                                        }).catch(err => {
                                            this.setState({
                                                btnDwonloadDisabled: false
                                            });
                                            if (err.response) {
                                                var resError = JSON.parse(err.response.request.response);
                                                var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                var dictionary = Object.keys(resError.errors);
                                                dictionary.forEach((satir, index) => {
                                                    mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                });
                                                mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                alert(mesaj);
                                                // client received an error response (5xx, 4xx)
                                            } else if (err.request) {
                                                // client never received a response, or request never left
                                            } else {
                                                // anything else
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupMerkeziFormGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formMerkeziSinavData} onInitialized={this.onInitializedFormMerkeziSinav} ref={(ref) => this.formMerkez = ref}
                                    showValidationSummary={true}
                                >
                                    <GroupItem cssClass="first-group" colCount={2}>
                                        <SimpleItem dataField="KaynakKurumId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.kurumDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "KurumAdi",
                                            onInitialized: this.onInitializedSelectBoxLocalKurum,
                                            ref: (ref) => { this.selectBoxKaynakKurum = ref },
                                            onValueChanged: async (e) => {
                                                if (e.value === "" | e.value === null | e.value === undefined) {

                                                }
                                                else {
                                                    this.formMerkeziSinavData.KaynakKurumId = e.value;

                                                }
                                            }
                                        }}>
                                            <Label text="Kaynak Kurum" />
                                            <RequiredRule message="Kurum alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="HedefKurumId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.kurumRemoteDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "KurumAdi",
                                            searchEnabled: true,
                                            searchMode: "contains",
                                            searchExpr: ["KurumAdi"],
                                            showClearButton: true,
                                            onInitialized: this.onInitializedSelectBoxRemoteKurum,
                                            ref: (ref) => { this.selectBoxHedefKurum = ref },
                                            onValueChanged: async (e) => {
                                                if (e.value === "" | e.value === null | e.value === undefined) {

                                                }
                                                else {
                                                    this.formMerkeziSinavData.HedefKurumId = e.value;

                                                }
                                            }
                                        }}>
                                            <Label text="Hedef Kurum" />
                                            <RequiredRule message="Kurum alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KaynakYayinId"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.yayinRemoteDataSource,
                                                keyExpr: "Id",
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                showClearButton: true,
                                                onInitialized: this.onInitializedSelectBoxRemoteYayin,
                                                ref: (ref) => { this.selectBoxRemoteYayin = ref },
                                                onValueChanged: async (e) => {
                                                    if (e.value === "" | e.value === null | e.value === undefined) {

                                                    }
                                                    else {

                                                        this.yayinRemotefilter.YayinId = e.value;
                                                        this.sinavTuruRemoteFilter.YayinId = e.value;
                                                        await this.sinavTuruRemoteDataSource.load();

                                                    }
                                                    // this.selectBoxYayin2.instance().option("value", null);
                                                    this.selectBoxRemoteSinavTuru.instance().option("value", null);
                                                    this.selectBoxRemoteMerkeziSinav.instance().option("value", null);
                                                    // this.selectBoxMerkeziSinav2.instance().option("value", null);
                                                    // this.selectBoxOptikform.instance().option("value", null);
                                                }
                                            }}>
                                            <Label text="Kaynak Yayın" />
                                            <RequiredRule message="Kaynak yayın alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"HedefYayinId"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.yayinDataSource,
                                                keyExpr: "Id",
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                showClearButton: true,
                                                onInitialized: this.onInitializedSelectBoxLocalYayin,
                                                ref: (ref) => { this.selectBoxLocalYayin = ref },
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null | e.value === undefined) {

                                                    }
                                                    else {

                                                        this.yayinfilter.YayinId = e.value;
                                                        this.sinavTuruFilter.YayinId = e.value;
                                                        this.sinavTuruDataSource.load();

                                                    }
                                                    // this.selectBoxYayin2.instance().option("value", null);
                                                    this.selectBoxLocalSinavTuru.instance().option("value", null);
                                                    // this.selectBoxMerkeziSinav2.instance().option("value", null);
                                                    // this.selectBoxOptikform.instance().option("value", null);
                                                }
                                            }}>
                                            <Label text="Hedef Yayın" />
                                            <RequiredRule message="Hedef yayın alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KaynakSinavTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruRemoteDataSource,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                onInitialized: this.onInitializedSelectBoxRemoteSinavTuru,
                                                ref: (ref) => { this.selectBoxRemoteSinavTuru = ref },
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null) {
                                                        return;
                                                    }
                                                    else {
                                                        this.sinavTuruRemoteFilter.SinavTuruId = e.value;
                                                        this.sinavTuruRemoteFilter.donemId = this.formDonemData.DonemId;
                                                        // if (e.value === 11) {
                                                        //     this.selectBoxMerkeziSinav2.instance().option("disabled", false);
                                                        //     this.selectBoxYayin2.instance().option("disabled", false);
                                                        // }
                                                        // else {
                                                        //     this.selectBoxMerkeziSinav2.instance().option("disabled", true);
                                                        //     this.selectBoxYayin2.instance().option("disabled", true);
                                                        // }
                                                        if (this.formMerkeziSinavData.Yayin !== null && this.formMerkeziSinavData.SinavTuru !== null) {

                                                            this.merkeziSinavRemoteDataSource.load();
                                                            this.selectBoxRemoteMerkeziSinav.instance().option("value", null);
                                                        }
                                                    }
                                                }
                                            }}>
                                            <Label text="Kaynak sınav türü" />
                                            <RequiredRule message="Kaynak sınav türü alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"HedefSinavTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruDataSource,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                onInitialized: this.onInitializedSelectBoxLocalSinavTuru,
                                                ref: (ref) => { this.selectBoxLocalSinavTuru = ref },
                                                onValueChanged: (e) => {
                                                    if (e.value === "" | e.value === null) {
                                                        return;
                                                    }
                                                    else {
                                                        this.sinavTuruRemoteFilter.SinavTuruId = e.value;
                                                        // if (e.value === 11) {
                                                        //     this.selectBoxMerkeziSinav2.instance().option("disabled", false);
                                                        //     this.selectBoxYayin2.instance().option("disabled", false);
                                                        // }
                                                        // else {
                                                        //     this.selectBoxMerkeziSinav2.instance().option("disabled", true);
                                                        //     this.selectBoxYayin2.instance().option("disabled", true);
                                                        // }
                                                        if (this.formMerkeziSinavData.Yayin !== null && this.formMerkeziSinavData.SinavTuru !== null) {

                                                            this.merkeziSinavRemoteDataSource.load();
                                                            this.selectBoxRemoteMerkeziSinav.instance().option("value", null);
                                                        }
                                                    }
                                                }
                                            }}>
                                            <Label text="Hedef sınav türü" />
                                            <RequiredRule message="Hedef sınav türü alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"MerkeziSinavId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.merkeziSinavRemoteDataSource,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxRemoteMerkeziSinav,
                                                ref: (ref) => { this.selectBoxRemoteMerkeziSinav = ref }
                                            }}>
                                            <Label text="Sınav" />
                                            <RequiredRule message="Merkez sınav alanı gerekli" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} title={"Sınav Aktarma İşlemleri"}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupSinavAktarGizle} visible={this.state.popupSinavAktar}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet',
                                    type: 'success',
                                    hint: 'Kaydet',
                                    icon: 'check',
                                    disabled: this.state.btnSinavAktarDisabled,
                                    onClick: (e) => {
                                        var data = {
                                            DonemId: this.formSinavAktarData.DonemId,
                                            KullaniciId: this.formSinavAktarData.KullaniciId,
                                            SinavId: this.satir.Id
                                        };
                                        this.setState({
                                            btnSinavAktarDisabled: true
                                        });
                                        service.post(sinavApi + 'merkezisinav/donemsinavaktar',
                                            data,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        ).then(async (res) => {
                                            if (res.data.Success) {
                                                await this.setState({
                                                    btnSinavAktarDisabled: false
                                                });
                                                await this.popupSinavAktarGizle();
                                            }
                                            else {
                                                this.setState({
                                                    btnSinavAktarDisabled: false
                                                });
                                                notify(res.data.ErrorMessage, 'error', 5000);
                                            }

                                        }).catch(err => {
                                            this.setState({
                                                btnSinavAktarDisabled: false
                                            });
                                            if (err.response) {
                                                var resError = JSON.parse(err.response.request.response);
                                                var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                var dictionary = Object.keys(resError.errors);
                                                dictionary.forEach((satir, index) => {
                                                    mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                });
                                                mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                alert(mesaj);
                                                // client received an error response (5xx, 4xx)
                                            } else if (err.request) {
                                                // client never received a response, or request never left
                                            } else {
                                                // anything else
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupSinavAktarGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formSinavAktarData} onInitialized={this.onInitializedFormSinavAktar} ref={(ref) => this.formSinavAktar = ref}
                                    showValidationSummary={true}
                                >
                                    <GroupItem cssClass="first-group" colCount={2}>
                                        <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.donemDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi",
                                            onValueChanged: (e) => {
                                                this.kullaniciFilter.DonemId = e.value;
                                            }
                                        }}>
                                            <Label text="Dönem Seçiniz" />
                                            <RequiredRule message="Dönem alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField="KurumId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.kurumDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "KurumAdi",
                                            onValueChanged: (e) => {
                                                if (e.value === "" | e.value === null | e.value === undefined) {

                                                }
                                                else {

                                                    this.kullaniciFilter.KurumId = e.value;
                                                    this.kullaniciDataSource.load();

                                                }
                                                this.selectBoxKullanici.instance().option("value", null);
                                            }
                                        }}>
                                            <Label text="Kurum Seçiniz" />
                                            <RequiredRule message="Kurum alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KullaniciId"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.kullaniciDataSource,
                                                keyExpr: "Id",
                                                searchEnabled: true,
                                                displayExpr: "AdSoyad",
                                                valueExpr: "Id",
                                                showClearButton: true,
                                                onInitialized: this.onInitializedSelectBoxKullanici,
                                                ref: (ref) => { this.selectBoxKullanici = ref }
                                            }}>
                                            <Label text="Kullanıcı" />
                                            <RequiredRule message="Kullanıcı alanı gerekli" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} title={"Aktarılacak olan sınavın dönemini seçiniz"}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.popupDonemSecim}
                    width="550" height={"200"}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Merkezi Sınav İndir',
                                    type: 'success',
                                    hint: 'Kaydet',
                                    icon: 'check',
                                    disabled: this.state.btnSinavAktarDisabled,
                                    onClick: (e) => {
                                        let merkeziSinavIndirmeDialog = custom({
                                            title: "Merkezi sınavınızı indirmeden önce lütfen uyarıyı okuyunuz",
                                            messageHtml: "<font style='color:red'>Ayarlar-Api Bilgileri ekranından Yayınevi Modülü kullanıcı bilgilerinizi eklemelisiniz.</font><br/><font style='color:red'>Sınav indirebilmek için ilk önce Yayınevi Modülünden sınavlarınızı değerlendirmeniz gerekmektedir.</font>",
                                            buttons: [
                                                {
                                                    text: "Devam Et",
                                                    onClick: (e) => {
                                                        this.popupDonemEkraniGizle();
                                                        service.get(otomasyonApi + "entegrasyon/authenticate/" + this.formDonemData.DonemId, {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                            }
                                                        })
                                                            .then(async res => {
                                                                if (res.data.Success) {
                                                                    await this.setState({
                                                                        remoteToken: res.data.Data
                                                                    });
                                                                    await this.yayinRemoteDataSource.load();
                                                                    await this.setState({
                                                                        popupMerkeziForm: true
                                                                    });
                                                                }
                                                                else {
                                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                                }
                                                            })
                                                            .catch(err => {
                                                                notify(err, "error", 5000);
                                                            });
                                                    }
                                                },
                                                {
                                                    text: "Kapat",
                                                    onClick: (e) => {
                                                        merkeziSinavIndirmeDialog.hide();
                                                    }
                                                }
                                            ]
                                        });

                                        merkeziSinavIndirmeDialog.show();
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupDonemEkraniGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formDonemData} onInitialized={this.onInitializedFormDonemSecimi} ref={(ref) => this.formDonemSecimi = ref}
                                    showValidationSummary={true}
                                >
                                    <GroupItem cssClass="first-group" colCount={1}>
                                        <SimpleItem dataField="DonemId" editorType="dxSelectBox" editorOptions={{
                                            dataSource: this.remoteDonemDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi"
                                        }}>
                                            <Label text="Dönem Seçiniz" />
                                            <RequiredRule message="Dönem alanı zorunludur" />
                                        </SimpleItem>

                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SinavTanimi);