import React from 'react';
import { sinavApi, errorMessage, errorMessageWithKonum } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
export default class DuyuruIslemleri extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            duyurular: [],
            yayinlar: [],
            disabled: true,
            secilenYayin: null,
            loadingText: "",
            toolbarDisabled: false
        };
        this.satir = null;
        this.mainDataGrid = null;

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.kayitYeni = this.kayitYeni.bind(this);
        this.kayitDuzenle = this.kayitDuzenle.bind(this);
        this.kayitAktifYap = this.kayitAktifYap.bind(this);
        this.kayitPasifYap = this.kayitPasifYap.bind(this);
        this.kayitSil = this.kayitSil.bind(this);
        this.kayitYenile = this.kayitYenile.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.popupGizle = () => {
            this.setState({ popup: false, toolbarDisabled: false });
            this.satirIptal();

        };
        this.popupAc = () => {
            this.setState({ popup: true, toolbarDisabled: true });
        };
        this.formData = {
            Id: 0,
            Yayin: { Id: 0 },
            Tarih: new Date(),
            Baslik: "",
            Aciklama: "",
            RowVersion: 0,
            Deleted: false
        };
        this.kayitYenile();
    }

    //#region form fonksiyonları
    //#endregion

    //#region  servis fonksiyonları

    getYayinlarByYteki() {
        return new Promise((resolve, reject) => {

            service.get(sinavApi + "yayin/getYayinlarByYetki",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(res => {
                    if (res.data.Success) {
                        this.setState({
                            yayinlar: res.data.Data
                        });
                        resolve("yayın bilgileri getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "yayın bilgileri"));
                })
        });
    }

    getSatir(id) {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + "duyuru/getDuyuru/" + id,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(res => {
                    if (res.data.Success) {
                        this.formData = {
                            Id: res.data.Data.Id,
                            Yayin: { Id: res.data.Data.Yayin.Id },
                            Tarih: res.data.Data.Tarih,
                            Baslik: res.data.Data.Baslik,
                            Aciklama: res.data.Data.Aciklama,
                            RowVersion: res.data.Data.RowVersion,
                            Deleted: res.data.Data.Deleted
                        };
                        resolve("satır bilgisi getirildi...")
                    } else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "satır bilgisi"));
                })
        });
    }
    //#endregion

    //#region  toolbar fonksiyonları

    async kayitYenile() {
        this.setState({ loadingText: "Duyurular yükleniyor..." });
        return await new Promise((resolve, reject) => {

            service.get(sinavApi + "duyuru/duyurular",
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            duyurular: res.data.Data,
                            loadingText: ""
                        });
                        resolve("duyurular getirildi...");
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessageWithKonum(error, "duyuru bilgileri"));
                });
        }).then(res => {
            this.setState({
                loadingText: "",
                toolbarDisabled: false
            });
            this.satirIptal();
        })
            .catch(error => {
                alert(error);
            });

    }

    async kayitYeni() {
        await this.getYayinlarByYteki().then(res => {
        }).catch(error => {
            alert(error);
        });

        return await new Promise((resolve, reject) => {
            try {
                this.formData = {
                    Id: 0,
                    Yayin: { Id: 0 },
                    Tarih: new Date(),
                    Baslik: "",
                    Aciklama: "",
                    RowVersion: 0,
                    Deleted: false
                };
                resolve("Yeni kayıt formu açıldı...");
            } catch (error) {
                reject(errorMessageWithKonum(error, "yeni kayıt ekranı"));
            }
        }).then(res => {
            this.popupAc();

        }).catch(error => {
            alert(error);
        });

    }

    async kayitDuzenle() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let hataMesajlari = "";
            let isError = false;

            await this.getSatir(satir.Id).then(res => {
            }).catch(error => {
                hataMesajlari += error + "<br/>";
                isError = true;
            });

            await this.getYayinlarByYteki().then(res => {
            }).catch(error => {
                hataMesajlari += error + "<br/>";
                isError = true;
            });

            if (isError)
                alert(hataMesajlari);
            else
                this.popupAc();

        }
    }

    async kayitAktifYap() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({ toolbarDisabled: true, loadingText: "Kaydınız aktif hale getiriliyor..." });
            return await new Promise((resolve, reject) => {
                service.post(sinavApi + 'duyuru/AktifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                        resolve("kaydınız aktif hale getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessage(error));
                });
            }).then(res => {
                this.kayitYenile();
            }).catch(error => {
                alert(error);
            });
        }
    }

    async kayitPasifYap() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({ toolbarDisabled: true, loadingText: "Kaydınız pasif hale getiriliyor..." });
            return await new Promise((resolve, reject) => {
                service.post(sinavApi + 'duyuru/PasifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                        resolve("kaydınız pasif hale getirildi.");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 5000);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(errorMessage(error));
                });
            }).then(res => {
                this.kayitYenile();
            }).catch(error => {
                alert(error);
            });
        }
    }

    //await anahtarı confirm kullanıdığı için kabul edilmiyor oyüzden ayrı bir fonksiyon olarak ekledim
    async sil(id) {
        return await new Promise((resolve, reject) => {
            service.post(sinavApi + "duyuru/duyuruSil/" + id,
                null, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    notify("Kaydınız silindi.", 'success', 5000);
                    resolve("kaydınız silindi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                reject(errorMessage(error));
            })
        }).then(res => {
            this.kayitYenile();
        }).catch(error => {
            alert(error);
        });
    }

    async kayitSil() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.setState({ toolbarDisabled: true, loadingText: "Kaydınız siliniyor..." });
                    this.sil(satir.Id);
                }
                else
                    this.satirIptal();

            });
        }
    }

    //#endregion

    //#region grid fonksiyonları

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitYeni
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitDuzenle
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'remove',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitSil
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitPasifYap
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitAktifYap
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    disabled: this.state.toolbarDisabled,
                    onClick: this.kayitYenile
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }

    //seçilen satırı iptal etmek için kullanıyoruz
    async satirIptal() {
        this.mainDataGrid.instance.clearSelection();
        this.mainDataGrid.instance.option("focusedRowIndex", -1);
    }

    //#endregion


    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.loadingText}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.state.duyurular}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                paging={{
                                    pageSize: 20
                                }}
                                pager={{
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                    showInfo: true
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onInitialized={this.onInitialized}
                                ref={(ref) => this.mainDataGrid = ref}
                            >
                                <Export enabled={true} fileName={'Dökümanlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" />
                                <Column dataField={'Yayin.Id'} caption="Yayın Id" visible={false} />
                                <Column dataField={'Tarih'} caption="Tarih" visible={true} dataType="date" />
                                <Column dataField={'YayinAdi'} caption="Yayın" visible={true} />
                                <Column dataField={'Baslik'} caption="Baslik" visible={true} />
                            </DataGrid>

                        </div>
                    </div>

                </div>

                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        this.formData.Yayin = { Id: this.state.secilenYayin };
                                        service.post(sinavApi + "duyuru/duyuruKaydet",
                                            this.formData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }).then(res => {
                                                if (res.data.Success) {
                                                    this.kayitYenile();
                                                    this.popupGizle();
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }
                                            }).catch(error => {
                                                alert(error);
                                            });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Duyuru  Bilgileri">
                                        <SimpleItem dataField={"Yayin.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.state.yayinlar,
                                                searchEnabled: true,
                                                value: this.state.secilenYayin,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onValueChanged: (e) => {
                                                    this.setState({
                                                        secilenYayin: e.value
                                                    });
                                                }
                                            }}>
                                            <Label text="Yayın" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Baslik"}>
                                            <Label text="Baslik" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Tarih"}>
                                            <Label text="Tarih" />
                                        </SimpleItem>

                                        <SimpleItem dataField={"Aciklama"} editorType="dxHtmlEditor" editorOptions={{
                                            toolbar: {
                                                items: [
                                                    "bold",
                                                    "italic",
                                                    "color",
                                                    "background",
                                                    "link",
                                                    {
                                                        "formatName": "header",
                                                        "formatValues": [
                                                            1,
                                                            2,
                                                            3,
                                                            false
                                                        ]
                                                    },
                                                    "variable",
                                                    "strike",
                                                    "image",
                                                    "subscript",
                                                    "superscript",
                                                    "underline",
                                                    "blockquote",
                                                    "increaseIndent",
                                                    "decreaseIndent",
                                                    "orderedList",
                                                    "bulletList",
                                                    "alignLeft",
                                                    "alignCenter",
                                                    "alignRight",
                                                    "alignJustify",
                                                    "codeBlock",
                                                    "undo",
                                                    "redo",
                                                    "clear",
                                                    "separator"
                                                ],
                                                multiline: true
                                            },
                                            height: "400",
                                            value: this.formData.Aciklama
                                        }
                                        }>
                                            <Label text="İçerik" />
                                        </SimpleItem>

                                    </GroupItem>


                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>


            </React.Fragment>
        );
    }
}