import React from 'react';
import Form, { GroupItem, TabbedItem, TabPanelOptions, Tab, SimpleItem, Label } from 'devextreme-react/form';
import DataGrid, {
    Export, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, ColumnFixing
} from 'devextreme-react/data-grid';
import { otomasyonApi, karneApi, yayinSinavApi, sinavApi } from '../Util';
import Globals from '../Globals';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import { alert } from 'devextreme/ui/dialog';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import { SelectBox, Button } from 'devextreme-react';
import DersTanim from '../classes/DersTanim';
import DersButon from '../components/DersButon';
import { setRemoteSystemInfo } from '../redux/actions/mainSystemAction';
import { setSanalSinavSystemInfo } from '../redux/actions/sanalSinavSystemAction';
import { connect } from 'react-redux';
const mapDispatchToProps = (dispatch) => {
    return {
        setRemoteSystem: (data) => dispatch(setRemoteSystemInfo(data)),
        setSanalSystemInfo: (data) => dispatch(setSanalSinavSystemInfo(data)),
    }
}
const mapStateToProps = state => ({
    remoteSystem: state.remoteSystem.info
});
class OgrenciDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupOdev: false,
            popupEtut: false,
            odevBaslik: "",
            odevDetay: "",
            etutBaslik: "",
            etutDetay: "",
            dersler: [],
            merkeziSinavGoster: false
        };
        this.dersTanim = new DersTanim();
        this.onInitializedGrdiOdemePlani = this.onInitializedGrdiOdemePlani.bind(this);
        this.selectBoxMerkeziSinav = null;
        this.selectBoxKitapcikTuru = null;
        this.satirOdev = null;
        this.satirEtut = null;
        this.satirSinav = null;
        this.satirSanalSinav = null;
        this.gridSinav = null;
        this.gridOdemePlaniDetay = null;
        this.griDevamsizlik = null;
        this.gridOdev = null;
        this.gridEtut = null;
        this.gridSanalSinav = null;
        this.formSanalSinavData = {
            YayinId: null,
            SinavTuruId: null,
            KitapcikTuru: null
        }
        console.log(Globals.service);
        this.ogrenciDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'kayitkabul/listveliogrenci',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruDataSource = new DataSource({
            loadMode: "raw",
            key: "Id",
            store: createStore({
                key: "Id",
                loadUrl: karneApi + 'ogrenci/getogrencisinavturu',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.karneDataSource = new DataSource({
            loadMode: "raw",
            key: "RowNumber",
            store: createStore({
                key: "RowNumber",
                loadUrl: karneApi + 'ogrenci/getotomasyonogrencikarneler',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.odevDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'odev/ogrenciodevlist',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.etutDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'etut/ogrencietutlist',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.devamsizlikuDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'devamsizlik/listogrencidevamsizlik',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadUrl: sinavApi + 'yayin/yayinlarByYetkiotomasyonogrenci',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sanalSinavFilter = {
            kurumKodu: Globals.service.Kullanici.KurumKodu,
            ogrenciNo: Globals.service.Kullanici.OgrenciNo
        }

        this.sanalSinavDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadParams: this.sanalSinavFilter,
                    loadUrl: sinavApi + 'merkezisinav/getsanalsinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.merkeziSinavFilter = {
            yayinId: 0,
            sinifSeviyesi: Globals.service.Kullanici.SinifSeviye
        }

        this.merkeziSinavDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadParams: this.merkeziSinavFilter,
                    loadUrl: sinavApi + 'merkezisinav/getsanalmerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.kitapcikTuruFilter = {
            merkeziSinavId: 0
        }

        this.kitapcikTuruDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadParams: this.kitapcikTuruFilter,
                    loadUrl: sinavApi + 'cevapanahtari/getmerkezssinavkitapcikturleri',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.devamsizlikFilter = {
            ogrenciId: Globals.service.Kullanici.OgrenciId
        };
        this.devamsizlikAltRaporDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.devamsizlikFilter,
                    loadUrl: otomasyonApi + 'devamsizlik/devamsizlikaltraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.devamsizlikUstRaporDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.devamsizlikFilter,
                    loadUrl: otomasyonApi + 'devamsizlik/devamsizlikustraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        this.popupOdevGizle = async () => {
            await this.setState(
                {
                    popupOdev: false
                }
            );
        };

        this.popupOdevAc = () => {
            this.setState(
                {
                    popupOdev: true
                });
        };

        this.popupEtutGizle = async () => {
            await this.setState(
                {
                    popupEtut: false
                }
            );
        };

        this.popupEtutAc = () => {
            this.setState(
                {
                    popupEtut: true
                });
        };
    }
    onInitializedGrdiOdemePlani(e) {
        this.gridOdemePlaniDetay = e.component;
    }

    getSinavApi() {
        let endpoint = "";
        endpoint = (this.props.remoteSystem.connectionState && this.state.merkeziSinavGoster) ? yayinSinavApi : sinavApi;
        return endpoint;
    }

    getHeaders() {
        return {
            'Authorization': (this.props.remoteSystem.connectionState && this.state.merkeziSinavGoster)
                ? 'Bearer ' + this.props.remoteSystem.remoteToken
                : 'Bearer ' + Globals.service.Token
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Form colCount={1} width={"100%"} formData={this.formEtutTanimiData} onInitialized={this.onInitializedFormEtutTanimi} ref={(ref) => this.formEtutTanimi = ref}>
                                <GroupItem cssClass="first-group" colCount={1}>
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} />
                                        <Tab title="Sanal Optik Sınavlar">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    Aşağıda bulunan butona basarak gireceğiniz sınavı sadece kurum katılımı ile yada genel katılım olarak değerlendirebilirsiniz.
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Button
                                                        type={this.state.merkeziSinavGoster ? 'success' : 'default'}
                                                        text={this.state.merkeziSinavGoster ? "Merkezi sınavlar ile ilgili işlem yapılıyor" : "Local sınavlar ile ilgili işlem yapılıyor"}
                                                        onClick={(e) => {

                                                            if (this.state.merkeziSinavGoster) {
                                                                this.sanalSinavDataSource = new DataSource(
                                                                    {
                                                                        paginate: false,
                                                                        pageSize: 100,
                                                                        store: createStore({
                                                                            key: "Id",
                                                                            loadParams: this.sanalSinavFilter,
                                                                            loadUrl: sinavApi + 'merkezisinav/getsanalsinavlar',
                                                                            onBeforeSend: (method, ajaxOptions) => {
                                                                                ajaxOptions.headers = {
                                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                                };
                                                                            }
                                                                        })
                                                                    });

                                                                this.yayinDataSource = new DataSource(
                                                                    {
                                                                        paginate: false,
                                                                        pageSize: 100,
                                                                        store: createStore({
                                                                            loadUrl: sinavApi + 'yayin/yayinlarByYetkiotomasyonogrenci',
                                                                            onBeforeSend: (method, ajaxOptions) => {
                                                                                ajaxOptions.headers = {
                                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                                };
                                                                            }
                                                                        })
                                                                    });

                                                                this.karneDataSource = new DataSource({
                                                                    loadMode: "raw",
                                                                    key: "RowNumber",
                                                                    store: createStore({
                                                                        key: "RowNumber",
                                                                        loadUrl: karneApi + "ogrenci/getotomasyonogrencikarneler",
                                                                        onBeforeSend: (method, ajaxOptions) => {
                                                                            ajaxOptions.headers = {
                                                                                'Authorization': 'Bearer ' + Globals.service.Token
                                                                            };
                                                                        }
                                                                    })
                                                                });
                                                                this.gridSinav.instance.refresh();
                                                                this.gridSanalSinav.instance.option("dataSource", this.sanalSinavDataSource);
                                                                this.gridSanalSinav.instance.refresh();
                                                            }
                                                            else {
                                                                this.sanalSinavDataSource = new DataSource(
                                                                    {
                                                                        paginate: false,
                                                                        pageSize: 100,
                                                                        store: createStore({
                                                                            key: "Id",
                                                                            loadParams: this.sanalSinavFilter,
                                                                            loadUrl: yayinSinavApi + 'merkezisinav/getsanalsinavlar',
                                                                            onBeforeSend: (method, ajaxOptions) => {
                                                                                ajaxOptions.headers = {
                                                                                    'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                                                                                };
                                                                            }
                                                                        })
                                                                    });
                                                                this.yayinDataSource = new DataSource(
                                                                    {
                                                                        paginate: false,
                                                                        pageSize: 100,
                                                                        store: createStore({
                                                                            loadUrl: yayinSinavApi + 'yayin/yayinlarByYetki',
                                                                            onBeforeSend: (method, ajaxOptions) => {
                                                                                ajaxOptions.headers = {
                                                                                    'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                                                                                };
                                                                            }
                                                                        })
                                                                    });

                                                                this.karneFilter = {
                                                                    ogrenciNo: Globals.service.Kullanici.OgrenciNo,
                                                                    kurumKodu: Globals.service.Kullanici.KurumKodu
                                                                };
                                                                this.karneDataSource = new DataSource({
                                                                    loadMode: "raw",
                                                                    key: "RowNumber",
                                                                    store: createStore({
                                                                        key: "RowNumber",
                                                                        loadParams: this.karneFilter,
                                                                        loadUrl: "https://gateway.teknodeneme.com/karne/ogrenci/getremoteogrencikarneler",
                                                                        onBeforeSend: (method, ajaxOptions) => {
                                                                            ajaxOptions.headers = {
                                                                                'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                                                                            };
                                                                        }
                                                                    })
                                                                });
                                                                this.gridSinav.instance.refresh();
                                                                this.gridSanalSinav.instance.option("dataSource", this.sanalSinavDataSource);
                                                                this.gridSanalSinav.instance.refresh();
                                                            }


                                                            this.setState({
                                                                merkeziSinavGoster: !this.state.merkeziSinavGoster
                                                            });


                                                        }}></Button><br></br><br></br><br></br>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-12'>

                                                    <Form formData={this.formSanalSinavData}>
                                                        <GroupItem cssClass="first-group" colCount={3} caption="Sınav  Bilgileri">
                                                            <SimpleItem dataField={"YayinId"} isRequired={true}
                                                                editorType="dxSelectBox"
                                                                editorOptions={{
                                                                    dataSource: this.yayinDataSource,
                                                                    keyExpr: "Id",
                                                                    searchEnabled: true,
                                                                    displayExpr: "Adi",
                                                                    valueExpr: "Id",
                                                                    showClearButton: true,
                                                                    onInitialized: this.onInitializedSelectBoxYayin,
                                                                    ref: (ref) => { this.selectBoxYayin = ref },
                                                                    onValueChanged: (e) => {
                                                                        this.merkeziSinavFilter.yayinId = parseInt(e.value);
                                                                        this.merkeziSinavDataSource = new DataSource(
                                                                            {
                                                                                paginate: false,
                                                                                pageSize: 100,
                                                                                store: createStore({
                                                                                    loadParams: this.merkeziSinavFilter,
                                                                                    loadUrl: this.getSinavApi() + 'merkezisinav/getsanalmerkezisinavlar',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = this.getHeaders()
                                                                                    }
                                                                                })
                                                                            });
                                                                        this.merkeziSinavDataSource.load();
                                                                        this.selectBoxMerkeziSinav.instance().option("dataSource", this.merkeziSinavDataSource);
                                                                    }
                                                                }}>
                                                                <Label text="Yayın" />
                                                            </SimpleItem>
                                                            <SimpleItem dataField={"MerkeziSinavId"} isRequired={true}
                                                                editorType="dxSelectBox"
                                                                editorOptions={{
                                                                    dataSource: this.merkeziSinavDataSource,
                                                                    keyExpr: "Id",
                                                                    searchEnabled: true,
                                                                    displayExpr: "Adi",
                                                                    valueExpr: "Id",
                                                                    showClearButton: true,
                                                                    onValueChanged: (e) => {
                                                                        this.kitapcikTuruFilter.merkeziSinavId = parseInt(e.value);
                                                                        this.kitapcikTuruDataSource = new DataSource(
                                                                            {
                                                                                paginate: false,
                                                                                pageSize: 100,
                                                                                store: createStore({
                                                                                    loadParams: this.kitapcikTuruFilter,
                                                                                    loadUrl: this.getSinavApi() + 'cevapanahtari/getmerkezssinavkitapcikturleri',
                                                                                    onBeforeSend: (method, ajaxOptions) => {
                                                                                        ajaxOptions.headers = this.getHeaders();
                                                                                    }
                                                                                })
                                                                            });
                                                                        this.kitapcikTuruDataSource.load();
                                                                        this.selectBoxKitapcikTuru.instance().option("dataSource", this.kitapcikTuruDataSource);
                                                                    },
                                                                    onInitialized: (e) => {
                                                                        this.selectBoxMerkeziSinav = e.component;
                                                                    },
                                                                    ref: (ref) => this.selectBoxMerkeziSinav = ref
                                                                }}>
                                                                <Label text="Sınav" />
                                                            </SimpleItem>
                                                            <SimpleItem dataField={"KitapcikTuru"} isRequired={true}
                                                                editorType="dxSelectBox"
                                                                editorOptions={{
                                                                    dataSource: this.kitapcikTuruDataSource,
                                                                    searchEnabled: true,
                                                                    displayExpr: "KitapcikTuru",
                                                                    valueExpr: "KitapcikTuru",
                                                                    showClearButton: true,
                                                                    onInitialized: (e) => {
                                                                        this.selectBoxKitapcikTuru = e.component;
                                                                    },
                                                                    ref: (ref) => this.selectBoxKitapcikTuru = ref
                                                                }}>
                                                                <Label text="Kitapçık Türü" />
                                                            </SimpleItem>
                                                            <SimpleItem
                                                                editorType="dxButton"
                                                                editorOptions={{
                                                                    text: "Seçtiğim kriterlere göre sınava girmek istiyorum",
                                                                    type: "success",
                                                                    onClick: () => {
                                                                        const data = {
                                                                            KurumKodu: Globals.service.Kullanici.KurumKodu,
                                                                            OgrenciNo: Globals.service.Kullanici.OgrenciNo,
                                                                            YayinId: this.formSanalSinavData.YayinId,
                                                                            MerkeziSinavId: this.formSanalSinavData.MerkeziSinavId,
                                                                            KitapcikTuru: this.formSanalSinavData.KitapcikTuru,
                                                                            SanalSinavId: 0,
                                                                            MerkeziSistem: this.state.merkeziSinavGoster
                                                                        };

                                                                        service.post(this.getSinavApi() + "merkezisinav/sanalsinavolustur"
                                                                            , data, {
                                                                            headers: this.getHeaders()
                                                                        })
                                                                            .then(res => {
                                                                                if (res.data.Success) {
                                                                                    let sanalSinavData = data;
                                                                                    sanalSinavData.SanalSinavId = parseInt(res.data.Data.Id);
                                                                                    sanalSinavData.SinavTuru = res.data.Data.SinavTuru;
                                                                                    sanalSinavData.KalanSure = res.data.Data.KalanSure;
                                                                                    this.props.setSanalSystemInfo(sanalSinavData);
                                                                                    notify("Sanal optik sınavınız oluşturuldu.2 saniye içerisinde sınav ekranına yönlendiriliyorsunuz", "success", 5000);
                                                                                    window.setTimeout(() => {
                                                                                        window.location.href = "/SanalSinif/SanalOptikSinav";
                                                                                    }, 2000)
                                                                                } else {
                                                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                                                }
                                                                                this.gridSanalSinav.instance.refresh();
                                                                            })
                                                                            .catch(err => {
                                                                                if (err.response) {
                                                                                    var resError = JSON.parse(err.response.request.response);
                                                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                                    var dictionary = Object.keys(resError.errors);
                                                                                    dictionary.forEach((satir, index) => {
                                                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                                    });
                                                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                                    alert(mesaj);
                                                                                    // client received an error response (5xx, 4xx)
                                                                                } else if (err.request) {
                                                                                    // client never received a response, or request never left
                                                                                } else {
                                                                                    // anything else
                                                                                }
                                                                            });

                                                                    }
                                                                }}>
                                                            </SimpleItem>
                                                        </GroupItem>
                                                        <GroupItem cssClass="first-group" colCount={1} caption="Girmiş olduğunuz sınavlar">
                                                            <SimpleItem>
                                                                <DataGrid
                                                                    showBorders={true}
                                                                    showRowLines={true}
                                                                    dataSource={this.sanalSinavDataSource}
                                                                    columnHidingEnabled={true}
                                                                    filterPanel={{ visible: true }}
                                                                    filterRow={{ visible: true }}
                                                                    columnAutoWidth={true}
                                                                    selection={{ mode: 'single' }}
                                                                    autoNavigateToFocusedRow={true}
                                                                    focusedRowEnabled={true}
                                                                    hoverStateEnabled={true}
                                                                    keyExpr="Id"
                                                                    paging={{
                                                                        pageSize: 20
                                                                    }}
                                                                    pager={{
                                                                        showPageSizeSelector: true,
                                                                        allowedPageSizes: [5, 10, 50, 75, 100],
                                                                        showInfo: true
                                                                    }}
                                                                    onToolbarPreparing={(e) => {
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                                    type: 'default', hint: "Karne Al", icon: 'exportpdf',
                                                                                    text: "Sınava Başla",
                                                                                    onClick: () => {
                                                                                        const satir = this.satirSanalSinav;
                                                                                        if (satir === null || satir === undefined) {
                                                                                            alert('Satır seçmelisiniz', 'Uyarı');
                                                                                            return;
                                                                                        }
                                                                                        else if (satir.TamamlanmaDurumu === "Tamamlandı") {
                                                                                            alert('Bu sınava katılmışsınız', 'Uyarı');
                                                                                            return;
                                                                                        }
                                                                                        else {
                                                                                            const data = {
                                                                                                KurumKodu: satir.KurumKodu,
                                                                                                OgrenciNo: satir.OgrenciNo,
                                                                                                YayinId: satir.YayinId,
                                                                                                MerkeziSinavId: satir.MerkeziSinavId,
                                                                                                KitapcikTuru: satir.KitapcikTuru,
                                                                                                SanalSinavId: satir.Id,
                                                                                                SinavTuru: satir.SinavTuru,
                                                                                                KalanSure: satir.KalanSure,
                                                                                                MerkeziSistem: this.state.merkeziSinavGoster
                                                                                            };
                                                                                            this.props.setSanalSystemInfo(data);
                                                                                            notify("Sanal optik sınavınız oluşturuldu.2 saniye içerisinde sınav ekranına yönlendiriliyorsunuz", "success", 5000);
                                                                                            window.setTimeout(() => {
                                                                                                window.location.href = "/SanalSinif/SanalOptikSinav";
                                                                                            }, 2000)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            },
                                                                            {
                                                                                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                                    type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                                    text: "Yenile",
                                                                                    onClick: (e) => {
                                                                                        this.gridSanalSinav.instance.refresh();
                                                                                    }
                                                                                }
                                                                            });
                                                                    }}
                                                                    onSelectionChanged={(selectedItems) => {
                                                                        this.satirSanalSinav = selectedItems.selectedRowsData[0];
                                                                    }}
                                                                    onInitialized={(e) => {
                                                                        this.gridSanalSinav = e.component;
                                                                    }}
                                                                    ref={(ref) => this.gridSanalSinav = ref}
                                                                >
                                                                    <ColumnChooser enabled={false} />
                                                                    <LoadPanel enabled={true} />
                                                                    <FilterRow visible={false} applyFilter={'auto'} />
                                                                    <Paging defaultPageSize={15} />
                                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                                    <Column dataField={'MerkeziSinavId'} visible={false} />
                                                                    <Column dataField={'YayinId'} caption={"YayinId"} visible={false} />
                                                                    <Column dataField={'KurumId'} caption="KurumId" visible={false} />
                                                                    <Column dataField={'SinavTuruId'} caption="SinavTuruId" visible={false} />
                                                                    <Column dataField={'YayinAdi'} caption="Yayın" />
                                                                    <Column dataField={'KurumAdi'} caption="Kurum" />
                                                                    <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                                                                    <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                                                                    <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                                                                    <Column dataField={'KalanSure'} caption="K. Süre" />
                                                                    <Column dataField={'KitapcikTuru'} caption="Kitapçık" />
                                                                    <Column dataField={'TamamlanmaDurumu'} caption="Durum" />

                                                                </DataGrid>
                                                            </SimpleItem>
                                                        </GroupItem>
                                                    </Form>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab title="Sınavlar">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.karneDataSource}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                keyExpr="RowNumber"
                                                paging={{
                                                    pageSize: 20
                                                }}
                                                pager={{
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                                    showInfo: true
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: "Karne Al", icon: 'exportpdf',
                                                                text: "Karne Al",
                                                                onClick: () => {
                                                                    const satir = this.satirSinav;
                                                                    if (satir === null || satir === undefined) {
                                                                        alert('Satır seçmelisiniz', 'Uyarı');
                                                                        return;
                                                                    }
                                                                    else {

                                                                        service.post((this.state.merkeziSinavGoster ? "https://gateway.teknodeneme.com/karne/" : karneApi) + "ogrenci/GetOtomasyonOgrenciKarne",
                                                                            {
                                                                                OgrenciNo: satir.OgrenciNo,
                                                                                KurumId: satir.KurumId,
                                                                                KullaniciId: satir.KullaniciId,
                                                                                KurumLogo: satir.KurumLogo,
                                                                                SinavTuru: satir.SinavTuru,
                                                                                SinavTuruId: satir.SinavTuruId,
                                                                                MerkeziSinavId: satir.Id,
                                                                                MerkeziSinavLogId: satir.MerkeziSinavLogId
                                                                            },
                                                                            {
                                                                                headers: this.getHeaders()
                                                                            })
                                                                            .then(res => {
                                                                                if (res.data.Success) {
                                                                                    this.gridSinav.instance.refresh();
                                                                                }
                                                                                else {
                                                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                                                }
                                                                            }).catch(error => {
                                                                                notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz", "error", 5000);
                                                                            });
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                text: "Yenile",
                                                                onClick: (e) => {
                                                                    this.gridSinav.instance.refresh();
                                                                }
                                                            }
                                                        });
                                                }}
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirSinav = selectedItems.selectedRowsData[0];
                                                }}
                                                ref={(ref) => this.gridSinav = ref}
                                            >
                                                <Export enabled={true} fileName={'Karneler'} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={false} applyFilter={'auto'} />
                                                <Paging defaultPageSize={15} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                <Column dataField={'RowNumber'} caption="Kayıt No" visible={false} />
                                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                <Column dataField="YayinAdi" caption=""
                                                    width={40}
                                                    allowSorting={false}
                                                    cellRender={linkRender}
                                                />
                                                <Column dataField={'KullaniciId'} caption="KullaniciId" visible={false} />
                                                <Column dataField={'ListeKarneId'} caption="ListeKarneId" visible={false} />
                                                <Column dataField={'KurumId'} caption="KurumId" visible={false} />
                                                <Column dataField={'YayinAdi'} caption="Yayın" />
                                                <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                                                <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                                                <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                                                <Column dataField={'IlAdi'} caption="İl" />
                                                <Column dataField={'IlceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                                <Column dataField={'Created'} caption="Tarih" dataType="date" format={
                                                    "dd/MM/yyyy HH:mm:ss"
                                                } />
                                                <Column dataField={'DosyaTuru'} caption="DosyaTuru" visible={false} />
                                                <Column dataField={'Dosya'} caption="Dosya" visible={false} />
                                            </DataGrid>
                                        </Tab>
                                        <Tab title="Hatalar">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        dataSource={this.sinavTuruDataSource}
                                                        displayExpr={"SinavTuru"}
                                                        valueExpr={"SinavTuru"}
                                                        onValueChanged={(e) => {
                                                            const dersler = this.dersTanim.GetDersler(e.value);
                                                            this.setState({
                                                                dersler: dersler
                                                            });
                                                        }}
                                                    >
                                                    </SelectBox>
                                                </div>
                                                <div className="row" style={{ minHeight: "10px" }}>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <DersButon Dersler={this.state.dersler}></DersButon>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab title="Ödev">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.odevDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                showCheckBoxesMode={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                key="Id"
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirOdev = selectedItems.selectedRowsData[0];
                                                    this.setState({
                                                        odevBaslik: this.satirOdev.Baslik,
                                                        odevDetay: this.satirOdev.Odev
                                                    })
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Ödev Detayı', icon: 'search',
                                                                onClick: () => {
                                                                    if (this.satirOdev === null | this.satirOdev === undefined) {
                                                                        alert("Lütfen bir satır seçiniz", "Uyarı");
                                                                    }
                                                                    else {
                                                                        this.popupOdevAc()
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                onClick: () => {
                                                                    this.gridOdev.instance.refresh();
                                                                }
                                                            }
                                                        }
                                                    );
                                                }}
                                                onInitialized={(e) => {
                                                    this.gridOdev = e.component;
                                                }}
                                                ref={(ref) => { this.gridOdev = ref }}
                                            >
                                                <Export
                                                    enabled={Globals.service.Rol === "SubeOgretmen" ? true : false}
                                                    fileName={'Öğrenciler'}
                                                    allowExportSelectedData={false}
                                                    texts={
                                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                                    }
                                                />
                                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? true : false} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={true} />
                                                <FilterPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'KonuAdi'} caption="Konu" />
                                                <Column dataField={'SeviyeAdi'} caption="Sınıf" />
                                                <Column dataField={'SubeAdi'} caption="Şube" />
                                                <Column dataField={'Baslik'} caption="Başlık" />
                                                <Column dataField={'OdevDurum'} caption="Tamamlanma Durumu" />
                                                <Column dataField={'Created'} caption="Tarih" dataType={"date"} />
                                            </DataGrid>

                                        </Tab>
                                        <Tab title="Etüt">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.etutDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                showCheckBoxesMode={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                key="Id"
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirEtut = selectedItems.selectedRowsData[0];
                                                    this.setState({
                                                        etutBaslik: this.satirEtut.Baslik,
                                                        etutDetay: this.satirEtut.Aciklama
                                                    })
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Etüt Detayı', icon: 'search',
                                                                onClick: () => {
                                                                    if (this.satirEtut === null | this.satirEtut === undefined) {
                                                                        alert("Lütfen bir satır seçiniz", "Uyarı");
                                                                    }
                                                                    else {
                                                                        this.popupEtutAc()
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                onClick: () => {
                                                                    this.gridEtut.instance.refresh();
                                                                }
                                                            }
                                                        }
                                                    );
                                                }}
                                                onInitialized={(e) => {
                                                    this.gridEtut = e.component;
                                                }}
                                                ref={(ref) => { this.gridEtut = ref }}
                                            >
                                                <Export
                                                    enabled={Globals.service.Rol === "SubeOgretmen" ? true : false}
                                                    fileName={'Öğrenciler'}
                                                    allowExportSelectedData={false}
                                                    texts={
                                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                                    }
                                                />
                                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? true : false} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={true} />
                                                <FilterPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'KonuAdi'} caption="Konu" />
                                                <Column dataField={'SeviyeAdi'} caption="Sınıf" />
                                                <Column dataField={'SubeAdi'} caption="Şube" />
                                                <Column dataField={'Baslik'} caption="Başlık" />
                                                <Column dataField={'EtutDurum'} caption="Tamamlanma Durumu" />
                                                <Column dataField={'Created'} caption="Tarih" dataType={"date"} />
                                            </DataGrid>

                                        </Tab>
                                        <Tab title="Devamsızlık">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <DataGrid
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        remoteOperations={true}
                                                        dataSource={this.devamsizlikUstRaporDataSource}
                                                        onInitialized={(e) => {
                                                            this.gridDevamsizlikUstRapor = e.component;
                                                        }}
                                                        columnHidingEnabled={true}
                                                        filterPanel={{ visible: true }}
                                                        filterRow={{ visible: true }}
                                                        export={{
                                                            enabled: true,
                                                            fileName: "devamsizlikustrapor"
                                                        }}
                                                        selection={
                                                            {
                                                                mode: 'single'
                                                            }}
                                                        autoNavigateToFocusedRow={true}
                                                        focusedRowEnabled={true}
                                                        hoverStateEnabled={true}
                                                        keyExpr="Id"
                                                        ref={(ref) => this.gridDevamsizlikUstRapor = ref}
                                                    >
                                                        <Export
                                                            fileName={'OgrenciDevamsizlikUstRapor'}
                                                            allowExportSelectedData={false}
                                                            texts={
                                                                { exportAll: "Ekrandaki Verileri Aktar" }
                                                            }
                                                        />

                                                        <LoadPanel enabled={true} />
                                                        <FilterRow visible={false} applyFilter={'auto'} />
                                                        <Paging defaultPageSize={15} enabled={true} />
                                                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                        <Column dataField={'Id'} caption="Id" visible={false} />
                                                        <Column dataField={'Sube'} caption="Şube" width={75} />
                                                        <Column dataField={'Adi'} caption="Ad" />
                                                        <Column dataField={'Soyadi'} caption="Soyad" />
                                                        <Column dataField={'OgrenciNo'} caption="Öğrenci N." dataType={"number"} />
                                                        <Column dataField={'Durum'} caption="Durum" width={100} />
                                                        <Column dataField={'Toplam'} dataType={"number"} caption="T. Sayı" />
                                                    </DataGrid>

                                                </div>
                                            </div>
                                            <div className="row" style={{ height: "10px" }}>
                                                <div className="col-md-12">

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <DataGrid
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        remoteOperations={true}
                                                        dataSource={this.devamsizlikAltRaporDataSource}
                                                        onInitialized={(e) => {
                                                            this.gridDevamsizlikAltRapor = e.component;
                                                        }}
                                                        columnHidingEnabled={true}
                                                        filterPanel={{ visible: true }}
                                                        filterRow={{ visible: true }}
                                                        columnAutoWidth={true}
                                                        export={{
                                                            enabled: true,
                                                            fileName: "smsRapor"
                                                        }}
                                                        selection={
                                                            {
                                                                mode: 'single'
                                                            }}
                                                        autoNavigateToFocusedRow={true}
                                                        focusedRowEnabled={true}
                                                        hoverStateEnabled={true}
                                                        keyExpr="Id"
                                                        ref={(ref) => this.gridDevamsizlikAltRapor = ref}
                                                    >
                                                        <Export
                                                            fileName={'OgrenciDevamsizlikAltRapor'}
                                                            allowExportSelectedData={false}
                                                            texts={
                                                                { exportAll: "Ekrandaki Verileri Aktar" }
                                                            }
                                                        />
                                                        <ColumnFixing enabled={true} />
                                                        <LoadPanel enabled={true} />
                                                        <FilterRow visible={false} applyFilter={'auto'} />
                                                        <Paging defaultPageSize={15} enabled={true} />
                                                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                        <Column dataField={'Id'} caption="Id" visible={false} />
                                                        <Column dataField={'Sube'} caption="Şube" />
                                                        <Column dataField={'Adi'} caption="Ad" />
                                                        <Column dataField={'Soyadi'} caption="Soyad" />
                                                        <Column dataField={'OgrenciNo'} caption="Öğrenci N." dataType={"number"} />
                                                        <Column dataField={'Tarih'} caption="Tarih" dataType={"datetime"} />
                                                        <Column dataField={'VeliCepTelefonu'} caption="V. Cep Telefonu" />
                                                        <Column dataField={'DersNo'} caption="Ders N." dataType={"number"} />
                                                        <Column dataField={'Turu'} caption="Durum" />
                                                    </DataGrid>

                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab title="Merkezi Sınav Sonuçları">
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/62' rel="noopener noreferrer" target='_blank'>Limit Yayınları</a><br /><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/63' rel="noopener noreferrer" target='_blank'>Avantaj Yayınları</a><br></br><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/64' rel="noopener noreferrer" target='_blank'>Kronometre Yayınları</a><br></br><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/65' rel="noopener noreferrer" target='_blank'>Esen Yayınları</a><br></br><br></br>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabbedItem>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupOdevGizle} visible={this.state.popupOdev}
                    title={this.state.odevBaslik}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => { this.popupOdevGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <span dangerouslySetInnerHTML={{ __html: this.state.odevDetay }} />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupEtutGizle} visible={this.state.popupEtut}
                    title={this.state.etutBaslik}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => { this.popupEtutGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <span dangerouslySetInnerHTML={{ __html: this.state.etutDetay }} />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OgrenciDashboard);
function linkRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Dosya === undefined || data.data.Dosya === null || data.data.Dosya === "") {
        return <p></p>;
    }
    else {
        return <a rel="noopener noreferrer" href={data.data.Dosya} target="_blank">
            <img src="/icons/download.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}