import React from 'react';
import service from 'axios';
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import notify from 'devextreme/ui/notify';
import Form, { SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import DataGrid, {
    Export, ColumnChooser, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Summary, TotalItem
} from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
export default class KurumTaksitTahsilatDurumRaporu extends React.Component {
    constructor(props) {
        super(props);
        this.formMain = null;
        this.onInitializedFormMain = this.onInitializedFormMain.bind(this);
        this.formData = {
            HizmetToplami: 0,
            IndirimToplami: 0,
            PesinatToplami: 0,
            ToplamAlacak: 0,
            TahsilatToplami: 0,
            KalanAlacak: 0,
            KasaToplami: 0,
            GiderToplami: 0
        };
        this.mainDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/listkurumtaksittahsilatdurumraporualt',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
        service.get(otomasyonApi + "dashboard/listkurumtaksittahsilatdurumraporuust", {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        }).then(res => {
            if (res.data.Success) {
                var result = res.data.Data;
                this.formData.HizmetToplami = result.HizmetToplami;
                this.formData.IndirimToplami = result.IndirimToplami;
                this.formData.PesinatToplami = result.PesinatToplami;
                this.formData.ToplamAlacak = result.ToplamAlacak;
                this.formData.TahsilatToplami = result.TahsilatToplami;
                this.formData.KalanAlacak = result.KalanAlacak;
                this.formData.KasaToplami = result.KasaToplami;
                this.formData.GiderToplami = result.GiderToplami;
                this.formMain.instance.repaint();
            } else {
                notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            }
        })
            .catch(err => {
                notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
            })
    }
    onInitializedFormMain(e) {
        this.formMain = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Rapor İşlemleri>>Kurum Taksit Tahsilat Durum Raporu"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Form formData={this.formData} onInitialized={this.onInitializedFormMain} ref={(ref) => this.formMain = ref}>
                                <GroupItem cssClass="first-group" colCount={2} caption="Kasa Özeti">
                                    <SimpleItem dataField={"HizmetToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Hizmet Toplamı" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"IndirimToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="İndirim Toplamı" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"PesinatToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Peşinat Toplamı" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"ToplamAlacak"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Toplam Alacak" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"TahsilatToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Tahsilat Toplamı" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"KalanAlacak"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Kalan Alacak" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"KasaToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Kasa Toplamı" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"GiderToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        readOnly: true,
                                        format: "#,##0.##"
                                    }}>
                                        <Label text="Gider Toplamı" />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem cssClass="first-group" caption="Yıllık Taksit Tahsilat Özeti">
                                    <SimpleItem>
                                        <DataGrid
                                            showBorders={true}
                                            showRowLines={true}
                                            dataSource={this.mainDataSource}
                                            columnHidingEnabled={true}
                                            filterPanel={{ visible: true }}
                                            filterRow={{ visible: true }}
                                            columnAutoWidth={true}
                                            selection={{ mode: 'single' }}
                                            autoNavigateToFocusedRow={true}
                                            focusedRowEnabled={true}
                                            hoverStateEnabled={true}
                                            keyExpr="Id"
                                            onToolbarPreparing={this.onToolbarPreparing}
                                            onInitialized={this.onInitialized}
                                            onSelectionChanged={this.onSelectionChanged}
                                            ref={(ref) => this.gridMain = ref}
                                        >
                                            <Export enabled={true} fileName={'yillikTaksitTahsilatOzet'} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={true} />
                                            <Editing mode={'popup'} />
                                            <LoadPanel enabled={true} />
                                            <FilterRow visible={false} applyFilter={'auto'} />
                                            <Paging defaultPageSize={15} enabled={true} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                            <Column dataField={'Yil'} caption="Yıl" />
                                            <Column dataField={'Ay'} caption="Ay" />
                                            <Column dataField={'TaksitToplami'} caption="Taksit Toplamı" format={"#,##0.##"} />
                                            <Column dataField={'TahsilatToplami'} caption="Tahsilat Toplamı" format={"#,##0.##"} />
                                            <Column dataField={'KalanAlacak'} caption="Kalan Alacak" dataType={"number"} format={"#,##0.##"} />
                                            <Column dataField={'GiderToplami'} caption="Gider Toplamı" dataType={"number"} format={"#,##0.##"} />
                                            <Summary>
                                                <TotalItem
                                                    column="TaksitToplami"
                                                    summaryType="sum"
                                                    valueFormat="#,##0.##"
                                                />
                                                <TotalItem
                                                    column="TahsilatToplami"
                                                    summaryType="sum"
                                                    valueFormat="#,##0.##"
                                                />
                                                <TotalItem
                                                    column="KalanAlacak"
                                                    summaryType="sum"
                                                    valueFormat="#,##0.##"
                                                />
                                                <TotalItem
                                                    column="GiderToplami"
                                                    summaryType="sum"
                                                    valueFormat="#,##0.##"
                                                />
                                            </Summary>
                                        </DataGrid>

                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}