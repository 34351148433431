import React from 'react';
import Globals from '../../../Globals';
import service from 'axios';
import { otomasyonApi } from '../../../Util';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Form, { SimpleItem, GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import DataGrid, {
    Export, ColumnFixing, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, RequiredRule
    , Selection, Scrolling
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
export default class OdevTanim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            odevTanimiVisible: false
        }
        this.onInitialized = this.onInitialized.bind(this);
        this.onInitializedGridOgrenci = this.onInitializedGridOgrenci.bind(this);
        this.onInitializedFormOdevTanimi = this.onInitializedFormOdevTanimi.bind(this);
        this.onInitializedSelectBoxSinifSeviye = this.onInitializedSelectBoxSinifSeviye.bind(this);
        this.onInitializedSelectBoxSinifSube = this.onInitializedSelectBoxSinifSube.bind(this);
        this.onToolbarPreparingGridOgrenci = this.onToolbarPreparingGridOgrenci.bind(this);
        this.onInitializedTextBoxOdev = this.onInitializedTextBoxOdev.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.toolTipOdevTakip = null;
        this.gridMain = null;
        this.textBoxBaslik = null;
        this.textBoxSoruSayisi = null;
        this.textBoxOdev = null;
        this.gridOgrenci = null;
        this.selectBoxSinifSeviye = null;
        this.selectBoxSinifSube = null;
        this.formOdevTanimi = null;
        this.satir = null;
        this.secilenOgrenciler = [];
        this.odevDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'odev/list',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinifSeviyeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.sinifSubeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifsubebykullanici',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.seviyeFilter = { seviyeId: 0 };
        this.konuDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.seviyeFilter,
                    loadUrl: otomasyonApi + 'konu/getKonularBySeviye',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.odevFilter = {
            odevId: 0,
            seviyeId: 0,
            subeId: 0
        };
        this.ogrenciDataSource = new DataSource(
            {
                cacheRawData: false,
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadParams: this.odevFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listogrencilerbyodev',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.formOdevTanimiData = {
            Konular: [],
            SinifSeviye: { Id: null },
            SinifSube: { Id: null },
            SoruSayisi: 0,
            Baslik: "",
            Odev: "",
            Dosya: null,
            Ogrenciler: [],
            Id: 0
        };
        this.gridKonu = null;
        this.konuDataGridRender = this.konuDataGridRender.bind(this);
        this.dropDownBoxKonu = null;
        this.onInitializedDropDownBoxKonu = this.onInitializedDropDownBoxKonu.bind(this);
        this.onInitializedGridKonu = this.onInitializedGridKonu.bind(this);
        this.onKonuDataGridSelectionChanged = this.onKonuDataGridSelectionChanged.bind(this);
        this.onDropDownBoxKonuValueChanged = this.onDropDownBoxKonuValueChanged.bind(this);
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Result.Value.Success) {
            notify("Dosyanız yüklenemedi," + res.Result.Value.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Dosyanız yüklendi", "success", 5000);
            this.formOdevTanimiData.Dosya = res.Result.Value.Data;
            e.component.reset();
        }
    }

    onInitialized(e) {
        this.gridMain = e.component;
    }

    onInitializedTextBoxOdev(e) {
        this.textBoxOdev = e.component;
    }
    onInitializedGridOgrenci(e) {
        this.gridOgrenci = e.component;
    }

    onInitializedFormOdevTanimi(e) {
        this.formOdevTanimi = e.component;
    }

    onInitializedSelectBoxSinifSeviye(e) {
        this.selectBoxSinifSeviye = e.component;
    }

    onInitializedSelectBoxSinifSube(e) {
        this.selectBoxSinifSube = e.component;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                locateInMenu: "auto",
                widget: "dxButton",
                options: {
                    type: "default",
                    hint: "Ödev Öğrenci Listesi",
                    icon: "exportpdf",
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            service.get(otomasyonApi + "odev/odevogrencilistesiraporu/" + satir.Id,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                })
                                .then(res => {
                                    if (res.data.Success) {
                                        alert("Ödev öğrenci listeniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Buranın üzerinde sağ tıklayarak bağlantıyı farklı kaydet seçeneği ile ödev öğrenci listesini bilgisayarınıza indiriniz</a>", "Bilgi");
                                        if (this.gridMain.instance !== null) {
                                            this.gridMain.instance.clearSelection();
                                            this.satir = null;
                                            this.gridMain.instance.option("focusedRowIndex", -1);
                                        }
                                    }
                                    else {
                                        alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                    }
                                })
                                .catch(err => {
                                    alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add',
                    onClick: async () => {
                        if (Globals.service.Rol !== "SubeOgretmen") {
                            alert("Üzgünüz bu işlem için yetkiniz yok");
                        }
                        else {
                            this.formOdevTanimiData = {
                                Konular: [],
                                SinifSeviye: { Id: null },
                                SinifSube: { Id: null },
                                SoruSayisi: 0,
                                Baslik: "",
                                Odev: "",
                                Dosya: null,
                                Ogrenciler: [],
                                Id: 0
                            };
                            this.gridOgrenci.instance.clearSelection();
                            this.gridKonu.instance.clearSelection();
                            this.odevFilter.odevId = 0;
                            this.ogrenciDataSource.filter(["SinifSeviye.Id", "=", -1]);

                            this.gridOgrenci.instance.refresh();
                            this.gridKonu.instance.refresh();
                            this.secilenOgrenciler = [];

                            this.popupOdevTanimiAc();
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen" && Globals.service.Rol !== "Admin") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                this.gridOgrenci.instance.clearSelection();


                                await this.sinifSeviyeDataSource.load().done(() => {
                                    if (this.selectBoxSinifSeviye !== "undefined" && this.selectBoxSinifSeviye !== null && this.selectBoxSinifSeviye !== "" && this.selectBoxSinifSeviye !== undefined) {
                                        this.selectBoxSinifSeviye.instance().option("value", this.satir.SinifSeviye.Id);
                                    }
                                });


                                await this.sinifSubeDataSource.load().done(() => {
                                    if (this.selectBoxSinifSube !== "undefined" && this.selectBoxSinifSube !== null && this.selectBoxSinifSube !== "" && this.selectBoxSinifSube !== undefined) {
                                        this.selectBoxSinifSube.instance().option("value", this.satir.SinifSube.Id);
                                    }
                                });

                                await this.konuDataSource.load().done(() => {

                                    if (!!this.satir.KonuIdEkran) {
                                        var konular = this.satir.KonuIdEkran.split(',');
                                        var secilenKonular = [];
                                        konular.forEach((x) => {
                                            this.formOdevTanimiData.Konular.push({
                                                Id: parseInt(x)
                                            });
                                            secilenKonular.push(parseInt(x));
                                        });
                                        this.gridKonu.instance.selectRows(secilenKonular);
                                    }
                                });

                                this.odevFilter.odevId = this.satir.Id;
                                this.odevFilter.seviyeId = this.satir.SinifSeviye.Id;
                                this.odevFilter.subeId = this.satir.SinifSube.Id;

                                await this.gridOgrenci.instance.refresh();
                                await this.getSecilenOgrenciler(this.satir.Id);
                                this.formOdevTanimiData.Id = this.satir.Id;
                                this.formOdevTanimiData.SinifSeviye.Id = this.satir.SinifSeviye.Id;
                                this.formOdevTanimiData.SinifSube.Id = this.satir.SinifSube.Id;
                                this.formOdevTanimiData.SoruSayisi = this.satir.SoruSayisi;
                                this.formOdevTanimiData.Baslik = this.satir.Baslik;
                                this.formOdevTanimiData.Odev = this.satir.Odev;
                                this.formOdevTanimiData.Dosya = this.satir.Dosya;
                                this.textBoxBaslik.instance().option("value", this.satir.Baslik);
                                this.textBoxSoruSayisi.instance().option("value", this.satir.SoruSayisi);
                                this.textBoxOdev.instance().option("value", this.satir.Odev);

                                this.popupOdevTanimiAc();
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen" && Globals.service.Rol === "Admin") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        service.post(otomasyonApi + "odev/delete/" + this.satir.Id,
                                            null, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(res => {
                                                if (res.data.Success) {
                                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                                    this.gridMain.instance.refresh();
                                                    this.gridMainSatirIptal();

                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                }
                                            });
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen" && Globals.service.Rol !== "Admin") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        service.post(otomasyonApi + 'odev/PasifYap/' + satir.Id,
                                            null,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        )
                                            .then(res => {
                                                if (res.data.Success) {
                                                    this.gridMain.instance.refresh();
                                                    this.gridMainSatirIptal();
                                                    notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }
                                            });
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen" && Globals.service.Rol !== "Admin") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        service.post(otomasyonApi + 'odev/AktifYap/' + satir.Id,
                                            null,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        )
                                            .then(res => {
                                                if (res.data.Success) {
                                                    this.gridMain.instance.refresh();
                                                    this.gridMainSatirIptal();
                                                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }
                                            });
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh();
                    }
                }
            }
        );
    }

    onToolbarPreparingGridOgrenci(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Yeni', text: "Yeni", icon: 'add',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                var data = {
                                    OdevId: this.satir.Id,
                                    Durum: 0,
                                    Ogrenciler: this.secilenOgrenciler
                                };
                                service.post(otomasyonApi + "odev/durumdegistir",
                                    data,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    }
                                )
                                    .then(res => {
                                        if (res.data.Success) {
                                            this.gridOgrenci.instance.refresh();
                                            notify("Seçtiğiniz öğrencilerin ödevleri yeni olarak işaretlendi", 'success', 5000);
                                        }
                                        else {
                                            notify(res.data.ErrorMessage, 'error', 5000);
                                        }
                                    });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Yapıldı', text: "Yapıldı", icon: 'check',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                var data = {
                                    OdevId: this.satir.Id,
                                    Durum: 1,
                                    Ogrenciler: this.secilenOgrenciler
                                };
                                service.post(otomasyonApi + "odev/durumdegistir",
                                    data,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    }
                                )
                                    .then(res => {
                                        if (res.data.Success) {
                                            this.gridOgrenci.instance.refresh();
                                            notify("Seçtiğiniz öğrencilerin ödevleri yapıldı olarak işaretlendi", 'success', 5000);
                                        }
                                        else {
                                            notify(res.data.ErrorMessage, 'error', 5000);
                                        }
                                    });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Yapılmadı', text: "Yapılmadı", icon: 'close',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                var data = {
                                    OdevId: this.satir.Id,
                                    Durum: 2,
                                    Ogrenciler: this.secilenOgrenciler
                                };
                                service.post(otomasyonApi + "odev/durumdegistir",
                                    data,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    }
                                )
                                    .then(res => {
                                        if (res.data.Success) {
                                            this.gridOgrenci.instance.refresh();
                                            notify("Seçtiğiniz öğrencilerin ödevleri yapıldı olarak işaretlendi", 'success', 5000);
                                        }
                                        else {
                                            notify(res.data.ErrorMessage, 'error', 5000);
                                        }
                                    });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Eksik', text: "Eksik", icon: 'minus',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                var data = {
                                    OdevId: this.satir.Id,
                                    Durum: 3,
                                    Ogrenciler: this.secilenOgrenciler
                                };
                                service.post(otomasyonApi + "odev/durumdegistir",
                                    data,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    }
                                )
                                    .then(res => {
                                        if (res.data.Success) {
                                            this.gridOgrenci.instance.refresh();
                                            notify("Seçtiğiniz öğrencilerin ödevleri yapıldı olarak işaretlendi", 'success', 5000);
                                        }
                                        else {
                                            notify(res.data.ErrorMessage, 'error', 5000);
                                        }
                                    });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Geç getirdi', text: "Geç getirdi", icon: 'clock',
                    onClick: async () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol !== "SubeOgretmen") {
                                alert("Üzgünüz bu işlem için yetkiniz yok");
                            }
                            else {
                                var data = {
                                    OdevId: this.satir.Id,
                                    Durum: 4,
                                    Ogrenciler: this.secilenOgrenciler
                                };
                                service.post(otomasyonApi + "odev/durumdegistir",
                                    data,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    }
                                )
                                    .then(res => {
                                        if (res.data.Success) {
                                            this.gridOgrenci.instance.refresh();
                                            notify("Seçtiğiniz öğrencilerin ödevleri yapıldı olarak işaretlendi", 'success', 5000);
                                        }
                                        else {
                                            notify(res.data.ErrorMessage, 'error', 5000);
                                        }
                                    });
                            }
                        }
                    }
                }
            }
        );
    }


    popupOdevTanimiGizle = () => {
        this.setState({
            odevTanimiVisible: false
        });
    };

    popupOdevTanimiAc = () => {
        this.setState({
            odevTanimiVisible: true
        });
    };

    gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.satir = null;
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }

    async getSecilenOgrenciler(odevId) {
        return new Promise((resolve, reject) => {
            service.get(otomasyonApi + "odev/getSecilenOgrenciler/" + odevId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        let arrayList = [];
                        res.data.Data.forEach((item, index) => {
                            arrayList.push(item.OgrenciId)
                        });
                        this.secilenOgrenciler = arrayList;
                        this.formOdevTanimiData.Ogrenciler = this.secilenOgrenciler;
                        this.gridOgrenci.instance.selectRows(this.secilenOgrenciler);
                        resolve("Öğrenci bilgileri getirildi");
                    }
                    else {
                        notify(res.data.ErrorMessage, 'error', 500);
                        reject(res.data.ErrorMessage);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }

    onInitializedDropDownBoxKonu(e) {
        this.dropDownBoxKonu = e.component;
    }

    onInitializedGridKonu(e) {
        this.gridKonu = e.value;
    }

    onKonuDataGridSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
            this.gridKonuBoxValue = [];
        }
        else {
            this.gridKonuBoxValue = e.selectedRowKeys;
        }
        this.dropDownBoxKonu.instance.option("value", this.gridKonuBoxValue);
    }

    onDropDownBoxKonuValueChanged(e) {
        if (this.gridKonuBoxValue === e.value) {
            return;
        }
        else {
            this.gridKonuBoxValue = e.value || [];
            this.dropDownBoxKonu.instance.option("value", this.gridKonuBoxValue);
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Ödev ve Etüt İşlemleri>>Ödev Takip"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.odevDataSource}
                                remoteOperations={true}
                                repaintChangesOnly={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                showCheckBoxesMode={true}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={true}
                                key="Id"
                                onInitialized={this.onInitialized}
                                onFocusedRowChanged={(e) => {
                                    this.satirIndex = e.rowIndex;
                                    var rowData = e.row && e.row.data;
                                    if (rowData) {
                                        this.satir = rowData;
                                    }
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export
                                    enabled={Globals.service.Rol === "SubeOgretmen" ? true : false}
                                    fileName={'Öğrenciler'}
                                    allowExportSelectedData={false}
                                    texts={
                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                    }
                                />
                                <ColumnFixing enabled={true} />
                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? true : false} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Id" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" />
                                <Column dataField={'KonuAdi'} caption="Konu" />
                                <Column dataField={'SeviyeAdi'} caption="Sınıf" />
                                <Column dataField={'SubeAdi'} caption="Şube" />
                                <Column dataField={'Baslik'} caption="Başlık" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    width={"90%"} height={"90%"}
                    visible={this.state.odevTanimiVisible}
                    title={"Seçili öğrencilere ödev ekleyebilirsiniz"}
                    toolbarItems={
                        [

                            {
                                location: 'after',
                                widget: 'dxButton',
                                options: {
                                    text: 'Kaydet',
                                    type: 'success',
                                    hint: 'Kaydet',
                                    disabled: this.formOdevTanimiData.Id === 0 ? false : true,
                                    icon: 'check'
                                    , onClick: async () => {
                                        if (this.gridKonuBoxValue === undefined | this.gridKonuBoxValue === null | this.gridKonuBoxValue === "") {
                                            alert("Lütfen en az 1 konu seçiniz", "Uyarı");
                                            return;
                                        }
                                        if (this.secilenOgrenciler.length === 0) {
                                            alert("Lütfen en az 1 öğrenci seçiniz", "Uyarı");
                                            return;
                                        }
                                        const validateStatus = this.formOdevTanimi.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {
                                            var konular = [];
                                            this.gridKonuBoxValue.forEach((satir) => {
                                                konular.push({
                                                    Id: satir
                                                })
                                            });
                                            this.formOdevTanimiData.Konular = konular;

                                            this.formOdevTanimiData.Ogrenciler = [];
                                            this.formOdevTanimiData.Ogrenciler = this.secilenOgrenciler;
                                            service.post(otomasyonApi + "odev/save", this.formOdevTanimiData, {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            })
                                                .then(async (res) => {
                                                    if (res.data.Success) {
                                                        notify("Ödev kaydınız eklendi", "success", 5000);
                                                        await this.gridMainSatirIptal();
                                                        await this.gridMain.instance.refresh().done(() => {

                                                            this.popupOdevTanimiGizle();
                                                        });
                                                    } else {
                                                        notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                                                    }
                                                })
                                                .catch(err => {
                                                    if (err.response) {
                                                        var resError = JSON.parse(err.response.request.response);
                                                        var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                        var dictionary = Object.keys(resError.errors);
                                                        dictionary.forEach((satir, index) => {
                                                            mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                        });
                                                        mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                        mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                        mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                        alert(mesaj);
                                                        // client received an error response (5xx, 4xx)
                                                    } else if (err.request) {
                                                        // client never received a response, or request never left
                                                    } else {
                                                        // anything else
                                                    }
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: async () => {
                                        await this.gridMainSatirIptal();
                                        await this.popupOdevTanimiGizle();
                                    }
                                }
                            }
                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form colCount={1} formData={this.formOdevTanimiData} onInitialized={this.onInitializedFormOdevTanimi} ref={(ref) => this.formOdevTanimi = ref}>
                                    <GroupItem cssClass="first-group" colCount={2}>
                                        <SimpleItem dataField={"SinifSeviye.Id"} editorType={"dxSelectBox"} editorOptions={{
                                            dataSource: this.sinifSeviyeDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi",
                                            onInitialized: this.onInitializedSelectBoxSinifSeviye,
                                            ref: (ref) => this.selectBoxSinifSeviye = ref,
                                            onValueChanged: async (e) => {
                                                this.selectBoxSinifSube.instance().option("value", null);
                                                var dataSource = this.selectBoxSinifSube.instance().option("dataSource");
                                                dataSource.filter(["SeviyeId", "=", e.value]);
                                                await dataSource.load().done(() => {
                                                    this.selectBoxSinifSube.instance().option("dataSource", dataSource);
                                                });
                                                this.seviyeFilter.seviyeId = e.value;
                                                this.gridKonu.instance.clearSelection();
                                                this.gridKonu.instance.refresh();
                                                this.dropDownBoxKonu.instance.option("value", null);
                                                this.secilenOgrenciler = [];

                                                if (this.formOdevTanimiData.Id === 0) {
                                                    this.ogrenciDataSource.filter(["SinifSeviye.Id", "=", e.value]);
                                                    this.gridOgrenci.instance.refresh();
                                                }

                                                this.gridOgrenci.instance.clearSelection();
                                            }
                                        }}>
                                            <Label text="Seviye"></Label>
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinifSube.Id"} editorType={"dxSelectBox"} editorOptions={{
                                            dataSource: this.sinifSubeDataSource,
                                            valueExpr: "Id",
                                            displayExpr: "Adi",
                                            onInitialized: this.onInitializedSelectBoxSinifSube,
                                            ref: (ref) => this.selectBoxSinifSube = ref,
                                            onValueChanged: (e) => {
                                                this.secilenOgrenciler = [];
                                                this.ogrenciDataSource.filter(["SinifSube.Id", "=", e.value]);
                                                this.gridOgrenci.instance.refresh();
                                                this.gridOgrenci.instance.clearSelection();
                                            }
                                        }}>
                                            <Label text="Şube"></Label>
                                            <RequiredRule message="Şube alanı zorunludur"></RequiredRule>
                                        </SimpleItem>
                                        <SimpleItem dataField={"Konu.Id"}>
                                            <DropDownBox
                                                valueExpr="Id"
                                                deferRendering={false}
                                                displayExpr="Adi"
                                                showClearButton={true}
                                                dataSource={this.konuDataSource}
                                                contentRender={this.konuDataGridRender}
                                                onValueChanged={this.onDropDownBoxKonuValueChanged}
                                                onInitialized={this.onInitializedDropDownBoxKonu}
                                                ref={(ref) => { this.dropDownBoxKonu = ref }}
                                            />
                                            <Label text="Konu"></Label>
                                            <RequiredRule message="Konu alanı zorunludur" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SoruSayisi"} editorType={"dxNumberBox"} editorOptions={{
                                            onInitialized: (e) => {
                                                this.textBoxSoruSayisi = e.component;
                                            },
                                            ref: (ref) => this.textBoxSoruSayisi = ref,
                                        }}>
                                            <Label text="Soru sayısı"></Label>
                                        </SimpleItem>
                                        <SimpleItem dataField={"File"}
                                            editorType="dxFileUploader"
                                            editorOptions={{
                                                uploadUrl: otomasyonApi + "odev/DosyaUpload",
                                                uploadMode: 'instantly',
                                                uploadHeaders: this.headers,
                                                accept: '*',
                                                maxFileSize: 4000000,
                                                name: 'myFile',
                                                onUploaded: this.onUploaded
                                            }}>
                                            <Label text="Dosya seçiniz" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1} caption="Ödev">
                                        <TabbedItem>
                                            <TabPanelOptions deferRendering={false} />
                                            <Tab title="Ödev İçeriği">
                                                <SimpleItem dataField={"Baslik"} editorType={"dxTextBox"} editorOptions={{
                                                    onInitialized: (e) => {
                                                        this.textBoxBaslik = e.component;
                                                    },
                                                    ref: (ref) => this.textBoxBaslik = ref,
                                                }}>
                                                    <Label text="Başlık"></Label>
                                                    <RequiredRule message="Başlık alanı zorunludur"></RequiredRule>
                                                </SimpleItem>
                                                <SimpleItem dataField={"Odev"} editorType={"dxHtmlEditor"} editorOptions={{
                                                    height: 300,
                                                    toolbar: {
                                                        items: [
                                                            "bold",
                                                            "italic",
                                                            "color",
                                                            "background",
                                                            "link",
                                                            {
                                                                "formatName": "header",
                                                                "formatValues": [
                                                                    1,
                                                                    2,
                                                                    3,
                                                                    false
                                                                ]
                                                            },
                                                            "variable",
                                                            "strike",
                                                            "image",
                                                            "subscript",
                                                            "superscript",
                                                            "underline",
                                                            "blockquote",
                                                            "increaseIndent",
                                                            "decreaseIndent",
                                                            "orderedList",
                                                            "bulletList",
                                                            "alignLeft",
                                                            "alignCenter",
                                                            "alignRight",
                                                            "alignJustify",
                                                            "codeBlock",
                                                            "undo",
                                                            "redo",
                                                            "clear",
                                                            "separator"
                                                        ],
                                                        multiline: true,

                                                    },
                                                    onInitialized: (e) => {
                                                        this.textBoxOdev = e.component;
                                                    },
                                                    ref: (ref) => this.textBoxOdev = ref,
                                                }}>
                                                    <Label text="Ödev"></Label>
                                                </SimpleItem>

                                            </Tab>
                                            <Tab title="Öğrenciler">
                                                <DataGrid
                                                    showBorders={true}
                                                    showRowLines={true}
                                                    dataSource={this.ogrenciDataSource}
                                                    remoteOperations={true}
                                                    repaintChangesOnly={true}
                                                    columnHidingEnabled={true}
                                                    filterPanel={{ visible: true }}
                                                    filterRow={{ visible: true }}
                                                    columnAutoWidth={true}
                                                    selection={{ mode: 'multiple' }}
                                                    showCheckBoxesMode={true}
                                                    autoNavigateToFocusedRow={true}
                                                    focusedRowEnabled={true}
                                                    hoverStateEnabled={true}
                                                    rowAlternationEnabled={true}
                                                    key="Id"
                                                    keyExpr="Id"
                                                    onToolbarPreparing={this.onToolbarPreparingGridOgrenci}
                                                    onInitialized={this.onInitializedGridOgrenci}
                                                    onSelectedRowKeysChange={(e) => {
                                                        this.secilenOgrenciler = [];
                                                        if (e.length > 0) {
                                                            e.forEach((satir) => {
                                                                this.secilenOgrenciler.push({
                                                                    OgrenciId: satir
                                                                });
                                                            });
                                                        }
                                                    }}
                                                    onEditingStart={(e) => {
                                                    }}

                                                    ref={(ref) => this.gridOgrenci = ref}
                                                >
                                                    <Export
                                                        enabled={Globals.service.Rol === "SubeOgretmen" ? false : true}
                                                        fileName={'Öğrenciler'}
                                                        allowExportSelectedData={false}
                                                        texts={
                                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                                        }
                                                    />
                                                    <ColumnFixing enabled={true} />
                                                    <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? false : true} />
                                                    <LoadPanel enabled={true} />
                                                    <FilterRow visible={true} />
                                                    <FilterPanel visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <Paging defaultPageSize={15} enabled={true} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                                    <Column dataField={'Deleted'} caption="Pasif" />
                                                    <Column dataField={'Adi'} caption="Adı">
                                                        <RequiredRule message="Ad alanı zorunludur" />
                                                    </Column>
                                                    <Column dataField={'Soyadi'} caption="Soyad">
                                                        <RequiredRule message="Soyad alanı zorunludur" />
                                                    </Column>
                                                    <Column dataField={'Seviye'} caption="Sınıf" />
                                                    <Column dataField={'Sube'} caption="Şube" />
                                                    <Column dataField={'KayitT'} caption="Kayıt Turu" />
                                                    <Column dataField={'OdevDurum'} caption="Ödev Durumu" />

                                                </DataGrid>
                                            </Tab>
                                        </TabbedItem>

                                    </GroupItem>
                                </Form><br></br>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }

    konuDataGridRender() {
        return (
            <DataGrid
                dataSource={this.konuDataSource}
                hoverStateEnabled={true}
                onSelectionChanged={this.onKonuDataGridSelectionChanged}
                columns={["SinavTuru", "DersAdi", "Adi"]}
                onInitialized={this.onInitializedGridKonu}
                ref={(ref) => { this.gridKonu = ref }}
            >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}