import React from 'react';
import { Chart, Series, Title, Label, Format, CommonSeriesSettings } from 'devextreme-react/chart';
import PieChart, { Legend, HoverStyle, Tooltip } from 'devextreme-react/pie-chart';
import service from 'axios';
import { otomasyonApi } from '../Util';
import Globals from '../Globals';
import notify from 'devextreme/ui/notify';
import List from 'devextreme-react/list';
import { Popover } from 'devextreme-react/popover';
import '../DashboardList.css';
const currencyFormatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
export default class KayitKabulDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HaftalikKayitSayilari: [],
            HaftalikTahsilatlar: [],
            SinifSeviyesiKayitSayilari: [],
            SinifSeviyesiKayitSayilariBugun: [],
            BugunTaksitTahsilEdilmesiGerekenBilgiler: [],
            DashboardData: []
        }
        this.toolTipKayitSayisiniGoster = null;
        this.toolTipBugunTahsilEdilmesiGerekenKayitSayisi = null;
        service.get(otomasyonApi + "dashboard/listhaftalikkayitsayilari",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        HaftalikKayitSayilari: res.data.Data
                    });
                }
                else
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            });

        service.get(otomasyonApi + "dashboard/listhaftaliktahsilatlar",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        HaftalikTahsilatlar: res.data.Data
                    });
                }
                else
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            });

        service.get(otomasyonApi + "dashboard/listsinifseviyesikayitsayilari",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        SinifSeviyesiKayitSayilari: res.data.Data
                    });
                }
                else
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            });

        service.get(otomasyonApi + "dashboard/listsinifseviyesikayitsayilaribugun",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        SinifSeviyesiKayitSayilariBugun: res.data.Data
                    });
                }
                else
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            });

        service.get(otomasyonApi + "dashboard/listgetbuguntahsilatedilmesigerekenbilgiler",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        BugunTaksitTahsilEdilmesiGerekenBilgiler: res.data.Data
                    });
                }
                else
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
            });

        service.get(otomasyonApi + "dashboard/listdashboarddata",
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            })
            .then(res => {
                if (res.data.Success) {
                    let result = res.data.Data;
                    var dashboardData = [];
                    dashboardData.push({
                        Name: "<b>Bugün</b> Yapılan Tahsilat",
                        Img: "./icons/try1.png",
                        Value: currencyFormatter.format(result.BugunkuTahsilat)
                    });
                    dashboardData.push({
                        Name: "<b>Toplam</b> Tahsilat",
                        Img: "./icons/try.png",
                        Value: currencyFormatter.format(result.ToplamTahsilat)
                    });
                    dashboardData.push({
                        Name: "<b>Bugün</b> Yapılan Kayıt Sayısı",
                        Img: "./icons/kayit1.png",
                        Value: result.BugunYapilanKayitSayisi
                    });
                    dashboardData.push({
                        Name: "<b>Toplam</b> Yapılan Kayıt Sayısı",
                        Img: "./icons/kayittop.png",
                        Value: result.ToplamYapilanKayitSayisi
                    });
                    dashboardData.push({
                        Name: "<b>Bugün</b> Tahsil Edilmesi Gereken Taksit Sayısı",
                        Img: "./icons/taksit1.png",
                        Value: result.BugunTahsilEdilmesiGerekenTaksitSayisi
                    });
                    dashboardData.push({
                        Name: "<b>Toplam</b> Tahsil Edilmesi Gereken Taksit Sayısı",
                        Img: "./icons/taksittop.png",
                        Value: result.ToplamTahsilEdilmesiGerekenTaksitSayisi
                    });
                    this.setState({
                        DashboardData: dashboardData
                    });
                }
                else {
                    notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                }
            });
    }
    customizeTooltip(arg) {
        return {
            text: `${arg.argumentText + ",kayıt sayısı " + arg.value + "(" + arg.percentText + ")"}`
        };
    }
    getBugunkuKayitSayilari() {
        if (this.state.SinifSeviyesiKayitSayilariBugun.length === 0) {
            return (
                <React.Fragment>
                    <strong>Bugün için herhangi bir kayıt ghirilmemiş</strong>
                </React.Fragment>

            );
        } else {
            return (
                <React.Fragment>
                    <div>
                        <table className='table' style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Kurum Adı</th>
                                    <th>Sınıf</th>
                                    <th>Sayı</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.SinifSeviyesiKayitSayilariBugun.map((kayitData) => {
                                        return (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        {kayitData.KurumAdi}
                                                    </td>
                                                    <td>
                                                        {kayitData.Adi}
                                                    </td>
                                                    <td>
                                                        {kayitData.Sayi}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div><br></br>
                </React.Fragment>
            )
        }
    }
    getBugunTahsilEdilmesiGerekenSayilar() {
        if (this.state.BugunTaksitTahsilEdilmesiGerekenBilgiler.length === 0) {
            return (
                <React.Fragment>
                    <strong>Bugün için herhangi bir kayıt ghirilmemiş</strong>
                </React.Fragment>

            );
        } else {

            return (
                <React.Fragment>
                    <div>
                        <table className='table' style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Taksit No</th>
                                    <th>Kurum Adı</th>
                                    <th>Öğrenci No</th>
                                    <th>Ad Soyad</th>
                                    <th>Tutar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.BugunTaksitTahsilEdilmesiGerekenBilgiler.map((kayitData) => {
                                        return (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        {kayitData.TaksitNo}
                                                    </td>
                                                    <td>
                                                        {kayitData.KurumAdi}
                                                    </td>
                                                    <td>
                                                        {kayitData.OgrenciNo}
                                                    </td>
                                                    <td>
                                                        {kayitData.AdSoyad}
                                                    </td>
                                                    <td>
                                                        {kayitData.Tutar}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div><br></br>
                </React.Fragment>
            );

        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" style={{ border: "1px solid silver", padding: "10px 10px 10px 10px" }}>
                            <List
                                dataSource={this.state.DashboardData}
                                height="100%"
                                itemRender={(data) => {
                                    if (data.Img === './icons/kayit1.png') {
                                        return (<React.Fragment>
                                            <Popover
                                                target="#divBugunYapilanKayitSayisi"
                                                position="top"
                                                width={"50%"}
                                                showTitle={true}
                                                title={"Bilgi"}
                                                ref={(ref) => this.toolTipKayitSayisiniGoster = ref}
                                            >
                                                {

                                                    this.getBugunkuKayitSayilari()
                                                }
                                            </Popover>
                                            <div className="product">
                                                <img src={data.Img} alt={data.Name} />
                                                <div id="divBugunYapilanKayitSayisi" dangerouslySetInnerHTML={{ __html: data.Name }} onMouseOver={() => {
                                                    this.toolTipKayitSayisiniGoster.instance.option("visible", true);
                                                }} onMouseLeave={() => {
                                                    this.toolTipKayitSayisiniGoster.instance.option("visible", false);
                                                }}></div>
                                                <div className="price">{data.Value}</div>
                                            </div>
                                        </React.Fragment>)
                                    }
                                    else if (data.Img === './icons/taksit1.png') {
                                        return (<React.Fragment>
                                            <Popover
                                                target="#divBugunTahsilEdilmesiGerekenKayitSayisi"
                                                position="top"
                                                width={"50%"}
                                                showTitle={true}
                                                title={"Bilgi"}
                                                ref={(ref) => this.toolTipBugunTahsilEdilmesiGerekenKayitSayisi = ref}
                                            >
                                                {
                                                    this.getBugunTahsilEdilmesiGerekenSayilar()
                                                }
                                            </Popover>
                                            <div className="product">
                                                <img src={data.Img} alt={data.Name} />
                                                <div id="divBugunTahsilEdilmesiGerekenKayitSayisi" dangerouslySetInnerHTML={{ __html: data.Name }} onMouseOver={() => {
                                                    this.toolTipBugunTahsilEdilmesiGerekenKayitSayisi.instance.option("visible", true);
                                                }} onMouseLeave={() => {
                                                    this.toolTipBugunTahsilEdilmesiGerekenKayitSayisi.instance.option("visible", false);
                                                }}></div>
                                                <div className="price">{data.Value}</div>
                                            </div>
                                        </React.Fragment>)
                                    }
                                    else {
                                        return (<React.Fragment>
                                            <div className="product">
                                                <img src={data.Img} alt={data.Name} />
                                                <div dangerouslySetInnerHTML={{ __html: data.Name }}></div>
                                                <div className="price">{data.Value}</div>
                                            </div>
                                        </React.Fragment>)
                                    }
                                }} />
                        </div>

                        <div className="col-md-6" style={{ border: "1px solid silver", padding: "10px 10px 10px 10px" }}>
                            <Chart id="chart"
                                palette="Soft"
                                dataSource={this.state.HaftalikKayitSayilari}>
                                <CommonSeriesSettings
                                    argumentField="state"
                                    type="bar"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                    barPadding={0.1}
                                    barWidth={10}
                                >
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={0} />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series
                                    valueField="Sayi"
                                    argumentField="Gun"
                                    type="bar"
                                    name=""
                                    showInLegend={false}
                                    width={10} />
                                <Title text="Kayıt Sayıları" subtitle="(Haftalık)" />
                            </Chart>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6" style={{ border: "1px solid silver", padding: "10px 10px 10px 10px" }}>
                            <Chart palette="Soft Pastel" id="chart" dataSource={this.state.HaftalikTahsilatlar} loadingIndicator={true}>
                                <CommonSeriesSettings
                                    argumentField="state"
                                    type="bar"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                    barPadding={0.1}
                                    barWidth={10}
                                >
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={0} />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series
                                    valueField="Tutar"
                                    argumentField="Gun"
                                    type="bar"
                                    name=""
                                    showInLegend={false}
                                    width={10}
                                    color="lightgreen" />
                                <Title text="Tahsilat Miktarı" subtitle="(Haftalık)" />
                            </Chart>
                        </div>

                        <div className="col-md-6" style={{ border: "1px solid silver", padding: "10px 10px 10px 10px" }}>
                            <PieChart
                                id="pie"
                                type="doughnut"
                                title="Sınıf Seviyelerine Göre Kayıt Sayıları"
                                palette="Soft Pastel"
                                dataSource={this.state.SinifSeviyesiKayitSayilari}
                            >
                                <Series argumentField="Adi" valueField="Sayi">
                                    <HoverStyle color="#ffd700" />
                                </Series>
                                <Legend
                                    margin={10}
                                    horizontalAlignment="right"
                                    verticalAlignment="top"
                                />
                                <Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
                                    <Format type="number" />
                                </Tooltip>
                            </PieChart>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}