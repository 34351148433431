export default class DersTanim {
    GetDersler(sinavTuru) {
        switch (sinavTuru) {
            case "TYT":
                return [
                    { DersSimge: "Tur", DersAdi: "Türkçe" }
                    , { DersSimge: "Mat", DersAdi: "Matematik" }
                    , { DersSimge: "Tarih", DersAdi: "Tarih" }
                    , { DersSimge: "Cogr", DersAdi: "Coğrafya" }
                    , { DersSimge: "Fels", DersAdi: "Felsefe" }
                    , { DersSimge: "SFels", DersAdi: "S.Felsefe" }
                    , { DersSimge: "DinK", DersAdi: "Din Kültürü" }
                    , { DersSimge: "Fizik", DersAdi: "Fizik" }
                    , { DersSimge: "Kimya", DersAdi: "Kimya" }
                    , { DersSimge: "Biyo", DersAdi: "Biyoloji" }
                ];
            case "AYT":
                return [
                    { DersSimge: "Edb", DersAdi: "Edebiyat" }
                    , { DersSimge: "Trh", DersAdi: "Tarih" }
                    , { DersSimge: "Cog", DersAdi: "Coğrafya" }
                    , { DersSimge: "Mtm", DersAdi: "Matematik" }
                    , { DersSimge: "Tar", DersAdi: "Tarih 2" }
                    , { DersSimge: "Cgr", DersAdi: "Coğrafya 2" }
                    , { DersSimge: "Fel", DersAdi: "Felsefe Grubu" }
                    , { DersSimge: "Din", DersAdi: "Din Kültürü" }
                    , { DersSimge: "Fls", DersAdi: "S. Felsefe" }
                    , { DersSimge: "Fiz", DersAdi: "Fizik" }
                    , { DersSimge: "Kim", DersAdi: "Kimya" }
                    , { DersSimge: "Biy", DersAdi: "Biyoloji" }
                ];
            case "9.SINIF":
                return [
                    { DersSimge: "Edb", DersAdi: "Edebiyat" }
                    , { DersSimge: "Trh", DersAdi: "Tarih" }
                    , { DersSimge: "Cog", DersAdi: "Coğrafya" }
                    , { DersSimge: "Mtm", DersAdi: "Matematik" }
                    , { DersSimge: "Tar", DersAdi: "Tarih 2" }
                    , { DersSimge: "Cgr", DersAdi: "Coğrafya 2" }
                    , { DersSimge: "Fel", DersAdi: "Felsefe Grubu" }
                    , { DersSimge: "Din", DersAdi: "Din Kültürü" }
                    , { DersSimge: "Fls", DersAdi: "S. Felsefe" }
                    , { DersSimge: "Fiz", DersAdi: "Fizik" }
                    , { DersSimge: "Kim", DersAdi: "Kimya" }
                    , { DersSimge: "Biy", DersAdi: "Biyoloji" }
                ];
            case "10.SINIF":
                return [
                    { DersSimge: "Edb", DersAdi: "Edebiyat" }
                    , { DersSimge: "Trh", DersAdi: "Tarih" }
                    , { DersSimge: "Cog", DersAdi: "Coğrafya" }
                    , { DersSimge: "Mtm", DersAdi: "Matematik" }
                    , { DersSimge: "Tar", DersAdi: "Tarih 2" }
                    , { DersSimge: "Cgr", DersAdi: "Coğrafya 2" }
                    , { DersSimge: "Fel", DersAdi: "Felsefe Grubu" }
                    , { DersSimge: "Din", DersAdi: "Din Kültürü" }
                    , { DersSimge: "Fls", DersAdi: "S. Felsefe" }
                    , { DersSimge: "Fiz", DersAdi: "Fizik" }
                    , { DersSimge: "Kim", DersAdi: "Kimya" }
                    , { DersSimge: "Biy", DersAdi: "Biyoloji" }
                ];
            case "11.SINIF":
                return [
                    { DersSimge: "Edb", DersAdi: "Edebiyat" }
                    , { DersSimge: "Trh", DersAdi: "Tarih" }
                    , { DersSimge: "Cog", DersAdi: "Coğrafya" }
                    , { DersSimge: "Mtm", DersAdi: "Matematik" }
                    , { DersSimge: "Tar", DersAdi: "Tarih 2" }
                    , { DersSimge: "Cgr", DersAdi: "Coğrafya 2" }
                    , { DersSimge: "Fel", DersAdi: "Felsefe Grubu" }
                    , { DersSimge: "Din", DersAdi: "Din Kültürü" }
                    , { DersSimge: "Fls", DersAdi: "S. Felsefe" }
                    , { DersSimge: "Fiz", DersAdi: "Fizik" }
                    , { DersSimge: "Kim", DersAdi: "Kimya" }
                    , { DersSimge: "Biy", DersAdi: "Biyoloji" }
                ];
            case "8.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SSb", DersAdi: "Sosyal / İnkılap" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                    , { DersSimge: "SDk", DersAdi: "Din Kültürü" }
                ];
            case "7.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SSb", DersAdi: "Sosyal / İnkılap" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                    , { DersSimge: "SDk", DersAdi: "Din Kültürü" }
                ];
            case "6.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SSb", DersAdi: "Sosyal / İnkılap" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                    , { DersSimge: "SDk", DersAdi: "Din Kültürü" }
                ];
            case "5.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SSb", DersAdi: "Sosyal / İnkılap" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                    , { DersSimge: "SDk", DersAdi: "Din Kültürü" }
                ];
            case "4.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SSb", DersAdi: "Sosyal / İnkılap" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                ];
            case "3.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SFt", DersAdi: "Fen Bilimleri" }
                    , { DersSimge: "SHy", DersAdi: "Hayat Bilgisi" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                ];
            case "2.SINIF":
                return [
                    { DersSimge: "STr", DersAdi: "Türkçe" }
                    , { DersSimge: "SMt", DersAdi: "Matematik" }
                    , { DersSimge: "SHy", DersAdi: "Hayat Bilgisi" }
                    , { DersSimge: "SIn", DersAdi: "Ingilizce" }
                ];
            case "YAZILI":
                return [
                    { DersSimge: "Drs", DersAdi: "Yazılı" }
                ];
            default:
                return [];
        }
    }
}