import React from 'react';
import { otomasyonApi } from '../../Util';
import Globals from '../../Globals';
import { alert, confirm } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { Button } from 'devextreme-react/button';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import ArrayStore from 'devextreme/data/array_store';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, Export, Selection, Scrolling

} from 'devextreme-react/data-grid';
import List from 'devextreme-react/list';
let selectedOgrenciList = [];
export default class DevamsizlikFilterPage extends React.Component {

    constructor(props) {
        super(props);
        this.buttonOptions = {
            text: 'Öğrencileri Getir',
            type: 'success',
            width: "100%",
            useSubmitBehavior: false,
            onClick: () => {
                this.setState({
                    filter: false,
                    Tarih: this.formData.Tarih
                })
            }
        };
        this.state = {
            filter: true,
            selectedItemKeys: [],
            Tarih: new Date()
        };
        this.dersDataGridRender = this.dersDataGridRender.bind(this);
        this.onInitializedFormDevamsizlik = this.onInitializedFormDevamsizlik.bind(this);
        this.onInitializedDropDownBoxDers = this.onInitializedDropDownBoxDers.bind(this);
        this.onInitializedGridDers = this.onInitializedGridDers.bind(this);
        this.onInitializedSelectBoxSinifSube = this.onInitializedSelectBoxSinifSube.bind(this);
        this.onInitializedSelectBoxSinifSeviye = this.onInitializedSelectBoxSinifSeviye.bind(this);
        this.onDersDataGridSelectionChanged = this.onDersDataGridSelectionChanged.bind(this);
        this.onDropDownBoxDersValueChanged = this.onDropDownBoxDersValueChanged.bind(this);
        this.onInitializedOgrenciList = this.onInitializedOgrenciList.bind(this);
        this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);
        this.formDevamsizlik = null;
        this.gridDers = null;
        this.selectBoxSinifSeviye = null;
        this.selectBoxSinifSube = null;
        this.listOgrenci = null;
        var tx = new Date();
        tx.setHours(tx.getHours() + 3)

        const dersData = [
            {
                Id: 1,
                Adi: "1. Ders"
            },
            {
                Id: 2,
                Adi: "2. Ders"
            },
            {
                Id: 3,
                Adi: "3. Ders"
            },
            {
                Id: 4,
                Adi: "4. Ders"
            },
            {
                Id: 5,
                Adi: "5. Ders"
            },
            {
                Id: 6,
                Adi: "6. Ders"
            },
            {
                Id: 7,
                Adi: "7. Ders"
            },
            {
                Id: 8,
                Adi: "8. Ders"
            }
        ];

        this.dersler = new ArrayStore({
            key: 'Id',
            data: dersData,
            // Other ArrayStore properties go here
        });

        this.formData = {
            Dersler: [],
            SinifSeviye: { Id: null },
            SubeId: null,
            Konular: [],
            Tip: null,
            Tur: null,
            Icerik: null,
            Tarih: tx.toJSON(),
            Ogrenciler: []
        }

        this.sinifSeviyeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.sinifSubeDataSource = new DataSource({
            loadMode: "raw",
            store: createStore({
                key: "Id",
                loadUrl: otomasyonApi + 'geneltanim/listsinifsubebykullanici',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.seviyeFilter = {
            seviyeId: 0
        };

        this.ogrenciFilter = {
            subeId: 0,
            seviyeId: 0
        };

        this.ogrenciDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciFilter,
                    loadUrl: otomasyonApi + 'ogrenci/getKurumOgrencileriBySubeIdSeviyeId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    onInitializedFormDevamsizlik(e) {
        this.formDevamsizlik = e.component;
    }

    onInitializedDropDownBoxDers(e) {
        this.dropDownBoxDers = e.component;
    }
    onInitializedGridDers(e) {
        this.gridDers = e.component;
    }

    onInitializedSelectBoxSinifSube(e) {
        this.selectBoxSinifSube = e.component;
    }

    onInitializedSelectBoxSinifSeviye(e) {
        this.selectBoxSinifSeviye = e.component;
    }

    onDersDataGridSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
            this.gridDersBoxValue = [];
        }
        else {
            this.gridDersBoxValue = e.selectedRowKeys;
        }
        this.dropDownBoxDers.instance.option("value", this.gridDersBoxValue);
    }

    onDropDownBoxDersValueChanged(e) {
        if (this.gridDersBoxValue === e.value) {
            return;
        }
        else {
            this.gridDersBoxValue = e.value || [];
            this.dropDownBoxDers.instance.option("value", this.gridDersBoxValue);
        }
    }

    dersDataGridRender() {
        return (
            <DataGrid
                dataSource={this.dersler}
                hoverStateEnabled={true}
                onSelectionChanged={this.onDersDataGridSelectionChanged}
                columns={["Adi"]}
                onInitialized={this.onInitializedGridDers}
                ref={(ref) => { this.gridDers = ref }}
            >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    onInitializedOgrenciList(e) {
        this.listOgrenci = e.component;
    }

    onSelectedItemKeysChange(args) {
        if (args.name === 'selectedItemKeys') {
            this.setState({
                selectedItemKeys: args.value,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className='container'>
                    <div className='row' style={{ marginTop: "10px", display: this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <Form formData={this.formData} onInitialized={this.onInitializedFormDevamsizlik} ref={(ref) => this.formDevamsizlik = ref}>
                                <SimpleItem dataField={"Ders.Id"}>
                                    <DropDownBox
                                        valueExpr="Id"
                                        deferRendering={false}
                                        displayExpr="Adi"
                                        showClearButton={true}
                                        dataSource={this.dersler}
                                        contentRender={this.dersDataGridRender}
                                        onValueChanged={this.onDropDownBoxDersValueChanged}
                                        onInitialized={this.onInitializedDropDownBoxDers}
                                        ref={(ref) => { this.dropDownBoxDers = ref }}
                                    />
                                    <Label text="Ders"></Label>
                                    <RequiredRule message="Ders alanı zorunludur" />
                                </SimpleItem>
                                <SimpleItem dataField={"SinifSeviye.Id"} editorType={"dxSelectBox"} editorOptions={{
                                    dataSource: this.sinifSeviyeDataSource,
                                    valueExpr: "Id",
                                    displayExpr: "Adi",
                                    onInitialized: this.onInitializedSelectBoxSinifSeviye,
                                    ref: (ref) => this.selectBoxSinifSeviye = ref,
                                    onValueChanged: async (e) => {
                                        this.selectBoxSinifSube.instance().option("value", null);
                                        var dataSource = this.selectBoxSinifSube.instance().option("dataSource");
                                        dataSource.filter(["SeviyeId", "=", e.value]);
                                        await dataSource.load().done(() => {
                                            this.selectBoxSinifSube.instance().option("dataSource", dataSource);
                                        });
                                        this.seviyeFilter.seviyeId = e.value;
                                        this.ogrenciFilter.seviyeId = e.value;
                                        this.ogrenciFilter.subeId = 0;
                                    }
                                }}>
                                    <Label text="Seviye"></Label>
                                    <RequiredRule message="Seviye alanı zorunludur"></RequiredRule>
                                </SimpleItem>
                                <SimpleItem dataField={"SubeId"} editorType={"dxSelectBox"} editorOptions={{
                                    dataSource: this.sinifSubeDataSource,
                                    valueExpr: "Id",
                                    displayExpr: "Adi",
                                    onInitialized: this.onInitializedSelectBoxSinifSube,
                                    ref: (ref) => this.selectBoxSinifSube = ref,
                                    onValueChanged: async (e) => {
                                        this.ogrenciFilter.subeId = e.value;
                                        await this.ogrenciDataSource.load().then((res) => {
                                            this.listOgrenci.instance.option("dataSource", this.ogrenciDataSource);
                                        });


                                    }

                                }}>
                                    <Label text="Şube"></Label>
                                    <RequiredRule message="Şube alanı zorunludur"></RequiredRule>
                                </SimpleItem>
                                <SimpleItem dataField={"Tur"} editorType={"dxSelectBox"}
                                    editorOptions={{
                                        showClearButton: true,
                                        dataSource: new DataSource({
                                            loadMode: "raw",
                                            store: createStore({
                                                key: "Value",
                                                loadParams: {
                                                    enumName: "DevamsizlikTuru"
                                                },
                                                loadUrl: otomasyonApi + 'common/getenums',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    };
                                                }
                                            })
                                        }),
                                        valueExpr: "Value",
                                        displayExpr: "Name"
                                    }}
                                >
                                    <Label text="Durum"></Label>
                                    <RequiredRule message="Durum alanı zorunludur" />
                                </SimpleItem>
                                <SimpleItem dataField={"Tarih"} editorType={"dxDateBox"}
                                    editorOptions={{
                                        value: new Date(),
                                        onValueChanged: (e) => {
                                            var t = new Date(e.value);
                                            t.setHours(t.getHours() + 3);
                                            this.formData.Tarih = t;

                                        }
                                    }}
                                >
                                    <Label text="Tarih"></Label>
                                    <RequiredRule message="Tarih alanı zorunludur" />
                                </SimpleItem>
                                <ButtonItem horizontalAlignment="left"
                                    buttonOptions={this.buttonOptions}
                                />
                            </Form>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", display: !this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <Button
                                text="Geri"
                                icon="back"
                                type="success"
                                width={"100%"}
                                onClick={() => {
                                    this.setState({
                                        filter: true
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", display: !this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <List
                                dataSource={this.ogrenciDataSource}
                                selectionMode="multiple"
                                showSelectionControls={true}
                                onInitialized={this.onInitializedOgrenciList}
                                ref={(ref) => this.listOgrenci = ref}
                                itemRender={OgrenciInfo}
                                searchExpr="Adi"
                                searchEnabled={true}
                                searchMode={"startswith"}
                                indicateLoading={false}
                                pageLoadMode={"scrollBottom"}
                                pageLoadingText='Daha fazla göster'
                                // selectionMode={this.state.selectionMode}
                                // selectAllMode={this.state.selectAllMode}
                                selectedItemKeys={this.state.selectedItemKeys}
                                onOptionChanged={this.onSelectedItemKeysChange}
                            >
                            </List>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", display: !this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <Button
                                width={"100%"}
                                text="Ekle"
                                type="success"
                                onClick={() => {
                                    const validateStatus = this.formDevamsizlik.instance.validate();
                                    if (!validateStatus.isValid) {
                                        return;
                                    }
                                    else {
                                        // if (this.formData.Konu.Id === "" || this.formData.Konu.Id === undefined || this.formData.Konu.Id === null) {
                                        //     this.formData.Konu.Id = 0;
                                        // }
                                        let dersler = [];
                                        this.gridDersBoxValue.forEach((satir) => {
                                            dersler.push({
                                                Id: satir
                                            });
                                        })

                                        var ogrenciler = [];
                                        this.state.selectedItemKeys.forEach((satir) => {
                                            ogrenciler.push({
                                                Id: parseInt(satir)
                                            })
                                        });
                                        this.formData.Ogrenciler = ogrenciler;
                                        this.formData.Dersler = dersler;
                                        this.formData.Tarih = this.state.Tarih;
                                        service.post(otomasyonApi + "devamsizlik/ekle", this.formData, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(async (res) => {
                                                if (res.data.Success) {
                                                    notify("Devamsızlık eklendi", "success", 5000);
                                                    this.gridDevamsizlik.instance.refresh();

                                                } else {
                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                }
                                            })
                                            .catch(err => {
                                                if (err.response) {
                                                    var resError = JSON.parse(err.response.request.response);
                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                    var dictionary = Object.keys(resError.errors);
                                                    dictionary.forEach((satir, index) => {
                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                    });
                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                    alert(mesaj);
                                                    // client received an error response (5xx, 4xx)
                                                } else if (err.request) {
                                                    // client never received a response, or request never left
                                                } else {
                                                    // anything else
                                                }
                                            })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", display: !this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <Button
                                text="Ders SMS Gönder"
                                icon="email"
                                type="success"
                                width={"100%"}
                                onClick={() => {
                                    let dersler = [];
                                    this.gridDersBoxValue.forEach((satir) => {
                                        dersler.push({
                                            Id: satir
                                        });
                                    })
                                    const data = {
                                        Tarih: this.state.Tarih,
                                        DersNo: dersler
                                    };
                                    // this.setState({
                                    //     toolbarDisabled: true
                                    // });
                                    service.post(otomasyonApi + "sms/postdevamsizliksmsgonder", data,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                // this.setState({
                                                //     toolbarDisabled: false
                                                // });
                                                notify(res.data.Data, "success", 5000);
                                            }
                                            else {
                                                // this.setState({
                                                //     toolbarDisabled: false
                                                // });
                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                            }
                                        })
                                        .catch(err => {
                                            // this.setState({
                                            //     toolbarDisabled: false
                                            // });
                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                        });
                                }}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "10px", display: !this.state.filter ? "" : "none" }}>
                        <div className='col-md-12'>
                            <Button
                                text="Günlük SMS Gönder"
                                icon="email"
                                type="success"
                                width={"100%"}
                                onClick={() => {
                                    const data = {
                                        Tarih: this.state.Tarih,
                                        DersNo: this.formData.Dersler
                                    };
                                    // this.setState({
                                    //     toolbarDisabled: true
                                    // });
                                    service.post(otomasyonApi + "sms/postgunlukdevamsizliksmsgonder", data,
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                        .then(res => {
                                            if (res.data.Success) {
                                                // this.setState({
                                                //     toolbarDisabled: false
                                                // });
                                                notify(res.data.Data, "success", 5000);
                                            }
                                            else {
                                                // this.setState({
                                                //     toolbarDisabled: false
                                                // });
                                                alert("Teknik bir hata oluştu,hata ayrıntısı," + res.data.ErrorMessage, "Hata");
                                            }
                                        })
                                        .catch(err => {
                                            // this.setState({
                                            //     toolbarDisabled: false
                                            // });
                                            alert("Teknik bir hata oluştu,hata ayrıntısı," + err, "Hata");
                                        });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function OgrenciInfo(item) {
    return (
        <React.Fragment>
            <div>{item.Adi} {item.Soyadi}</div>
        </React.Fragment>
    );
}