import AuthorizedLayout from './layouts/AuthorizedLayout';
import GuestLayout from './layouts/GuestLayout';
import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from "./components/shared/AppRoute";
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AyarlarPage from './pages/AyarlarPage';
import HomePage from './pages/HomePage';
import KayitKabulDashboard from './pages/KayitKabulDashboard';
import VeliDashBoard from './pages/VeliDashboard';
import OgrenciDashBoard from './pages/OgrenciDashboard';
import OtomasyonAdminDasboard from './pages/OtomasyonAdminDasboard';
import RolIslemleri from './pages/YonetimModulu/RolIslemleri';
import KullaniciIslemleri from './pages/YonetimModulu/KullaniciIslemleri';
import RolEkranIslemleri from './pages/YonetimModulu/RolEkranIslemleri';
import KurumAktarmaIslemleri from './pages/YonetimModulu/KurumAktarmaIslemleri';
import ExcelKullaniciAktar from './pages/YonetimModulu/ExcelKullaniciAktar';
import OtomasyonKullaniciAktar from './pages/OtomasyonModulu/ExcelKullaniciAktar';
import OtomasyonKullanicilari from './pages/YonetimModulu/OtomasyonKullanicilari';
import OtomasyonGenelTanimlar from './pages/YonetimModulu/OtomasyonGenelTanimlar';
import OtomasyonGenelMerkezTanim from './pages/OtomasyonModulu/GenelTanimlar';
import OtomasyonSubeKullanici from './pages/OtomasyonModulu/SubeKullanici';
import OtomasyonDigerKullanici from './pages/OtomasyonModulu/Kullanici';
import OtomasyonOgrenciKayit from './pages/OtomasyonModulu/OgrenciIslemleri/OgrenciKayit';
import OtomasyonDevamsizlikKayit from './pages/OtomasyonModulu/DevamsizlikIslemleri/DevamsizlikKayit';
import OtomasyonGiderKayit from './pages/OtomasyonModulu/GiderIslemleri/GiderKayit';
import OdevTanim from './pages/OtomasyonModulu/OdevVeEtutIslemleri/OdevTanim';
import EtutTanim from './pages/OtomasyonModulu/OdevVeEtutIslemleri/EtutTanim';
import YayinIslemleri from './pages/SinavModulu/YayinIslemleri';
import YayineviKullaniciIslemleri from './pages/SinavModulu/YayineviKullaniciIslemleri';
import OptikFormTanimi from './pages/SinavIslemleri/OptikFormTanimi';
import SinavTanimi from './pages/SinavIslemleri/SinavTanimi';
import CevapAnahtariTanimi from './pages/SinavIslemleri/CevapAnahtariTanimi';
import SinavGonderimi from './pages/SinavIslemleri/SinavGonderimi';
import KurumEkle from './pages/KurumIslemleri/KurumEkle';
import DokumanIslemleri from './pages/SinavModulu/DokumanIslemleri';
import DuyuruIslemleri from './pages/SinavModulu/DuyuruIslemleri';
import OgrenciVeliAktarma from './pages/SinavModulu/OgrenciVeliAktarma';
import OgrenciSinavSonuclari from './pages/OgrenciModulu/OgrenciSinavSonuclari';
import KurumToplamTaksitRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumToplamTaksitRaporu';
import KurumTaksitTahsilatRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumTaksitTahsilatRaporu';
import KurumTaksitTahsilatDurumRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumTaksitTahsilatDurumRaporu';
import KurumTahsilatRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumTahsilatRaporu';
import KurumPesinatRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumPesinatRaporu';
import OgrenciSinavSonucPage from './pages/OgrenciSinavSonucPage';
import EduAsistOgrenciLoginPage from './pages/EduAsistOgrenciLoginPage';
import EduAsistVeliLoginPage from './pages/EduAsistVeliLoginPage';
import MobilKutuphane from './pages/OtomasyonModulu/InteraktifIslemler/MobilKutuphane';
import AkilliTahta from './pages/OtomasyonModulu/InteraktifIslemler/AkilliTahta';
import VideoCozum from './pages/OtomasyonModulu/InteraktifIslemler/VideoCozum';
import CrmGorevTuru from './pages/CrmModulu/CrmGorevTuru';
import Crm from './pages/CrmModulu/Crm';
import SanalOptikPage from './pages/SanalSinavModulu/SanalOptikPage';
import MobileLayout from './layouts/MobileLayout';
import Index from './pages/MobilModulu/Index';
import DevamsizlikFilterPage from './pages/MobilModulu/DevamsizlikFilterPage';
import KurumGiderRaporu from './pages/OtomasyonModulu/RaporIslemleri/KurumGiderRaporu';
export const routes = <Switch>
    <AppRoute layout={GuestLayout} exact path="/" component={LoginPage} />
    <AppRoute layout={GuestLayout} exact path="/register" component={RegisterPage} />
    <AppRoute layout={GuestLayout} exact path="/login" component={LoginPage} />
    <AppRoute layout={GuestLayout} exact path="/eaogrencilogin/:KurumId/:KullaniciId/:Sifre" component={EduAsistOgrenciLoginPage} />
    <AppRoute layout={GuestLayout} exact path="/eavelilogin/:KurumId/:OgrenciKullaniciId/:KullaniciId/:Sifre" component={EduAsistVeliLoginPage} />
    <AppRoute layout={GuestLayout} exact path="/sinavsonucu/:yayinParam" component={OgrenciSinavSonucPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/ayarlar" component={AyarlarPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/home" component={HomePage} />
    <AppRoute layout={AuthorizedLayout} exact path="/kayitkabuldashboard" component={KayitKabulDashboard} />
    <AppRoute layout={AuthorizedLayout} exact path="/velidashboard" component={VeliDashBoard} />
    <AppRoute layout={AuthorizedLayout} exact path="/ogrencidashboard" component={OgrenciDashBoard} />
    <AppRoute layout={AuthorizedLayout} exact path="/otomasyondashboard" component={OtomasyonAdminDasboard} />
    <AppRoute layout={AuthorizedLayout} exact path="/KullaniciIslemleri" component={KullaniciIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/RolIslemleri" component={RolIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/RolEkranIslemleri" component={RolEkranIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/YayinIslemleri" component={YayinIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/ExcelKullaniciAktar" component={ExcelKullaniciAktar} />
    <AppRoute layout={AuthorizedLayout} exact path="/KurumAktarmaIslemleri" component={KurumAktarmaIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/OtomasyonKullanicilari" component={OtomasyonKullanicilari} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/GenelTanimlar" component={OtomasyonGenelTanimlar} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/GenelTanim" component={OtomasyonGenelMerkezTanim} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/SubeKullanici" component={OtomasyonSubeKullanici} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/Kullanici" component={OtomasyonDigerKullanici} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/OgrenciIslemleri/OgrenciKayit" component={OtomasyonOgrenciKayit} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/DevamsizlikIslemleri/DevamsizlikKayit" component={OtomasyonDevamsizlikKayit} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/GiderIslemleri/GiderKayit" component={OtomasyonGiderKayit} />
    <AppRoute layout={AuthorizedLayout} exact path="/YayineviKullaniciIslemleri" component={YayineviKullaniciIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/YayinIslemleri/DokumanIslemleri" component={DokumanIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/YayinIslemleri/DuyuruIslemleri" component={DuyuruIslemleri} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/OptikFormTanimi" component={OptikFormTanimi} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/SinavTanimi" component={SinavTanimi} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/CevapAnahtariTanimi" component={CevapAnahtariTanimi} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/SinavGonderimi" component={SinavGonderimi} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/KurumEkle" component={KurumEkle} />
    <AppRoute layout={AuthorizedLayout} exact path="/SinavIslemleri/OgrenciVeliAktarma" component={OgrenciVeliAktarma} />
    <AppRoute layout={AuthorizedLayout} exact path="/OgrenciIslemleri/SinavSonuclari" component={OgrenciSinavSonuclari} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumToplamTaksitRaporu" component={KurumToplamTaksitRaporu} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumTaksitTahsilatRaporu" component={KurumTaksitTahsilatRaporu} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumTaksitTahsilatDurumRaporu" component={KurumTaksitTahsilatDurumRaporu} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumTahsilatRaporu" component={KurumTahsilatRaporu} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumPesinatRaporu" component={KurumPesinatRaporu} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/OdevVeEtutIslemleri/OdevTakip" component={OdevTanim} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/OdevVeEtutIslemleri/EtutTakip" component={EtutTanim} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/ExcelKullaniciAktar" component={OtomasyonKullaniciAktar} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/InteraktifIslemler/MobilKutuphane" component={MobilKutuphane} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/InteraktifIslemler/AkilliTahta" component={AkilliTahta} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/InteraktifIslemler/VideoCozum" component={VideoCozum} />
    <AppRoute layout={AuthorizedLayout} exact path="/Crm/CrmGorevTurleri" component={CrmGorevTuru} />
    <AppRoute layout={AuthorizedLayout} exact path="/Crm/Crm" component={Crm} />
    <AppRoute layout={AuthorizedLayout} exact path="/SanalSinif/SanalOptikSinav" component={SanalOptikPage} />
    <AppRoute layout={MobileLayout} exact path="/mobile/index" component={Index} />
    <AppRoute layout={MobileLayout} exact path="/mobile/devamsizlikfilter" component={DevamsizlikFilterPage} />
    <AppRoute layout={AuthorizedLayout} exact path="/Otomasyon/RaporIslemleri/KurumGiderRaporu" component={KurumGiderRaporu} />
</Switch>;