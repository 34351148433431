import React from 'react';
import { sinavApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
export default class KurumEkle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            disabled: true,
            kodVerDisabled: false
        };
        this.gridMain = null;
        this.selectBoxIl = null;
        this.selectBoxIlce = null;
        this.numberBoxKurumKodu = null;
        this.formKurum = null;
        this.popupForm = null;
        this.satir = null;

        this.kurumTurleri = [
            { Id: 0, Adi: "Seçiniz" },
            { Id: 1, Adi: "İlkokul" },
            { Id: 2, Adi: "Ortaokul" },
            { Id: 3, Adi: "Lise" },
            { Id: 4, Adi: "Etüt Merkezi" },
            { Id: 5, Adi: "Özel Öğretim Kurs Merkezi" },
            { Id: 6, Adi: "Halk Eğitim Merkezi" },
            { Id: 7, Adi: "İlçe Milli Eğitim Müdürlüğü" },
            { Id: 8, Adi: "İl Milli Eğitim Müdürlüğü" },
            { Id: 9, Adi: "Kişisel Gelişim Kursu" },
            { Id: 10, Adi: "Diğer" },
        ];
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onInitializedGridMain = this.onInitializedGridMain.bind(this);
        this.onInitializedSelectBoxIl = this.onInitializedSelectBoxIl.bind(this);
        this.onInitializedSelectBoxIlce = this.onInitializedSelectBoxIlce.bind(this);
        this.onInitializedFormKurum = this.onInitializedFormKurum.bind(this);
        this.onInitializedNumberBoxKurumKodu = this.onInitializedNumberBoxKurumKodu.bind(this);
        this.onInitializedPopup = this.onInitializedPopup.bind(this);
        this.yeniEkleClick = this.yeniEkleClick.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.aktifYapClick = this.aktifYapClick.bind(this);
        this.pasifYapClick = this.pasifYapClick.bind(this);
        this.silClick = this.silClick.bind(this);
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };
        this.popupGizle = () => {

            this.gridMainSatirIptal();
            this.setState({ popup: false, kodVerDisabled: false });

        };
        this.formData = {
            Il: { Id: 0 },
            Ilce: { Id: 0 },
            KurumKodu: 1,
            KurumEmail: null,
            KurumAdi: null,
            Logo: null,
            Gsm: null
        }
        this.onUploaded = this.onUploaded.bind(this);
        this.kurumDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ilDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'ortak/iller',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ilfilter = { IlId: 0 };
        this.ilceDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    key: "Id",
                    loadParams: this.ilfilter,
                    loadUrl: sinavApi + 'ortak/ilceler',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    //#region  grid fonksiyonları

    onInitializedGridMain(e) {
        this.gridMain = e.component;
    }

    onInitializedSelectBoxIl(e) {
        this.selectBoxIl = e.component;
    }

    onInitializedSelectBoxIlce(e) {
        this.selectBoxIlce = e.component;
    }

    onInitializedNumberBoxKurumKodu(e) {
        this.numberBoxKurumKodu = e.component;
    }

    onInitializedFormKurum(e) {
        this.formKurum = e.component;
    }

    onInitializedPopup(e) {
        this.popupForm = e.component;
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Result.Value.Success) {
            notify("Logonuz yüklenemedi," + res.Result.Value.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Logonuz yüklendi", "success", 5000);
            this.formData.Logo = res.Result.Value.Data;
            e.component.reset();
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    onClick: this.yeniEkleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger',
                    hint: 'Kurum logosu sil',
                    icon: 'image',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            if (Globals.service.Rol === "Rapor" | Globals.service.Rol === "Doküman" | Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") {
                                alert("Bu işlem için yetkiniz yok");
                            }
                            else {
                                let result = confirm("<i>Kurum logosunu silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
                                result.then((dialogResult) => {
                                    if (dialogResult === true) {
                                        service.post(sinavApi + "kurum/kurumLogoSil/" + this.satir.Id,
                                            null, {
                                            headers: {
                                                'Authorization': 'Bearer ' + Globals.service.Token
                                            }
                                        })
                                            .then(res => {
                                                if (res.data.Success) {
                                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                                    this.gridMain.instance.refresh();
                                                    this.gridMainSatirIptal();

                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                }
                                            });
                                    }
                                });
                            }
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger',
                    hint: 'Sil',
                    icon: 'trash',
                    onClick: this.silClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: this.pasifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: this.aktifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.kurumDataSource.load().done(() => {
                            this.gridMain.instance.refresh();
                        });
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }
    //#endregion

    //#region  toolbar fonksiyonları

    yeniEkleClick() {

        this.formData = {
            Il: { Id: 0 },
            Ilce: { Id: 0 },
            KurumKodu: 1,
            KurumEmail: null,
            KurumAdi: null,
            Logo: null,
            Gsm: null
        }

        this.setState({
            disabled: false,
            popup: true
        });

    }

    duzenleClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.formData = {
                Id: satir.Id,
                Il: { Id: satir.Il.Id },
                Ilce: { Id: satir.Ilce.Id },
                KurumTuruId: satir.KurumTuruId,
                KurumKodu: satir.KurumKodu,
                KurumEmail: satir.KurumEmail,
                KurumAdi: satir.KurumAdi,
                Logo: satir.Logo,
                Gsm: satir.Gsm,
                RowVersion: satir.RowVersion,
                Deleted: satir.Deleted
            }
            this.selectBoxIl.instance().option("value", satir.Il.Id);
            this.ilfilter.IlId = satir.Il.Id;
            this.ilceDataSource.load().done(() => {
                this.selectBoxIlce.instance().option("value", satir.Ilce.Id);
            })
            this.setState({
                disabled: true,
                kodVerDisabled: true,
                popup: true
            });
        }
    }

    aktifYapClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (Globals.service.Rol === "Rapor" | Globals.service.Rol === "Doküman" | Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") {
                alert("Bu işlem için yetkiniz yok");
            }
            else {
                this.setState({
                    kodVerDisabled: false
                });
                service.post(sinavApi + 'kurum/AktifYap/' + satir.Id, null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            this.gridMain.instance.refresh();
                            this.gridMainSatirIptal();
                            notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                        }
                    });
            }
        }
    }

    pasifYapClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (Globals.service.Rol === "Rapor" | Globals.service.Rol === "Doküman" | Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") {
                alert("Bu işlem için yetkiniz yok");
            }
            else {
                service.post(sinavApi + 'kurum/PasifYap/' + satir.Id,
                    null,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                )
                    .then(res => {
                        if (res.data.Success) {
                            this.gridMain.instance.refresh();
                            this.gridMainSatirIptal();
                            notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                        }
                        else {
                            notify(res.data.ErrorMessage, 'error', 5000);
                        }
                    });
            }
        }
    }

    silClick() {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (Globals.service.Rol === "Rapor" | Globals.service.Rol === "Doküman" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") {
                alert("Bu işlem için yetkiniz yok");
            }
            else {
                let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                result.then((dialogResult) => {
                    if (dialogResult === true) {
                        service.post(sinavApi + "kurum/kurumSil/" + satir.Id,
                            null, {
                            headers: {
                                'Authorization': 'Bearer ' + Globals.service.Token
                            }
                        })
                            .then(res => {
                                if (res.data.Success) {
                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                    this.gridMain.instance.refresh();
                                    this.gridMainSatirIptal();
                                }
                                else {
                                    notify(res.data.ErrorMessage, "error", 5000);
                                }
                            });
                    }
                });
            }
        }
    }


    //#endregion

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Sınav İşlemleri>>Kurumlar"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                remoteOperations={true}
                                dataSource={this.kurumDataSource}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onInitialized={this.onInitializedGridMain}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" visible={false} />
                                <Column dataField={'ilAdi'} caption="İl" dataType="string" />
                                <Column dataField={'ilceAdi'} caption="İlçe" dataType="string" />
                                <Column dataField={'KurumKodu'} caption="Kodu" allowSearch={false} />
                                <Column dataField={'KurumAdi'} caption="Adı" dataType="string" allowSearch={false} />

                            </DataGrid>

                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref} onInitialized={this.onInitializedPopup}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        const validateStatus = this.formKurum.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {

                                            service.post(sinavApi + 'kurum/KurumKaydet',
                                                this.formData,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                }
                                            ).then(res => {
                                                if (res.data.Success) {
                                                    this.gridMain.instance.refresh();
                                                    this.popupGizle();
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }

                                            })
                                                .catch(err => {
                                                    notify(err, "error", 5000);
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData} onInitialized={this.onInitializedFormKurum} ref={(ref) => this.formKurum = ref}
                                    showValidationSummary={true}
                                >
                                    <GroupItem cssClass="first-group" colCount={2} caption="Kurum  Bilgileri">
                                        <SimpleItem dataField={"KurumTuruId"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.kurumTurleri,
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onValueChanged: (e) => {
                                                    this.formData.KurumTuruId = e.value;
                                                }
                                            }}>
                                            <Label text="Tür" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Il.Id"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.ilDataSource,
                                                searchEnabled: false,
                                                remoteOperations: { filtering: false },
                                                disabled: this.state.disabled,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxIl,
                                                ref: (ref) => { this.selectBoxIl = ref },
                                                onValueChanged: (e) => {
                                                    this.ilfilter.IlId = e.value;
                                                    this.ilceDataSource.load();
                                                    this.selectBoxIlce.instance().option("value", null);
                                                }
                                            }}>
                                            <RequiredRule message="İl alanı gerekli" />
                                            <Label text="İl" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Ilce.Id"} isRequired={true}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.ilceDataSource,
                                                remoteOperations: { filtering: false },
                                                disabled: this.state.disabled,
                                                searchEnabled: false,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxIlce,
                                                ref: (ref) => { this.selectBoxIlce = ref },
                                                onValueChanged: (e) => {

                                                }
                                            }}
                                        >
                                            <RequiredRule message="İlçe alanı gerekli" />
                                            <Label text="İlçe" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KurumAdi"} isRequired={true}
                                            editorType="dxTextBox"
                                        >
                                            <Label text="Adı" />
                                            <RequiredRule message="Adı alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"KurumEmail"} isRequired={true}>
                                            <Label text="Email" />
                                            <RequiredRule message="Email alanı gerekli" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Gsm"} isRequired={true}
                                            editorType="dxTextBox"
                                            editorOptions={{
                                                mask: "(000) 000-0000"
                                            }}>
                                            <Label text="Gsm" />
                                            <RequiredRule message="Gsm alanı gerekli" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Kurum Kodu Bilgileri">
                                        <SimpleItem dataField={"KurumKodu"} isRequired={true}
                                            editorType="dxNumberBox"
                                            editorOptions={{
                                                disabled: this.state.disabled,
                                                onInitialized: this.onInitializedNumberBoxKurumKodu,
                                                ref: (ref) => { this.numberBoxKurumKodu = ref },
                                            }}>
                                            <Label text="Kodu" />

                                        </SimpleItem>
                                        <SimpleItem editorType="dxButton"
                                            editorOptions={{
                                                location: "after",
                                                name: "txtKurumKodu",
                                                icon: "check",
                                                type: "default",
                                                text: "Kurum Kodu Ver",
                                                disabled: this.state.kodVerDisabled,
                                                onClick: () => {
                                                    service.get(sinavApi + "kurum/kurumKoduUret", {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        }
                                                    }).then(res => {
                                                        if (res.data.Success) {
                                                            this.numberBoxKurumKodu.instance().option("value", res.data.Data);
                                                            this.formData.KurumKodu = res.data.Data;
                                                        }
                                                        else {
                                                            notify(res.data.ErrorMessage, "error", 5000);
                                                        }
                                                    });
                                                }
                                            }}>

                                        </SimpleItem>

                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={1} caption="Kurum  Logosu(Logolu liste ve karne için)">
                                        <SimpleItem dataField={"File"}
                                            editorType="dxFileUploader"
                                            editorOptions={{
                                                uploadUrl: sinavApi + "kurum/KurumLogoUpload",
                                                uploadMode: 'instantly',
                                                uploadHeaders: this.headers,
                                                accept: '*',
                                                maxFileSize: 4000000,
                                                name: 'myFile',
                                                onUploaded: this.onUploaded
                                            }}>
                                            <Label text="Logo seçiniz" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

            </React.Fragment>
        );
    }
}