import React from "react";
import { sinavApi, crmApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

export default class CrmGorevTuru extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateOpen: false
        }
        this.formData = {
            Id: 0,
            Kurum: { Id: 0 },
            Adi: "",
            RowVersion: 0
        }
        this.gridMain = null;
        this.satir = null;
        this.formCrmGorevTuru = null;
        this.popupForm = null;
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onInitializedGridMain = this.onInitializedGridMain.bind(this);
        this.onInitializedFormCrmGorevTuru = this.onInitializedFormCrmGorevTuru.bind(this);
        this.onInitializedFormCrmGorevTuru = this.onInitializedFormCrmGorevTuru.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onInitializedPopup = this.onInitializedPopup.bind(this);
        this.kurumDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.crmGorevTuruDataSource = new DataSource({
            paginate: true,
            pageSize: 10,
            store: createStore({
                key: "Id",
                loadUrl: crmApi + 'crmgorevturu/gorevturleri',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });
    };



    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onInitializedGridMain(e) {
        this.gridMain = e.component;
    }

    gridMainSatirIptal() {
        if (this.gridMain.instance !== null) {
            this.gridMain.instance.clearSelection();
            this.gridMain.instance.option("focusedRowIndex", -1);
        }
    }

    onInitializedFormCrmGorevTuru(e) {
        this.formCrmGorevTuru = e.component;
    };

    onInitializedPopup(e) {
        this.popupForm = e.component;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yeni',
                    icon: 'add',
                    onClick: () => {
                        this.formData = {
                            Id: 0,
                            Kurum: { Id: null },
                            Adi: "",
                            RowVersion: 0,
                            Deleted: false
                        };
                        this.popupAc();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            this.formData = {
                                Id: satir.Id,
                                Kurum: { Id: satir.Kurum.Id },
                                Adi: satir.Adi,
                                Deleted: satir.Deleted,
                                RowVersion: satir.RowVersion
                            };
                            this.popupAc();
                        }
                    }
                }
            },

            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger',
                    hint: 'Sil',
                    icon: 'trash',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
                            result.then((dialogResult) => {
                                if (dialogResult === true) {
                                    service.post(crmApi + "crmgorevturu/sil/" + satir.Id,
                                        null, {
                                        headers: {
                                            'Authorization': 'Bearer ' + Globals.service.Token
                                        }
                                    })
                                        .then(res => {
                                            if (res.data.Success) {
                                                notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                                this.gridMain.instance.refresh();
                                                this.gridMainSatirIptal();
                                            }
                                            else {
                                                notify(res.data.ErrorMessage, "error", 5000);
                                            }
                                        });
                                }
                            });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {

                            service.post(crmApi + 'crmgorevturu/pasifyap/' + satir.Id, null,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                }
                            )
                                .then(res => {
                                    if (res.data.Success) {
                                        this.gridMain.instance.refresh();
                                        this.gridMainSatirIptal();
                                        notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                                    }
                                    else {
                                        notify(res.data.ErrorMessage, 'error', 5000);
                                    }
                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {

                            service.post(crmApi + 'crmgorevturu/aktifyap/' + satir.Id, null,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Globals.service.Token
                                    }
                                }
                            )
                                .then(res => {
                                    if (res.data.Success) {
                                        this.gridMain.instance.refresh();
                                        this.gridMainSatirIptal();
                                        notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                                    }
                                    else {
                                        notify(res.data.ErrorMessage, 'error', 5000);
                                    }
                                });
                        }
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    popupGizle() {
        this.setState({
            stateOpen: false
        });
        this.gridMainSatirIptal();
    };

    popupAc() {
        this.setState({
            stateOpen: true
        });
    };
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Crm>>Görev Türleri"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                remoteOperations={true}
                                dataSource={this.crmGorevTuruDataSource}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onInitialized={this.onInitializedGridMain}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export enabled={true} fileName={'CrmGorevTurleri'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={true} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" visible={true} />
                                <Column dataField={'Adi'} caption="Adı" dataType="string" />
                            </DataGrid>

                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true} close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    visible={this.state.stateOpen}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        const validateStatus = this.formCrmGorevTuru.instance.validate();
                                        if (!validateStatus.isValid) {
                                            return;
                                        }
                                        else {

                                            service.post(crmApi + 'crmgorevturu/gorevturukaydet',
                                                this.formData,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                    }
                                                }
                                            ).then(res => {
                                                if (res.data.Success) {
                                                    this.gridMain.instance.refresh();
                                                    this.popupGizle();
                                                }
                                                else {
                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                }

                                            })
                                                .catch(err => {
                                                    notify(err, "error", 5000);
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                    ref={(ref) => this.popupForm = ref} onInitialized={this.onInitializedPopup}
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData} onInitialized={this.onInitializedFormCrmGorevTuru} ref={(ref) => this.formCrmGorevTuru = ref}
                                    showValidationSummary={true}
                                >
                                    <GroupItem cssClass="first-group" colCount={2} caption="Kurum  Bilgileri">
                                        <SimpleItem dataField={"Kurum.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.kurumDataSource,
                                                searchEnabled: true,
                                                displayExpr: "KurumAdi",
                                                valueExpr: "Id"
                                            }}>
                                            <Label text="Tür" />
                                        </SimpleItem>

                                        <SimpleItem dataField={"Adi"} isRequired={true}
                                            editorType="dxTextBox"
                                        >
                                            <Label text="Adı" />
                                            <RequiredRule message="Adı alanı gerekli" />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }

}