import React from 'react';
import Moment from 'react-moment';
export default function DokumanItem(data) {
  return (
    <React.Fragment>
      <p>
        <b><Moment format="YYYY/MM/DD">{data.Created}</Moment> {data.SinavKodu === 0 ? "" : "Sınav Kodu:" + data.SinavKodu}</b>
      </p>
      {
        data.Dosyalar.map((item, index) =>
          <p key={index.toString()}>
            <a href={item.Dosya} rel="noopener noreferrer" target="_blank">{item.Dosya}</a>
          </p>
        )
      }
    </React.Fragment>
  )
}
