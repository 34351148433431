import React from 'react';
import { sinavApi, karneApi, otomasyonApi, yayinSinavApi, yayinKarneApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Column, Editing, Paging, Pager, FilterRow
} from 'devextreme-react/data-grid';
import { Button, Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import j from 'jquery';
import { LoadPanel } from 'devextreme-react/load-panel';
import { setRemoteSystemInfo } from "../../redux/actions/mainSystemAction";
import { connect } from 'react-redux';
const position = { of: '#sinav' };

const mapDispatchToProps = (dispatch) => {
    return {
        setRemoteSystem: (data) => dispatch(setRemoteSystemInfo(data))
    }
}
const mapStateToProps = state => ({
    remoteSystem: state.remoteSystem.info
}
);





class SinavGonderimi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            popup: false,
            popupKurum: false,
            tyt2Goster: false,
            otomatikKurumKodu: false,
            sinifSiraliListeText: "Sınıf Liste",
            sinifSiraliListeDisabled: false,
            kurumSiraliListeText: "Kurum Liste",
            kurumSiraliListeDisabled: false,
            kurumSiraliExcelListeText: "Excel Liste",
            kurumSiraliExcelListeDisabled: false,
            listeKarneText: "Liste ve Karne",
            tekPdfText: "Tek Pdf",
            tekPdfDisabled: false,
            listeKarneDisabled: false,
            toolbarDisabled: false,
            secilenMerkeziSinavId: null,
            secilenKurumId: null,
            merkeziSinavGoster: false
        };
        this.mainDataGrid = null;
        this.formKurum = null;
        this.buttonDegerlendir = null;
        this.selectBoxIl = null;
        this.selectBoxIlce = null;
        this.selectBoxYayin = null;
        this.selectBoxYayin2 = null;
        this.selectBoxMerkeziSinav = null;
        this.selectBoxMerkeziSinav2 = null;
        this.selectBoxOptikform = null;
        this.selectBoxSinavTuru = null;
        this.fileUploadTxt = null;
        this.numberBoxKurumKodu = null;
        this.selectBoxKurumId = null;
        this.formData = {
            Yayin: { Id: 0 },
            Yayin2: { Id: 0 },
            MerkeziSinav: { Id: 0 },
            MerkeziSinav2: { Id: 0 },
            SinavTuru: { Id: 0 },
            OptikForm: { Id: 0 },
            Kurum: { Id: null },
            BosKitapcikTuru: "A",
            BosOgrenciNo: 0,
            DosyaAdi: "",
            VeriSilme: false
        };
        this.formSinavGonderimi = null;
        this.kurumTurleri = [
            { Id: 0, Adi: "Seçiniz" },
            { Id: 1, Adi: "İlkokul" },
            { Id: 2, Adi: "Ortaokul" },
            { Id: 3, Adi: "Lise" },
            { Id: 4, Adi: "Etüt Merkezi" },
            { Id: 5, Adi: "Özel Öğretim Kurs Merkezi" },
            { Id: 6, Adi: "Halk Eğitim Merkezi" },
            { Id: 7, Adi: "İlçe Milli Eğitim Müdürlüğü" },
            { Id: 8, Adi: "İl Milli Eğitim Müdürlüğü" },
            { Id: 9, Adi: "Kişisel Gelişim Kursu" },
            { Id: 10, Adi: "Diğer" },
        ];

        this.formDataKurum = {
            Id: 0,
            Il: { Id: 0 },
            Ilce: { Id: 0 },
            KurumKodu: 1,
            KurumEmail: null,
            KurumAdi: null,
            Logo: null,
            Gsm: null
        };


        this.popupForm = null;
        this.popupKurumForm = null;
        this.satir = null;
        this.bosOgrenciNo = 0;
        this.bosKitapcikTuru = "";
        this.dosyaAdi = "";
        this.yayinlar = [];
        this.headers = { 'Authorization': 'Bearer ' + Globals.service.Token };

        this.popupGizle = () => {
            this.setState({ popup: false, toolbarDisabled: false });
        };

        this.popupGizleKurum = () => {
            this.setState({ popupKurum: false, toolbarDisabled: false })
        }

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.sinavYukleClick = this.sinavYukleClick.bind(this);
        this.listeVeKarneClick = this.listeVeKarneClick.bind(this);
        this.tekPdfClick = this.tekPdfClick.bind(this);
        this.listeClick = this.listeClick.bind(this);
        this.listeKurumClick = this.listeKurumClick.bind(this);
        this.listeExcelClick = this.listeExcelClick.bind(this);
        this.sinavSil = this.sinavSil.bind(this);
        this.ogrenciNoVer = this.ogrenciNoVer.bind(this);
        this.getKitapcikTuruVer = this.getKitapcikTuruVer.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onUploadedKurumLogo = this.onUploadedKurumLogo.bind(this);
        this.onTxtIndir = this.onTxtIndir.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onInitializedSelectBoxIl = this.onInitializedSelectBoxIl.bind(this);
        this.onInitializedSelectBoxIlce = this.onInitializedSelectBoxIlce.bind(this);
        this.onInitializedSelectBoxMerkeziSinav = this.onInitializedSelectBoxMerkeziSinav.bind(this);
        this.onInitializedSelectBoxMerkeziSinav2 = this.onInitializedSelectBoxMerkeziSinav2.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxYayin2 = this.onInitializedSelectBoxYayin2.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.onInitializedSelectBoxOptikform = this.onInitializedSelectBoxOptikform.bind(this);
        this.onInitializedFormKurum = this.onInitializedFormKurum.bind(this);
        this.onInitializedNumberBoxKurumKodu = this.onInitializedNumberBoxKurumKodu.bind(this);
        this.onInitializedSelectBoxKurumId = this.onInitializedSelectBoxKurumId.bind(this);
        this.onInitializedFormSinavGonderimi = this.onInitializedFormSinavGonderimi.bind(this);
        this.onInitializedFileUploadTxt = this.onInitializedFileUploadTxt.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.onInitializedButtonDegerlendir = this.onInitializedButtonDegerlendir.bind(this);
        this.gridMainSatirIptal = this.gridMainSatirIptal.bind(this);
        this.showLoadPanel = this.showLoadPanel.bind(this);
        this.hideLoadPanel = this.hideLoadPanel.bind(this);

        this.kurumDataSource = new DataSource(
            {
                pageSize: 100,
                paginate: true,
                store: createStore({
                    loadUrl: sinavApi + 'kurum/sgekurum',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ilDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    loadUrl: sinavApi + 'ortak/iller',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ilfilter = { IlId: 0 };
        this.ilceDataSource = new DataSource(
            {
                cacheRawData: false,
                paginate: false,
                store: createStore({
                    loadParams: this.ilfilter,
                    loadUrl: sinavApi + 'ortak/ilceler',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruFilter = {
            YayinId: 0,
            SinavTuruId: 0,
            MerkeziSinavId: 0
        };

        this.merkeziSinavDataSource = new DataSource(
            {
                filter: ["Degerlendirilebilir", "=", true],
                paginate: false,
                store: createStore({
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'merkezisinav/sgemerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayin2filter = { YayinId: 0 };

        this.tytMerkeziSinavDataSource = new DataSource(
            {
                filter: ["Degerlendirilebilir", "=", true],
                paginate: false,
                store: createStore({
                    loadParams: this.yayin2filter,
                    loadUrl: sinavApi + 'merkezisinav/sgetytmerkezisinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.optikFormDataSource = new DataSource(
            {
                paginate: false,
                store: createStore({
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'optikform/sgeoptikformlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.merkeziSinavId = 0;

        this.degerlendirilenSinavlarDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'merkezisinav/sgedegerlendirilensinavlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    hideLoadPanel() {
        this.setState({
            loadPanelVisible: false
        });
    }

    showLoadPanel() {
        this.setState({
            loadPanelVisible: true
        });
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Sınav Yükle',
                    icon: 'upload',
                    text: "Sınav Yükle",
                    visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                    disabled: this.state.toolbarDisabled,
                    onClick: this.sinavYukleClick
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: this.state.listeKarneText, icon: 'exportpdf',
                    text: this.state.listeKarneText,
                    disabled: this.state.toolbarDisabled,
                    onClick: this.listeVeKarneClick
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: this.state.tekPdfText, icon: 'exportpdf',
                    text: this.state.tekPdfText,
                    disabled: this.state.toolbarDisabled,
                    onClick: this.tekPdfClick
                }
            },
            {
                location: 'before',
                widget: 'dxDropDownButton',
                locateInMenu: 'auto',
                options: {
                    displayExpr: 'name',
                    keyExpr: "value",
                    valueExpr: 'value',
                    //icon: "orderedlist",
                    useSelectMode: false,
                    width: 120,
                    text: "Listeler",
                    items: [
                        { name: 'Sınıf Liste', value: 1 },
                        { name: 'Kurum Liste', value: 2 },
                        { name: 'Excel Liste', value: 3 },
                        { name: 'TYT-AYT Liste', value: 4 }
                    ],
                    onItemClick: (e) => {
                        switch (e.itemData.value) {
                            case 1:
                                this.listeClick();
                                break;
                            case 2:
                                this.listeKurumClick();
                                break;
                            case 3:
                                this.listeExcelClick();
                                break;
                            case 4:
                                this.listeTytAytClick();
                                break;
                            default:
                                notify("Herhangi bir seçim yapılmadı", "warning", 5000);
                                break;
                        }
                    }
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Txt İnidr',
                    text: 'Txt',
                    icon: 'download',
                    //text: "Txt İndir",
                    disabled: this.state.toolbarDisabled,
                    onClick: this.onTxtIndir
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger',
                    hint: 'Sil',
                    icon: 'trash',
                    text: "Sil",
                    visible: Globals.service.Rol === "SubeOgretmen" ? false : true,
                    disabled: this.state.toolbarDisabled,
                    onClick: this.sinavSil
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Yenile',
                    icon: 'refresh',
                    //text: "Yenile",
                    disabled: this.state.toolbarDisabled,
                    onClick: (e) => {
                        this.mainDataGrid.instance.refresh();
                    }
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Veli SMS',
                    icon: 'email',
                    disabled: this.state.toolbarDisabled,
                    visible: (Globals.service.Rol !== "SubeOgretmen" && window.ayarlar.projeturu === "otomasyon") ? true : false,
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {
                            this.setState({
                                toolbarDisabled: true
                            });
                            const postData = {
                                KurumId: satir.Kurum_Id,
                                SinavId: satir.Sinav_Id,
                                SinavAdi: satir.SinavAdi
                            }
                            service.post(otomasyonApi + "sms/postvelisinavsonucugonder", postData, {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            }).then(res => {
                                if (res.data.Success) {
                                    this.setState({
                                        toolbarDisabled: false
                                    });
                                    notify(res.data.Data, "success", 5000);
                                }
                                else {
                                    this.setState({
                                        toolbarDisabled: false
                                    });
                                    notify(res.data.ErrorMessage, "error", 5000);
                                }
                            }).catch((err) => {
                                this.setState({
                                    toolbarDisabled: false
                                });
                                notify(err, "error", 5000);
                            });
                        }
                    }
                }
            },
            {
                location: 'before', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success',
                    hint: 'Öğrenci SMS',
                    icon: 'email',
                    visible: (window.ayarlar.projeturu === "otomasyon") ? true : false,
                    disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        const satir = this.satir;
                        if (satir === null || satir === undefined) {
                            alert('Satır seçmelisiniz', 'Uyarı');
                            return;
                        }
                        else {

                            const postData = {
                                KurumId: satir.Kurum_Id,
                                SinavId: satir.Sinav_Id,
                                SinavAdi: satir.SinavAdi
                            }
                            this.setState({
                                toolbarDisabled: true
                            });
                            service.post(otomasyonApi + "sms/postogrencisinavsonucugonder", postData, {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            }).then(res => {
                                if (res.data.Success) {
                                    this.setState({
                                        toolbarDisabled: false
                                    });
                                    notify(res.data.Data, "success", 5000);
                                }
                                else {
                                    this.setState({
                                        toolbarDisabled: false
                                    });
                                    notify(res.data.ErrorMessage, "error", 5000);
                                }
                            }).catch((err) => {
                                this.setState({
                                    toolbarDisabled: false
                                });
                                notify(err, "error", 5000);
                            });
                        }
                    }
                }
            },
            {
                location: 'before',
                widget: 'dxDropDownButton',
                locateInMenu: 'auto',
                options: {
                    displayExpr: 'name',
                    keyExpr: "value",
                    valueExpr: 'value',
                    stylingMode: 'default',
                    useSelectMode: false,
                    width: 100,
                    text: "Puan Analizi",
                    items: [
                        { name: 'Kurumlar Genel', value: 1 },
                        { name: 'Kurumlar İl', value: 2 },
                        { name: 'Kurumlar İlçe', value: 3 },
                        { name: 'Kurumlar Sınıf', value: 4 },
                        { name: 'Öğrenci Genel', value: 5 },
                        { name: 'Öğrenci İl', value: 6 },
                        { name: 'Öğrenci İlçe', value: 7 }
                    ],
                    onItemClick: (e) => {
                        switch (e.itemData.value) {
                            case 1:
                                this.kurumGenelRaporuGetir();
                                break;
                            case 2:
                                this.kurumGenelIlRaporuGetir();
                                break;
                            case 3:
                                this.kurumGenelIlceRaporuGetir();
                                break;
                            case 4:
                                this.kurumGenelSinifRaporuGetir();
                                break;
                            case 5:
                                this.ilk100GenelRaporuGetir();
                                break;
                            case 6:
                                this.ilk100IlRaporuGetir();
                                break;
                            case 7:
                                this.ilk100IlceRaporuGetir();
                                break;
                            default:
                                notify("Herhangi bir seçim yapılmadı", "warning", 5000);
                                break;
                        }
                        // if (e.itemData.id === 1) {
                        //     this.props.children.props.history.push("/ayarlar");
                        // }
                        // else if (e.itemData.id === 2) {
                        //     this.onClickSignOut(e);
                        // }
                    }
                }
            },
            {
                location: 'before',
                widget: 'dxDropDownButton',
                locateInMenu: 'auto',
                options: {
                    displayExpr: 'name',
                    keyExpr: "value",
                    valueExpr: 'value',
                    //icon: "orderedlist",
                    stylingMode: 'default',
                    useSelectMode: false,
                    width: 130,
                    text: "Konu-Soru Analizi",
                    items: [
                        { name: 'Kurum-Sınav-Konu', value: 1 },
                        { name: 'Kurum-Sınav-Soru', value: 2 },
                        { name: 'Sınıf-Sınav-Konu', value: 3 },
                        { name: 'Sınıf-Sınav-Soru', value: 4 }
                    ],
                    onItemClick: (e) => {
                        switch (e.itemData.value) {
                            case 1:
                                this.kurumKonuanaliziGetir();
                                break;
                            case 2:
                                this.kurumSoruanaliziGetir();
                                break;
                            case 3:
                                this.sinifKonuAnaliziGetir();
                                break;
                            case 4:
                                this.sinifSoruAnaliziGetir();
                                break;
                            default:
                                notify("Herhangi bir seçim yapılmadı", "warning", 5000);
                                break;
                        }
                    }
                }
            },
            {
                location: 'before',
                widget: 'dxDropDownButton',
                locateInMenu: 'auto',
                options: {
                    displayExpr: 'name',
                    keyExpr: "value",
                    valueExpr: 'value',
                    //icon: "orderedlist",
                    stylingMode: 'default',
                    useSelectMode: false,
                    width: 130,
                    text: "Net Analizi",
                    items: [
                        { name: 'Kurumlar-İl', value: 1 },
                        { name: 'Kurumlar-İlçe', value: 2 },
                        { name: 'Kurumlar-Sınıf', value: 3 },
                        { name: 'Kurumlar-Öğrenci', value: 4 }
                    ],
                    onItemClick: (e) => {
                        switch (e.itemData.value) {
                            case 1:
                                this.kurumlarIlAnaliziGetir();
                                break;
                            case 2:
                                this.kurumlarIlceAnaliziGetir();
                                break;
                            case 3:
                                this.kurumlarSinifAnaliziGetir();
                                break;
                            case 4:
                                this.kurumOgrenciAnaliziGetir();
                                break;
                            default:
                                notify("Herhangi bir seçim yapılmadı", "warning", 5000);
                                break;
                        }
                    }
                }
            }
        );
    }

    getKarneApi() {
        let endpoint = "";
        endpoint = (this.props.remoteSystem.connectionState && this.state.merkeziSinavGoster) ? yayinKarneApi : karneApi;
        return endpoint;
    }
    getSinavApi() {
        let endpoint = "";
        endpoint = (this.props.remoteSystem.connectionState && this.state.merkeziSinavGoster) ? yayinSinavApi : sinavApi;
        return endpoint;
    }

    getHeaders() {
        return {
            'Authorization': (this.props.remoteSystem.connectionState && this.state.merkeziSinavGoster)
                ? 'Bearer ' + this.props.remoteSystem.remoteToken
                : 'Bearer ' + Globals.service.Token
        }
    }
    kurumlarIlAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "netanalizi/getKurumlarNetAnaliziGetir/" + satir.SinavTuru + "/1/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurumlar il net analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumlarIlceAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "netanalizi/getKurumlarNetAnaliziGetir/" + satir.SinavTuru + "/2/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurumlar il net analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumlarSinifAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "netanalizi/getKurumlarNetAnaliziGetir/" + satir.SinavTuru + "/3/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurumlar il net analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumOgrenciAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            let data = {
                SinavTuru: satir.SinavTuru,
                KurumId: satir.Kurum_Id
            };
            service.post(this.getKarneApi() + "analiz/kurumogrencianalizi", data, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kuruml-Öğrenci net analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    sinifKonuAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/sinifkonuanalizi/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Sınıf konu analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    sinifSoruAnaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/sinifsoruanalizi/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Yayin_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Sınıf soru analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumSoruanaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/kurumsoruanalizi/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Yayin_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum soru analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumKonuanaliziGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/kurumkonuanalizi/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum konu analiziniz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumGenelRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getkurumgenelpuanortalamaraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum genel puan ortalamanız oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumGenelSinifRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getkurumsinifpuanortalamaraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum genel sınıf puan ortalamanız oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    ilk100GenelRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getilk100ogrencigenelraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Öğrenci genel  ilk 100 öğrenci raporunuz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    ilk100IlRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getilk100ogrenciilraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Il_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Öğrenci il  raporunuz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    ilk100IlceRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getilk100ogrenciilceraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Ilce_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Öğrenci ilçe  raporunuz oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumGenelIlRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getkurumilpuanortalamaraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Il_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum il puan ortalamanız oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    kurumGenelIlceRaporuGetir() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "analiz/getkurumilcepuanortalamaraporu/" + satir.Sinav_Id + "/" + satir.Kurum_Id + "/" + satir.Ilce_Id, {
                headers: this.getHeaders()
            }).then(async (res) => {
                if (res.data.Success) {
                    this.hideLoadPanel();
                    alert("Kurum ilçe puan ortalamanız oluşturuldu.<br/><a href='" + res.data.Data + "' target='_blank'> Dosyaya erişmek için buraya tıklayınız.</a>", "Bilgi");
                    this.gridMainSatirIptal();
                }
                else {
                    this.hideLoadPanel();
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            })
                .catch(err => {
                    this.hideLoadPanel();
                    notify(err, "error", 5000);
                });
        }
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    onInitializedSelectBoxIl(e) {
        this.selectBoxIl = e.component;
    }

    onInitializedSelectBoxIlce(e) {
        this.selectBoxIlce = e.component;
    }

    onInitializedFormKurum(e) {
        this.formKurum = e.component;
    }

    onInitializedNumberBoxKurumKodu(e) {
        this.numberBoxKurumKodu = e.component;
    }

    onInitializedSelectBoxKurumId(e) {
        this.selectBoxKurumId = e.component;
    }

    onInitializedFormSinavGonderimi(e) {
        this.formSinavGonderimi = e.component;
    }

    onInitializedFileUploadTxt(e) {
        this.fileUploadTxt = e.component;
    }

    onInitializedSelectBoxMerkeziSinav(e) {
        this.selectBoxMerkeziSinav = e.component;
    }

    onInitializedSelectBoxMerkeziSinav2(e) {
        this.selectBoxMerkeziSinav2 = e.component;
    }

    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }

    onInitializedSelectBoxYayin2(e) {
        this.selectBoxYayin2 = e.component;
    }

    onInitializedSelectBoxOptikform(e) {
        this.selectBoxOptikform = e.component;
    }

    onInitialized(e) {
        this.mainDataGrid = e.component;
    }

    onInitializedButtonDegerlendir(e) {
        this.buttonDegerlendir = e.component;
    }

    onTxtIndir(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            var a = document.createElement('a');
            a.target = "_blank";
            a.href = satir.SinavTxt;
            a.click();
        }
    }

    onUploaded(e) {
        const response = JSON.parse(e.request.response);
        if (response.Success) {
            this.dosyaAdi = response.Data;
            e.component.reset();
            notify("Txt dosyanız yüklendi", "success", 5000);

        } else {
            notify("Teknik bir hata oluştu, hata ayrıntısı:" + response.ErrorMessage, "error", 5000);
        }
    }

    onFileSelected(e) {
        if (e.value.length > 0) {
            var dosya = e.value[0];
            j("#spnYuklenenDosya").html("Seçilen Dosya:" + dosya.name);
        }

    }

    onUploadedKurumLogo(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Result.Value.Success) {
            notify("Logonuz yüklenemedi," + res.Result.Value.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            notify("Logonuz yüklendi", "success", 5000);
            this.formDataKurum.Logo = res.Result.Value.Data;
            e.component.reset();
        }
    }

    sinavSil() {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Sınav verilerini silmek istiyorsunuz, emin misiniz?</i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    service.post(this.getSinavApi() + "sinavgonderimi/sinavGonderimVerileriniSil/" + satir.Id,
                        null,
                        {
                            headers: this.getHeaders()
                        })
                        .then(res => {
                            if (res.data.Success) {
                                this.mainDataGrid.instance.refresh();
                                this.gridMainSatirIptal();
                            }
                            else
                                notify("Teknik bir hata oluştu:Hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);
                        });
                }
            });
        }
    }

    sinavYukleClick() {
        this.formData = {
            Yayin: { Id: null },
            Yayin2: { Id: null },
            MerkeziSinav: { Id: null },
            MerkeziSinav2: { Id: null },
            SinavTuru: { Id: null },
            OptikForm: { Id: null },
            Kurum: { Id: null },
            BosKitapcikTuru: "A",
            BosOgrenciNo: 0,
            DosyaAdi: ""
        };
        this.bosKitapcikTuru = null;
        this.bosOgrenciNo = 0;
        this.setState({
            popup: true
        });

    }

    ogrenciNoVer() {
        if (this.formData.MerkeziSinav.Id === null) return;
        this.merkeziSinavDataSource.load().done(() => {
            const sinavKodu = this.merkeziSinavDataSource.items().find(x => x.Id === this.formData.MerkeziSinav.Id).Kodu;
            let sinavKoduPart = sinavKodu.toString().substring(sinavKodu.toString().length === 4 ? 0 : sinavKodu.toString().length, 4);
            this.bosOgrenciNo = sinavKoduPart === "0" ? "90001" : sinavKoduPart + "0001";
        })

    }

    getKitapcikTuruVer() {
        if (this.formData.MerkeziSinav.Id === null) return;
        service.get(this.getSinavApi() + "cevapanahtari/GetKitapcikTurleri/" + this.formData.MerkeziSinav.Id,
            {
                headers: this.getHeaders()
            })
            .then(res => {
                if (res.data.Success) {
                    this.bosKitapcikTuru = res.data.Data;
                }
                else
                    notify(res.data.ErrorMessage, "error", 5000);
            });
    }

    async listeVeKarneClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({
                listeKarneText: "!Bekleyiniz",
                listeKarneDisabled: true,
                toolbarDisabled: true
            });
            await service.get(this.getKarneApi() + "hizlirapor/GetKarne/" + satir.Id,
                {
                    timeout: 1200000,
                    headers: this.getHeaders()
                })
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            listeKarneText: "Liste ve Karne",
                            toolbarDisabled: false
                        });
                        this.mainDataGrid.instance.refresh().then(() => {
                            this.gridMainSatirIptal();
                            notify("Liste ve karneniz oluşturuldu", "success", 5000);
                        });
                    }
                    else {
                        this.setState({
                            listeKarneText: "Liste ve Karne",
                            toolbarDisabled: false
                        });
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                }).catch(error => {
                    this.setState({
                        listeKarneText: "Liste ve Karne",
                        toolbarDisabled: false
                    });
                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                });
        }
    }

    async tekPdfClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.setState({
                tekPdfText: "!Bekleyiniz",
                toolbarDisabled: true
            });
            var configAjax = {
                timeout: 1200000,
                headers: this.getHeaders()
            }
            await service.get(this.getKarneApi() + "hizlirapor/getkurumsiniflistekarne/" + satir.Id,
                configAjax)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            tekPdfText: "Tek Pdf",
                            toolbarDisabled: false
                        });
                        this.mainDataGrid.instance.refresh().then(() => {
                            this.gridMainSatirIptal();
                            notify("Tek pdf oluşturuldu", "success", 5000);
                        });
                    }
                    else {
                        this.setState({
                            tekPdfText: "Tek Pdf",
                            toolbarDisabled: false
                        });
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                }).catch(error => {
                    this.setState({
                        tekPdfText: "Tek Pdf",
                        toolbarDisabled: false
                    });
                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                });
        }
    }

    listeClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            var configAjax = {
                timeout: 1200000,
                headers: this.getHeaders()
            };
            service.get(this.getKarneApi() + "hizlirapor/GetListe/" + satir.Id,
                configAjax)
                .then(res => {
                    if (res.data.Success) {
                        this.hideLoadPanel();
                        this.mainDataGrid.instance.refresh().then(() => {
                            notify("Sınıf sıralı listeniz oluşturuldu", "success", 5000);
                            this.gridMainSatirIptal();
                        });

                    }
                    else {
                        this.hideLoadPanel();
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(error => {
                    this.hideLoadPanel();
                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                })
        }
    }

    listeKurumClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            var configAjax = {
                timeout: 1200000,
                headers: this.getHeaders()
            };
            service.get(this.getKarneApi() + "hizlirapor/GetKurumListe/" + satir.Id,
                configAjax)
                .then(res => {
                    if (res.data.Success) {
                        this.hideLoadPanel();
                        this.mainDataGrid.instance.refresh().then(() => {
                            this.gridMainSatirIptal();
                            notify("Kurum sıralı listeniz oluşturuldu", "success", 5000);
                        });
                    }
                    else {
                        this.hideLoadPanel();
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                })
                .catch(error => {
                    this.hideLoadPanel();
                    notify("Bir hata oluştu, lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                });
        }
    }

    listeExcelClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            this.showLoadPanel();
            service.get(this.getKarneApi() + "hizlirapor/GetListeExcel/" + satir.Id, {
                timeout: 1200000,
                headers: this.getHeaders()

            })
                .then(res => {
                    if (res.data.Success) {
                        this.hideLoadPanel();
                        this.mainDataGrid.instance.refresh().then(() => {
                            this.gridMainSatirIptal();
                            notify("Excel formatında listeniz oluşturuldu", "success", 5000);
                        });
                    }
                    else {
                        this.hideLoadPanel();
                        notify(res.data.ErrorMessage, "error", 5000);
                    }
                }).catch(error => {
                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                    this.hideLoadPanel();
                });
        }
    }

    listeTytAytClick() {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            if (this.satir.SinavTuru !== "AYT") {
                alert("Bu rapor sadece AYT sınav türünde alınabilir");
            } else {
                this.showLoadPanel();
                service.get(this.getKarneApi() + "hizlirapor/getlistetytayt/" + satir.Id, {
                    timeout: 1200000,
                    headers: this.getHeaders()

                })
                    .then(res => {
                        if (res.data.Success) {
                            this.hideLoadPanel();
                            this.mainDataGrid.instance.refresh().then(() => {
                                this.gridMainSatirIptal();
                                notify("Excel formatında listeniz oluşturuldu", "success", 5000);
                            });
                        }
                        else {
                            this.hideLoadPanel();
                            notify(res.data.ErrorMessage, "error", 5000);
                        }
                    }).catch(error => {
                        notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz," + error, "error", 5000);
                        this.hideLoadPanel();
                    });
            }

        }
    }

    gridMainSatirIptal() {
        if (this.mainDataGrid.instance !== null) {
            this.mainDataGrid.instance.clearSelection();
            this.mainDataGrid.instance.option("focusedRowIndex", -1);
        }
    }

    render() {
        return (
            <React.Fragment>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    onHiding={this.hideLoadPanel}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <div style={{ padding: "10px" }} id="sinav">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Sınav İşlemleri>>Sınav Yükle"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <b>Güncel sıralamaların olduğu liste ve karne için ilgili satırı seçip tekrar rapor alınması gerekmektedir.</b><br></br>
                        </div>
                    </div>
                    <div className="row" style={{ display: window.ayarlar.projeturu === "otomasyon" ? "" : "none" }}>
                        <div className="col-md-12">
                            <b>Merkezi sınavlar ile ilgili işlem yampak için ağaşıda ki butona basarak local ve merkezi sınavlar arasında geçiş yapabilirsiniz</b><br></br><br></br><br></br>
                        </div>
                    </div>
                    <div className='row gy-2'>
                        <div className='col-md-2 text-start'>
                            <Button
                                type={'default'}
                                text={"Liste ve Karne"}
                                onClick={(e) => {
                                    this.listeVeKarneClick();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Tek Pdf"}
                                onClick={(e) => {
                                    this.tekPdfClick();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Sınıf Liste"}
                                onClick={(e) => {
                                    this.listeClick();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurum Liste"}
                                onClick={(e) => {
                                    this.listeKurumClick();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Excel Liste"}
                                onClick={(e) => {
                                    this.listeExcelClick();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"TYT-AYT Liste"}
                                onClick={(e) => {
                                    this.listeTytAytClick();

                                }}></Button>
                        </div>


                    </div>
                    <div className='mt-2'></div>
                    <div className='row gy-2'>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar Genel Analiz"}
                                onClick={(e) => {
                                    this.kurumGenelRaporuGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar İl Analiz"}
                                onClick={(e) => {
                                    this.kurumlarIlAnaliziGetir();

                                }}></Button>
                        </div>

                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar İlçe Analiz"}
                                onClick={(e) => {
                                    this.kurumGenelIlceRaporuGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar Sınıf Analiz"}
                                onClick={(e) => {
                                    this.kurumGenelSinifRaporuGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Öğrenci Genel Analiz"}
                                onClick={(e) => {
                                    this.ilk100GenelRaporuGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Öğrenci İl Analiz"}
                                onClick={(e) => {
                                    this.ilk100IlRaporuGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Öğrenci İlçe Analiz"}
                                onClick={(e) => {
                                    this.ilk100IlceRaporuGetir();

                                }}></Button>
                        </div>

                    </div>
                    <div className='mt-2'></div>

                    <div className='row'>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurum Sınav Konu Analiz"}
                                onClick={(e) => {
                                    this.kurumlarSinifAnaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurum Sınav Soru Analiz"}
                                onClick={(e) => {
                                    this.kurumSoruanaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Sınıf Sınav Konu Analiz"}
                                onClick={(e) => {
                                    this.sinifKonuAnaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Sınıf Sınav Soru Analiz"}
                                onClick={(e) => {
                                    this.sinifSoruAnaliziGetir();

                                }}></Button>
                        </div>
                    </div>
                    <div className='mt-2'></div>
                    <div className='row'>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar İl Net Analiz"}
                                onClick={(e) => {
                                    this.sinifSoruAnaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar İlçe Net Analiz"}
                                onClick={(e) => {
                                    this.kurumlarIlceAnaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar Sınıf Net Analiz"}
                                onClick={(e) => {
                                    this.kurumlarSinifAnaliziGetir();

                                }}></Button>
                        </div>
                        <div className='col-md-2  text-start'>
                            <Button
                                type={'default'}
                                text={"Kurumlar Öğrenci Net Analiz"}
                                onClick={(e) => {
                                    this.kurumOgrenciAnaliziGetir();

                                }}></Button>
                        </div>
                    </div>
                    <div className='mt-2'></div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                visible={window.ayarlar.projeturu === "otomasyon" ? true : false}
                                type={this.state.merkeziSinavGoster ? 'success' : 'default'}
                                text={this.state.merkeziSinavGoster ? "Merkezi sınavlar ile ilgili işlem yapılıyor" : "Local sınavlar ile ilgili işlem yapılıyor"}
                                onClick={(e) => {
                                    if (!this.state.merkeziSinavGoster) {

                                        this.degerlendirilenSinavlarDataSource = new DataSource(
                                            {
                                                paginate: true,
                                                pageSize: 100,
                                                store: createStore({
                                                    key: "Id",
                                                    loadUrl: yayinSinavApi + 'merkezisinav/sgedegerlendirilensinavlar',
                                                    onBeforeSend: (method, ajaxOptions) => {
                                                        ajaxOptions.headers = {
                                                            'Authorization': 'Bearer ' + this.props.remoteSystem.remoteToken
                                                        };
                                                    }
                                                })
                                            });


                                        this.mainDataGrid.instance.option("dataSource", this.degerlendirilenSinavlarDataSource);
                                        this.mainDataGrid.instance.refresh();


                                    }
                                    else {

                                        this.degerlendirilenSinavlarDataSource = new DataSource(
                                            {
                                                paginate: true,
                                                pageSize: 100,
                                                store: createStore({
                                                    key: "Id",
                                                    loadUrl: sinavApi + 'merkezisinav/sgedegerlendirilensinavlar',
                                                    onBeforeSend: (method, ajaxOptions) => {
                                                        ajaxOptions.headers = {
                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                        };
                                                    }
                                                })
                                            });
                                        this.mainDataGrid.instance.option("dataSource", this.degerlendirilenSinavlarDataSource);
                                        this.mainDataGrid.instance.refresh();
                                    }

                                    this.setState({
                                        merkeziSinavGoster: !this.state.merkeziSinavGoster
                                    });

                                    this.ilDataSource = new DataSource(
                                        {
                                            cacheRawData: false,
                                            paginate: false,
                                            store: createStore({
                                                loadUrl: this.getSinavApi() + 'ortak/iller',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    this.ilceDataSource = new DataSource(
                                        {
                                            cacheRawData: false,
                                            paginate: false,
                                            store: createStore({
                                                loadParams: this.ilfilter,
                                                loadUrl: this.getSinavApi() + 'ortak/ilceler',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    this.yayinDataSource = new DataSource(
                                        {
                                            paginate: false,
                                            pageSize: 100,
                                            store: createStore({
                                                loadUrl: this.getSinavApi() + 'yayin/yayinlarByYetki',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    //this.selectBoxYayin.instance.option("dataSource", this.yayinDataSource);
                                    this.sinavTuruDataSource = new DataSource(
                                        {
                                            store: createStore({
                                                loadParams: this.yayinfilter,
                                                loadUrl: this.getSinavApi() + 'ortak/GetSinavTurleriByYayinId',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    this.merkeziSinavDataSource = new DataSource(
                                        {
                                            filter: ["Degerlendirilebilir", "=", true],
                                            paginate: false,
                                            store: createStore({
                                                loadParams: this.sinavTuruFilter,
                                                loadUrl: this.getSinavApi() + 'merkezisinav/sgemerkezisinavlar',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    this.tytMerkeziSinavDataSource = new DataSource(
                                        {
                                            filter: ["Degerlendirilebilir", "=", true],
                                            paginate: false,
                                            store: createStore({
                                                loadParams: this.yayin2filter,
                                                loadUrl: this.getSinavApi() + 'merkezisinav/sgetytmerkezisinavlar',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });

                                    this.optikFormDataSource = new DataSource(
                                        {
                                            paginate: false,
                                            store: createStore({
                                                loadParams: this.sinavTuruFilter,
                                                loadUrl: this.getSinavApi() + 'optikform/sgeoptikformlar',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });
                                    this.kurumDataSource = new DataSource(
                                        {
                                            pageSize: 100,
                                            paginate: true,
                                            store: createStore({
                                                loadUrl: this.getSinavApi() + 'kurum/sgekurum',
                                                onBeforeSend: (method, ajaxOptions) => {
                                                    ajaxOptions.headers = this.getHeaders();
                                                }
                                            })
                                        });
                                }}></Button><br></br><br></br><br></br>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="sinavGonderimiformpanel">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    remoteOperations={true}
                                    dataSource={this.degerlendirilenSinavlarDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={{ mode: 'single' }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="Id"
                                    onInitialized={this.onInitialized}
                                    onToolbarPreparing={this.onToolbarPreparing}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={(ref) => this.mainDataGrid = ref}
                                >
                                    <Editing mode={'popup'} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                    <Column dataField={'ListeKarneId'} caption="ListeKarneId" visible={false} />
                                    <Column dataField={'Sinav_Id'} caption="Sınav Id" visible={false} />
                                    <Column dataField={'Kurum_Id'} caption="Kurum Id" visible={false} />
                                    <Column dataField="Yayin_Adi" caption=""
                                        width={40}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        cellRender={linkRender}
                                    />
                                    <Column dataField="Yayin_Adi" caption=""
                                        width={40}
                                        allowSorting={false}
                                        allowFiltering={false}
                                        cellRender={linkWhatsAppRender}
                                    />
                                    <Column dataField="Yayin_Adi" caption=""
                                        width={40}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        cellRender={linkMailRender}
                                    />
                                    <Column dataField="Yayin_Adi" caption=""
                                        width={40}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        visible={(window.ayarlar.projeturu === "otomasyon") ? true : false}
                                        cellRender={linkKayitliOgrenciEslestir}
                                    />
                                    <Column dataField={'Yayin_Adi'} caption="Yayın" />
                                    <Column dataField={'SinavTuru_Adi'} caption="Sınav Türü" />
                                    <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                                    <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                                    <Column dataField={'Il_Adi'} caption="İl" />
                                    <Column dataField={'Ilce_Adi'} caption="İlçe" />
                                    <Column dataField={'Kurum_Adi'} caption="Kurum" />
                                    <Column dataField={'Katilim'} caption="Katılım" dataType="number" />

                                    <Column dataField={'Created'} caption="Tarih" dataType="date" sortOrder={"desc"} format={
                                        "dd/MM/yyyy HH:mm:ss"
                                    } />
                                    <Column dataField={'SinavTxt'} caption="Dosya" dataType="string" visible={false} />
                                </DataGrid>
                                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                                    onHiding={this.popupGizle} visible={this.state.popup}
                                    toolbarItems={
                                        [
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Sınavı Değerlendir',
                                                    type: 'success',
                                                    hint: 'Sınavı Değerlendir',
                                                    icon: 'check',
                                                    ref: (ref) => { this.buttonDegerlendir = ref },
                                                    onInitialized: this.onInitializedButtonDegerlendir
                                                },
                                                onClick: (e) => {
                                                    const validateStatus = this.formSinavGonderimi.instance.validate();
                                                    if (!validateStatus.isValid) {
                                                        return;
                                                    }
                                                    else {
                                                        this.buttonDegerlendir.instance().option("disabled", true);
                                                        this.formData.BosOgrenciNo = this.bosOgrenciNo;
                                                        this.formData.BosKitapcikTuru = this.bosKitapcikTuru;
                                                        this.formData.DosyaAdi = this.dosyaAdi;
                                                        this.formData.KurumId = this.formData.Kurum.Id;
                                                        if (this.formData.MerkeziSinav2.Id === null || this.formData.MerkeziSinav2.Id === undefined) {
                                                            this.formData.MerkeziSinav2.Id = 0;
                                                        }
                                                        if (this.formData.Yayin2.Id === null || this.formData.MerkeziSinav2.Id === undefined) {
                                                            this.formData.Yayin2.Id = 0;
                                                        }
                                                        service.post(this.getSinavApi() + "SinavGonderimi/SinavGonder",
                                                            this.formData,
                                                            {
                                                                headers: this.getHeaders()
                                                            })
                                                            .then(async (res) => {
                                                                if (res.data.Success) {
                                                                    notify("Sınavınız değerlendirildi.", "success", 3000);
                                                                    this.buttonDegerlendir.instance().option("disabled", false);
                                                                    await this.mainDataGrid.instance.refresh();
                                                                    await this.gridMainSatirIptal();
                                                                    await this.setState({
                                                                        popup: false
                                                                    });

                                                                }
                                                                else {
                                                                    this.buttonDegerlendir.instance().option("disabled", false);
                                                                    notify("Teknik bir hata oluştu," + res.data.ErrorMessage, "error", 3000);
                                                                }
                                                            }).catch(err => {
                                                                this.buttonDegerlendir.instance().option("disabled", false);
                                                                if (err.response) {
                                                                    var resError = JSON.parse(err.response.request.response);
                                                                    var mesaj = "<font color='red'>status:</font>" + resError.status + "<br/>";
                                                                    var dictionary = Object.keys(resError.errors);
                                                                    dictionary.forEach((satir, index) => {
                                                                        mesaj += "<font color='red'>" + satir + ":</font>" + resError.errors[satir.toString()][0] + "<br/>";
                                                                    });
                                                                    mesaj += "<font color='red'>title:</font>" + resError.title + "<br/>";
                                                                    mesaj += "<font color='red'>traceId:</font>" + resError.traceId + "<br/>";
                                                                    mesaj += "<font color='red'>type:</font>" + resError.type + "<br/>";
                                                                    alert(mesaj);
                                                                } else {
                                                                    notify(err, "error", 5000);
                                                                }
                                                            });
                                                    }

                                                }

                                            },
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                                    , onClick: () => { this.popupGizle(); }
                                                }
                                            }

                                        ]
                                    }
                                >
                                    <Position my={'center'} at={'center'} of={window} />
                                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form formData={this.formData} onInitialized={this.onInitializedFormSinavGonderimi} ref={(ref) => this.formSinavGonderimi = ref}
                                                    showValidationSummary={true}
                                                >
                                                    <GroupItem cssClass="first-group" colCount={1} caption="Sınav Yükleme  Bilgileri">
                                                        <SimpleItem dataField={"Yayin.Id"} isRequired={true}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.yayinDataSource,
                                                                keyExpr: "Id",
                                                                searchEnabled: true,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                showClearButton: true,
                                                                onInitialized: this.onInitializedSelectBoxYayin,
                                                                ref: (ref) => { this.selectBoxYayin = ref },
                                                                onValueChanged: (e) => {
                                                                    if (e.value === "" | e.value === null | e.value === undefined) {

                                                                    }
                                                                    else {
                                                                        this.yayinfilter.YayinId = e.value;
                                                                        this.sinavTuruFilter.YayinId = e.value;
                                                                        this.sinavTuruDataSource.load();

                                                                    }
                                                                    this.kurumDataSource.filter(null);
                                                                    this.kurumDataSource.load();
                                                                    this.selectBoxYayin2.instance().option("value", null);
                                                                    this.selectBoxSinavTuru.instance().option("value", null);
                                                                    this.selectBoxMerkeziSinav.instance().option("value", null);
                                                                    this.selectBoxMerkeziSinav2.instance().option("value", null);
                                                                    //this.selectBoxOptikform.instance().option("value", null);
                                                                }
                                                            }}>
                                                            <Label text="Yayın" />
                                                            <RequiredRule message="Yayın alanı gerekli" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"SinavTuru.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.sinavTuruDataSource,
                                                                displayExpr: "SinavTuruAdi",
                                                                valueExpr: "SinavTuru.Id",
                                                                onInitialized: this.onInitializedSelectBoxSinavTuru,
                                                                ref: (ref) => { this.selectBoxSinavTuru = ref },
                                                                onValueChanged: (e) => {
                                                                    if (e.value === "" | e.value === null) {
                                                                        return;
                                                                    }
                                                                    else {
                                                                        this.sinavTuruFilter.SinavTuruId = e.value;
                                                                        if (e.value === 11) {
                                                                            this.selectBoxMerkeziSinav2.instance().option("disabled", false);
                                                                            this.selectBoxYayin2.instance().option("disabled", false);
                                                                        }
                                                                        else {
                                                                            this.selectBoxMerkeziSinav2.instance().option("disabled", true);
                                                                            this.selectBoxYayin2.instance().option("disabled", true);
                                                                        }
                                                                        if (this.formData.Yayin !== null && this.formData.SinavTuru !== null) {
                                                                            this.merkeziSinavDataSource.load();
                                                                            this.selectBoxYayin2.instance().option("value", null);
                                                                            this.selectBoxMerkeziSinav.instance().option("value", null);
                                                                            this.selectBoxMerkeziSinav2.instance().option("value", null);
                                                                            //this.selectBoxOptikform.instance().option("value", null);
                                                                        }
                                                                        this.kurumDataSource.filter(null);
                                                                        this.kurumDataSource.load();
                                                                    }
                                                                }
                                                            }}>
                                                            <Label text="Sınav Türü" />
                                                            <RequiredRule message="Sınav türü alanı gerekli" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"MerkeziSinav.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.merkeziSinavDataSource,
                                                                showClearButton: true,
                                                                searchEnabled: true,
                                                                searchMode: "contains",
                                                                searchExpr: ["Kodu", "Adi"],
                                                                displayExpr: (item) => {
                                                                    if (!item) {
                                                                        return "";
                                                                    }
                                                                    else {
                                                                        return item.Kodu + " - " + item.Adi;
                                                                    }
                                                                },
                                                                valueExpr: "Id",
                                                                itemTemplate: (itemData, itemIndex, itemElement) => {
                                                                    return "<div style='color:" + itemData.Renk + "'>" + itemData.Kodu + "-" + itemData.Adi + "</div>";
                                                                },
                                                                onInitialized: this.onInitializedSelectBoxMerkeziSinav,
                                                                ref: (ref) => { this.selectBoxMerkeziSinav = ref },
                                                                onValueChanged: (e) => {
                                                                    this.kurumDataSource.filter(null);
                                                                    this.kurumDataSource.load();
                                                                    this.ogrenciNoVer();
                                                                    this.getKitapcikTuruVer();
                                                                    this.merkeziSinavId = e.value;

                                                                    this.fileUploadTxt.instance().option("uploadUrl", this.getSinavApi() + "sinavgonderimi/TxtUpload?MerkeziSinavId=" + this.merkeziSinavId + "&KurumId=" + e.value);

                                                                    if (this.formData.Yayin !== null && this.formData.SinavTuru !== null) {
                                                                        this.sinavTuruFilter.MerkeziSinavId = isNaN(this.merkeziSinavId) ? 0 : parseInt(this.merkeziSinavId);
                                                                        this.merkeziSinavDataSource.load();
                                                                        this.optikFormDataSource.load();
                                                                        this.selectBoxYayin2.instance().option("value", null);
                                                                        this.selectBoxMerkeziSinav2.instance().option("value", null);
                                                                        this.selectBoxOptikform.instance().option("value", null);
                                                                    }
                                                                }
                                                            }}>
                                                            <Label text="Sınav" />
                                                            <RequiredRule message="Merkez sınav alanı gerekli" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"OptikForm.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.optikFormDataSource,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                searchEnabled: true,
                                                                showClearButton: true,
                                                                onInitialized: this.onInitializedSelectBoxOptikform,
                                                                ref: (ref) => { this.selectBoxOptikform = ref },
                                                                onValueChanged: (e) => {
                                                                    if (e.value === "" | e.value === null)
                                                                        return;
                                                                    else {
                                                                        this.sinavTuru = e.component.option('selectedItem');
                                                                    }
                                                                }
                                                            }}>
                                                            <Label text="Optik Form" />
                                                            <RequiredRule message="Optik form alanı gerekli" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"Yayin2.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.yayinDataSource,
                                                                remoteOperations: true,
                                                                searchEnabled: true,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                disabled: true,
                                                                onInitialized: this.onInitializedSelectBoxYayin2,
                                                                ref: (ref) => { this.selectBoxYayin2 = ref },
                                                                onValueChanged: (e) => {
                                                                    if (e.value !== null) {
                                                                        this.yayin2filter.YayinId = e.value;
                                                                        this.tytMerkeziSinavDataSource.load();
                                                                    }
                                                                }
                                                            }}>
                                                            <Label text="Yayın" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"MerkeziSinav2.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.tytMerkeziSinavDataSource,
                                                                remoteOperations: true,
                                                                searchEnabled: true,
                                                                searchMode: "contains",
                                                                searchExpr: ["Kodu", "Adi"],
                                                                displayExpr: (item) => {
                                                                    if (!item) {
                                                                        return "";
                                                                    }
                                                                    else {
                                                                        return item.Kodu + " - " + item.Adi;
                                                                    }
                                                                },
                                                                itemTemplate: (itemData, itemIndex, itemElement) => {
                                                                    return "<div style='color:" + itemData.Renk + "'>" + itemData.Kodu + "-" + itemData.Adi + "</div>";
                                                                },
                                                                disabled: true,
                                                                valueExpr: "Id",
                                                                onInitialized: this.onInitializedSelectBoxMerkeziSinav2,
                                                                ref: (ref) => { this.selectBoxMerkeziSinav2 = ref },
                                                            }}>
                                                            <Label text="TYT Sınav" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"Kurum.Id"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.kurumDataSource,
                                                                pageSize: 100,
                                                                remoteOperations: true,
                                                                searchMode: "contains",
                                                                searchExpr: ["KurumAdi", "KurumEmail", "KurumKodu"],
                                                                searchEnabled: true,
                                                                displayExpr: (item) => {
                                                                    if (!item) {
                                                                        return "";
                                                                    }
                                                                    else {
                                                                        return item.KurumAdi + " - " + item.ilAdi + " - " + item.ilceAdi;
                                                                    }
                                                                },
                                                                showClearButton: true,
                                                                valueExpr: "Id",
                                                                onValueChanged: (e) => {
                                                                    if (e.value === 0) {
                                                                        this.formDataKurum = {
                                                                            Id: 0,
                                                                            Il: { Id: 0 },
                                                                            Ilce: { Id: 0 },
                                                                            KurumKodu: 1,
                                                                            KurumEmail: null,
                                                                            KurumAdi: null,
                                                                            Logo: null,
                                                                            Gsm: null
                                                                        }
                                                                        this.setState({
                                                                            secilenMerkeziSinavId: this.merkeziSinavId,
                                                                            popupKurum: true
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.fileUploadTxt.instance().option("uploadUrl", this.getSinavApi() + "sinavgonderimi/TxtUpload?MerkeziSinavId=" + this.merkeziSinavId + "&KurumId=" + e.value);
                                                                    }

                                                                },
                                                                onInitialized: this.onInitializedSelectBoxKurumId,
                                                                ref: (ref) => { this.selectBoxKurumId = ref }
                                                            }} isRequired={true}>
                                                            <Label text="Kurum Seçiniz" />
                                                            <RequiredRule message="Kurum alanı gerekli" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"VeriSilme"}
                                                            editorType="dxCheckBox">
                                                            <Label text="Yüklü sınav verilerini silme" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"File"}
                                                            editorType="dxFileUploader"
                                                            editorOptions={{
                                                                uploadUrl: this.getSinavApi() + "sinavgonderimi/TxtUpload?MerkeziSinavId=" + this.state.secilenMerkeziSinavId + "&KurumId=" + this.state.secilenKurumId,
                                                                uploadMode: 'instantly',
                                                                uploadHeaders: this.getHeaders(),
                                                                accept: '*',
                                                                maxFileSize: 4000000,
                                                                name: 'myFile',
                                                                onValueChanged: this.onFileSelected,
                                                                onUploaded: this.onUploaded,
                                                                onInitialized: this.onInitializedFileUploadTxt,
                                                                ref: (ref) => { this.fileUploadTxt = ref },
                                                            }}>
                                                            <Label text="Txt dosyasını seçiniz" />
                                                        </SimpleItem>
                                                        <SimpleItem>
                                                            <div id="spnYuklenenDosya" className="col-md-6">

                                                            </div>
                                                        </SimpleItem>
                                                    </GroupItem>
                                                    <GroupItem cssClass="first-group" colCount={1} caption="Sınav Yükleme  Hakkında">
                                                        <p style={{ fontWeight: "bold" }}>
                                                            TYT-AYT sınavları öğrenci numarası alanı ile birleştirilmektedir.
                                                        </p>
                                                        <p style={{ fontWeight: "bold" }}>
                                                            İki oturumlu LGS sınavları öğrenci numarası ve adı soyadı ile birleştirilmektedir.
                                                        </p>
                                                        <p style={{ fontWeight: "bold" }}>
                                                            Sözel ve Sayısal oturumlarında öğrenci numarası veya adı soyadı kodlanmalıdır.
                                                        </p>
                                                        <p style={{ fontWeight: "bold" }}>
                                                            İki oturumlu LGS sınavlarında Sözel ve Sayısal datalar tek dosya şeklinde ve çift optik form seçilerek yüklenmelidir.
                                                        </p>
                                                    </GroupItem>
                                                </Form>
                                            </div>

                                        </div>

                                    </ScrollView>
                                </Popup>
                                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupKurumForm = ref}
                                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                                    onHiding={this.popupGizleKurum} visible={this.state.popupKurum} height={function () {
                                        return window.innerHeight / 1;
                                    }} width={function () {
                                        return window.innerWidth / 1;
                                    }
                                    }
                                    toolbarItems={
                                        [
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Yeni Kurumu Kaydet', type: 'success', hint: 'Yeni Kurumu Kaydet', icon: 'check'
                                                    , onClick: (e) => {
                                                        const validateStatus = this.formKurum.instance.validate();
                                                        if (!validateStatus.isValid) {
                                                            return;
                                                        }
                                                        else {
                                                            service.post(sinavApi + 'kurum/KurumKaydet',
                                                                this.formDataKurum,
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                }
                                                            ).then(res => {
                                                                if (res.data.Success) {
                                                                    this.kurumDataSource.load().done(() => {
                                                                        this.kurumDataSource.filter("Id", "=", res.data.Data.Id);
                                                                        this.kurumDataSource.load().done(() => {
                                                                            this.selectBoxKurumId.instance().option("value", res.data.Data.Id);
                                                                            this.fileUploadTxt.instance().option("uploadUrl", this.getSinavApi() + "sinavgonderimi/TxtUpload?MerkeziSinavId=" + this.merkeziSinavId + "&KurumId=" + res.data.Data.Id);
                                                                            this.kurumDataSource.filter(null);
                                                                            this.selectBoxKurumId.instance().option("dataSource", this.kurumDataSource);
                                                                            this.setState({
                                                                                secilenMerkeziSinavId: this.merkeziSinavId,
                                                                                secilenKurumId: res.data.Data.Id,
                                                                                popupKurum: false
                                                                            });
                                                                        })

                                                                    });


                                                                }
                                                                else {
                                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                                }

                                                            }).catch(err => {
                                                                notify(err, 'error', 5000);
                                                            });
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                location: 'after', widget: 'dxButton', options: {
                                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                                    , onClick: () => { this.popupGizleKurum(); }
                                                }
                                            }

                                        ]
                                    }
                                >
                                    <Position my={'center'} at={'center'} of={window} />
                                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form formData={this.formDataKurum} onInitialized={this.onInitializedFormKurum} ref={(ref) => this.formKurum = ref}>
                                                    <GroupItem cssClass="first-group" colCount={2} caption="Kurum  Bilgileri">
                                                        <SimpleItem dataField={"KurumTuruId"}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.kurumTurleri,
                                                                searchEnabled: true,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                onValueChanged: (e) => {
                                                                    this.formDataKurum.KurumTuruId = e.value;
                                                                }
                                                            }}>
                                                            <Label text="Tür" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"Il.Id"} isRequired={true}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.ilDataSource,
                                                                searchEnabled: false,
                                                                remoteOperations: { filtering: false },
                                                                disabled: this.state.disabled,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                onInitialized: this.onInitializedSelectBoxIl,
                                                                ref: (ref) => { this.selectBoxIl = ref },
                                                                onValueChanged: (e) => {
                                                                    this.ilfilter.IlId = e.value;
                                                                    this.ilceDataSource.load();
                                                                    this.selectBoxIlce.instance().option("value", null);
                                                                }
                                                            }}>
                                                            <RequiredRule message="İl alanı gerekli" />
                                                            <Label text="İl" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"Ilce.Id"} isRequired={true}
                                                            editorType="dxSelectBox"
                                                            editorOptions={{
                                                                dataSource: this.ilceDataSource,
                                                                remoteOperations: { filtering: false },
                                                                searchEnabled: false,
                                                                displayExpr: "Adi",
                                                                valueExpr: "Id",
                                                                onInitialized: this.onInitializedSelectBoxIlce,
                                                                ref: (ref) => { this.selectBoxIlce = ref },
                                                                onValueChanged: (e) => {

                                                                }
                                                            }}
                                                        >
                                                            <RequiredRule message="İlçe alanı gerekli" />
                                                            <Label text="İlçe" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"KurumAdi"} isRequired={true}>
                                                            <Label text="Adı" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"KurumEmail"} isRequired={true}>
                                                            <Label text="Email" />
                                                        </SimpleItem>
                                                        <SimpleItem dataField={"Gsm"} isRequired={true}
                                                            editorType="dxTextBox"
                                                            editorOptions={{
                                                                mask: "(000) 000-0000"
                                                            }}>
                                                            <Label text="Gsm" />
                                                        </SimpleItem>
                                                    </GroupItem>
                                                    <GroupItem cssClass="first-group" colCount={2} caption="Kurum Kodu Bilgileri">
                                                        <SimpleItem dataField={"KurumKodu"} isRequired={true}
                                                            editorType="dxNumberBox"
                                                            editorOptions={{
                                                                onInitialized: this.onInitializedNumberBoxKurumKodu,
                                                                ref: (ref) => { this.numberBoxKurumKodu = ref },
                                                                disabled: false
                                                            }}>
                                                            <Label text="Kodu" />
                                                        </SimpleItem>
                                                        <SimpleItem editorType="dxButton"
                                                            editorOptions={{
                                                                location: "after",
                                                                name: "txtKurumKodu",
                                                                icon: "check",
                                                                type: "default",
                                                                text: "Kurum Kodu Ver",
                                                                disabled: false,
                                                                onClick: () => {
                                                                    service.get(sinavApi + "kurum/kurumKoduUret", {
                                                                        headers: {
                                                                            'Authorization': 'Bearer ' + Globals.service.Token
                                                                        }
                                                                    }).then(res => {
                                                                        if (res.data.Success) {
                                                                            this.numberBoxKurumKodu.instance().option("value", res.data.Data);
                                                                            this.formDataKurum.KurumKodu = res.data.Data;
                                                                        }
                                                                        else {
                                                                            notify(res.data.ErrorMessage, "error", 5000);
                                                                        }
                                                                    });
                                                                }
                                                            }}>

                                                        </SimpleItem>
                                                    </GroupItem>
                                                    <GroupItem cssClass="first-group" colCount={1} caption="Kurum  Logosu(Logolu liste ve karne için)">
                                                        <SimpleItem dataField={"File"}
                                                            editorType="dxFileUploader"
                                                            editorOptions={{
                                                                uploadUrl: this.getSinavApi() + "kurum/KurumLogoUpload",
                                                                uploadMode: 'instantly',
                                                                uploadHeaders: this.getHeaders(),
                                                                accept: '*',
                                                                maxFileSize: 4000000,
                                                                name: 'myFile',
                                                                onUploaded: this.onUploadedKurumLogo,

                                                            }}>
                                                            <Label text="Logo seçiniz" />
                                                        </SimpleItem>
                                                    </GroupItem>
                                                </Form>
                                            </div>
                                        </div>
                                    </ScrollView>
                                </Popup>
                            </div>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SinavGonderimi);

function linkRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Dosya === undefined || data.data.Dosya === null || data.data.Dosya === "") {
        return <p></p>;
    }
    else {
        return <a rel="noopener noreferrer" href={data.data.Dosya} target="_blank">
            <img src="/icons/download.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}

function linkMailRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.KurumEmail === undefined || data.data.KurumEmail === null || data.data.KurumEmail === "") {
        return <img src="/icons/mailing.png" alt="Mail Gönder" style={{ cursor: "pointer" }} onClick={() => {
            alert("Bu kuruma ait tanımlı mail adresi olmadığından mail gönderme işlemi yapılamıyor.<br/> Kurumlar sayfasından mail tanımlaması yapabilirsiniz.");
        }} />;
    }
    else {
        return <img src="/icons/mailing.png" alt="Mail Gönder" style={{ cursor: "pointer" }} onClick={() => {
            service.get(karneApi + "hizlirapor/MailGonder/" + data.data.ListeKarneId,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        notify(res.data.Data, "success", 5000);
                    }
                    else
                        notify(res.data.ErrorMessage, "error", 5000);
                })
                .catch(error => {
                    notify("Teknik bir hata oluştuğundan dolayı mailiniz gönderilemedi.", "error", 5000);
                });
        }} />;
    }
}

function linkWhatsAppRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Gsm === undefined || data.data.Gsm === null || data.data.Gsm === "") {
        return <img src="/icons/whatsapp.png" alt="Whatspp da paylaş" onClick={() => {
            alert("Bu kuruma ait tanımlı cep telefonu olmadığından paylaşım yapılamıyor.<br/> Kurumlar sayfasından cep telefonu ekleyebilirsiniz.");
        }} />;
    }
    else {
        return <a rel="noopener noreferrer"
            href={"https://api.whatsapp.com/send?phone= +90" + data.data.Gsm + "&text=" + data.data.SinavAdi + "  " + data.data.Dosya}
            target="_blank"
            data-action="share/whatsapp/share" style={{ cursor: "pointer" }}>
            <img src="/icons/whatsapp.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}

function linkKayitliOgrenciEslestir(data) {
    if (data === null)
        return <p></p>;
    else {
        return <img src="/icons/equal.png" alt="Kayıtlı Öğrenciler ile Eşleştir" style={{ cursor: "pointer" }} onClick={
            () => {
                var postData = {
                    SinavId: data.row.data.Sinav_Id,
                    KurumId: data.row.data.Kurum_Id
                };
                service.post(otomasyonApi + "entegrasyon/ogrencieslestir", postData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    })
                    .then(res => {
                        if (res.data.Success) {
                            notify("Öğrenci bilgileri eşleştirildi", "success", 5000);
                        }
                        else
                            notify(res.data.ErrorMessage, "error", 5000);
                    })
                    .catch(error => {
                        notify("Teknik bir hata oluştuğundan dolayı öğrenci bilgileri güncellenemedi.", "error", 5000);
                    });
            }} />;
    }
}
