import * as React from "react";
import { sinavApi, errorMessage, errorMessageWithKonum } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Selection, Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
export default class YayinIslemleri extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            yayinlar: [],
            popup: false,
            loadingText: "",
            toolbarDisabled: false
        };
        this.secilenSinavTurleri = [];
        this.dataGrid = null;
        this.gridSinavTuru = null;
        this.satir = null;
        this.formData = {
            Id: 0,
            Adi: null,
            KlasorAdi: null,
            Deleted: false,
            RowVersion: 0
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridSinavTuruSelectionChanged = this.gridSinavTuruSelectionChanged.bind(this);
        this.yeniEkleClick = this.yeniEkleClick.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.aktifYapClick = this.aktifYapClick.bind(this);
        this.pasifYapClick = this.pasifYapClick.bind(this);
        this.silClick = this.silClick.bind(this);
        this.popupGizle = () => {
            this.setState(
                {
                    popup: false,
                    toolbarDisabled: false
                });
            this.secilenSinavTurleri = [];
            this.satirIptal();
        };
        this.popupAc = () => {
            this.secilenSinavTurleri = [];
            this.setState(
                {
                    popup: true,
                    toolbarDisabled: true
                });
        };
        this.refreshData();
        this.sinavTuruDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'ortak/sinavturleri',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinFilter = { YayinId: 0 };
        this.sinavTuruYayinDataSource = new DataSource(
            {
                loadMode: "raw",
                store: createStore({
                    loadParams: this.yayinFilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }


    onInitialized(e) {
        this.dataGrid = e.component;
    }

    yeniEkleClick() {

        return new Promise((resolve, reject) => {
            try {
                this.formData = {
                    Id: 0,
                    Adi: null,
                    Deleted: false,
                    RowVersion: 0,
                    KlasorAdi: null
                };
                resolve("Yeni form açıldı");
            } catch (error) {
                reject(errorMessageWithKonum(error, "yeni form"));
            }
        }).then(res => {
            this.popupAc();
        }).catch(error => {
            alert(error);
        })


    }

    duzenleClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            return new Promise((resolve, reject) => {
                try {
                    this.formData = {
                        Id: satir.Id,
                        Adi: satir.Adi,
                        KlasorAdi: satir.KlasorAdi,
                        Deleted: satir.Deleted,
                        RowVersion: satir.RowVersion === null ? 0 : (satir.RowVersion)
                    };
                    resolve("Yayın düzenleme formu açıldı..");
                } catch (error) {
                    reject(errorMessageWithKonum(error, "Yayın düzenleme formu"));
                }
            }).then(res => {
                this.popupAc();
            }).catch(error => {
                alert(error)
            });
        }
    }

    silClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif almayı seçebilirsiniz</font></i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    return new Promise((resolve, reject) => {
                        service.post(sinavApi + 'yayin/yayinDelete/' + satir.Id,
                            {
                                Id: satir.Id
                            },
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            }
                        )
                            .then(res => {
                                if (res.data.Success) {
                                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                                    resolve("Silme işleminiz gerçekleştirildi.");
                                }
                                else {
                                    notify(res.data.ErrorMessage, "error", 5000);
                                    reject(res.data.ErrorMessage);
                                }
                            })
                            .catch((error) => {
                                reject(errorMessageWithKonum(error, "yayın silme"));
                            });
                    }).then(res => {
                        this.refreshData();
                    }).catch(error => {
                        alert(error);
                    });

                }
            });
        }
    }

    aktifYapClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    return new Promise((resolve, reject) => {
                        service.post(sinavApi + 'yayin/aktifYap/' + satir.Id,
                            null,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            }
                        )
                            .then(res => {
                                if (res.data.Success) {

                                    resolve("Kaydınız aktif hale getirildi.");
                                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                                }
                                else {
                                    notify(res.data.ErrorMessage, 'error', 5000);
                                    reject(res.data.ErrorMessage);
                                }
                            })
                            .catch((error) => {
                                reject(errorMessage(error));
                            });
                    }).then(res => {
                        this.refreshData();
                    }).catch(error => {
                        alert(error);
                    });
                }
            });

        }
    };

    pasifYapClick(e) {
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    return new Promise((resolve, reject) => {
                        service.post(sinavApi + 'yayin/pasifYap/' + satir.Id,
                            null,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            }
                        )
                            .then(res => {
                                if (res.data.Success) {

                                    resolve("Kaydınız pasif hale getirildi.");

                                }
                                else {
                                    notify(res.data.ErrorMessage, 'error', 5000);
                                    reject(res.data.ErrorMessage);
                                }
                            })
                            .catch((error) => {
                                // Error 😨
                                reject(errorMessage(error));

                            });

                    }).then(res => {
                        notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                        this.refreshData();
                    }).catch(error => {
                        alert(error);
                    });

                }
            });
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yeni', icon: 'add', disabled: this.state.toolbarDisabled,
                    onClick: this.yeniEkleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Düzenle', icon: 'edit', disabled: this.state.toolbarDisabled,
                    onClick: async () => {
                        await this.duzenleClick();
                        this.yayinFilter.YayinId = this.satir.Id;
                        this.sinavTuruYayinDataSource.load().done(() => {
                            let arrayList = [];
                            this.sinavTuruYayinDataSource.items().forEach((item, index) => {
                                arrayList.push(item.SinavTuru.Id);
                            });
                            this.gridSinavTuru.instance.selectRows(arrayList);
                        });

                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'remove', disabled: this.state.toolbarDisabled,
                    onClick: this.silClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank', disabled: this.state.toolbarDisabled,
                    onClick: this.pasifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank', disabled: this.state.toolbarDisabled,
                    onClick: this.aktifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh', disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.refreshData();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa', disabled: this.state.toolbarDisabled,
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }


    gridSinavTuruSelectionChanged() {
        this.secilenSinavTurleri = this.gridSinavTuru.instance.getSelectedRowsData();
    }

    async refreshData() {

        this.setState({
            loadingText: "yayın bilgileri getiriliyor...",
            toolbarDisabled: true
        });

        await this.getYayinlar().then(res => { }).catch(error => {
            alert(error);
        });

        await this.satirIptal();
        this.setState({
            loadingText: "",
            toolbarDisabled: false,
            popup: false
        });

    }

    //seçilen satırı iptal etmek için kullanıyoruz
    async satirIptal() {
        this.dataGrid.instance.clearSelection();
        this.dataGrid.instance.option("focusedRowIndex", -1);
    }

    async getYayinlar() {
        return new Promise((resolve, reject) => {
            service.get(sinavApi + 'yayin/getYayinlar', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        yayinlar: res.data.Data
                    });
                    resolve("yayın bilgileri getirildi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                reject(errorMessageWithKonum(error, "yayın bilgileri"));
            })
        })
    }


    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.loadingText}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.state.yayinlar}
                                repaintChangesOnly={true}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={true}

                                keyExpr="Id"
                                paging={{
                                    pageSize: 20
                                }}
                                pager={{
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                    showInfo: true
                                }}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onInitialized={this.onInitialized}
                                ref={(ref) => this.dataGrid = ref}
                            >
                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                <Column dataField={'Id'} caption="Id" visible={false} />
                                <Column dataField={'Deleted'} caption="Pasif" />
                                <Column dataField={'Adi'} caption="Yayın" />
                                <Column dataField={'KlasorAdi'} caption="Klasör" />
                            </DataGrid>
                            <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                                close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                                onHiding={this.popupGizle} visible={this.state.popup}
                                toolbarItems={
                                    [
                                        {
                                            location: 'after', widget: 'dxButton', options: {
                                                text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                                , onClick: (e) => {
                                                    this.setState({
                                                        loadingText: "Kayıt işlemi gerçekleştiriliyor"
                                                    })
                                                    this.formData.SinavTurleri = this.secilenSinavTurleri;
                                                    return new Promise((resolve, reject) => {
                                                        service.post(sinavApi + 'yayin/yayinSave',
                                                            this.formData,
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                }
                                                            }
                                                        )
                                                            .then(res => {
                                                                if (res.data.Success) {
                                                                    notify('Düzenleme işlemi gerçekleştirildi', 'success', 5000);
                                                                    resolve('Düzenleme işlemi gerçekleştirildi');
                                                                }
                                                                else {
                                                                    notify(res.data.ErrorMessage, 'error', 5000);
                                                                    reject(res.data.ErrorMessage);
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                // Error 😨
                                                                reject(errorMessageWithKonum(error, "yayın kayıt işlemi"));
                                                            });

                                                    }).then(res => {
                                                        this.refreshData();
                                                    }).catch(error => {
                                                        alert(error);
                                                    });

                                                }
                                            }
                                        },
                                        {
                                            location: 'after', widget: 'dxButton', options: {
                                                text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                                , onClick: () => { this.popupGizle(); }
                                            }
                                        }

                                    ]
                                }
                            >
                                <Position my={'center'} at={'center'} of={window} />
                                <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                                    <Form formData={this.formData}>
                                        <GroupItem cssClass="first-group" colCount={1}>
                                            <SimpleItem dataField={"Adi"} isRequired={true}>
                                                <Label text="Yayın" />
                                            </SimpleItem>
                                            <SimpleItem dataField={"KlasorAdi"} isRequired={true}>
                                                <Label text="Klasör" />
                                            </SimpleItem>
                                            <SimpleItem>
                                                <DataGrid
                                                    showBorders={true}
                                                    showRowLines={true}
                                                    dataSource={this.sinavTuruDataSource}
                                                    columnHidingEnabled={true}
                                                    filterPanel={{ visible: true }}
                                                    filterRow={{ visible: true }}
                                                    columnAutoWidth={true}
                                                    columnFixing={true}
                                                    autoNavigateToFocusedRow={true}
                                                    focusedRowEnabled={true}
                                                    hoverStateEnabled={true}
                                                    onSelectionChanged={this.gridSinavTuruSelectionChanged}
                                                    keyExpr="Id"
                                                    paging={{
                                                        pageSize: 20
                                                    }}
                                                    pager={{
                                                        showPageSizeSelector: true,
                                                        allowedPageSizes: [5, 10, 50, 75, 100],
                                                        showInfo: true
                                                    }}
                                                    ref={(ref) => this.gridSinavTuru = ref}
                                                    width="100%"
                                                >

                                                    <Paging defaultPageSize={15} />
                                                    <Selection mode="multiple"
                                                        selectAllMode="allPages"
                                                        showCheckBoxesMode="onClick" />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                    <Column dataField={'Id'} caption="Id" visible={false} />
                                                    <Column dataField={'Adi'} caption="Sınav Türü" />

                                                </DataGrid>
                                            </SimpleItem>
                                        </GroupItem>
                                    </Form>
                                </ScrollView>
                            </Popup>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
