import * as React from "react";
import { withRouter } from "react-router";
import TreeView from 'devextreme-react/tree-view';
import Globals from "../../Globals";
class LeftMenu extends React.Component {

    constructor(props) {
        super(props);
        this.treeViewRef = React.createRef();
        this.selectedNodes = [];
        this.getSelectedNodes = () => {
            this.selectedNodes = this.treeView.getSelectedNodes();
        }
    }
    get treeView() {
        return this.treeViewRef.current.instance;
    }
    menuClick = (event) => {
        localStorage.setItem("menuItem", JSON.stringify(event.itemData));
        if (event.itemData.Href != null) {
            this.props.history.push('/' + event.itemData.Href);

        }
    }
    render() {

        return (
            <React.Fragment>

                <TreeView
                    stylingMode={'filled'}
                    displayExpr={'EkranAdi'}
                    dataStructure={'plain'}
                    onItemClick={this.menuClick}
                    items={Globals.service.Menu}
                    width={200}
                    searchmode={'contains'}
                    searchEnabled={true}
                    selectionMode={"single"}
                    parentIdExpr={'ParentId'}
                    keyExpr={'Id'}
                    ref={this.treeViewRef}
                    expandEvent={'click'}
                    selectedItem={9}
                >
                </TreeView>
            </React.Fragment>
        )
    }
}

export default withRouter(LeftMenu);