import * as React from "react";
import { sinavApi } from '../../Util';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import DataGrid, {
    Selection, Column, Paging, Pager
} from 'devextreme-react/data-grid';
import Globals from '../../Globals';
import service from 'axios';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';



const yayinDataSource = new DataSource(
    {
        paginate: false,
        pageSize: 100,
        store: createStore({
            key: "Id",
            loadUrl: sinavApi + 'yayin/yayinlarByYetki',
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.headers = {
                    'Authorization': 'Bearer ' + Globals.service.Token
                };
            }
        })
    });

const sinavTuruDataSource = new DataSource(
    {
        store: createStore({
            key: "Id",
            loadUrl: sinavApi + 'ortak/sinavturleri',
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.headers = {
                    'Authorization': 'Bearer ' + Globals.service.Token
                };
            }
        })
    });
export default class OgrenciAktarma extends React.Component {
    constructor(props) {
        super(props)

        this.formData = {
            KurumId: 0,
            SinifId: 0,
            SecilenYayinlar: [],
            DosyaAdi: ""
        }
        this.onUploaded = this.onUploaded.bind(this);
        this.yayinSecimiChanged = this.yayinSecimiChanged.bind(this);
        this.onInitializedYayinGrid = this.onInitializedYayinGrid.bind(this);
        this.headers = {
            'Authorization': 'Bearer ' + Globals.service.Token
        };
        this.saveButtonOptions = {
            text: 'Aktarma İşlemini Başlat',
            icon: 'check',
            hint: 'Aktarma İşlemini Başlat',
            type: "success",
            onClick: () => {
                service.post(sinavApi + 'OgrenciVeliAktarma/OgrenciVeliAktar', this.formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        alert(res.data.Data, "Bilgi");
                    }
                    else
                        notify(res.data.ErrorMessage, "error", 5000);
                })
                    .catch((error) => {
                        // Error 😨
                        notify("Teknik bir hata oluştu.formu göndermeden tüm alanları doldurduğunuza emin olunuz," + error, 'error', 5000);
                    });
            }
        };
        this.kurumDataSource = new DataSource(
            {
                loadMode: "raw",
                paginate: true,
                pageSize: 10,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'kurum/kurumlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Şablon dosyası yüklenemedi," + res.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            this.formData.DosyaAdi = res.Data;
            notify("Şablon dosyası yüklendi", "success", 5000);
            e.component.reset();
        }
    }

    yayinSecimiChanged() {
        this.formData.SecilenYayinlar = this.dataGridYayin.instance.getSelectedRowsData();
    }

    onInitializedYayinGrid(e) {
        this.dataGridYayin = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="col-md-12">
                        <div className="row">
                            <Form formData={this.formData}>
                                <GroupItem cssClass="first-group" colCount={2}>
                                    <SimpleItem dataField={"KurumId"}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: this.kurumDataSource,
                                            remoteOperations: true,
                                            searchEnabled: true,
                                            displayExpr: "KurumAdi",
                                            showClearButton: true,
                                            valueExpr: "Id"
                                        }}>
                                        <Label text="Kurum Seçiniz" />
                                    </SimpleItem>
                                    <SimpleItem dataField={"SinifId"}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: sinavTuruDataSource,
                                            searchEnabled: true,
                                            displayExpr: "Adi",
                                            valueExpr: "Id"
                                        }}>
                                        <Label text="Sınav Türü" />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem caption="Yayın Yetkileri(Bu kısım çok önemli işlem yapmak istediğiniz yayınları seçiniz)" colCount={1}>
                                    <SimpleItem>
                                        <DataGrid
                                            showBorders={true}
                                            showRowLines={true}
                                            dataSource={yayinDataSource}
                                            repaintChangesOnly={true}
                                            columnHidingEnabled={true}
                                            columnAutoWidth={true}
                                            autoNavigateToFocusedRow={true}
                                            focusedRowEnabled={true}
                                            hoverStateEnabled={true}
                                            rowAlternationEnabled={true}
                                            onInitialized={this.onInitializedYayinGrid}
                                            onSelectionChanged={this.yayinSecimiChanged}
                                            keyExpr="Id"
                                            paging={{
                                                pageSize: 20
                                            }}
                                            pager={{
                                                showPageSizeSelector: true,
                                                allowedPageSizes: [5, 10, 50, 75, 100],
                                                showInfo: true
                                            }}
                                            ref={(ref) => this.dataGridYayin = ref}
                                        >
                                            <Paging defaultPageSize={15} />
                                            <Selection mode="multiple"
                                                selectAllMode="allPages"
                                                showCheckBoxesMode="onClick" />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                            <Column dataField={'Id'} caption="Id" visible={false} />
                                            <Column dataField={'Adi'} caption="Yayın" />
                                        </DataGrid>

                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem cssClass="first-group" colCount={1} caption="Öğrenci-Veli aktarım şablonunu seçiniz(İşlem bitene kadar bekleyiniz.)">
                                    <SimpleItem dataField={"File"}
                                        editorType="dxFileUploader"
                                        editorOptions={{
                                            uploadUrl: sinavApi + "ogrenciveliaktarma/OgrenciVeliAktarmaSablonUpload",
                                            uploadMode: 'instantly',
                                            uploadHeaders: this.headers,
                                            accept: '*',
                                            maxFileSize: 4000000,
                                            name: 'myFile',
                                            onUploaded: this.onUploaded
                                        }}>
                                        <Label text="Şablon seçiniz" />
                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                            <Toolbar>
                                <Item location="after"
                                    widget="dxButton"
                                    options={this.saveButtonOptions} />
                            </Toolbar>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}