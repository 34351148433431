import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import Globals from './Globals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './redux/reducers';
import { createStore } from "redux";
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["remoteSystem", "kepsSystem", "sanalSinavSystem"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

/*
Redux toolkit by default enables devtools on all environments.
It is best practice to disable devtools on production.
However, some say having devtools enabled in production is not an issue.
*/

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


let persistor = persistStore(store);

function App() {
  Globals.init();
  return routes;
}
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);

