import React from 'react';
import ActionSheet from 'devextreme-react/action-sheet';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import auth from '../Auth';
import trMessages from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";
const actionSheetItems = [
    { id: 0, text: 'Oturumu Kapat', url: '' },
    { id: 1, text: 'Devamsızlık Girişi', url: '/mobile/devamsizlikfilter' }
];
export default class MobileLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActionSheetVisible: false,
            showTitle: true,
            showCancelButton: true,
        };

        this.showActionSheet = this.showActionSheet.bind(this);
        this.onActionSheetItemClick = this.onActionSheetItemClick.bind(this);
        this.onActionSheetCancelClick = this.onActionSheetCancelClick.bind(this);
        this.onActionSheetButtonClick = this.onActionSheetButtonClick.bind(this);
        this.changeTitle = this.changeTitle.bind(this);
        this.changeCancelButton = this.changeCancelButton.bind(this);
        loadMessages(trMessages);
        locale(navigator.language);
    }
    render() {

        return (
            <React.Fragment>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            {this.props.children}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div>
                                <ActionSheet
                                    dataSource={actionSheetItems}
                                    title="Menü Seçiniz"
                                    cancelText='Menü Gizle'
                                    showTitle={this.state.showTitle}
                                    showCancelButton={this.state.showCancelButton}
                                    visible={this.state.isActionSheetVisible}
                                    onItemClick={this.onActionSheetItemClick}
                                    onCancelClick={this.onActionSheetCancelClick} />

                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "100px" }}>
                        <div className='col-md-12'>
                            <Button width={"100%"} text="Menü Göster" onClick={this.showActionSheet} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    showActionSheet() {
        this.setState({
            isActionSheetVisible: true,
        });
    }

    onActionSheetItemClick(e) {
        if (parseInt(e.itemData.id) === 0) {
            auth.logout(() => {
                try {
                    localStorage.removeItem("oturum");
                    localStorage.removeItem("session");
                    localStorage.removeItem("initialized");
                } catch (error) {
                }

            });
            this.props.children.props.history.push("/login")
        }
        else if (parseInt(e.itemData.id) === 1) {
            window.location.href = "/mobile/devamsizlikfilter";
        }
        //this.onActionSheetButtonClick(e.itemData.text);
    }

    onActionSheetCancelClick() {
        this.setState({
            isActionSheetVisible: false,
        });
    }

    onActionSheetButtonClick(buttonName) {
        this.setState({
            isActionSheetVisible: false,
        });
        notify(`The "${buttonName}" button is clicked.`);
    }

    changeTitle(e) {
        this.setState({
            showTitle: e.value,
        });
    }

    changeCancelButton(e) {
        this.setState({
            showCancelButton: e.value,
        });
    }
};