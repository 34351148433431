import React from 'react';
import Form, { SimpleItem, GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import service from 'axios';
import { otomasyonApi, sinavApi, karneApi } from '../../../Util';
import Globals from '../../../Globals';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, {
    Export, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, ColumnFixing
} from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
class OgrenciDetail extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            data: null
        };
        this.gridSinav = null;
        this.ogrenciId = props.data.data.Id;
        service.get(otomasyonApi + "kayitkabul/getotomasyonogrenciresult/" + this.ogrenciId, {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        })
            .then(res => {
                console.log(res.data);
                this.setState({
                    data: res.data.Data
                });
            });
        this.karneDataSource = new DataSource({
            loadMode: "raw",
            key: "RowNumber",
            store: createStore({
                key: "RowNumber",
                loadParams: {
                    ogrenciId: this.ogrenciId
                },
                loadUrl: karneApi + 'ogrenci/getotomasyonogrencikarnelerbyogrenciid',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });
    }
    render() {
        if (this.state.data === null) {
            return (<React.Fragment>
                Öğrenci bilgisi getiriliyor...
            </React.Fragment>)
        }
        else {
            return (
                <React.Fragment>
                    <Form colCount={1} formData={this.state.data}>
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} />
                            <Tab title="Öğrenci Bilgileri">
                                <GroupItem cssClass="first-group" colCount={2}>
                                    <SimpleItem dataField={"Adi"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Adı"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"Soyadi"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Soyadı"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"TcNo"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="T.C. No"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"CepTelefonu"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Cep Telefonu"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"OgrenciSifre"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Şifre"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliAdres"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Adres"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"AnneMeslek"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Anne Meslek"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"BabaMeslek"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Baba Meslek"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"DogumTarihi"} editorType={"dxDateBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Doğum Tarihi"></Label>
                                    </SimpleItem>
                                    <SimpleItem>
                                        <img src={this.state.data.Fotograf} alt={this.state.data.Adi} style={{ width: "64px", height: "64px" }}></img>
                                    </SimpleItem>
                                </GroupItem>
                            </Tab>
                            <Tab title="Anne-Baba ve Veli Bilgileri">
                                <GroupItem cssClass="first-group" colCount={2}>
                                    <SimpleItem dataField={"AnneAdSoyad"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Anne Ad Soyad"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"BabaAdSoyad"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Baba Ad Soyad"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliAdSoyad"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Ad Soyad"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliTcNo"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli T.C. No"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliCepTelefonu"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Cep Telefonu"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliCepTelefonu2"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Cep Telefonu 2"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliSifre"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Şifre"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliYakinlik"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Yakınlık"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"VeliMeslek"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Veli Meslek"></Label>
                                    </SimpleItem>
                                </GroupItem>
                            </Tab>
                            <Tab title="Kayıt Bilgileri">
                                <GroupItem cssClass="first-group" colCount={2}>
                                    <SimpleItem dataField={"KurumAdi"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Kurum"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"SirketAdi"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Şirket"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"MahkemeAdi"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Mahkeme"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"KayitTarihi"} editorType={"dxDateBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Kayıt Tarihi"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"SinifSeviye"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Sınıf Seviye"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"EgitimTuru"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Eğitim Türü"></Label>
                                    </SimpleItem>
                                </GroupItem>
                            </Tab>
                            <Tab title="Ödeme Bilgileri" visible={Globals.service.Rol === "SubeOgretmen" ? false : true}>
                                <GroupItem cssClass="first-group" colCount={2}>
                                    <SimpleItem dataField={"HizmetToplami"} editorType={"dxNumberBox"} editorOptions={{
                                        format: "#,##0.##",
                                        disabled: true
                                    }}>
                                        <Label text="Hizmet Toplamı"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"Pesinat"} editorType={"dxNumberBox"} editorOptions={{
                                        format: "#,##0.##",
                                        disabled: true
                                    }}>
                                        <Label text="Peşinat"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"IndirimToplamTutar"} editorType={"dxNumberBox"} editorOptions={{
                                        format: "#,##0.##",
                                        disabled: true
                                    }}>
                                        <Label text="İndirim Toplam tutar"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"OdenecekToplamTutar"} editorType={"dxNumberBox"} editorOptions={{
                                        format: "#,##0.##",
                                        disabled: true
                                    }}>
                                        <Label text="Ödenecek Toplam tutar"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"KalanTutar"} editorType={"dxNumberBox"} editorOptions={{
                                        format: "#,##0.##",
                                        disabled: true
                                    }}>
                                        <Label text="Kalan tutar"></Label>
                                    </SimpleItem>
                                    <SimpleItem dataField={"KayitTuru"} editorType={"dxTextBox"} editorOptions={{
                                        disabled: true
                                    }}>
                                        <Label text="Kayit Türu"></Label>
                                    </SimpleItem>
                                </GroupItem>
                            </Tab>
                            <Tab title="Sınavlar">
                                <DataGrid
                                    showBorders={true}
                                    showRowLines={true}
                                    dataSource={this.karneDataSource}
                                    columnHidingEnabled={true}
                                    filterPanel={{ visible: true }}
                                    filterRow={{ visible: true }}
                                    columnAutoWidth={true}
                                    selection={{ mode: 'single' }}
                                    autoNavigateToFocusedRow={true}
                                    focusedRowEnabled={true}
                                    hoverStateEnabled={true}
                                    keyExpr="RowNumber"
                                    paging={{
                                        pageSize: 20
                                    }}
                                    pager={{
                                        showPageSizeSelector: true,
                                        allowedPageSizes: [5, 10, 50, 75, 100],
                                        showInfo: true
                                    }}
                                    onToolbarPreparing={(e) => {
                                        e.toolbarOptions.items.unshift(
                                            {
                                                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                    type: 'default', hint: "Karne Al", icon: 'exportpdf',
                                                    text: "Karne Al",
                                                    onClick: () => {
                                                        const satir = this.satirSinav;
                                                        if (satir === null || satir === undefined) {
                                                            alert('Satır seçmelisiniz', 'Uyarı');
                                                            return;
                                                        }
                                                        else {

                                                            service.post(karneApi + "ogrenci/GetOtomasyonOgrenciKarne",
                                                                {
                                                                    OgrenciNo: satir.OgrenciNo,
                                                                    KurumId: satir.KurumId,
                                                                    KullaniciId: satir.KullaniciId,
                                                                    KurumLogo: satir.KurumLogo,
                                                                    SinavTuru: satir.SinavTuru,
                                                                    SinavTuruId: satir.SinavTuruId,
                                                                    MerkeziSinavId: satir.Id,
                                                                    MerkeziSinavLogId: satir.MerkeziSinavLogId
                                                                },
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    }
                                                                })
                                                                .then(res => {
                                                                    if (res.data.Success) {
                                                                        this.gridSinav.instance.refresh();
                                                                    }
                                                                    else {
                                                                        notify(res.data.ErrorMessage, "error", 5000);
                                                                    }
                                                                }).catch(error => {
                                                                    notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz", "error", 5000);
                                                                });
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                    type: 'default', hint: 'Yenile', icon: 'refresh',
                                                    text: "Yenile",
                                                    onClick: (e) => {

                                                        this.gridSinav.instance.refresh();
                                                    }
                                                }
                                            });
                                    }}
                                    onSelectionChanged={(selectedItems) => {
                                        this.satirSinav = selectedItems.selectedRowsData[0];
                                    }}
                                    ref={(ref) => this.gridSinav = ref}
                                >
                                    <Export enabled={true} fileName={'Karneler'} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <LoadPanel enabled={true} />
                                    <FilterRow visible={false} applyFilter={'auto'} />
                                    <Paging defaultPageSize={15} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                    <Column dataField={'RowNumber'} caption="Kayıt No" visible={false} />
                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                    <Column dataField="YayinAdi" caption=""
                                        width={40}
                                        allowSorting={false}
                                        cellRender={linkRender}
                                    />
                                    <Column dataField={'KullaniciId'} caption="KullaniciId" visible={false} />
                                    <Column dataField={'ListeKarneId'} caption="ListeKarneId" visible={false} />
                                    <Column dataField={'KurumId'} caption="KurumId" visible={false} />
                                    <Column dataField={'YayinAdi'} caption="Yayın" />
                                    <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                                    <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                                    <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                                    <Column dataField={'IlAdi'} caption="İl" />
                                    <Column dataField={'IlceAdi'} caption="İlçe" />
                                    <Column dataField={'KurumAdi'} caption="Kurum" />
                                    <Column dataField={'Created'} caption="Tarih" dataType="date" format={
                                        "dd/MM/yyyy HH:mm:ss"
                                    } />
                                    <Column dataField={'DosyaTuru'} caption="DosyaTuru" visible={false} />
                                    <Column dataField={'Dosya'} caption="Dosya" visible={false} />
                                </DataGrid>
                            </Tab>
                        </TabbedItem>
                    </Form>
                </React.Fragment>
            );
        }
    }
};
export default OgrenciDetail;
function linkRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Dosya === undefined || data.data.Dosya === null || data.data.Dosya === "") {
        return <p></p>;
    }
    else {
        return <a rel="noopener noreferrer" href={data.data.Dosya} target="_blank">
            <img src="/icons/download.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}