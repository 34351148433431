import React from 'react';
import Moment from 'react-moment';
export default function DuyuruItem(data) {
  return (
    <React.Fragment>
        <p>
          <b><Moment format="YYYY/MM/DD">{data.Tarih}</Moment></b>
        </p>
        <p dangerouslySetInnerHTML={{__html:data.Aciklama}}>
      </p>
      </React.Fragment>
  );
}
