import * as React from "react";
import { sinavApi, errorMessage, errorMessageWithKonum } from '../../Util';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import Globals from '../../Globals';
import service from 'axios';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
export default class ExcelKullaniciAktar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            yayinlar: [],
            roller: [],
            kullanicilar: [],
            secilenKullanici: null,
            secilenYayin: null,
            secilenRol: null,
            DosyaAdi: "",
            yayinMesaj: "Yayınlar yükleniyor...",
            rolMesaj: "Roller yükleniyor...",
            kullaniciMesaj: "",
            toolbarDisabled: false,
            loadingText: ""
        }
        this.formData = {
            YayinId: 0,
            RolId: 0,
            KullaniciId: 0,
            DosyaAdi: ""
        }
        this.onUploaded = this.onUploaded.bind(this);

        this.headers = {
            'Authorization': 'Bearer ' + Globals.service.Token
        };
        this.saveButtonOptions = {
            text: 'Aktarma İşlemini Başlat',
            icon: 'check',
            hint: 'Aktarma İşlemini Başlat',
            type: "success",
            disabled: this.state.toolbarDisabled,
            onClick: () => {

                this.setState({
                    loadingText: "Kullanıcı aktarma işlemi başladı...",
                    toolbarDisabled: true
                })
                this.formData.DosyaAdi = this.state.DosyaAdi;
                service.post(sinavApi + 'admin/kullaniciExcekAktar', this.formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        }
                    }
                ).then(res => {
                    if (res.data.Success) {
                        alert(res.data.Data);
                        this.setState({
                            loadingText: "Kullanıcı aktarma işlemi başladı...",
                            toolbarDisabled: true
                        });
                    }
                    else {
                        notify(res.data.ErrorMessage, "error", 5000);
                        this.setState({
                            loadingText: "",
                            toolbarDisabled: false
                        });
                    }
                })
                    .catch(error => {
                        // Error 😨

                        this.setState({
                            loadingText: "",
                            toolbarDisabled: false
                        });
                        alert(errorMessage(error));
                    });
            }
        };
        this.refreshData();
        this.yayinDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/sistemyayinlari',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }

    onUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Şablon dosyası yüklenemedi," + res.ErrorMessage, "error", 5000);
            e.component.reset();
        }
        else {
            this.setState({ DosyaAdi: res.Data });
            this.formData.DosyaAdi = this.state.DosyaAdi;

            notify("Şablon dosyası yüklendi", "success", 5000);
            e.component.reset();
        }
    }

    async refreshData() {
        let isError = false;
        let hataMesajlari = "";
        // await this.getYayinlar().then(res => { }).catch(error => {
        //     isError = true;
        //     hataMesajlari += error + "<br/>";
        // });

        await this.getRoller().then(res => { }).catch(error => {
            isError = true;
            hataMesajlari += error + "<br/>";
        });
        if (isError)
            alert(hataMesajlari);

        this.setState({ toolbarDisabled: false })

    }

    async getRoller() {
        return new Promise((resolve, reject) => {
            this.setState({
                rolMesaj: "Roller yükleniyor...",
                loadingText: "Roller yükleniyor..."
            });
            service.get(sinavApi + 'admin/getRoller', {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        roller: res.data.Data,
                        rolMesaj: "Seçiniz",
                        loadingText: ""
                    });
                    resolve("Rol bilgileri getirildi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    this.setState({
                        rolMesaj: res.data.ErrorMessage,
                        loadingText: ""
                    });
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                this.setState({ rolMesaj: "Rol bilgileri getirilemedi", loadingText: "" });
                reject(errorMessageWithKonum(error, "rol bilgileri"));
            });
        });
    }

    async getKullanicilar() {
        return new Promise((resolve, reject) => {
            this.setState({
                kullaniciMesaj: "Kullanıcılar yükleniyor...",
                loadingText: "kullanıcılar yükleniyor..."
            });
            service.get(sinavApi + 'admin/getKullanicilarByYayinRol/' + this.state.secilenRol + "/" + this.state.secilenYayin, {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }).then(res => {
                if (res.data.Success) {
                    this.setState({
                        kullanicilar: res.data.Data,
                        kullaniciMesaj: "Seçiniz",
                        loadingText: ""
                    });
                    resolve("Kullanıcı bilgileri getirildi.");
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 500);
                    this.setState({
                        rolMesaj: res.data.ErrorMessage,
                        loadingText: ""
                    });
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                this.setState({ rolMesaj: "Kullanıcı bilgileri getirilemedi", loadingText: "" });
                reject(errorMessageWithKonum(error, "kullanıcı bilgileri"));
            });
        });
    }

    // async getYayinlar() {
    //     return new Promise((resolve, reject) => {
    //         this.setState({
    //             loadingText: "Yayınlar yükleniyor...",
    //         });
    //         service.get(sinavApi + "yayin/sistemyayinlari",
    //             {
    //                 headers: {
    //                     'Authorization': 'Bearer ' + Globals.service.Token
    //                 }
    //             })
    //             .then(res => {
    //                 if (res.data.Success) {
    //                     this.setState({
    //                         yayinlar: res.data.Data,
    //                         yayinMesaj: "Seçiniz",
    //                         loadingText: ""
    //                     });
    //                     resolve("Yayın bilgileri getirildi.");
    //                 }
    //                 else {
    //                     this.setState({ yayinMesaj: res.data.ErrorMessage, loadingText: "" });
    //                     reject(res.data.ErrorMessage);
    //                 }
    //             }).catch(error => {
    //                 this.setState({ yayinMesaj: "yayın bilgileri getirilemedi", loadingText: "" });
    //                 reject(errorMessageWithKonum(error, "yayın bilgileri"));
    //             });
    //     })
    // }

    render() {
        return (<React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loadingText}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Form formData={this.formData}>
                            <GroupItem cssClass="first-group" colCount={2}>
                                <SimpleItem dataField={"YayinId"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.yayinDataSource,
                                        searchEnabled: true,
                                        displayExpr: "Adi",
                                        showClearButton: true,
                                        valueExpr: "Id",
                                        onValueChanged: (e) => {
                                            this.setState({
                                                secilenYayin: e.value
                                            });
                                        }
                                    }}>
                                    <Label text="Yayın Seçiniz" />
                                </SimpleItem>
                                <SimpleItem dataField={"RolId"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.state.roller,
                                        searchEnabled: true,
                                        placeHolder: this.state.rolMesaji,
                                        value: this.state.secilenRol,
                                        displayExpr: "Adi",
                                        showClearButton: true,
                                        valueExpr: "Id",
                                        onValueChanged: (e) => {
                                            this.setState({
                                                secilenRol: e.value
                                            });
                                            this.getKullanicilar(this.state.secilenRol, this.state.secilenYayin).then(res => {
                                            })
                                                .catch(error => {
                                                    alert(error);
                                                });
                                        }
                                    }}>
                                    <Label text="Rol Seçiniz" />
                                </SimpleItem>
                                <SimpleItem dataField={"KullaniciId"}
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.state.kullanicilar,
                                        searchEnabled: true,
                                        placeHolder: this.state.kullaniciMesaj,
                                        value: this.state.secilenKullanici,
                                        displayExpr: "Adi",
                                        showClearButton: true,
                                        valueExpr: "Id",
                                        onValueChanged: (e) => {
                                            this.setState({
                                                secilenKullanici: e.value
                                            });

                                        }
                                    }}>
                                    <Label text="Kullanıcı Seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem cssClass="first-group" colCount={1} caption="Kullanıcı aktarım şablonunu seçiniz(İşlem bitene kadar bekleyiniz.)">
                                <SimpleItem dataField={"File"}
                                    editorType="dxFileUploader"
                                    editorOptions={{
                                        uploadUrl: sinavApi + "admin/kullaniciAktarSablonUpload",
                                        uploadMode: 'instantly',
                                        uploadHeaders: this.headers,
                                        accept: '*',
                                        maxFileSize: 4000000,
                                        name: 'myFile',
                                        onUploaded: this.onUploaded
                                    }}>
                                    <Label text="Şablon seçiniz" />
                                </SimpleItem>
                            </GroupItem>
                        </Form>

                        <Toolbar>
                            <Item location="after"
                                widget="dxButton"
                                options={this.saveButtonOptions} />
                        </Toolbar>
                    </div>
                </div>
            </div>
        </React.Fragment>);

    }
}