import React from 'react';
import { otomasyonApi } from '../../../Util';
import Globals from '../../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel
} from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
export default class KurumToplamTaksitRaporu extends React.Component {
    constructor(props) {
        super(props);
        this.mainDataSource = new DataSource(
            {
                paginate: true,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'dashboard/listkurumtoplamtaksittahsilatraporu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Rapor İşlemleri>>Kurum Toplam Taksit Raporu"}</h4>
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.mainDataSource}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitialized={this.onInitialized}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={(ref) => this.gridMain = ref}
                            >
                                <Export enabled={true} fileName={'KurumToplamTaksitTahsilatRaporu'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                <Column dataField={'OgrenciNo'} caption="Öğrenci No" />
                                <Column dataField={'AdiSoyadi'} caption="Adı Soyadı" />
                                <Column dataField={'VeliAdSoyad'} caption="Velisi" />
                                <Column dataField={'VeliCepTelefonu'} caption="V. Cep Telefonu" />
                                <Column dataField={'CepTelefonu'} caption="Cep Telefonu" />
                                <Column dataField={'ToplamOdenecek'} caption="T. Ödenecek" dataType="number" format={"#,##0.##"} />
                                <Column dataField={'ToplamOdenen'} caption="T. Ödenen" dataType="number" format={"#,##0.##"} />
                                <Column dataField={'ToplamKalan'} caption="T. Kalan" dataType="number" format={"#,##0.##"} />
                            </DataGrid>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}