import React from 'react';
import Form, { GroupItem, TabbedItem, TabPanelOptions, Tab, SimpleItem, Label } from 'devextreme-react/form';
import DataGrid, {
    Export, ColumnFixing, Column, Paging, Pager, FilterRow, LoadPanel, HeaderFilter, FilterPanel, ColumnChooser, MasterDetail
} from 'devextreme-react/data-grid';
import { otomasyonApi, karneApi } from '../Util';
import Globals from '../Globals';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DetailTemplate from '../components/shared/VeliDashboardTaksitTahsilatDetailView';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import { alert } from 'devextreme/ui/dialog';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import { SelectBox } from 'devextreme-react';
import VeliDersButon from '../components/VeliDersButon';
import DersTanim from '../classes/DersTanim';
export default class VeliDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupOdev: false,
            popupEtut: false,
            odevBaslik: "",
            odevDetay: "",
            etutBaslik: "",
            etutDetay: "",
            dersler: [],
            ogrenciId: 0
        };
        this.onInitializedGrdiOdemePlani = this.onInitializedGrdiOdemePlani.bind(this);
        this.onInitializedGridGorusmeNotu = this.onInitializedGridGorusmeNotu.bind(this);
        this.satirOdev = null;
        this.satirEtut = null;
        this.gridOdemePlaniDetay = null;
        this.griDevamsizlik = null;
        this.gridOdev = null;
        this.gridEtut = null;
        this.dersTanim = new DersTanim();
        this.grdiGorusmeNotu = null;
        this.sinavTuruDataSource = new DataSource({
            loadMode: "raw",
            key: "Id",
            store: createStore({
                key: "Id",
                loadUrl: karneApi + 'ogrenci/getvelisinavturu',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.karneDataSource = new DataSource({
            loadMode: "raw",
            key: "Id",
            store: createStore({
                key: "Id",
                loadUrl: karneApi + 'ogrenci/getotomasyonveliogrencikarneler',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    };
                }
            })
        });

        this.ogrenciDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'kayitkabul/listveliogrenci',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.odevDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'odev/veliogrenciodevlist',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.etutDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'etut/veliogrencietutlist',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.devamsizlikuDataSource = new DataSource(
            {
                loadMode: "raw",
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadUrl: otomasyonApi + 'devamsizlik/listveliogrencidevamsizlik',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.ogrenciFilter = {
            ogrenciId: 0
        };

        this.veliOdemePlaniDataSource = new DataSource(
            {
                cacheRawData: false,
                key: "Id",
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listveliodemeplanidetay',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });


        this.gorusmeNotuDataSource = new DataSource(
            {
                cacheRawData: false,
                filter: ["IsVeliGorsun", "=", true],
                store: createStore({
                    key: "Id",
                    loadParams: this.ogrenciFilter,
                    loadUrl: otomasyonApi + 'kayitkabul/listgorusmenotu',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.popupOdevGizle = async () => {
            await this.setState(
                {
                    popupOdev: false
                }
            );
        };

        this.popupOdevAc = () => {
            this.setState(
                {
                    popupOdev: true
                });
        };

        this.popupEtutGizle = async () => {
            await this.setState(
                {
                    popupEtut: false
                }
            );
        };

        this.popupEtutAc = () => {
            this.setState(
                {
                    popupEtut: true
                });
        };

    }
    onInitializedGrdiOdemePlani(e) {
        this.gridOdemePlaniDetay = e.component;
    }
    onInitializedGridGorusmeNotu(e) {
        this.grdiGorusmeNotu = e.component;
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Form colCount={1} width={"100%"} formData={this.formEtutTanimiData} onInitialized={this.onInitializedFormEtutTanimi} ref={(ref) => this.formEtutTanimi = ref}>
                                <GroupItem cssClass="first-group" colCount={1}>
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} />
                                        <Tab title="Sınavlar">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.karneDataSource}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                keyExpr="Id"
                                                paging={{
                                                    pageSize: 20
                                                }}
                                                pager={{
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [5, 10, 50, 75, 100],
                                                    showInfo: true
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: "Karne Al", icon: 'exportpdf',
                                                                text: "Karne Al",
                                                                onClick: () => {
                                                                    const satir = this.satirSinav;
                                                                    if (satir === null || satir === undefined) {
                                                                        alert('Satır seçmelisiniz', 'Uyarı');
                                                                        return;
                                                                    }
                                                                    else {

                                                                        service.post(karneApi + "ogrenci/GetOtomasyonVeliOgrenciKarne",
                                                                            {
                                                                                OgrenciNo: satir.OgrenciNo,
                                                                                KurumId: satir.KurumId,
                                                                                KurumLogo: satir.KurumLogo,
                                                                                SinavTuru: satir.SinavTuru,
                                                                                SinavTuruId: satir.SinavTuruId,
                                                                                MerkeziSinavId: satir.Id,
                                                                                MerkeziSinavLogId: satir.MerkeziSinavLogId
                                                                            },
                                                                            {
                                                                                headers: {
                                                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                                                }
                                                                            })
                                                                            .then(res => {
                                                                                if (res.data.Success) {
                                                                                    this.gridSinav.instance.refresh();
                                                                                }
                                                                                else {
                                                                                    notify(res.data.ErrorMessage, "error", 5000);
                                                                                }
                                                                            }).catch(error => {
                                                                                notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz", "error", 5000);
                                                                            });
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                text: "Yenile",
                                                                onClick: (e) => {
                                                                    this.gridSinav.instance.refresh();
                                                                }
                                                            }
                                                        });
                                                }}
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirSinav = selectedItems.selectedRowsData[0];
                                                }}
                                                ref={(ref) => this.gridSinav = ref}
                                            >
                                                <Export enabled={true} fileName={'Karneler'} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <ColumnFixing enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={false} applyFilter={'auto'} />
                                                <Paging defaultPageSize={15} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={false} />
                                                <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                <Column dataField="YayinAdi" caption=""
                                                    width={40}
                                                    allowSorting={false}
                                                    cellRender={linkRender}
                                                />
                                                <Column dataField={'KullaniciId'} caption="KullaniciId" visible={false} />
                                                <Column dataField={'ListeKarneId'} caption="ListeKarneId" visible={false} />
                                                <Column dataField={'KurumId'} caption="KurumId" visible={false} />
                                                <Column dataField={'YayinAdi'} caption="Yayın" />
                                                <Column dataField={'OgrenciNo'} caption="Ö. No" dataType="number" />
                                                <Column dataField={'Adi'} caption="Adı" dataType="string" />
                                                <Column dataField={'Soyadi'} caption="Soyadı" dataType="string" />
                                                <Column dataField={'SinavTuru'} caption="Sınav Türü" />
                                                <Column dataField={'SinavKodu'} caption="Sınav Kodu" />
                                                <Column dataField={'SinavAdi'} caption="Sınav Adı" />
                                                <Column dataField={'IlAdi'} caption="İl" />
                                                <Column dataField={'IlceAdi'} caption="İlçe" />
                                                <Column dataField={'KurumAdi'} caption="Kurum" />
                                                <Column dataField={'Created'} caption="Tarih" dataType="date" format={
                                                    "dd/MM/yyyy HH:mm:ss"
                                                } />
                                                <Column dataField={'DosyaTuru'} caption="DosyaTuru" visible={false} />
                                                <Column dataField={'Dosya'} caption="Dosya" visible={false} />
                                            </DataGrid>

                                        </Tab>
                                        <Tab title="Hatalar">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        dataSource={this.sinavTuruDataSource}
                                                        displayExpr={"SinavTuru"}
                                                        valueExpr={"SinavTuru"}
                                                        onValueChanged={(e) => {
                                                            const dersler = this.dersTanim.GetDersler(e.value);
                                                            this.setState({
                                                                dersler: dersler
                                                            });
                                                        }}
                                                    >
                                                    </SelectBox>
                                                </div>
                                                <div className="row" style={{ minHeight: "10px" }}>

                                                </div>
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        dataSource={this.ogrenciDataSource}
                                                        displayExpr={(item) => {
                                                            if (!item) {
                                                                return "";
                                                            }
                                                            else {
                                                                return "(" + item.Adi + " " + item.Soyadi + ")";
                                                            }
                                                        }}
                                                        valueExpr={"Id"}
                                                        onValueChanged={(e) => {
                                                            this.setState({
                                                                ogrenciId: e.value
                                                            });
                                                        }}
                                                    >
                                                    </SelectBox>
                                                </div>
                                                <div className="row" style={{ minHeight: "10px" }}>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <VeliDersButon Dersler={this.state.dersler} OgrenciId={this.state.ogrenciId}></VeliDersButon>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab title="Ödev">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.odevDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                showCheckBoxesMode={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                key="Id"
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirOdev = selectedItems.selectedRowsData[0];
                                                    this.setState({
                                                        odevBaslik: this.satirOdev.Baslik,
                                                        odevDetay: this.satirOdev.Odev
                                                    })
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Ödev Detayı', icon: 'search',
                                                                onClick: () => {
                                                                    if (this.satirOdev === null | this.satirOdev === undefined) {
                                                                        alert("Lütfen bir satır seçiniz", "Uyarı");
                                                                    }
                                                                    else {
                                                                        this.popupOdevAc()
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                onClick: () => {
                                                                    this.gridOdev.instance.refresh();
                                                                }
                                                            }
                                                        }
                                                    );
                                                }}
                                                onInitialized={(e) => {
                                                    this.gridOdev = e.component;
                                                }}
                                                ref={(ref) => { this.gridOdev = ref }}
                                            >
                                                <Export
                                                    enabled={Globals.service.Rol === "SubeOgretmen" ? true : false}
                                                    fileName={'Öğrenciler'}
                                                    allowExportSelectedData={false}
                                                    texts={
                                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                                    }
                                                />
                                                <ColumnFixing enabled={true} />
                                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? true : false} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={true} />
                                                <FilterPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'OgrenciAdSoyad'} caption="Öğrenciniz" />
                                                <Column dataField={'KonuAdi'} caption="Konu" />
                                                <Column dataField={'SeviyeAdi'} caption="Sınıf" />
                                                <Column dataField={'SubeAdi'} caption="Şube" />
                                                <Column dataField={'Baslik'} caption="Başlık" />
                                                <Column dataField={'OdevDurum'} caption="Tamamlanma Durumu" />
                                                <Column dataField={'Created'} caption="Tarih" dataType={"date"} />
                                            </DataGrid>
                                        </Tab>
                                        <Tab title="Etüt">
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.etutDataSource}
                                                remoteOperations={true}
                                                repaintChangesOnly={true}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                selection={{ mode: 'single' }}
                                                showCheckBoxesMode={true}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                rowAlternationEnabled={true}
                                                key="Id"
                                                onSelectionChanged={(selectedItems) => {
                                                    this.satirEtut = selectedItems.selectedRowsData[0];
                                                    this.setState({
                                                        etutBaslik: this.satirEtut.Baslik,
                                                        etutDetay: this.satirEtut.Aciklama
                                                    })
                                                }}
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift(
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Etüt Detayı', icon: 'search',
                                                                onClick: () => {
                                                                    if (this.satirEtut === null | this.satirEtut === undefined) {
                                                                        alert("Lütfen bir satır seçiniz", "Uyarı");
                                                                    }
                                                                    else {
                                                                        this.popupEtutAc()
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        {
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                onClick: () => {
                                                                    this.gridEtut.instance.refresh();
                                                                }
                                                            }
                                                        }
                                                    );
                                                }}
                                                onInitialized={(e) => {
                                                    this.gridEtut = e.component;
                                                }}
                                                ref={(ref) => { this.gridEtut = ref }}
                                            >
                                                <Export
                                                    enabled={Globals.service.Rol === "SubeOgretmen" ? true : false}
                                                    fileName={'Öğrenciler'}
                                                    allowExportSelectedData={false}
                                                    texts={
                                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                                    }
                                                />
                                                <ColumnFixing enabled={true} />
                                                <ColumnChooser mode="dragAndDrop" enabled={Globals.service.Rol === "SubeOgretmen" ? true : false} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={true} />
                                                <FilterPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'OgrenciAdSoyad'} caption="Öğrenciniz" />
                                                <Column dataField={'KonuAdi'} caption="Konu" />
                                                <Column dataField={'SeviyeAdi'} caption="Sınıf" />
                                                <Column dataField={'SubeAdi'} caption="Şube" />
                                                <Column dataField={'Baslik'} caption="Başlık" />
                                                <Column dataField={'EtutDurum'} caption="Tamamlanma Durumu" />
                                                <Column dataField={'Created'} caption="Tarih" dataType={"date"} />
                                            </DataGrid>

                                        </Tab>
                                        <Tab title="Devamsızlık">
                                            <DataGrid
                                                id="grdTaksit"
                                                showBorders={true}
                                                showRowLines={true}
                                                remoteOperations={true}
                                                dataSource={this.devamsizlikuDataSource}
                                                columnHidingEnabled={true}
                                                filterPanel={{ visible: true }}
                                                filterRow={{ visible: true }}
                                                columnAutoWidth={true}
                                                key={"Id"}
                                                selection={
                                                    {
                                                        mode: 'single'
                                                    }}
                                                autoNavigateToFocusedRow={true}
                                                focusedRowEnabled={true}
                                                hoverStateEnabled={true}
                                                keyExpr="Id"
                                                onToolbarPreparing={(e) => {
                                                    e.toolbarOptions.items.unshift({
                                                        location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                            type: 'default', hint: 'Yenile', icon: 'refresh',
                                                            onClick: () => {
                                                                this.griDevamsizlik.instance.refresh();
                                                            }
                                                        }
                                                    });
                                                }}
                                                onInitialized={(e) => {
                                                    this.griDevamsizlik = e.component;
                                                }}
                                                ref={(ref) => { this.griDevamsizlik = ref }}
                                            >
                                                <Export
                                                    enabled={true}
                                                    fileName={'Devamsizliklar'}
                                                    allowExportSelectedData={false}
                                                    texts={
                                                        { exportAll: "Ekrandaki Verileri Aktar" }
                                                    }
                                                />
                                                <ColumnFixing enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <FilterRow visible={true} />
                                                <FilterPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <Paging defaultPageSize={15} enabled={true} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                <Column dataField={'Id'} caption="Kayıt No" visible={false} allowExporting={false} />
                                                <Column dataField={'DersNo'} caption="Ders No" dataType={"number"} />
                                                <Column dataField={'AdSoyad'} caption="Öğrenci" dataType={"string"} />
                                                <Column dataField={'VeliAdSoyad'} caption="VeliAdSoyad" dataType={"string"} visible={false} />
                                                <Column dataField={'VeliCepTelefonu'} caption="VeliCepTelefonu" dataType={"string"} visible={false} />
                                                <Column dataField={'DersAdi'} caption="Ders" />
                                                <Column dataField={'KonuBaslik'} caption="Konu" />
                                                <Column dataField={'Tipi'} caption="Tipi" />
                                                <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} />
                                                <Column dataField={'Turu'} caption="Tür" />
                                                <Column dataField={'IcerikTuru'} caption="İçerik" />

                                            </DataGrid>
                                        </Tab>
                                        <Tab title="Taksit Tahsilat">
                                            <SimpleItem dataField={"OgrenciId"}
                                                editorType={"dxSelectBox"}
                                                editorOptions={{
                                                    dataSource: this.ogrenciDataSource,
                                                    displayExpr: (item) => {
                                                        if (!item) {
                                                            return "";
                                                        }
                                                        else {
                                                            return "(" + item.Adi + " " + item.Soyadi + ")";
                                                        }
                                                    },
                                                    valueExpr: "Id",
                                                    onValueChanged: (e) => {
                                                        this.ogrenciFilter.ogrenciId = e.value;
                                                        this.veliOdemePlaniDataSource.load();
                                                        this.gridOdemePlaniDetay.instance.refresh();
                                                    }
                                                }}>
                                                <Label text="Öğrenci Seçiniz"></Label>
                                            </SimpleItem>
                                            <SimpleItem>
                                                <DataGrid
                                                    showBorders={true}
                                                    showRowLines={true}
                                                    remoteOperations={true}
                                                    dataSource={this.veliOdemePlaniDataSource}
                                                    columnHidingEnabled={true}
                                                    filterPanel={{ visible: true }}
                                                    filterRow={{ visible: true }}
                                                    columnAutoWidth={true}
                                                    key={"Id"}
                                                    selection={
                                                        {
                                                            mode: 'single'
                                                        }}
                                                    autoNavigateToFocusedRow={true}
                                                    focusedRowEnabled={true}
                                                    hoverStateEnabled={true}
                                                    keyExpr="Id"
                                                    onToolbarPreparing={(e) => {
                                                        e.toolbarOptions.items.unshift({
                                                            location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                                                                type: 'default', hint: 'Yenile', icon: 'refresh',
                                                                onClick: () => {
                                                                    this.gridOdemePlaniDetay.instance.refresh();
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    onInitialized={this.onInitializedGrdiOdemePlani}
                                                    ref={(ref) => { this.gridOdemePlaniDetay = ref }}
                                                >
                                                    <MasterDetail
                                                        enabled={true}
                                                        component={DetailTemplate}
                                                    />
                                                    <Export
                                                        enabled={true}
                                                        fileName={'Devamsizliklar'}
                                                        allowExportSelectedData={false}
                                                        texts={
                                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                                        }
                                                    />
                                                    <ColumnFixing enabled={true} />
                                                    <LoadPanel enabled={true} />
                                                    <FilterRow visible={true} />
                                                    <FilterPanel visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <Paging defaultPageSize={15} enabled={true} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} allowExporting={false} />
                                                    <Column dataField={'HizmetToplami'} caption="Hizmet Toplamı" dataType={"number"} format={"#,##0.##"} />
                                                    <Column dataField={'IndirimToplamTutar'} caption="İndirim" dataType={"number"} format={"#,##0.##"} />
                                                    <Column dataField={'Pesinat'} caption="Peşinat" dataType={"number"} format={"#,##0.##"} />
                                                    <Column dataField={'OdenecekToplamTutar'} caption="Ö. Toplam Tutar" dataType={"number"} format={"#,##0.##"} />
                                                    <Column dataField={'KalanTutar'} caption="Kalan Tutar" dataType={"number"} format={"#,##0.##"} />
                                                    <Column dataField={'IsNakit'} caption="Nakit" dataType={"boolean"} />
                                                </DataGrid>
                                            </SimpleItem>

                                        </Tab>
                                        <Tab title="Görüşmeler">
                                            <SimpleItem dataField={"OgrenciId"}
                                                editorType={"dxSelectBox"}
                                                editorOptions={{
                                                    dataSource: this.ogrenciDataSource,
                                                    displayExpr: (item) => {
                                                        if (!item) {
                                                            return "";
                                                        }
                                                        else {
                                                            return "(" + item.Adi + " " + item.Soyadi + ")";
                                                        }
                                                    },
                                                    valueExpr: "Id",
                                                    onValueChanged: (e) => {
                                                        this.ogrenciFilter.ogrenciId = e.value;
                                                        this.gorusmeNotuDataSource.load();
                                                        this.grdiGorusmeNotu.instance.refresh();
                                                    }
                                                }}>
                                                <Label text="Öğrenci Seçiniz"></Label>
                                            </SimpleItem>
                                            <SimpleItem>
                                                <DataGrid
                                                    id="grdGorusmeNotu"
                                                    showBorders={true}
                                                    showRowLines={true}
                                                    remoteOperations={true}
                                                    dataSource={this.gorusmeNotuDataSource}
                                                    columnHidingEnabled={true}
                                                    filterPanel={{ visible: true }}
                                                    filterRow={{ visible: true }}
                                                    columnAutoWidth={true}
                                                    export={{
                                                        enabled: true,
                                                        fileName: "notlar"
                                                    }}
                                                    selection={
                                                        {
                                                            mode: 'single'
                                                        }}
                                                    autoNavigateToFocusedRow={true}
                                                    focusedRowEnabled={true}
                                                    hoverStateEnabled={true}
                                                    keyExpr="Id"
                                                    onInitialized={this.onInitializedGridGorusmeNotu}
                                                    ref={(ref) => this.grdiGorusmeNotu = ref}
                                                >
                                                    <Export
                                                        fileName={'Notlar'}
                                                        allowExportSelectedData={false}
                                                        texts={
                                                            { exportAll: "Ekrandaki Verileri Aktar" }
                                                        }
                                                    />
                                                    <ColumnFixing enabled={true} />
                                                    <LoadPanel enabled={true} />
                                                    <FilterRow visible={false} applyFilter={'auto'} />
                                                    <Paging defaultPageSize={15} enabled={true} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                                    <Column dataField={'Id'} caption="Kayıt No" visible={false} />
                                                    <Column dataField={'Tarih'} caption="Tarih" dataType={"date"} width={100} />
                                                    <Column dataField={'KullaniciAdi'} caption="Gerçekleştiren" width={100} />
                                                    <Column dataField={'Aciklama'} caption="Görüşme Notu" hidingPriority={0} dataType={"string"} />

                                                </DataGrid>
                                            </SimpleItem>

                                        </Tab>
                                        <Tab title="Merkezi Sınav Sonuçları">
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-12 text-center'>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/62' rel="noopener noreferrer" target='_blank'>Limit Yayınları</a><br /><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/63' rel="noopener noreferrer" target='_blank'>Avantaj Yayınları</a><br></br><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/64' rel="noopener noreferrer" target='_blank'>Kronometre Yayınları</a><br></br><br></br>
                                                        <a href='https://yayin.teknodeneme.com/sinavsonucu/65' rel="noopener noreferrer" target='_blank'>Esen Yayınları</a><br></br><br></br>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabbedItem>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                </div>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupOdevGizle} visible={this.state.popupOdev}
                    title={this.state.odevBaslik}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => { this.popupOdevGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <span dangerouslySetInnerHTML={{ __html: this.state.odevDetay }} />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup showTitle={true} shading={true} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupEtutGizle} visible={this.state.popupEtut}
                    title={this.state.etutBaslik}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kapat', type: 'danger', hint: 'Kapat', icon: 'close'
                                    , onClick: () => { this.popupEtutGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <span dangerouslySetInnerHTML={{ __html: this.state.etutDetay }} />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

            </React.Fragment>
        );
    }
}
function linkRender(data) {
    if (data === null)
        return <p></p>;
    else if (data.data.Dosya === undefined || data.data.Dosya === null || data.data.Dosya === "") {
        return <p></p>;
    }
    else {
        return <a rel="noopener noreferrer" href={data.data.Dosya} target="_blank">
            <img src="/icons/download.png" alt="Excel Kurum Liste İndir" /></a>;
    }
}