import * as React from "react";
import { otomasyonApi, sinavApi, GetStore } from '../../Util';
import DataGrid, {
    Export, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, RequiredRule
} from 'devextreme-react/data-grid';
import Tabs from 'devextreme-react/tabs';
import 'devextreme/data/odata/store';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Globals from '../../Globals';
export default class GenelTanimlar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelectedIndex: 0
        };
        this.gridOtomasyonKurum = null;
        this.gridServis = null;
        this.gridGider = null;
        this.gridBanka = null;
        this.gridYurt = null;
        this.gridSinifSube = null;
        this.gridGrup = null;
        this.gridBrans = null;
        this.gridUcret = null;
        this.gridIndirim = null;
        this.branslar = [];
        this.kurumFilter = { kurumId: 0 };
        this.onInitializedGridServis = this.onInitializedGridServis.bind(this);
        this.onInitializedGridYurt = this.onInitializedGridYurt.bind(this);
        this.onInitializedGridSinifSube = this.onInitializedGridSinifSube.bind(this);
        this.onInitializedGridGrup = this.onInitializedGridGrup.bind(this);
        this.onInitializedGridGider = this.onInitializedGridGider.bind(this);
        this.onInitializedGridBrans = this.onInitializedGridBrans.bind(this);
        this.onInitializedGridUcret = this.onInitializedGridUcret.bind(this);
        this.onInitializedGridIndirim = this.onInitializedGridIndirim.bind(this);
        this.onInitializedGridOtomasyonKurum = this.onInitializedGridOtomasyonKurum.bind(this);
        this.onInitializedGridBanka = this.onInitializedGridBanka.bind(this);
        this.renderDetay = this.renderDetay.bind(this);
        this.renderTabDetay = this.renderTabDetay.bind(this);
        this.tabsSelectionChanged = this.tabsSelectionChanged.bind(this);
        this.tabs = ["Servis Tnm.", "Yurt Tnm.", "Sınıf Şube Tnm.", "Grup Tnm.", "Branş Tnm.", "Ücret Tnm.", "İndirim Tnm..", "Şirket Tnm.", "Gider Tnm.", "Banka Tnm."];
        this.servisDataSource = GetStore(
            "Id"
            , "OtomasyonServis"
            , otomasyonApi + "geneltanim/listservisbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.yurtDataSource = GetStore(
            "Id"
            , "OtomasyonYurt"
            , otomasyonApi + "geneltanim/listyurtbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.sinifSubeDataSource = GetStore(
            "Id"
            , "OtomasyonSinifSube"
            , otomasyonApi + "geneltanim/listsinifsubebykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.grupDataSource = GetStore(
            "Id"
            , "OtomasyonGrup"
            , otomasyonApi + "geneltanim/listgrupbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.bransDataSource = GetStore(
            "Id"
            , "OtomasyonBrans"
            , otomasyonApi + "geneltanim/listbransbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.ucretDataSource = GetStore(
            "Id"
            , "OtomasyonUcretTanim"
            , otomasyonApi + "geneltanim/listucretbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.indirimDataSource = GetStore(
            "Id"
            , "OtomasyonIndirimTanim"
            , otomasyonApi + "geneltanim/listindirimbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.otomasyonKurumDataSource = GetStore(
            "Id"
            , "OtomasyonKurum"
            , otomasyonApi + "geneltanim/listotomasyonkurumbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.sinifSeviyeDataSource = GetStore(
            "Id"
            , "OtomasyonSinifSeviye"
            , otomasyonApi + "geneltanim/listsinifseviye"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.giderDataSource = GetStore(
            "Id"
            , "OtomasyonGiderTanim"
            , otomasyonApi + "geneltanim/listgidertanimbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");

        this.bankaDataSource = GetStore(
            "Id"
            , "OtomasyonBankaTanim"
            , otomasyonApi + "geneltanim/listbankatanimbykullanici"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/save"
            , otomasyonApi + "common/delete");
    }


    onInitializedGridServis(e) {
        this.gridServis = e.component;
    }

    onInitializedGridBrans(e) {
        this.gridBrans = e.component;
    }

    onInitializedGridGrup(e) {
        this.gridGrup = e.component;
    }

    onInitializedGridGider(e) {
        this.gridGider = e.component;
    }

    onInitializedGridBanka(e) {
        this.gridBanka = e.component;
    }


    onInitializedGridIndirim(e) {
        this.gridIndirim = e.component;
    }

    onInitializedGridUcret(e) {
        this.gridUcret = e.component;
    }

    onInitializedGridYurt(e) {
        this.gridYurt = e.component;
    }

    onInitializedGridSinifSube(e) {
        this.gridSinifSube = e.component;
    }

    onInitializedGridOtomasyonKurum(e) {
        this.gridOtomasyonKurum = e.component;
    }

    tabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            this.setState({
                tabSelectedIndex: parseInt(args.value)
            });
        }
    }

    renderDetay() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs selectedIndex={this.state.tabSelectedIndex} dataSource={this.tabs} onOptionChanged={this.tabsSelectionChanged} />
                            {this.renderTabDetay(this.state.tabSelectedIndex)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderTabDetay(tabIndex) {
        if (tabIndex === 0) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.servisDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridServis}
                        ref={(ref) => this.gridServis = ref}
                    >
                        <Export enabled={true} fileName={'Servisler'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Servis Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridServis.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridServis.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 1) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.yurtDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridYurt}
                        ref={(ref) => this.gridYurt = ref}
                    >
                        <Export enabled={true} fileName={'Yurtlar'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Yurt Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridYurt.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridYurt.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 2) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.sinifSubeDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridSinifSube}
                        ref={(ref) => this.gridSinifSube = ref}
                    >
                        <Export enabled={true} fileName={'SinifSubeler'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Sınıf Şube Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridSinifSube.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridSinifSube.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            },
                                            {
                                                dataField: "SeviyeId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "Id",
                                                                loadUrl: otomasyonApi + 'geneltanim/listsinifseviye',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "Id",
                                                    displayExpr: "Adi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                        <Column dataField={'SeviyeId'} caption="Seviye" visible={false}>
                            <RequiredRule message="Seviye alanı zorunludur" />
                        </Column>
                        <Column dataField={'SeviyeAdi'} caption="Seviye" />
                        <Column dataField={'DonemOgrenciSayisi'} caption="Dönem Ö. Sayısı" dataType={"number"} />
                        <Column dataField={'OgrenciSayisi'} caption="Toplam Ö. Sayısı" dataType={"number"} />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 3) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.grupDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridGrup}
                        ref={(ref) => this.gridGrup = ref}
                    >
                        <Export enabled={true} fileName={'Gruplar'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Grup Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridGrup.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridGrup.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 4) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.bransDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridBrans}
                        ref={(ref) => this.gridBrans = ref}
                    >
                        <Export enabled={true} fileName={'Branslar'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Branş Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridBrans.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridBrans.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 5) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.ucretDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridUcret}
                        ref={(ref) => this.gridUcret = ref}
                    >
                        <Export enabled={true} fileName={'Ucretler'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Ücret Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridUcret.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridUcret.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "DonemId", visible: false, editorType: "dxTextBox", editorOptions: {
                                                    visible: false,
                                                    value: Globals.service.DonemId
                                                }
                                            },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            },
                                            {
                                                dataField: "Tutar",
                                                dataType: "number",
                                                format: "#,##0.##"
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                        <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} editorOptions={{
                            format: "#,##0.##"
                        }}>
                            <RequiredRule message={"Tutar alanı zorunludur"} />
                        </Column>
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 6) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.indirimDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridIndirim}
                        ref={(ref) => this.gridIndirim = ref}
                    >
                        <Export enabled={true} fileName={'Indirimler'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "İndirim Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridIndirim.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridIndirim.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            },
                                            {
                                                dataField: "Tutar",
                                                dataType: "number",
                                                format: "#,##0.##"
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                        <Column dataField={'Tutar'} caption="Tutar" dataType={"number"} editorOptions={{
                            format: "#,##0.##"
                        }}>
                            <RequiredRule message={"Tutar alanı zorunludur"} />
                        </Column>
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 7) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.otomasyonKurumDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridOtomasyonKurum}
                        ref={(ref) => this.gridOtomasyonKurum = ref}
                    >
                        <Export enabled={true} fileName={'sirketbilgileri'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Şirket Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridOtomasyonKurum.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridOtomasyonKurum.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "SirketAdi" },
                                            { dataField: "MahkemeAdi" },
                                            {
                                                dataField: "DonemId", visible: false, editorType: "dxTextBox", editorOptions: {
                                                    visible: false,
                                                    value: Globals.service.DonemId
                                                }
                                            },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'MahkemeAdi'} caption="Mahkeme bilgisi">
                            <RequiredRule message="Mahkeme bilgisi alanı zorunludur" />
                        </Column>
                        <Column dataField={'SirketAdi'} dataType={"string"} caption="Şirket(Kurum) Ünvanı">
                            <RequiredRule message="Şirket(Kurum) ünvanı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 8) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.giderDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridGider}
                        ref={(ref) => this.gridGider = ref}
                    >
                        <Export enabled={true} fileName={'Giderler'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Gider Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridGider.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridGider.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "DonemId", visible: false, editorType: "dxTextBox", editorOptions: {
                                                    visible: false,
                                                    value: Globals.service.DonemId
                                                }
                                            },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
        else if (tabIndex === 9) {
            return (
                <div style={{ paddingTop: "10px" }}>
                    <DataGrid
                        showBorders={true}
                        keyboardNavigation={
                            {
                                editOnKeyPress: true,
                                enterKeyAction: "startEdit",
                                enabled: true,
                                enterKeyDirection: "column"
                            }
                        }
                        showRowLines={true}
                        dataSource={this.bankaDataSource}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        columnHidingEnabled={true}
                        filterPanel={{ visible: true }}
                        filterRow={{ visible: true }}
                        columnAutoWidth={true}
                        selection={{ mode: 'none' }}
                        autoNavigateToFocusedRow={true}
                        focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        key="Id"
                        onInitialized={this.onInitializedGridBanka}
                        ref={(ref) => this.gridBanka = ref}
                    >
                        <Export enabled={true} fileName={'Bankalar'} allowExportSelectedData={false} />
                        <Editing
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}
                            useIcons={true}
                            mode={'popup'}
                            texts={{
                                confirmDeleteMessage: "<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/></i>",
                                confirmDeleteTitle: "Silme Onay"
                            }}
                            popup={{
                                title: "Banka Bilgileri",
                                showTitle: true,
                                width: "80%",
                                height: "80%",
                                shading: true,
                                dragEnabled: true,
                                showCloseButton: false,
                                ref: (ref) => { this.popupForm = ref },
                                close_ClickOnOutsideClick: false,
                                position: {
                                    my: "center",
                                    at: "center",
                                    of: window
                                },
                                toolbarItems: [
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check',
                                            onClick: async () => {
                                                await this.gridBanka.instance.saveEditData().catch(err => {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + err, "error", 5000);
                                                });
                                                // await this.gridMainSatirIptal();
                                            }
                                        }
                                    },
                                    {
                                        location: 'after', widget: 'dxButton', options: {
                                            text: 'Vazgeç', type: 'danger', hint: 'Vazgeç', icon: 'remove',
                                            onClick: () => {
                                                this.gridBanka.instance.cancelEditData();
                                            }
                                        }
                                    }
                                ]
                            }}
                            form={{
                                colCount: 2,
                                items: [
                                    {
                                        colSpan: 2,
                                        itemType: "group",
                                        items: [
                                            { dataField: "Adi" },
                                            {
                                                dataField: "DonemId", visible: false, editorType: "dxTextBox", editorOptions: {
                                                    visible: false,
                                                    value: Globals.service.DonemId
                                                }
                                            },
                                            {
                                                dataField: "KurumId",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    dataSource: new DataSource(
                                                        {
                                                            loadMode: "raw",
                                                            store: createStore({
                                                                key: "KurumId",
                                                                loadUrl: sinavApi + 'kurum/otoGetYetkiliKurumlar',
                                                                onBeforeSend: (method, ajaxOptions) => {
                                                                    ajaxOptions.headers = {
                                                                        'Authorization': 'Bearer ' + Globals.service.Token
                                                                    };
                                                                }
                                                            })
                                                        }),
                                                    valueExpr: "KurumId",
                                                    displayExpr: "KurumAdi"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }}
                        >

                        </Editing>
                        <ColumnFixing enabled={true} />
                        <LoadPanel enabled={true} />
                        <FilterRow visible={false} applyFilter={'auto'} />
                        <Paging defaultPageSize={15} enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                        <Column dataField={'Id'} caption="Id" visible={false} />
                        <Column dataField={'Adi'} caption="Adı">
                            <RequiredRule message="Adı alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumId'} caption="Kurum" visible={false}>
                            <RequiredRule message="Kurum alanı zorunludur" />
                        </Column>
                        <Column dataField={'KurumAdi'} caption="Kurum" />
                    </DataGrid>
                </div>
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{"Otomasyon İşlemleri>>Genel Tanımlar"}</h4>
                            {this.renderDetay()}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}