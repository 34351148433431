import { SanalSinavSystemActionTypes } from "../constants/action-types";

const initialState = {
    info: {
    }
};
export const sanalSinavSystemReducer = (state = initialState, action) => {
    switch (action.type) {
        case SanalSinavSystemActionTypes.SET_SANAL_SINAV_BILGISI:
            return { ...state, info: action.payload };
        default:
            return state;
    }
}