import { MainSystemActionTypes } from "../constants/action-types";

const initialState = {
    info: {
        connectionState: false,
        remoteToken: null
    }
};
export const mainSystemUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case MainSystemActionTypes.SET_REMOTE_SYSTEM_INFO:
            return { ...state, info: action.payload };
        default:
            return state;
    }
}