import * as React from "react";
import { sinavApi } from '../../Util';
import service from 'axios';
import Globals from '../../Globals';
import DataGrid, {
    Export, ColumnChooser, ColumnFixing, Column, Editing, Paging, Pager, FilterRow, LoadPanel, Grouping, GroupPanel
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import { Position } from 'devextreme-react/data-grid';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { Popover } from 'devextreme-react/popover';
export default class OptikFormTanimi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            allowEditingOgrenciNoAdSoyad: true,
        };
        this.satir = null;
        this.toolTipInfo = null;
        this.optikFormAlan = {};
        this.optikFormAlanlar = [];
        this.formData = {
            Id: 0,
            Yayin: { Id: 0 },
            SinavTuru: { Id: 0 },
            Kullanici: { Id: 0 },
            Adi: "",
            Bilgi: "",
            CiftOturum: false,
            CiftOturumOzel: false,
            HerYayinGorsun: false,
            AdSoyadBir: false,
            Deleted: false,
            RowVersion: 0,
            optikFormAlanlar: [],
            HerDonemGozuksun: false,
            Kds: false
        };
        this.gridMain = null;
        this.gridOptikFormAlan = null;
        this.gridOptikFormParametre = null;
        this.selectBoxSinavTuru = null;
        this.selectBoxYayin = null;
        this.popupForm = null;
        this.chkBoxCiftOturum = null;
        this.chkBoxCiftOturumOzel = null;
        this.chkBoxKds = null;
        this.onInitializedGridMain = this.onInitializedGridMain.bind(this);
        this.onInitializedGridOptikFormParametre = this.onInitializedGridOptikFormParametre.bind(this);
        this.onInitializedGridOptikFormAlan = this.onInitializedGridOptikFormAlan.bind(this);
        this.onInitializedSelectBoxYayin = this.onInitializedSelectBoxYayin.bind(this);
        this.onInitializedSelectBoxSinavTuru = this.onInitializedSelectBoxSinavTuru.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onEditorOptikFormAlanPreparing = this.onEditorOptikFormAlanPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.yeniEkleClick = this.yeniEkleClick.bind(this);
        this.duzenleClick = this.duzenleClick.bind(this);
        this.aktifYapClick = this.aktifYapClick.bind(this);
        this.pasifYapClick = this.pasifYapClick.bind(this);
        this.silClick = this.silClick.bind(this);
        this.sil = this.sil.bind(this);
        this.kopyalaClick = this.kopyalaClick.bind(this);
        this.aktifYap = this.aktifYap.bind(this);
        this.pasifYap = this.pasifYap.bind(this);

        this.popupGizle = () => {
            this.setState({ popup: false });
        };

        this.mainDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'optikform/optikformlar',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadUrl: sinavApi + 'yayin/yayinlarByYetki',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.yayinfilter = { YayinId: 0 };
        this.sinavTuruDataSource = new DataSource(
            {
                store: createStore({
                    key: "Id",
                    loadParams: this.yayinfilter,
                    loadUrl: sinavApi + 'ortak/GetSinavTurleriByYayinId',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.sinavTuruFilter = { SinavTuruId: 0 };
        this.optikFormParametreDataSource = new DataSource(
            {
                paginate: false,
                pageSize: 100,
                store: createStore({
                    key: "Id",
                    loadParams: this.sinavTuruFilter,
                    loadUrl: sinavApi + 'optikform/optikformparametreleri',
                    onBeforeSend: (method, ajaxOptions) => {
                        ajaxOptions.headers = {
                            'Authorization': 'Bearer ' + Globals.service.Token
                        };
                    }
                })
            });

        this.optikFormAlanDataSource = new DataSource({
            store: {
                type: 'array',
                key: 'Id',
                data: this.optikFormAlanlar
            },
            paginate: false
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    elementAttr: {
                        id: "btnInfo"
                    },
                    type: 'default',
                    icon: 'add',
                    visible: Globals.service.Rol === "Yayin" || Globals.service.Rol === "SubeKayitKabul" || Globals.service.Rol === "Sube" ? true : false,
                    onClick: this.yeniEkleClick,
                    onContentReady: (e) => {
                        e.element.addEventListener("mouseover", () => {
                            this.toolTipInfo.instance.option("visible", true);
                        });
                        e.element.addEventListener("mouseleave", () => {
                            this.toolTipInfo.instance.option("visible", false);
                        });
                    },

                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default',
                    hint: 'Düzenle',
                    icon: 'edit',
                    onClick: this.duzenleClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Sil', icon: 'trash',
                    onClick: this.silClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Kopyala', icon: 'unselectall',
                    onClick: this.kopyalaClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'danger', hint: 'Pasif yap', icon: 'isblank',
                    onClick: this.pasifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'success', hint: 'Aktif yap', icon: 'isnotblank',
                    onClick: this.aktifYapClick
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', hint: 'Yenile', icon: 'refresh',
                    onClick: () => {
                        this.gridMain.instance.refresh();
                    }
                }
            },
            {
                location: 'after', locateInMenu: 'auto', widget: 'dxButton', options: {
                    type: 'default', icon: 'home', hint: 'Ana Sayfa',
                    onClick: () => {
                        this.props.history.push("/home");
                    }
                }
            }
        );
    }

    onEditorOptikFormAlanPreparing = (e) => {
        if (e.dataField === "Uzunluk" && e.parentType === "dataRow" && e.row.data.AlanAdi === "OgrenciNo") {
            if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi")
                e.editorOptions.disabled = false;
        }
        else if (e.dataField === "Uzunluk" && e.parentType === "dataRow" && e.row.data.AlanAdi === "Adi") {
            if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi")
                e.editorOptions.disabled = false;
        }
        else if (e.dataField === "Uzunluk" && e.parentType === "dataRow" && e.row.data.AlanAdi === "Soyadi") {
            if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi")
                e.editorOptions.disabled = false;
        }
        else if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") {
            if (e.dataField === "Uzunluk" && e.parentType === "dataRow") {
                e.editorOptions.disabled = true;
            }

        }

        // if ( ( | e.row.data.AlanAdi === 'Adi' | e.row.data.AlanAdi === 'Soyadi')) {
        //     if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi")
        //         e.editorOptions.disabled = false;
        //     else
        //         e.editorOptions.disabled = true;

        // }
    }

    onSelectionChanged(selectedItems) {
        this.satir = selectedItems.selectedRowsData[0];
    }

    yeniEkleClick() {
        if (Globals.service.Rol === "Bayi" | Globals.service.Rol === "AltBayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "Yayin") {
            this.setState({
                allowEditingOgrenciNoAdSoyad: true
            });
        }

        this.formData = {
            Id: 0,
            Yayin: { Id: 0 },
            SinavTuru: { Id: 0 },
            Kullanici: { Id: 0 },
            Adi: "",
            Bilgi: "",
            CiftOturum: false,
            CiftOturumOzel: false,
            HerYayinGorsun: false,
            AdSoyadBir: false,
            Deleted: false,
            RowVersion: 0,
            OptikFormAlanlar: [],
            HerDonemGozuksun: false,
            Kds: false
        };
        this.setState({
            popup: true

        });

    }

    async duzenleClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {

            await service.get(sinavApi + "optikForm/GetOptikForm/" + satir.Id,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Globals.service.Token
                    }
                }).then(async (resForm) => {
                    if (resForm.data.Success) {
                        this.formData = {
                            Id: resForm.data.Data.Id,
                            Yayin: { Id: resForm.data.Data.Yayin.Id },
                            SinavTuru: { Id: resForm.data.Data.SinavTuru.Id },
                            Deleted: resForm.data.Data.Deleted,
                            Adi: resForm.data.Data.Adi,
                            Yetkili: resForm.data.Data.Yetkili,
                            Aciklama: resForm.data.Data.Aciklama,
                            CiftOturum: resForm.data.Data.CiftOturum,
                            CiftOturumOzel: resForm.data.Data.CiftOturumOzel,
                            HerYayinGorsun: resForm.data.Data.HerYayinGorsun,
                            AdSoyadBir: resForm.data.Data.AdSoyadBir,
                            CreatedUserId: resForm.data.Data.CreatedUserId,
                            Kullanici: { Id: resForm.data.Data.Kullanici.Id },
                            RowVersion: resForm.data.Data.RowVersion,
                            HerDonemGozuksun: resForm.data.Data.HerDonemGozuksun,
                            Kds: resForm.data.Data.Kds
                        };

                        await this.yayinDataSource.load();
                        this.selectBoxYayin.instance().option("value", this.formData.Yayin.Id);
                        this.yayinfilter.YayinId = this.formData.Yayin.Id;
                        await this.sinavTuruDataSource.load();
                        this.selectBoxSinavTuru.instance().option("value", this.formData.SinavTuru.Id);
                        this.sinavTuruFilter.SinavTuruId = this.formData.SinavTuru.Id;
                        await this.optikFormParametreDataSource.load();
                        this.gridOptikFormParametre.instance.refresh();
                        await service.get(sinavApi + "optikform/GetOptikFormAlanlari/" + this.formData.Id,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + Globals.service.Token
                                }
                            })
                            .then(async (resAlan) => {
                                if (resAlan.data.Success) {
                                    var existStore = this.optikFormAlanDataSource.store();
                                    existStore.clear();
                                    await this.gridOptikFormAlan.instance.refresh().then(() => {
                                        resAlan.data.Data.forEach((satir, index) => {
                                            this.optikFormAlan = {
                                                OptikForm: { Id: satir.OptikForm_Id },
                                                AlanAdi: satir.AlanAdi,
                                                Id: satir.Id,
                                                Baslangic: satir.Baslangic,
                                                Uzunluk: satir.Uzunluk,
                                                Sira: satir.Sira
                                            };
                                            var store = this.optikFormAlanDataSource.store();
                                            store.insert(this.optikFormAlan);
                                        });

                                    })
                                    await this.gridOptikFormAlan.instance.refresh();
                                }
                                else {
                                    notify(resAlan.data.ErrorMessage, 'error', 5000);
                                }
                            });
                    }
                    else
                        notify(resForm.data.ErrorMessage, "error", 5000);
                });

            await this.setState({
                popup: true
            });
        }
    }

    silClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınızı silmek istiyorsunuz, emin misiniz?<br/><font color='red'>Bunun yerine pasif etmeyi seçebilirsiniz</font></i>", "Silme Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.sil(satir);
                }
            });
        }
    }

    kopyalaClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydını kopyalamk istediğinize emin misiniz?</i>", "Kopyalama Onay");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.kopyala(satir);
                }
            });
        }
    }

    aktifYapClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız aktif hale getirilecektir emin misiniz?</i>", "Aktif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.aktifYap(satir);
                }
            });
        }
    }

    pasifYapClick(e) {
        //const satir = this.mainDataGrid.instance.option('dataSource')[this.mainDataGrid.instance.option('focusedRowIndex')];
        const satir = this.satir;
        if (satir === null || satir === undefined) {
            alert('Satır seçmelisiniz', 'Uyarı');
            return;
        }
        else {
            let result = confirm("<i>Kaydınız pasif hale getirilecektir emin misiniz?</i>", "Pasif etme onayı");
            result.then((dialogResult) => {
                if (dialogResult === true) {
                    this.pasifYap(satir);
                }
            });
        }
    }

    async sil(satir) {
        this.setState({
            loadPanelVisible: true
        });
        service.post(sinavApi + "optikform/optikformsil/" + satir.Id,
            null, {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        })
            .then(res => {
                if (res.data.Success) {
                    notify("Silme işleminiz gerçekleştirildi.", "success", 5000);
                    this.gridMain.instance.refresh();
                }
                else {
                    this.setState({
                        loadPanelVisible: false
                    });
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            });
    }

    async kopyala(satir) {

        service.post(sinavApi + "optikform/kopyala/" + satir.Id,
            null, {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        })
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh();
                }
                else {
                    notify(res.data.ErrorMessage, "error", 5000);
                }
            }).catch(error => {

                notify("Teknik bir hata oluştu,lütfen daha sonra tekrar deneyiniz." + error, "error", 5000);
            });

    }

    async aktifYap(satir) {

        service.post(sinavApi + 'optikform/AktifYap/' + satir.Id, null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh();
                    notify("Kaydınız aktif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    async pasifYap(satir) {
        service.post(sinavApi + 'optikform/PasifYap/' + satir.Id,
            null,
            {
                headers: {
                    'Authorization': 'Bearer ' + Globals.service.Token
                }
            }
        )
            .then(res => {
                if (res.data.Success) {
                    this.gridMain.instance.refresh();
                    notify("Kaydınız pasif hale getirildi.", 'success', 5000);
                }
                else {
                    notify(res.data.ErrorMessage, 'error', 5000);
                }
            });
    }

    onInitializedGridMain(e) {
        this.gridMain = e.component;
    }

    onInitializedGridOptikFormParametre(e) {
        this.gridOptikFormParametre = e.component;
    }

    onInitializedGridOptikFormAlan(e) {
        this.gridOptikFormAlan = e.component;
    }

    onInitializedSelectBoxSinavTuru(e) {
        this.selectBoxSinavTuru = e.component;
    }

    onInitializedSelectBoxYayin(e) {
        this.selectBoxYayin = e.component;
    }



    render() {
        return (
            <React.Fragment>
                <Popover
                    target="#btnInfo"
                    position="top"
                    width={300}
                    showTitle={true}
                    title={"Optik Form Tanımı-Bilgilendirme"}
                    ref={(ref) => this.toolTipInfo = ref}
                >
                    Optik form tanımında Kurum Kodu, Öğrenci No, Kitapçık Türü ve Dersler mutlaka olmalıdır.<br></br>
                </Popover>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <DataGrid
                                showBorders={true}
                                showRowLines={true}
                                dataSource={this.mainDataSource}
                                columnHidingEnabled={true}
                                filterPanel={{ visible: true }}
                                filterRow={{ visible: true }}
                                columnAutoWidth={true}
                                selection={{ mode: 'single' }}
                                autoNavigateToFocusedRow={true}
                                focusedRowEnabled={true}
                                hoverStateEnabled={true}
                                keyExpr="Id"
                                onInitialized={this.onInitializedGridMain}
                                onToolbarPreparing={this.onToolbarPreparing}
                                ref={(ref) => this.gridMain = ref}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                <Export enabled={true} fileName={'Yayinlar'} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <Editing mode={'popup'} />
                                <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                                <GroupPanel visible={true} emptyPanelText="Gruplamak için sütün başlıklarını buraya sürükleyiniz." />
                                <ColumnFixing enabled={true} />
                                <LoadPanel enabled={true} />
                                <FilterRow visible={false} applyFilter={'auto'} />
                                <Paging defaultPageSize={15} enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[15, 30, 45]} showInfo={true} />
                                <Column dataField={'Id'} caption="Kayıt No" visible={true} />
                                <Column dataField={'Deleted'} caption="Pasif" dataType="boolean" />
                                <Column dataField={'Yayin_Adi'} caption="Yayın" />
                                <Column dataField={'SinavTuru_Adi'} caption="Sınav Türü" />
                                <Column dataField={'Adi'} caption="Optik Form" />
                                <Column dataField={'KullaniciAdi'} caption="Ekleyen K." />
                            </DataGrid>

                        </div>
                    </div>

                </div>
                <Popup showTitle={true} shading={false} dragEnabled={true} ref={(ref) => this.popupForm = ref}
                    close_ClickOnOutsideClick={false} popupOptions={{ animation: null }} showCloseButton={false}
                    onHiding={this.popupGizle} visible={this.state.popup}
                    toolbarItems={
                        [
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Kaydet', type: 'success', hint: 'Kaydet', icon: 'check'
                                    , onClick: (e) => {
                                        if (this.formData.CiftOturumOzel) {
                                            this.formData.CiftOturum = true;
                                        }
                                        this.formData.OptikFormAlanlar = this.optikFormAlanDataSource.items();
                                        //this.formData.CiftOturum = this.state.CiftOturum;
                                        service.post(sinavApi + 'optikform/optikformsave',
                                            this.formData,
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Globals.service.Token
                                                }
                                            }
                                        ).then(res => {
                                            if (res.data.Success) {
                                                this.gridMain.instance.refresh().then(() => {
                                                    this.setState({ popup: false })
                                                });
                                            }
                                            else {
                                                notify(res.data.ErrorMessage, 'error', 5000);
                                            }

                                        });
                                    }
                                }
                            },
                            {
                                location: 'after', widget: 'dxButton', options: {
                                    text: 'Vazgeç', type: 'danger', hint: 'Çıkış', icon: 'runner'
                                    , onClick: () => { this.popupGizle(); }
                                }
                            }

                        ]
                    }
                >
                    <Position my={'center'} at={'center'} of={window} />
                    <ScrollView id={'scrollview'} showScrollBarMode='onScroll' scrollByContent={true} scrollByThumb={true} bounceEnabled={false}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form formData={this.formData}>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Optik Form  Bilgileri">
                                        <SimpleItem dataField={"Yayin.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.yayinDataSource,
                                                searchEnabled: true,
                                                displayExpr: "Adi",
                                                valueExpr: "Id",
                                                onInitialized: this.onInitializedSelectBoxYayin,
                                                ref: (ref) => { this.selectBoxYayin = ref },
                                                onValueChanged: (e) => {
                                                    this.yayinfilter.YayinId = e.value;
                                                    this.sinavTuruDataSource.load();
                                                }
                                            }}>
                                            <Label text="Yayın" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"SinavTuru.Id"}
                                            editorType="dxSelectBox"
                                            editorOptions={{
                                                dataSource: this.sinavTuruDataSource,
                                                searchEnabled: true,
                                                displayExpr: "SinavTuruAdi",
                                                valueExpr: "SinavTuru.Id",
                                                onInitialized: this.onInitializedSelectBoxSinavTuru,
                                                ref: (ref) => { this.selectBoxSinavTuru = ref },
                                                onValueChanged: (e) => {
                                                    this.sinavTuruFilter.SinavTuruId = e.value;
                                                    this.optikFormParametreDataSource.load();
                                                    this.gridOptikFormParametre.instance.refresh();
                                                    var store = this.optikFormAlanDataSource.store();
                                                    store.clear();
                                                    this.gridOptikFormAlan.instance.refresh();
                                                }
                                            }}>
                                            <Label text="Form Türü" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Adi"} isRequired={true}>
                                            <Label text="Adı" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Aciklama"}>
                                            <Label text="Açıklama" />
                                        </SimpleItem>

                                        <SimpleItem dataField={"CiftOturum"}
                                            editorType="dxCheckBox" editorOptions={{
                                                disabled: (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") ? true : false,
                                                onInitialized: (e) => {
                                                    this.chkBoxCiftOturum = e.component;
                                                },
                                                onValueChanged: (e) => {
                                                    if (e.value) {
                                                        this.chkBoxCiftOturumOzel.instance().option("value", true);
                                                    }
                                                },
                                                ref: (ref) => { this.chkBoxCiftOturum = ref }
                                            }}>
                                            <Label text="Çift Oturum" />
                                        </SimpleItem>

                                        <SimpleItem dataField={"AdSoyadBir"} isRequired={true} editorType="dxCheckBox">
                                            <Label text="Ad Soyad Birleşik" />
                                        </SimpleItem>

                                        <SimpleItem dataField={"CiftOturumOzel"} editorType="dxCheckBox" editorOptions={{
                                            disabled: (Globals.service.Rol === "Bayi" | Globals.service.Rol === "Kurum" | Globals.service.Rol === "AltBayi") ? true : false,
                                            onInitialized: (e) => {
                                                this.chkBoxCiftOturumOzel = e.component;
                                            },
                                            onValueChanged: (e) => {
                                                if (e.value) {
                                                    this.chkBoxCiftOturum.instance().option("value", true);
                                                }
                                            },
                                            ref: (ref) => { this.chkBoxCiftOturumOzel = ref }
                                        }}>
                                            <Label text="Çift Oturum Özel" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"HerYayinGorsun"} isRequired={true} editorType="dxCheckBox" editorOptions={{
                                            visible: Globals.service.Rol === "Admin" ? true : false
                                        }}>
                                            <Label text="Tüm Yayınlarda Gözüksün" visible={Globals.service.Rol === "Admin" ? true : false} />
                                        </SimpleItem>
                                        <SimpleItem dataField={"HerDonemGozuksun"} editorType="dxCheckBox" >
                                            <Label text="Her Donem Görünsün" />
                                        </SimpleItem>
                                        <SimpleItem dataField={"Kds"} editorType="dxCheckBox" editorOptions={{
                                            onInitialized: (e) => {
                                                this.chkBoxKds = e.component;
                                            },
                                            onValueChanged: (e) => {
                                                if (e.value) {
                                                    this.chkBoxKds.instance().option("value", true);
                                                }
                                            },
                                            ref: (ref) => { this.chkBoxKds = ref }
                                        }}>
                                            <Label text="Kds Optik Form" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem cssClass="first-group" colCount={2} caption="Optik Form  Alanları">
                                        <SimpleItem>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.optikFormParametreDataSource}
                                                columnHidingEnabled={true}
                                                keyExpr="Id"
                                                onRowClick={(e) => {

                                                    if (this.optikFormAlanDataSource.items().filter(x => x.AlanAdi === e.data.Kodu).length > 0) {
                                                        notify(e.data.Kodu + " alanı eklenmiş", "warning", 2000);
                                                        return;
                                                    }
                                                    else {
                                                        if (Globals.service.Rol === "Yayin" || Globals.service.Rol === "SubeKayitKabul" || Globals.service.Rol === "Sube") {
                                                            this.optikFormAlan = {
                                                                OptikForm: { Id: 0 },
                                                                AlanAdi: e.data.Kodu,
                                                                Id: e.data.Id,
                                                                Baslangic: 1,
                                                                Uzunluk: 1,
                                                                Sira: e.data.Sira
                                                            };
                                                            var store = this.optikFormAlanDataSource.store();

                                                            store.insert(this.optikFormAlan);
                                                            this.gridOptikFormAlan.instance.refresh();
                                                        }
                                                    }
                                                }}
                                                paging={{
                                                    pageSize: 50
                                                }}
                                                onInitialized={this.onInitializedGridOptikFormParametre}
                                                ref={(ref) => this.gridOptikFormParametre = ref}
                                            >
                                                <Editing mode={'inline'} />
                                                <ColumnFixing enabled={true} />
                                                <LoadPanel enabled={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'Adi'} caption="Adı" />
                                                <Column dataField={'Kodu'} caption="Kodu" />
                                            </DataGrid>
                                        </SimpleItem>
                                        <SimpleItem>
                                            <DataGrid
                                                showBorders={true}
                                                showRowLines={true}
                                                dataSource={this.optikFormAlanDataSource}
                                                columnHidingEnabled={true}
                                                keyExpr="Id"
                                                ref={(ref) => this.gridOptikFormAlan = ref}
                                                onInitialized={this.onInitializedGridOptikFormAlan}
                                                onEditorPreparing={this.onEditorOptikFormAlanPreparing}
                                            >
                                                <Editing mode={'cell'} useIcons={true} allowUpdating={true} allowDeleting={Globals.service.Rol === "Yayin" || Globals.service.Rol === "SubeKayitKabul" || Globals.service.Rol === "Sube" ? true : false} />
                                                <LoadPanel enabled={true} />
                                                <Column dataField={'Id'} caption="Id" visible={false} />
                                                <Column dataField={'AlanAdi'} caption="Kodu" allowEditing={false} />
                                                <Column dataField={'Baslangic'} caption="Başlangıç" dataType="number" allowEditing={true} />
                                                <Column dataField={'Uzunluk'} caption="Uzunluk" dataType="number" editorOptions={{
                                                    disabled: false
                                                }} />
                                            </DataGrid>
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}