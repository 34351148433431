import Globals from './Globals';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import service from 'axios';
import DataSource from 'devextreme/data/data_source';
import axios from 'axios';
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
export const authApi = window.ayarlar.type === "developer" ? window.ayarlar.localauth : window.ayarlar.prodauth;
export const sinavApi = window.ayarlar.type === "developer" ? window.ayarlar.localsinav : window.ayarlar.prodsinav;
export const karneApi = window.ayarlar.type === "developer" ? window.ayarlar.localkarne : window.ayarlar.prodkarne;
export const otomasyonApi = window.ayarlar.type === "developer" ? window.ayarlar.localotomasyon : window.ayarlar.prodotomasyon;
export const cacheApi = window.ayarlar.type === "developer" ? window.ayarlar.localcache : window.ayarlar.prodcache;
export const crmApi = window.ayarlar.type === "developer" ? window.ayarlar.localcrm : window.ayarlar.prodcrm;
export const olusturulanListeKarneDosyasi = window.ayarlar.type === "developer" ? window.ayarlar.localOlusturulanListeKarneDosyasi : window.ayarlar.prodOlusturulanListeKarneDosyasi;
export const dokumanUrl = window.ayarlar.type === "developer" ? window.ayarlar.localDokumanUrl : window.ayarlar.prodDokumanUrl;
export const sinavRealApi = window.ayarlar.sinavRealApi;
export const yayinGatewayUrl = window.ayarlar.yayinGatewayUrl;
export const yayinSinavApi = window.ayarlar.yayinSinavApi;
export const yayinKarneApi = window.ayarlar.yayinKarneApi;
//#region  consts
export const selectedStudentRows = "selectedStudentRows";
//#endregion
export function errorMessageWithKonum(msg, konum) {
    return "Teknik bir hata oluştu, " + konum + "getirilemedi.Hata Detayı:<font style='color:red;font-weight:bold'>" + msg + "</font>";
}

export function errorMessage(msg) {
    return "Teknik bir hata oluştu, hata Detayı:<font style='color:red;font-weight:bold'>" + msg + "</font>";
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

export async function sendRequest(url, method, data) {
    var a = await service.post(url, data, {
        headers: {
            'Authorization': 'Bearer ' + Globals.service.Token
        }
    })
        .then(res => {
            if (res.data.Success) {

            }
            else {
                throw res.data.ErrorMessage;
            }
        })
        .catch(err => {
            throw err;
        })
    return a;
}

var parameters = null;
function loadx(loadOptions, url) {
    let params = '?';
    [
        'filter',
        'group',
        'groupSummary',
        'parentIds',
        'requireTotalCount',
        'searchExpr',
        'searchOperation',
        'searchValue',
        'select',
        'sort',
        'skip',
        'take',
    ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
    });
    params = params.slice(0, -1);
    parameters = params;
    return service.get(url + parameters,
        {
            headers: {
                'Authorization': 'Bearer ' + Globals.service.Token
            }
        })
        .then(response => {
            return {
                data: response.data.data,
                totalCount: response.data.totalCount
            };
        })
        .catch((err) => { notify(err, "error", 5000) });
}

export function GetStore(primaryKey
    , tabloAdi
    , loadUrl
    , insertUrl
    , updateUrl
    , deleteUrl) {
    return new DataSource({
        store: new CustomStore({
            key: primaryKey,
            cacheRawData: false,
            load: async (loadOptions) => {
                return loadx(loadOptions, loadUrl);
            },
            insert: async (values) => {
                return await sendRequest(insertUrl, "POST", {
                    values: JSON.stringify(values),
                    tablo: tabloAdi
                })
                    .then(() => {
                        notify("İşleminiz gerçekleşti", "success", 3000);
                    })
                    .catch(err => {
                        notify("İşleminiz gerçekleşmedi,hata ayrıntısı:" + err, "error", 3000);
                        throw err;
                    });
            },
            update: async (key, values) => {
                return await sendRequest(updateUrl, "POST", {
                    key: key,
                    values: JSON.stringify(values),
                    tablo: tabloAdi
                }).then((res) => {
                    notify("İşleminiz gerçekleşti", "success", 3000);
                })
                    .catch(err => {
                        notify("İşleminiz gerçekleşmedi,hata ayrıntısı:" + err, "error", 3000);
                        throw err;
                    });
            },
            remove: async (key) => {
                return sendRequest(deleteUrl, "POST", {
                    key: key,
                    tablo: tabloAdi
                }).then((res) => {
                    notify("İşleminiz gerçekleşti", "success", 3000);
                })
                    .catch(err => {
                        notify("İşleminiz gerçekleşmedi,hata ayrıntısı:" + err, "error", 3000);
                        throw err;
                    });
            },
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.headers = {
                    'Authorization': 'Bearer ' + Globals.service.Token
                };
            }
        })
    })
};

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

// async function refreshAccessToken() {
//     try {
//         debugger;
//         let oturum = JSON.parse(localStorage.getItem("oturum"));
//         const response = await axios.post(authApi + "auth/refresh", {
//             RefreshToken: oturum.RefreshToken,
//             AccessToken: oturum.Token
//         });
//         Auth.login(() => {
//             var localStoregeData = {};
//             response.data.AdSoyad = response.data.Kullanici.Adi + " " + response.data.Kullanici.Soyadi;
//             response.data.Menu = response.data.Kullanici.KullaniciMenuler;
//             response.data.Rol = response.data.Kullanici.Rol;
//             response.data.Id = response.data.Kullanici.Id;
//             response.data.DonemId = response.data.Kullanici.Donem.Id;
//             response.data.DonemAdi = response.data.Kullanici.DonemAdi;
//             localStorage.setItem("oturum", JSON.stringify(response.data));
//             // Globals.AdSoyad = localStoregeData.Kullanici.Adi + " " + localStoregeData.Kullanici.Soyadi;
//             // Globals.menuler = localStoregeData.Kullanici.KullaniciMenuler;
//             Globals.service = localStoregeData;
//             accessToken = response.data.Token;
//         });

//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// }

// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         debugger;
//         const originalRequest = error.config;

//         if (error.response.status === 401) {
//             alert("Oturum süreniz dolmuş, tekrar giriş yapmak zorundasınız. Giriş sayfasına yönlendiriliyorsunuz...")
//             window.location.href = "/login";
//             // originalRequest._retry = true;
//             // return refreshAccessToken().then(() => {
//             //     let oturum = JSON.parse(localStorage.getItem("oturum"));
//             //     originalRequest.headers.Authorization = `Bearer ${oturum.Token}`;
//             //     return axios(originalRequest);
//             // });
//         }

//         return Promise.reject(error);
//     }
// );

